@import '../shared/styles/variables';

.ufr-item-page {
  #breadcrumbs ol .crumb:not(.current-crumb) {
    a {
      color: @uberPink;
    }
  }

  .ufr-input-label {
    display: inline-block;
  }

  .ufr-item-info {
    display: flex;
  }

  #ufr-page-header .ufr-page-header-title {
    padding-top: 0;
  }
}
