@import '../../shared/styles/variables';

.ufr-character-count {
  height: 16px;
  margin-bottom: -16px;
  position: relative;
  width: 50px;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  background: #eee;
  color: #333;
  padding: 3px 3px 4px;
  font-family: @roboto;
}
