.link-input {
  display: flex;
  gap: 4px;
}
.link-input .ufr-input-container {
  width: 80px;
}
.link-input .ufr-input-container .ufr-select-dropdown {
  width: 80px;
  height: 32px;
  border: none;
}
.link-input .ufr-input-container .ufr-select-dropdown .ufr-dropdown-button {
  width: 80px;
  height: 32px;
  border: solid 1px #ccc;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.link-input .ufr-input-container .ufr-select-dropdown .ufr-dropdown-button .ufr-dropdown-selected-item {
  font-size: 13px;
}
.link-input .ufr-input-container .ufr-select-dropdown .ufr-select-dropdown-container {
  left: 0px;
  border: solid 1px #ccc;
  border-top: 1px solid white;
}
.link-input .ufr-input-container .ufr-select-dropdown .ufr-select-dropdown-container .ufr-select-dropdown-item-container {
  width: 78px;
}
.link-input .ufr-input-container .ufr-select-dropdown .ufr-select-dropdown-container .ufr-select-dropdown-item-container .ufr-select-dropdown-item {
  width: 78px;
  font-size: 13px;
}
.link-input .ufr-input-container .react-input-description {
  margin: 0;
}
.link-input #link-url-ufr-input-container {
  flex-grow: 1;
}
.link-input .ufr-input-container .ufr-text-inner {
  height: 32px;
}
.link-input .ufr-input-inner .ufr-input-component {
  font-size: 13px;
  width: 100%;
}
.link-input-label {
  font-size: 13px;
  font-weight: 500;
  color: #555;
}
