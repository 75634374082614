@import "../../../shared/styles/base";

.ufr-tag-modal {
  .ufr-modal-header {
    padding-bottom: 15px;

    .ufr-modal-header-title {
      margin-bottom: 10px;
    }
  }

  .ufr-input-container {
    .ufr-input-inner {
      .ufr-input-component {
        width: 100%;
      }

      .ufr-input-component::placeholder {
        color: @placeholderGray;
      }
    }

    .ufr-textarea-inner {
      .ufr-input-component {
        width: 100%;
      }
    }
  }
}
