
// ***************************************************************************************
// Hubs Component Z-Indexes
//  **note: all z-indexes are grouped here for easy management

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Normal State:
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Content Layer (1-999):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.tile.single .icon { z-index: 1; }
.tile.single > a { z-index: 4; }
.tile.single > a.view { z-index: 6; }

.cta-item-container .cta { z-index: 1; }
.cta .hidden-cta-fields { z-index: 5; }
.cta .cta-form-response { z-index: 5; }
.cta .run-away { z-index: 6; }
.cta .submission-loader-container { z-index: 12; }
.cta .submission-loader-container img { z-index: 1; }

.blocking-cta.prevent-interaction { z-index: 2; }

.share-single,
.share-item.type-single { z-index: 7; }

.search-results-overlay .mobile-search-header { z-index: 1; }

.share-social,
.share-twitter,
.share-item { z-index: 401; }

// Top Menu Layer (1000-1999):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.top-nav { z-index: 1000; }
.top-nav .secondary-logo { z-index: 1100; }
.top-nav .left-nav.desktop { z-index: 1200; }

.top-nav .nav-back,
.top-nav .nav-toggle,
.top-nav .right-side-btns { z-index: 1300; }

// Large Header Layer (2000-2999):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.large-header { z-index: 2000; }

// Left Menu Layer (3000-3999):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.left-nav.desktop { z-index: 3000; }

.left-nav > li ul { z-index: 36; }
.left-nav > li .sub-menu-arrow { z-index: 41; }

// Overlay Layer (4000-4999):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.search-results-backdrop { z-index: 4200; }
.search-results-overlay { z-index: 4250; }
.search-drop-down { z-index: 4300; }

.share-hub,
.share-item { z-index: 4400; }

.mobile-nav,
.mobile-share { z-index: 4500; }

#colorbox,
#cboxOverlay,
#cboxWrapper { z-index: 4700; }

// Fullscreen Layer (7000-7999):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.cta.full-screen-cta { z-index: 7000; }
.cta.full-screen-cta .run-away { z-index: 10; }
.cta.full-screen-cta .hidden-cta-fields { z-index: 9; }

.document-container { z-index: 7100; }
.document-container .document-control { z-index: 7200; }

// Loading Layer (8000-8999):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

#loading-overlay { z-index: 8000; }

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Lab State (Sticky Footer):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

body.lab_sticky_footer {
  // Content Layer:
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  > footer { z-index: 999; }
  .expired-trial { z-index: 1000; }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Lab State (Permanent Header)
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

body.lab_permanent_header {
  // Overlay Layer:
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  .search-results-backdrop { z-index: 5200; }
  .search-results-overlay { z-index: 5250; }
  .search-drop-down { z-index: 5300; }

  // When Results are Opened:
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  &.search-results-opened {
    .KeepNavAtTop;
  }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Lab State (Top Menu Always Visible):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

body.lab_nav_always_top {
  .KeepNavAtTop;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Local Mixin (Keep Nav At Top):
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.KeepNavAtTop {
  // Menu Layer:
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  .top-nav { z-index: 6000; }

  .share-hub,
  .share-item,
  .search-drop-down,
  .mobile-nav,
  .mobile-share { z-index: 6100; }

  .search-results-backdrop { z-index: 6500; }

  .search-results-overlay { z-index: 6600; }

  .search-drop-down { z-index: 6700; }
}

