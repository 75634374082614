@import (reference) "../../shared/_variables.less";

#s2id_connect-purpose-select {
  .select2-choice {
    background: white;
    border-radius: 3px;
    border: 1px solid #ccc;
    height: 50px;
    padding-left: 15px;
    padding-top: 10px;
  }

  .select2-arrow {
    background: white;
    border: none;
    padding-top: 10px;
  }
}

.select2-container-active .select2-choice {
  border: 1px solid #5897fb !important;
}

#select2-drop {
  .select2-highlighted {
    background: none;
    color: @uberPinkDark;
  }
}

.select2-drop-active {
  border: 1px solid #5897fb !important;
}

.select2-results li {
  padding-left: 5px;
}
