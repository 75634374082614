.ufr-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 26px;
  background: #fff;
  min-height: 62px;

  @media all and (max-width: 450px) {
    flex-direction: column;

    * {
      text-align: center;
    }

    .modal-v3-footer {
      min-height: 94px;
    }

    .ufr-btn-back {
      margin: 0 auto;
    }
  }
}
