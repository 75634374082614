@import '../styles/variables';

.editable-text {
  width: 100%;
  overflow-wrap: anywhere;

  > * {
    display: inline-block;
  }

  .editable-text-static-header {
    padding-left: 1px;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  &.large-text {
    .editable-text-static-header {
      line-height: @pageTitleLineHeight;
      padding: 6px 5px 6px 1px;

      // Make room for the edit button to float to the right
      max-width: calc(~'100% - ' @largeInlineInputHeight + 2);
    }

    &.editing-mode {
      max-height: @largeInlineInputHeight;
    }
  }

  &.medium-text {
    .editable-text-static-header {
      line-height: @pageTitleMediumLineHeight;
      padding: 6px 5px 6px 1px;

      // Make room for the edit button to float to the right
      max-width: calc(~'100% - ' @pageTitleMediumLineHeight + 2);
    }

    &.editing-mode {
      max-height: @largeInlineInputHeight;
    }
  }

  &.small-text {
    .editable-text-static-header {
      line-height: @pageSubtitleLineHeight;
      padding: 4.5px 5px 4.5px 1px;

      // Make room for the edit button to float to the right
      max-width: calc(~'100% - ' @smallInlineInputHeight + 2);
    }

    &.editing-mode {
      max-height: @smallInlineInputHeight;
    }
  }
}
