.ufr-modal-navbar-container {
  display: flex;
  height: 100%;
  width: 320px;
  flex-direction: column;
  align-items: flex-start;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #d4e0e8;
  background-color: #fff;
}
.ufr-modal-navbar-container .ufr-modal-close-container {
  display: flex;
  height: 72px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  padding: 1rem 0.5rem;
}
.ufr-modal-navbar-container .ufr-modal-close-container .ufr-modal-close-button {
  display: flex;
  height: 40px;
  width: 105px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1.25rem 0.5rem 1rem;
  background-color: transparent;
  background-image: none;
  border: none;
}
.ufr-modal-navbar-container .ufr-modal-close-container .ufr-modal-close-button .close-icon {
  stroke: #364a87;
  stroke-width: 1.5px;
}
.ufr-modal-navbar-container .ufr-modal-close-container .ufr-modal-close-button .ufr-modal-close-button-text {
  font-family: sans-serif;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-style: normal;
  color: #364a87;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.ufr-modal-navbar-container .ufr-modal-sections-container {
  display: flex;
  height: 332px;
  width: 320px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.ufr-modal-navbar-container .ufr-modal-sections-container .ufr-modal-sections-text-container {
  display: flex;
  height: 68px;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.625rem;
  padding: 1.5rem 1rem 1rem 1.5rem;
}
.ufr-modal-navbar-container .ufr-modal-sections-container .ufr-modal-sections-text-container .ufr-modal-sections-text {
  height: 28px;
  width: 280px;
  font-family: sans-serif;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #030d36;
}
.ufr-modal-navbar-container .ufr-modal-sections-pages-container {
  display: flex;
  height: 248px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.ufr-modal-navbar-container .ufr-modal-sections-pages-container .ufr-modal-sections-page-outer-container {
  display: flex;
  height: 56px;
  width: 320px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  padding: 0.25rem 0.5rem;
}
.ufr-modal-navbar-container .ufr-modal-sections-pages-container .ufr-modal-sections-page-outer-container .ufr-modal-sections-page-middle-container {
  display: flex;
  height: 48px;
  width: 304px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3rem;
  padding: 0.25rem 1rem;
}
.ufr-modal-navbar-container .ufr-modal-sections-pages-container .ufr-modal-sections-page-outer-container .ufr-modal-sections-page-middle-container .ufr-modal-sections-page-inner-container {
  display: flex;
  height: 40px;
  width: 272px;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  background-image: none;
  border: none;
}
.ufr-modal-navbar-container .ufr-modal-sections-pages-container .ufr-modal-sections-page-outer-container .ufr-modal-sections-page-middle-container .ufr-modal-sections-page-inner-container .ufr-modal-sections-page-text {
  height: 24px;
  width: 224px;
  text-align: left;
  font-family: sans-serif;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #364a87;
  margin-block-start: 1.5em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.ufr-modal-navbar-container .ufr-modal-sections-pages-container .ufr-modal-sections-page-outer-container .ufr-modal-sections-page-middle-container .ufr-modal-sections-page-inner-container .ufr-modal-sections-page-text.ufr-modal-text-active {
  color: #ce0058;
}
.ufr-modal-navbar-container .ufr-modal-sections-pages-container .ufr-modal-sections-page-outer-container .ufr-modal-sections-page-middle-container.ufr-modal-container-active {
  background-color: #ce005814;
}
