@import '../../shared/styles/base';
@import '../../shared/styles/mixins';

.sb-show-main,
div[data-hypernova-key='Cta15ListingPage'] {
  #ufr-calls-to-action-data-table {
    font-family: @roboto;
    font-size: 13px;

    .react-btn-dropdown {
      min-width: 132px;
      border-right: 1px solid #ccc;
      padding: 4px 8px;
      height: 41px;
      white-space: nowrap;
    }

    .ReactTable.ufr-react-table {
      min-height: initial;
    }

    .rt-table,
    .rt-tbody {
      overflow: visible;
    }

    .ufr-dt-cta-listing-cta-tagline-header,
    .ufr-dt-cta-listing-cta-tagline-cell {
      padding-left: 19px;
    }

    .ufr-dt-cta-listing-cta-id-header,
    .ufr-dt-cta-listing-cta-id-cell {
      padding-left: 27px;
    }

    .ufr-dt-cta-listing-cta-status-header,
    .ufr-dt-cta-listing-cta-status-cell {
      padding-left: 19px;
    }

    .rt-tr-group {
      .ufr-dt-cta-listing-cta-name-cell {
        display: flex;
        align-items: center;

        a {
          color: @fontColorDark;
          font-weight: 500;
        }
      }

      .ufr-dt-cta-listing-cta-preview-cell {
        display: flex;
        align-items: center;

        a {
          color: @uberPink;
          font-size: 11px;
        }
      }

      .ufr-dt-cta-listing-buttons-cell {
        .ufr-modal-header {
          padding: 12px 17px 12px 26px;

          h4 {
            line-height: 30px;
            margin-top: 8px;
          }

          button {
            font-family: 'Glyphicons Regular', serif;
            font-size: 11px;
            line-height: 11px;
            padding: 5px;
            opacity: 1;

            &:hover:before {
              color: @fontColorDark;
            }

            &:before {
              content: '\E208';
              color: #ccc;
              display: block;
            }

            span {
              display: none;
            }
          }
        }

        .ufr-modal-body {
          padding: 29px 23px 18px 23px;

          p {
            font-family: @roboto;
            font-size: 13px;
            line-height: 19px;
          }

          .cta-name-highlight {
            font-weight: 500;
          }
        }

        .ufr-modal-footer {
          padding-right: 20px;

          .ufr-btn + .ufr-btn {
            margin-left: 8px;
          }
        }
      }

      .ufr-cta-truncated-cell-value {
        width: auto;
        max-width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .rt-noData {
      .no-data-create-cta {
        font-weight: 500;
      }

      #ufr-no-data-create-cta-btn {
        margin-top: 22px;
      }
    }
  }

  .ufr-pagination select {
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .ufr-toaster p {
    font-family: @roboto;
    font-size: 16px;
  }
}

div[id^='full-value-name-'],
div[id^='full-value-tagline-'] {
  max-width: 100%;
  word-break: break-all;
}
