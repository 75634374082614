.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.chevron-after {
  content: '';
  position: absolute;
  top: 11px;
  width: 0;
  height: 0;
  margin-left: 8px;
  vertical-align: middle;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.ufr-react-table.ReactTable {
  margin-top: 25px;
  border: none;
}
.ufr-react-table.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}
.ufr-react-table.ReactTable .rt-thead.-header .rt-th {
  border: none;
  box-shadow: none;
  transition: none;
  flex: none;
  text-align: left;
}
.ufr-react-table.ReactTable .rt-thead.-header .rt-th.-cursor-pointer:hover {
  color: #ce0058;
}
.ufr-react-table.ReactTable .rt-thead.-header .rt-th .header-div {
  display: inline-block;
}
.ufr-react-table.ReactTable .rt-thead.-header .rt-th > div {
  display: inline-block;
}
.ufr-react-table.ReactTable .rt-thead.-header .rt-th > div:focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-react-table.ReactTable .rt-thead.-header .rt-th.-sort-asc > div:first-child::after {
  content: '';
  position: absolute;
  top: 11px;
  width: 0;
  height: 0;
  margin-left: 8px;
  vertical-align: middle;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 7px dashed;
}
.ufr-react-table.ReactTable .rt-thead.-header .rt-th.-sort-desc > div:first-child {
  display: inline-block;
}
.ufr-react-table.ReactTable .rt-thead.-header .rt-th.-sort-desc > div:first-child::after {
  content: '';
  position: absolute;
  top: 11px;
  width: 0;
  height: 0;
  margin-left: 8px;
  vertical-align: middle;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 7px dashed;
}
.ufr-react-table.ReactTable .rt-tbody .rt-td,
.ufr-react-table.ReactTable .rt-tbody .rt-tr-group {
  border: none;
}
.ufr-react-table.ReactTable .rt-tbody .rt-tr-group:hover {
  background: #f9f9f9;
}
