@import '../../shared/styles/variables.less';

#tile-design-accordion {
  // max-width: 305px;
  #tile-design-accordion-content {
    padding: 0 5px 0 5px;
  }

  #tile-design-button-label-text-area-ufr-input-container {
    .react-input-title {
      // flex-grow: 1;
      margin: 3px 38px 4px 0;
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #555;
    }

    .react-input-description {
      margin: 0;
    }

    .ufr-input-wrapper {
      .ufr-input-component {
        width: 100%;
      }
    }
  }

  #tile-design-background-image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0 10px 0;

    & > label {
      display: flex;
      align-items: center;
      margin: 3px 0 4px 0;
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #555;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    #ufr-tile-design-background-image-upload-file-btn {
      color: @fontColorDark;
      background: white;
      border-color: #979797;
      margin-left: 2px;

      &:hover {
        background-color: @uberPinkBlush;
      }
    }

    #ufr-tile-design-background-image-remove-btn {
      color: @fontColorDark;
      background: white;
      border-color: #979797;
      margin-left: 2px;

      &:hover {
        background-color: @uberPinkBlush;
      }
    }
  }

  .ufr-color-picker-label-cta {
    display: flex;
    align-items: center;
  }
}
