@import "../../../shared/styles/base";
@import "../../../shared/styles/mixins";

#ufr-privacy-group-app-functionality-data-table {
  .rt-table {
    overflow: unset;

    .rt-tbody {
      overflow: unset;
    }
  }

  .rt-tr {
    align-items: inherit;
  }

  .rt-tr-group {
    margin-top: 15px;

    .ufr-dt-privacy-groups-app-functionality-listing-app-name-cell {
      .ufr-app {
        display: flex;
        align-items: center;
      }

      .ufr-app-image {
        background-size: cover;
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }

      .ufr-app-name {
        max-width: 90%;
      }
    }

    .ufr-dt-privacy-groups-app-functionality-listing-privacy-group-select-cell {
      padding-top: 0;

      .ufr-privacy-group-selector {
        width: 300px;
      }
    }
  }
}

