@import "../../shared/styles/variables";
@import "../../shared/styles/mixins";

.ufr-toggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;

  .ufr-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 100px;

    &:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 100px;
    }
  }

  &.active .ufr-toggle-slider {
    background-color: #7db33e;
    box-shadow: 0 0 1px #7db33e;

    &:before {
      -webkit-transform: translateX(23px);
      -ms-transform: translateX(23px);
      transform: translateX(23px);
    }
  }

  &.disabled {
    cursor: not-allowed;

    .ufr-toggle-slider {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &:focus {
    border-radius: 100px;
    .focus-outline;
  }
}
