// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// ----------------------------------------------------------------------------------------------------------
// Hubs Content Management Section
// ----------------------------------------------------------------------------------------------------------

.mgmt-container {
  .content-container.hubs-sections-embed {

    // --------------------------------------------------------------------------------------------------
    // Navbar on all Appearance Pages

    .embed-navbar {
      span.nb-icon {
        i {
          background-image: url("/img/hubs/backend/spritesheet.png");
        }

        &.nb-embed-hub i { background-position: -444px -283px; }
        &.nb-embed-iframe i { background-position: -285px -286px; }
        &.nb-embed-close i { background-position: -126px -283px; }
      }

      .nav {
        .active > a:not(:hover) {
          .nb-embed-hub i { background-position: -444px -321px; }
          .nb-embed-iframe i { background-position: -285px -323px; }
          .nb-embed-close i { background-position: -127px -322px; }
        }
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Tab Panes

    .tab-pane {
      margin: 0 auto;

      &#hubs-embed-iframe,
      &#hubs-embed-collection {
        max-width: 1120px;

        .col-md-5 { width: 37%; }
        .col-md-7 { width: 60%; }
      }
    }

    .embed-configure,
    .embed-preview {
      .embed-header {
        h3 {
          color: #555;
          text-align: center;

          i {
            margin-top: 3px;
          }
        }
      }

      .form-inline,
      .form-horizontal {
        min-height: 290px;
        border: 2px solid #ddd;
        padding: 0px 10px;
      }

      .form-horizontal {
        .input-lock-to-url {
          display: inline-block;
          width: 365px;
        }

        #embedIframeLockBtn {
          display: inline-block;
          margin-top: -5px;
        }
      }

      .embed-body {
        padding: 10px 0 0 0;

        textarea {
          width: 100%;
          min-height: 170px;
          margin: 6px 0;
          font-size: 10px;
          .transition(all linear 0.2s);

          &:focus {
            background: #f5f5f5;
            border-color: #ddd;
            .box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
          }
        }

        .embedIframeTextarea {
          min-height: 187px;
        }

        .longNote {
          margin: 1px 0 0 0;
          padding: 1px 6px 1px 24px;
        }
      }

      .preview-block {
        width: 100%;
        height: 380px;
        border-width: 2px;
        text-align: center;
        overflow: hidden;

        .mixed-content-help {
          display: none;
          margin: 20px;
          padding: 20px 20px 0 20px;
          background: #f9f9f9;
        }

        &.allow-overflow {
          overflow: auto;
        }
      }

      .alert { margin: 0 0 10px 0; }

      .inline-label {
        font-weight: normal;
        color: #444;
        font-size: 12.5px;
        padding: 0;
        cursor: pointer;

        input {
          position: static;
          margin: 0 10px 0 0;
        }
      }

      .form-group {
        margin-left: 0;
        margin-right: 0;
        .clearfix;

        > label {
          color: #777;
          margin: 10px 0 5px 0;
        }

        .control-label {
          width: 140px;
        }

        .note { margin: 0; }

        .btn.pull-right {
          margin-top: 10px;
        }
      }

      #heg_form {
        .form-group {
          .control-label {
            width: 120px;
          }

          .controls {
            margin-left: 140px;
          }
        }
      }
    }

    #hubs-embed-collection {
      .form-horizontal {
        .form-group {
          margin-bottom: 10px;

          .controls,
          .control-label {
            display: inline-block;
            margin: 0;
          }
        }
      }
    }

    #hubs-embed-iframe {
      .alert {
        margin-left: 0;
      }

      .embed-configure {
        .form-horizontal {
          height: 180px;
        }
      }

      .embed-preview {
        .preview-block { height: auto; }
      }

      .embed-configure,
      .embed-preview {
        .form-horizontal {
          height: 200px;
          margin-bottom: 20px;

          .embed-body {
            textarea {
              height: 130px;
            }
          }
        }
      }
    }
  }
}
