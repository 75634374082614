@import "../../../shared/styles/variables";
@import "../../../shared/styles/mixins";

.success-cta-accordion-content {
  margin-top: 20px;

  .ufr-input-container {
    padding: 0 0 0 0 !important;

    .react-input-title {
      font-size: 13px;
      font-weight: 500;
    }

    .ufr-input-wrapper {
      textarea {
        margin-top: 5px;
      }
    }

    .react-input-description {
      margin-bottom: 0px;
    }

    .ufr-textarea-inner {
      .ufr-input-component {
        width: 100%;
      }
    }
  }

  .success-cta-success-message {
    .ufr-textarea-inner .ufr-character-count {
      min-width: 20px;
      right: 2px;
      top: 40px;
      background-color: white;
      color: #b7b7b7;
    }
  }

  .success-cta-checkbox {
    width: 94%;
    margin-left: 10px;

    .link-to-content-checked-content {
      border-left: solid 2px #eee;
      margin: 8px 0px 0px 5px;

      #link-destination-link-to-content-ufr-input-container {
        z-index: 1;
      }

      .link-destination-text-input {
        .react-input-title {
          margin-bottom: 4px;
        }

        .link-destination-text-note {
          display: flex;
          margin-left: 5px;

          .glyphicon-info-sign {
            color: #a4a4a4;
            top: 12px;
          }
        }
      }

      .link-destination-text-input,
      .link-button-label-text-area {
        margin-left: 10px;
        position: relative;

        .find-item-link {
          position: absolute;
          z-index: 100;
          padding-bottom: 2px;
          font-size: 13px;
          color: @uberPink;
          margin-left: 212px;

          &:hover {
            cursor: pointer;
          }
        }

        .link-destination-note {
          width: 85%;
          font-size: 13px;
          color: #999;
          margin: 10px 0px 10px 7px;
        }

        .ufr-input-container {
          margin-bottom: 5px;

          .ufr-input-component {
            width: 100%;
          }
        }
      }
    }

    .ufr-textarea-inner .ufr-character-count {
      min-width: 20px;
      right: 2px;
      top: 21px;
      background-color: white;
      color: #b7b7b7;
    }

    .never-hide-cta {
      display: inline-flex;

      .ufr-input-container.ufr-checkbox-input-container {
        width: 100%;
      }

      .glyphicon {
        padding-left: 3px;
        top: 15px;
        font-size: 11px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
