@import "../../shared/styles/base";
@import "../../shared/styles/mixins";

#ufr-enrichment-source-fields-data-table {
  .rt-td {
    padding: 16px 12px 16px;

    .placeholder {
      color: @fontColorMedium;
    }
  }

  .rt-th {
    padding-left: 12px;
    padding-right: 12px;
  }

  .ufr-react-table {
    margin-top: 25px;
  }

  .ufr-dt-enrichment-source-fields-visitor-profile-api-code-header,
  .ufr-dt-enrichment-source-fields-visitor-profile-api-code-cell,
  .ufr-dt-enrichment-source-fields-format-header,
  .ufr-dt-enrichment-source-fields-format-cell {
    padding-left: 16px;
  }

  .ufr-dt-enrichment-source-fields-category-cell,
  .ufr-dt-enrichment-source-fields-enrichment-field-cell,
  .ufr-dt-enrichment-source-fields-format-cell,
  .ufr-dt-enrichment-source-fields-visitor-profile-field-type-cell {
    text-transform: capitalize;
  }

  .ufr-dt-enrichment-source-fields {
    &-category-cell {
      font-weight: bold;
    }

    &-is-mapped-cell {
      font-size: 18px;
      text-align: center;

      .glyphicons-link {
        color: @mediumGreyBorder;
      }

      .is-mapped {
        color: @uberPink;
      }
    }

    &-visitor-profile-field-name-cell {
      padding-right: 40px;
      overflow: visible;

      input {
        font-size: 13px;
      }
    }

    &-buttons-cell {
      text-align: right;
      padding-right: 20px;
    }
  }

  .rt-tbody,
  .rt-table {
    overflow: visible;
  }
}

#reset-field-mapping-tooltip {
  pointer-events: none;
}
