@import '../../shared/styles/variables';
@import "../../shared/styles/mixins";

.salesforce-dropdown.dropdown {
  width: 95%;

  .dropdown-toggle.btn.btn-default {
    align-items: center;
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    width: 100%;

    &:focus {
      box-shadow: 0px 0px 4px 1px lightskyblue;
      border-radius: 4px;
    }
  }

  .dropdownError {
    &:extend(.dropdown-toggle.btn.btn-default);

    box-shadow: 0px 0px 4px 1px rgba(169, 68, 67, 0.6);
  }

  .dropdown-toggle.btn[disabled].btn-default {
    background: whitesmoke;
    color: #555;

    &:hover {
      background: whitesmoke;
      color: #555;
    }
  }

  .salesforce-dropdown-menu.dropdown-menu {
    min-width: 100%;

    li {
      font-family: @roboto;
      font-size: 13px;

      a {
        &:hover {
          background: whitesmoke;

          span {
            color: #555;
          }
        }
      }
    }
  }
}

.selected-item > a > span {
  color: @uberPinkDark;
}

.dropdown-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loading-spinner {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid darkgrey;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
