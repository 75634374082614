@import '../shared/styles/variables';

// Topnav only styles
#topnav {
  position: relative;
  width: 100%;
  height: 40px;
  background: @uberPink;
  color: white;

  &.topnav-whitelabelled {
    background: #f7f7f7;
    color: #333;
  }
}
