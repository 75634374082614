// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

.tag-modal-wrapper {
  .form-wrapper {
    padding: 10px;
    width: 450px;
    margin: 0 auto;
    max-width: 100%;

    input[type=text] {
      border-radius: @input-border-radius;
      margin-bottom: 10px;
      padding: 12px;
    }

    textarea {
      height: 170px;
    }

    .mini-counter {
      float: right;
    }
  }

  .tag-submit-button {
    margin-right: 10px;
  }

  .counter-description-wrapper {
    overflow: hidden;
    padding-top: 3px;
  }

  .counter-name-wrapper {
    position: relative;
    top: -6px;
  }
}

.tags-table {
  font-family: @roboto;
  display: table;
  min-width: 1000px;
  margin: 0 auto 30px;

  thead > tr > th {
    color: #8c8f91;

    &.sorting_asc,
    &.sorting_desc {
      -webkit-filter: grayscale(100%);
      color: #48484a;
    }

    &.sorting {
      color: #8c8f91;
    }

    &.no-sort {
      background: transparent !important;
      cursor: default;
    }
  }

  .tag-title {
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  a.tag-name,
  a.tags-count {
    color: @uberGrayDarker;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .tags-buttons {
    text-align: right;

    > a .halflings {
      color: #8c8f91;
    }
  }
}

.mgmt-container #tag-container {
  .text-center.add-new {
    margin-top: 40px;

    a {
      font-size: @font-size-base + 1; // 13px
    }
  }
}

.hubs-section-tags .tags-table .table.collection-table td {
  padding: 8px 8px 8px 0;

  &.tags-counter a,
  &.tags-counter span {
    margin-left: 2px;
  }
}

.edit-tag-modal-wrapper {
  .item-tile-wrapper {
    .item-feature-link-new {
      display: none;
    }

    .item-placeholder {
      .img {
        opacity: 1;
      }
    }
  }
}

.recommendation-section-edit-rule,
.edit-tag-modal-wrapper,
.advanced-search-wrapper,
.item-tags-wrapper,
.tags-wrapper,
.add-user-modal {
  .edit-tag-form {
    padding-top: 5px;
    padding-right: 20px;

    textarea {
      height: 210px;
    }
  }

  div.tagsinput {
    height: auto !important;
    overflow: visible;
    border-radius: @input-border-radius;
    padding: 1px 5px;
  }

  div.tagsinput:after {
    content: "";
    display: table;
    clear: both;
  }

  div.tagsinput input {
    padding: 4px 0;
    margin-bottom: 0;
    outline: none;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  div.tagsinput > div {
    position: relative;
  }

  ul.autocompleter-list {
    background-color: @white;

    li.autocompleter-item {
      border-bottom: none;
      white-space: nowrap;

      &:hover,
      &.autocompleter-item-selected {
        background-color: #eeeded;
        border-color: #eeeded;
        border-bottom: none;
      }
    }
  }

  div.tagsinput span.tag {
    background: @white;
    color: @gray;
    border: 1px solid #a7a9ab;
    font-size: 13px;
    font-family: @roboto;
    padding: 1px 5px;
    margin: 2px 5px 2px 0;

    span {
      word-break: break-all;
    }

    a {
      position: relative;
      font-weight: 600;
      color: #808284;
      top: -1px;
      font-size: 12px;
    }
  }

  div.tagsinput span.tag a:hover {
    color: @gray;
  }
}

.modal.model-has-tags .modal-body {
  overflow: visible;
}

div.tagsinput {
  div.autocompleter-closed {
    display: none;
  }
}
