@import '../../../shared/styles/variables';
@import '../../../shared/styles/mixins';

#ufr-button-cta-accordion {
  hr {
    margin-top: 0;
  }

  #button-color-picker + hr {
    margin-top: 20px;
  }

  .ufr-input-container > .react-input-title {
    font-size: 13px;
    font-weight: 500;
    color: @fontColorMediumDark;
  }

  .ufr-input-container .ufr-textarea-inner .ufr-input-component {
    width: 100%;
  }

  #banner-color-picker {
    padding-bottom: 10px;
  }
}
