.layout-bs3 .new-cta-title-input {
  color: #555;
  width: 450px;
  height: 36px;
  font-size: 1.5em;
  padding-left: 15px;
  margin: 0 auto;
}

.page-width.item-level .blocking-cta,
#collection-items.blocking-cta {
  .opacity(20);
  .filter-blur(4px);
}

html.mobile .page-width.item-level .blocking-cta,
html.mobile #collection-items.blocking-cta {
  .filter(none);
}

.prevent-interaction {
  display: none;
}

.blocking-cta.prevent-interaction {
  .prepare-overlay({display: block; position: absolute; });
}

.cta-item-container {
  float: left;
  width: auto;
  padding-top: 0px;
  padding-left: 20px;

  .cta {
    position: fixed !important;
  }
}

.cta {
  margin-bottom: 20px;

  .submission-loader-container {
    display: inline;
    .pos-abs(0, auto, auto, 0);  // T, R, B, L
    .square(100%);

    .loader-background {
      .pos-abs(50%, auto, auto, 50%);  // T, R, B, L
      .square(52px);

      margin: -26px 0 0 -26px;
      background-color: #1a3d18;
      border-radius: 25px;
      .opacity(50);
    }

    .backdrop {
      .pos-abs(0);    // T, R, B, L
      .square(100%);
      .opacity(40);

      background-color: #1a3d18;
    }

    img {
      .pos-abs(50%, auto, auto, 50%);  // T, R, B, L
      .square(32px);

      margin: -16px 0 0 -16px;
    }

    &.hidden { display: none; }
  }

  &.full-screen-cta {
    .prepare-overlay({margin-bottom: 0 });

    .hidden-cta-fields {
      .pos-abs(50px, 0, 0, 0);
      .square(auto);

      margin: 0 auto;
      padding: 0;
      max-width: 400px;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      .backface-visibility(hidden);

      .cta-field-section {
        height: auto;
        padding-top: 5px;

        select {
          width: 96.5%;
        }
      }

      .cta-field-section.one-line {
        padding-top: 10px;
      }

      .cta-button-container {
        width: 88%;

        > input.cta-button {
          width: 96.5%;
        }
      }
    }

    .run-away {
      .pos-abs(0);
    }
  }

  &.tile.single:before {
    background-color: inherit;
  }

  &:before {
    background-color: inherit;
    content: "";
    .opacity(100);
  }

  .cta-sprite {
    background: url("/img/hubs/hubs-cta-spritesheet.png") no-repeat;
    display: inline-block;
  }

  .cta-sprite-check {
    background-position: 0px 0;
    width: 94px;
    height: 94px;
  }

  .cta-sprite-exclaim {
    background-position: -98px 0;
    width: 94px;
    height: 94px;
  }

  .cta-sprite-close {
    background-position: -29px -99px;
    width: 23px;
    height: 23px;
  }

  .cta-sprite-email {
    background-position: -56px -99px;
    width: 24px;
    height: 24px;
  }

  .cta-sprite-left-half-body {
    background-position: -84px -99px;
    width: 24px;
    height: 24px;
  }

  .cta-sprite-right-half-body {
    background-position: -112px -99px;
    width: 24px;
    height: 24px;
  }

  .cta-sprite-company {
    background-position: -140px -99px;
    width: 24px;
    height: 24px;
  }

  .cta-sprite-small-exclaim {
    background-position: -2px -99px;
    width: 24px;
    height: 24px;
  }

  .hide-preview-cancel {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    height: 25px;
    line-height: 25px;

    .cta-close {
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      text-align: center;
      width: 25px;
    }
  }

  .run-away {
    height: 74%;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid;
    position: absolute;

    &.cta-transition {
      .transition(top 0.25s linear);
    }
  }

  .hidden-cta-fields {
    height: 95%;
    width: 100%;
    top: 75%;
    position: absolute;
    margin: auto;
    overflow: hidden;
    padding-top: 3%;

    input {
      margin-bottom: 14px;
    }

    &.fields-revealed {
      height: 92%;

      .cta-field-section {
        padding-bottom: 0px;

        input {
          margin-bottom: 6px;
        }
      }

      .cta-button-container {
        input {
          margin-bottom: 0px;
        }
      }

      .cta-field-section.one-line {
        padding-top: 10px;
      }
    }

    form {
      margin: 0 0 20px;
    }

    &.cta-transition {
      .transition(top 0.25s linear);
    }

    .cta-field-section {
      height: 25%;
      position: relative;
      width: 88%;
      margin: 0 auto;
      padding-bottom: 15px;

      &.smaller {
        .cta-field-name {
          font-size: 14px;
          padding-bottom: 0;
        }

        .cta-field-sprite-container.cta-front {
          top: 27px !important;
        }

        input {
          height: 19px;
          margin-bottom: 0px;
        }

        select {
          height: 27px !important;
          line-height: 32px !important;
          margin: 0px;
        }
      }

      .cta-field-name {
        display: inline-block;
        zoom: 1;
        *display: inline;
        font-size: 16px;
        padding-bottom: 4px;
        color: @white;

        &.label-only-text {
          font-size: 11px;
          line-height: 1.5em;
        }
      }

      .cta-field-name-optional {
        font-size: 80%;
        .opacity(75);
      }

      .cta-field-sprite-container {
        position: absolute;
        background-color: transparent;
        top: 36px;
        left: 8px;
      }

      .preview-form-field {
        display: inline-block;
        zoom: 1;
        *display: inline;
        height: 25px;
        .placeholder-color( rgba(255, 255, 255, 0.7) );

        &.error {
          border-color: @white;
          background-color: @fieldErrorBG;
        }
      }

      input {
        width: 100%;
      }

      select {
        width: 100%;
        height: 35px !important;
        line-height: 32px !important;
        margin-bottom: 6px;
      }

      &.one-line {
        padding-top: 30px;
        padding-bottom: 10px;

        .opt-in-required {
          display: none;
          float: left;
          background: @fieldErrorBG;
          border: 1px solid @white;
          height: 20px;
          width: 20px;
          .border-radius(4px);

          text-align: center;
          color: @black;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          margin-right: 5px;

          &.show-required-text {
            display: inline-block;
          }
        }

        input {
          width: auto;
          margin: 3px 8px 0px 3px;
          vertical-align: top;
          height: auto;
          text-indent: auto;
          display: inline;
        }

        span {
          display: inline;
        }
      }
    }

    .cta-button-container {
      height: 22%;
      padding-top: 14px;
      width: 88%;
      margin: 0 auto;
    }

    .cta-activate-button-container {
      padding-top: 0;
      width: 100%;
    }
  }

  .cta-form-response {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &.cta-form-error {
      background-color: white;
    }

    .cta-form-message-container {
      width: 75%;
      margin: 0 auto;
      margin-top: 28%;
      text-align: center;

      .cta-form-message-sprite {
        display: block;
        margin: 0 auto;
      }

      .cta-form-message {
        padding-top: 30px;

        span {
          font-size: 22px;
        }
      }
    }

    .cta-button-container {
      width: 90%;
      margin-left: 5%;
      position: absolute;
      bottom: 15px;
    }
  }

  .cta-button {
    font-family: Droid Sans, sans-serif;
    line-height: 40px;
    height: 40px;
    vertical-align: middle;
    display: block;
    font-size: 17px;
    text-align: center;
    width: 100%;
    border: 0;

    &:hover {
      .box-shadow(0px 3px 4px rgba(0, 0, 0, 0.3));
    }
  }

  > img {
    width: 100%;
    position: absolute;
  }

  p {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    padding: 20px;
    color: @white;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    line-height: normal;
  }

  > a,
  &.tile.single > a {
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    width: 68%;
    height: 1em;
    margin: 20px 8.5%;
    text-align: center;
    box-sizing: content-box;
  }

  > a.accent-button {
    background-color: @darkBackground;
    color: @white;
    display: block;
    font-size: 20px;
    line-height: 1;
    margin-top: 20px;
    padding: 20px;
    text-decoration: none;

    &:hover { color: @white; }
  }

  .ps-container {
    &:not(:hover) {
      .ps-scrollbar-y-rail {
        .opacity(30);
      }
    }

    .ps-scrollbar-y-rail,
    .ps-scrollbar-y-rail.hover {
      background: transparent;

      .ps-scrollbar-y {
        background-color: @white;
      }
    }
  }

  &.setValues {
    background-image: none !important;

    > .run-away {
      top: -300px;
    }

    > .fields-revealed {
      top: 25px;
    }

    > .hide-preview-cancel {
      display: block;
    }
  }
}
