@import "../../../shared/styles/base";
@import "../../../shared/styles/mixins";

#ufr-privacy-group-data-table {
  .rt-tr-group {
    .ufr-privacy-group-name {
      font-weight: 700;
    }

    &:hover {
      .ufr-dt-privacy-groups-buttons-cell .ufr-btn-icon {
        opacity: 1;
      }
    }

    .rt-tr {
      position: relative;
    }
  }

  .ufr-dt-privacy-groups-buttons-cell {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    overflow: unset !important;
    text-overflow: unset !important;

    .ufr-delete-button {
      margin-left: 10px;
    }

    .ufr-btn-icon {
      opacity: 0;
    }
  }

  .ufr-dt-privacy-groups-buttons-cell ul#ufr-privacy-groups-dependencies {
    margin-left: 22px;

    li {
      list-style: disc;
    }
  }
}
