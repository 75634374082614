@import '../../../shared/styles/variables';
@import "../../../shared/styles/mixins";

.searchable-dropdown-container {
  margin-top: 1px;
}

.menu-item {
  color: @fontColorDark;
  font-size: 13px;
  font-family: @roboto;
  line-height: 20px;
  margin-bottom: 1px;
  padding: 7px 9px;

  &:hover {
    background-color: whitesmoke;
    cursor: pointer;

    .selected-item {
      color: @fontColorDark;
    }
  }

  .selected-item {
    color: @uberPinkDark;
  }
}
