@import "../../shared/styles/variables";
@import '../../shared/styles/mixins';

.ufr-select-dropdown {
  position: relative;
  width: @fourColumns;
  border: 1px solid #ddd;
  font-family: @roboto;

  .ufr-dropdown-button {
    width: 100%;
    padding: 5px 3px 5px 10px;
    background-color: white;
    border: none;
    text-align: left;
    font-size: 16px;

    .ufr-dropdown-selected-item {
      display: inline-block;
      font-size: 16px;
      max-width: 250px;
      vertical-align: middle;
      .truncate;
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 8px #5bc0de;
    }
  }

  .react-icon {
    &.glyphicons-chevron-down,
    &.glyphicons-chevron-up {
      margin: 7px 5px 5px 5px;
      float: right;
      font-size: 9px;
    }

    &.glyphicons-chevron-down {
      color: @fontColorMedium;
    }

    &.glyhicons-chevron-up {
      color: @fontColorDark;
    }
  }

  .ufr-select-dropdown-container {
    position: absolute;
    top: 30px;
    left: -1px;
    border: 1px solid #ddd;
    border-top: 1px solid white;
    z-index: 15;

    .ufr-dropdown-search-input {
      margin: 6px 5px 0 5px;
      border-radius: 2px;
      border: 1px solid #ddd;
      background-color: #fff;
      display: none;

      &.show-search {
        display: block;
      }

      > input {
        width: 250px;
        height: 32px;
        padding-left: 5px;
        border: 0;
        outline: none;
        font-size: 16px;
      }

      .glyphicons-search {
        margin: 8px 0 0 8px;
        font-size: 16px;
        color: #333;
      }
    }

    .ufr-select-dropdown-item-container {
      width: 292px;
      padding: 5px 0;
      border-top: 1px solid white;
      background-color: #fff;
      cursor: pointer;
      max-height: 350px;
      overflow-y: auto;
      overflow-x: hidden;

      .ufr-select-dropdown-item {
        width: 100%;
        padding: 13px 0 13px 10px;
        font-size: 16px;
        color: @fontColorDark;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        cursor: pointer;
        .truncate;

        &.highlighted {
          color: @uberPink;
        }
      }
    }

    &.show-search {
      top: 33px;
    }
  }

  &.glyphicon-dropdown {
    width: 80px;

    .ufr-dropdown-button {
      font-size: 24px;
    }

    .react-icon {
      &.glyphicons-chevron-down,
      &.glyphicons-chevron-up {
        margin: 14px 5px;
      }
    }

    .ufr-select-dropdown-container {
      top: 40px;

      .ufr-select-dropdown-item-container {
        width: 78px;

        .ufr-select-dropdown-item {
          font-size: 24px;
          padding: 13px 0px;
          text-align: center;
        }
      }
    }
  }
}

.ufr-multi-select-container {
  font-family: @roboto;
}

.ufr-dropdown-disabled {
  .ufr-select-dropdown {
    &:hover {
      cursor: not-allowed;
    }

    .ufr-input-component--disabled {
      pointer-events: none;
    }

    button {
      pointer-events: none;
      background: #eee;
      color: #777;
    }
  }
}
