.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.transfer-list {
  display: flex;
  gap: 34px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
}
.transfer-list .tl-box-col {
  display: flex;
  flex-direction: column;
}
.transfer-list .tl-box-col .tl-box-text {
  gap: 6px;
  margin-bottom: 12px;
}
.transfer-list .tl-box-col .tl-box-text .tl-box-header {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.transfer-list .tl-box-col .tl-box-text .tl-box-description {
  font-size: 14px;
  color: #333;
}
.transfer-list .tl-box-col .tl-box {
  display: flex;
  flex-direction: column;
  height: 385px;
  width: 367px;
  border: solid 1px #ccc;
  background-color: white;
}
.transfer-list .tl-box-col .tl-box .tl-control-bar-box {
  padding: 15px 14px 0px 14px;
}
.transfer-list .tl-box-col .tl-box .tl-control-bar-box .tl-control-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.transfer-list .tl-box-col .tl-box .tl-control-bar-box .tl-control-bar .tl-search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 41px;
  border: solid 2px #eee;
  border-radius: 4px;
  padding: 0px 22px;
  gap: 12px;
}
.transfer-list .tl-box-col .tl-box .tl-control-bar-box .tl-control-bar .tl-search-box .tl-search-icon {
  font-size: 16px;
  color: black;
}
.transfer-list .tl-box-col .tl-box .tl-control-bar-box .tl-control-bar .tl-search-box .tl-search-input {
  all: unset;
  flex-grow: 1;
  color: #333;
}
.transfer-list .tl-box-col .tl-box .tl-control-bar-box .tl-control-bar .tl-button {
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 32px;
  border: solid 1px #ccc;
  border-radius: 4px;
  cursor: pointer;
}
.transfer-list .tl-box-col .tl-box .tl-control-bar-box .tl-control-bar .tl-button:hover {
  background-color: #f2f2f2;
}
.transfer-list .tl-box-col .tl-box .tl-control-bar-box .tl-control-bar .tl-button:disabled {
  background-color: #e6e6e6;
}
.transfer-list .tl-box-col .tl-box .tl-row-box {
  margin: 9px 0px;
  overflow-y: auto;
  flex-grow: 1;
  scrollbar-width: thin;
  scrollbar-color: #bababa transparent;
}
.transfer-list .tl-box-col .tl-box .tl-row-box::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}
.transfer-list .tl-box-col .tl-box .tl-row-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #bababa;
  border: solid 2px white;
}
.transfer-list .tl-box-col .tl-box .tl-row-box .tl-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  height: 100%;
}
.transfer-list .tl-box-col .tl-box .tl-row-box .tl-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  padding: 0 18px 0 14px;
  cursor: pointer;
}
.transfer-list .tl-box-col .tl-box .tl-row-box .tl-row:hover {
  background-color: rgba(216, 216, 216, 0.57);
}
.transfer-list .tl-box-col .tl-box .tl-row-box .tl-row:hover .tl-icon {
  visibility: visible;
}
.transfer-list .tl-box-col .tl-box .tl-row-box .tl-row .tl-text {
  color: #333;
}
.transfer-list .tl-box-col .tl-box .tl-row-box .tl-row .tl-icon {
  color: #ce0058;
  visibility: hidden;
}
