@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
ul.ufr.ufr-dt-control-bar {
  position: relative;
  display: table;
  height: 55px;
  margin: 10px 0;
  padding: 6px 0;
  background: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  list-style: none;
}
ul.ufr.ufr-dt-control-bar > * {
  display: table-cell;
  vertical-align: top;
  position: relative;
}
ul.ufr.ufr-dt-control-bar .ufr-control-bar-dropdown-toggle > .react-dropdown {
  position: relative;
}
