@import '../shared/styles/variables';
@top: 22px;

.date-picker-container,
.single-date-picker-container {
  font-family: @roboto;
  position: relative;

  .title {
    font-weight: 400;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    line-height: 1em;
  }

  .description {
    font-weight: 400;
    font-size: 13px;
    color: #777;
    margin-bottom: 0px;
  }

  .date-range-wrapper {
    position: relative;
    width: 254px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid #ccc;

    .date-range {
      margin: 5px 10px;
    }

    button {
      float: left;
      position: absolute;
      right: 0;
      width: 40px;
      height: 100%;
      border: transparent;
      border-left: 1px solid #ccc;
      background: #eee;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    p {
      float: left;
    }
  }

  .calendarFooter {
    display: flex;
    justify-content: flex-end;
    padding: 0 30px 20px;
  }

  .uf-month-header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: @top;
    left: 50%;
    transform: translateX(-50%);
  }
}

.DateRangePicker,
.SingleDatePicker {
  font-family: @roboto;
  top: 10px;
  border: #ccc;

  .DateInput_input__focused {
    border-bottom: none;
  }

  .DateInput {
    width: 105px;

    .DateInput_input {
      font-size: 13px;
      font-weight: 400;
      color: #333;
      padding: 0 0 0 10px;
      border-bottom: 0px;
    }
  }

  .DateRangePickerInput_arrow {
    margin-left: 10px;
  }

  .DateRangePickerInput__withBorder {
    border: 1px solid #ccc;
  }

  .DateRangePickerInput_calendarIcon,
  .SingleDatePickerInput_calendarIcon {
    padding: 8px 13px;
    margin: 0;
    color: #333;
    background: #eee;
    margin: 0 0 0 25px;
    border-left: 1px solid #ccc;

    .DateRangePickerInput_calendarIcon_svg,
    .SingleDatePickerInput_calendarIcon_svg {
      fill: #333;
      height: 12px;
      width: 12px;
    }
  }

  .joiningWord {
    margin: 0;
    font-family: @roboto;
    font-size: 13px;
  }

  .DateInput_fang {
    display: none;
  }

  // Calendar
  .SingleDatePicker_picker {
    top: 32px !important;
    margin-top: 8px;
  }

  .DayPicker__withBorder {
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 0;

    .CalendarMonthGrid {
      height: 100%;
      background: transparent;
    }

    .CalendarMonth {
      background: transparent;
    }

    .CalendarDay__default {
      border: 2px solid white;
      font-size: 13px;
      margin: 4px;
    }

    .CalendarDay__default:hover {
      border: 2px solid white;
    }

    .CalendarDay__default:focus {
      outline: 1px @uberPink solid;
    }

    .CalendarDay__hovered_span {
      background: #ccc;
      color: black;
      border: 2px solid white;
    }

    .CalendarDay__selected_start,
    .CalendarDay__selected,
    .CalendarDay__selected_span,
    .CalendarDay__default:active,
    .CalendarDay__default:hover,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover,
    .CalendarDay__selected_span:active,
    .CalendarDay__selected_span:hover,
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span:active {
      background: #ebccd9;
      color: black;
      border: 2px solid white;
    }

    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      background: #fff;
      color: #cacccd;
    }

    .DateInput_fang .DateInput_fang_1 {
      display: none;
    }

    .datepicker-tooltip {
      font-size: 13px;
      padding: 5px 10px 0 0;
      float: left;
      color: @uberPink;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight {
      display: none;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right-color: @uberPink;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
      border-right-color: #cacccd;
    }

    .DayPicker_weekHeader {
      font-weight: 600;
      font-size: 13px;
      color: #333;
      top: 42px;
    }

    .uf-month-header p {
      font-size: 13px;
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .ufr-btn {
      font-size: 13px;
      height: 22px;
      padding: 0 10px;
      margin: 0 0 0 10px;
    }
  }

  button.with-icon {
    font-size: 11px;
    font-family: 'Glyphicons Halflings', sans-serif;
    border: none;
    height: 15px;
    padding: 0;
    background: transparent;
    position: absolute;
    top: @top;

    &.prev-month {
      left: 22px;

      &::before {
        content: "\e079";
      }
    }

    &.next-month {
      right: 22px;

      &::before {
        content: "\e080";
      }
    }
  }

  .CalendarMonth_caption {
    position: relative;
  }
}
