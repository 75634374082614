@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-enrichment-sources-import-summary-data-table .rt-tbody {
  padding-top: 15px;
}
#ufr-enrichment-sources-import-summary-data-table .rt-td {
  padding: 16px 0;
}
#ufr-enrichment-sources-import-summary-data-table .rt-tr-group .rt-tr {
  min-height: 72px;
}
#ufr-enrichment-sources-import-summary-data-table .ufr-dt-enrichment-sources-import-summary-leadsCreated-cell {
  white-space: inherit;
}
#ufr-enrichment-sources-import-summary-data-table .ufr-dt-enrichment-sources-import-summary-leadsCreated-cell .import-summary-title {
  padding-bottom: 10px;
}
#ufr-enrichment-sources-import-summary-data-table .ufr-dt-enrichment-sources-import-summary-leadsCreated-cell .import-summary-prefix {
  font-weight: bold;
}
#ufr-enrichment-sources-import-summary-data-table .ufr-dt-enrichment-sources-import-summary-leadsCreated-cell .import-summary-list {
  padding-left: 25px;
}
#ufr-enrichment-sources-import-summary-data-table .ufr-dt-enrichment-sources-import-summary-leadsCreated-cell .import-summary-list li {
  list-style: disc;
}
