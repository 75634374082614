@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-installed-apps-data-table .ufr-dt-installed-apps-listing-image-cell .ufr-app-image {
  background-size: cover;
  width: 60px;
  height: 60px;
}
#ufr-installed-apps-data-table .ufr-dt-installed-apps-listing-name-cell {
  font-weight: 700;
  padding: 8px;
}
#ufr-installed-apps-data-table .ufr-dt-installed-apps-listing-description-cell {
  white-space: unset;
}
