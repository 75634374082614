@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-tag-tags-data-table .ufr-control-bar-search > div {
  width: 278px;
  height: 41px;
  margin: 1px 0 0 16.5px;
  border-radius: 2px;
}
#ufr-tag-tags-data-table .ufr-dt-tags-listing-tag-name-cell,
#ufr-tag-tags-data-table .ufr-dt-tags-listing-tag-author-cell {
  display: flex;
  align-items: center;
}
#ufr-tag-tags-data-table .ufr-tag-name {
  padding-left: 8px;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  word-wrap: break-word;
}
#ufr-tag-tags-data-table .ufr-tag-name a {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}
#ufr-tag-tags-data-table .tag-name {
  color: #333;
  font-weight: bold;
}
#ufr-tag-tags-data-table .tag-name:hover,
#ufr-tag-tags-data-table .tag-name:active {
  color: #333;
  text-decoration: underline;
}
.save-edit-tag-name {
  display: inline-block;
}
.save-edit-tag-name span.save-tag-name {
  color: #b70150;
  padding-left: 8px;
  font-size: 16px;
}
.save-edit-tag-name span.save-tag-name:hover {
  cursor: pointer;
  color: #ce0058;
}
.input-edit-tag-name {
  border: #5bc0de;
  box-shadow: 0 0 7px #5bc0de;
  border-radius: 3px;
  font-size: 13px !important;
}
.editable-tag-name:hover span.react-icon.edit-tag-name-icon {
  cursor: pointer;
  opacity: 1;
  color: #b70150;
  padding-left: 8px;
  font-size: 16px;
}
.edit-tag-name {
  display: inline-block;
}
.edit-tag-name span.react-icon.edit-tag-name-icon {
  opacity: 0;
}
.edit-tag-name span.react-icon.edit-tag-name-icon:hover {
  cursor: pointer;
  opacity: 1;
  color: #ce0058 !important;
  padding-left: 8px;
  font-size: 16px;
}
