@import "../../shared/styles/variables";
@import "../../shared/styles/mixins";
@import "../../shared/styles/base";

.ufr-control-bar-search {
  position: relative;
  width: 100%;

  > div {
    margin: 0 10px;

    .react-icon {
      position: absolute;
      left: 36px;
      top: 13px;
      font-size: 16px;
      color: @fontColorMedium;
    }

    input {
      width: 100%;
      height: 41px;
      border: 1px solid @mediumGreyBorder;
      border-radius: 2px;
      padding: 13px 40px 12px 50px;
      font-size: 13px;
      font-family: @roboto;

      &:focus {
        border-color: transparent;

        .focus-outline;
      }

      &:disabled {
        background: #eee;
        cursor: not-allowed;
      }
    }
  }
}
