.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-radio-button-input .ufr-radio-button {
  margin-bottom: 10px;
  overflow: visible;
}
.ufr-radio-button-input .ufr-radio-button input {
  margin: 0 10px 0 0;
  overflow: visible;
}
.ufr-radio-button-input .ufr-radio-button input:focus {
  outline: initial;
  outline-offset: initial;
}
.ufr-radio-button-input .ufr-radio-button label {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 1em;
  cursor: pointer;
}
