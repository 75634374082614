@import "../styles/variables";
@import "../styles/mixins";

.react-dropdown.dropdown.btn-group {
  button {
    padding: 6px;

    .react-icon {
      font-size: 27px;
      padding: 0 5px;
    }

    &:focus > .react-icon {
      .focus-outline;
    }
  }

  button,
  button:focus {
    border: 0;
    background: none;
    box-shadow: none;
  }

  .react-dropdown-menu {
    right: 12px;
    left: auto;
    margin-top: 4px;

    li {
      a {
        min-width: 180px;
        padding: 8px 38px 8px 34px;
        font-size: 13px;
        line-height: 16px;

        &:hover {
          background: @hoverBackground;
        }

        &:active,
        &:focus {
          color: white;
          background: @activeBackground;
        }

        .menu-text {
          font-size: 13px;
        }
      }

      &.with-icon {
        a {
          padding-left: 12px;

          .menu-text {
            font-size: 13px;
            padding-left: 10px;
          }

          .react-icon {
            font-size: 14px;

            // Overriding our core css
            opacity: 1;
            line-height: 1;
            width: auto;
            left: 0;
            position: relative;
          }
        }
      }
    }
  }

  &.open {
    button {
      box-shadow: none;
      background: none;

      &:hover,
      &:focus {
        background: none;
        border-color: transparent;
      }

      span {
        &.glyphicons {
          color: @uberPink;
        }
      }
    }

    .dropdown-menu {
      padding: 0;
      border-radius: initial;
    }
  }
}

