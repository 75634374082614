@import '../../shared/styles/variables';

.salesforce-edit-modal {
  .ufr-modal-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 26px;
  }

  .sfi-fields {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }

  .sfi-field-section {
    border: none;
    font-family: @roboto;
    margin: 0 0 28px;
    padding: 0px;
    width: 448px;
  }

  .sfi-field-section-header {
    font-family: @roboto;
    font-size: 16px;
    font-weight: normal;
  }

  .sfi-field-section-body {
    margin-top: 10px;
    width: 100%;
  }

  .sfi-field-description {
    color: @fontColorMedium;
    font-size: @pageSubtitleFontSize;
  }

  .dropdown-title {
    color: #555;
    font-family: @roboto;
    font-size: @pageSubtitleFontSize;
    font-weight: normal;
    margin-bottom: 0px;
  }

  // These colors override newer brand colors to align with other edit modals
  .ufr-btn.ufr-btn-primary {
    background: @uberBlueLight;
    border-color: @uberBlueLight;

    &:not(.disabled):hover {
      background: @uberBlue;
      border-color: @uberBlue;
    }

    &:not(.disabled):active {
      background-color: #269abc;
      border-color: #1b6d85;
      //box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }

  .ufr-checkbox-input-container label.react-input-title {
    font-size: 13px;
  }
}

.error {
  color: @errorRed;
  font-family: @roboto;
  font-size: 13px;
  margin: 5px;
}

#salesforce-edit-modal-button {
  button {
    outline: none;
  }

  span.halflings-edit {
    float: left;
    margin-right: 3px;
    position: relative;
    top: 2px;
  }

  div.ufr-modal-outer {
    z-index: 200000;
  }
}

a[data-service=Salesforce] + div[data-hypernova-key=ModalOpener],
#salesforce-edit-modal-button {
  display: inline;
}
