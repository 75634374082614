@import '../../shared/styles/base';
@import '../../shared/styles/mixins';

#ufr-installed-apps-data-table {
  .ufr-dt-installed-apps-listing-image-cell .ufr-app-image {
    background-size: cover;
    width: 60px;
    height: 60px;
  }

  .ufr-dt-installed-apps-listing-name-cell {
    font-weight: 700;
    padding: 8px;
  }

  .ufr-dt-installed-apps-listing-description-cell {
    white-space: unset;
  }
}
