@import '../shared/styles/variables';
@import '../shared/styles/mixins';
@import './variables.less';

#topnav {
  .topnav-dropdown-item {
    position: relative;
    height: 32px;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background: @hoverBackground;
    }

    &:active,
    &.topnav-item-active {
      color: @uberPink;
    }

    > a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      .react-avatar {
        position: absolute;
        left: 12px;
        top: 8px;
      }

      .topnav-icon {
        position: absolute;
        left: 12px;
        top: 8px;
        font-size: 13px;
      }

      .topnav-item-favicon {
        position: absolute;
        left: 12px;
        top: 8px;
        height: 14px;
        width: 14px;
      }

      .topnav-dropdown-item-text {
        padding: 9px 12px 0 34px;
        height: 100%;
        line-height: 1.1;
        font-size: 13px;
        .truncate;

        &.no-icon {
          padding-left: 12px;
        }
      }

      .topnav-icon-right + .topnav-dropdown-item-text {
        padding-right: 34px;
      }

      .topnav-icon-right .react-icon {
        position: absolute;
        right: 8px;
        top: 7px;
        line-height: 1;
        font-size: 14px;
      }
    }

    &.topnav-item-top-divider {
      border-top: 1px solid #cdcdcd;
    }
  }

  &.topnav-whitelabelled .topnav-dropdown-item {
    &:active,
    &.topnav-item-active {
      color: @uberBlue;
    }
  }
}
