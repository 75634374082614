// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// ----------------------------------------------------------------------------------------------------------
// Hubs Content Management Section
// ----------------------------------------------------------------------------------------------------------

.mgmt-container {
  .content-container.hubs-section-options {
    font-family: @roboto;

    // --------------------------------------------------------------------------------------------------
    // Navbar on all Appearance Pages

    .options-navbar .glyphicons {
      font-size: 15px;

      &:before {
        padding: 0.5px 6px 0 11px;
      }
    }

    .page-header {
      position: relative;
      width: 780px;
      margin: 0 auto;
    }

    // --------------------------------------------------------------------------------------------------
    // Authors

    .hub-authors-container {
      .author-avatar-small {
        width: 50px;
        .border-radius(50%);
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Advanced Options

    .hubs-options-container {
      position: relative;
      width: 780px;
      margin: 0 auto;

      .alert {
        margin-left: 0;
      }

      .section-title {
        color: @gray;
        font-weight: bold;
        margin: 0 0 10px -20px;
      }

      .hubs-option-block {
        margin-bottom: 20px;

        label {
          color: #444;
          font-weight: normal;
          line-height: 1.2em;
          margin-bottom: 0;
        }

        .select-label {
          margin-left: -20px;
        }

        select {
          font-size: 14px;
          width: 200px;
          display: inline;
        }

        .note {
          margin-left: 20px;

          p { color: inherit; }
        }
      }

      .hub-option-secondary-block {
        padding-left: 27px;
      }

      .enlarged {
        width: 100%;
        margin-bottom: 16px;
        font-size: 14px;
      }

      &.advanced-options {
        .hubs-option-block {
          label {
            padding-left: 18px;
          }

          .hub-option-sub-container {
            margin-top: 5px;
            margin-left: 26px;

            .hub-option-sub-option {
              padding-top: 8px;

              .sub-option-label {
                padding-left: 0;
                padding-bottom: 5px;
              }

              .hub-option-sub-option-header {
                .badge {
                  margin: 0.5em 0;
                }
              }
            }

            input[type='textarea'] {
              height: 100px;
            }

            .note {
              margin-left: 0;
            }
          }
        }
      }

      // Authentication Options

      &.authentication-options {
        .auth-status-container {
          margin-top: 40px;
          margin-bottom: 15px;
          .clearfix;

          .auth-status {
            float: left;
            font-size: 20px;
            line-height: 30px;

            .protected {
              display: none;
              color: @green;
            }

            .not-protected {
              color: @gray-light;
            }

            &.enabled {
              .protected {
                display: inline;
              }

              .not-protected {
                display: none;
              }
            }
          }

          .toggle-switch-parent {
            width: 54px;
            float: right;

            &.disabled {
              cursor: not-allowed;
            }
          }

          .uf-toggle-switch-container {
            float: right;

            &.disabled {
              pointer-events: none;
            }
          }
        }

        .auth-custom-code-container {
          margin: 30px 0 10px 0;

          .section-title {
            color: @gray;       // #555
            font-size: 1.1em;
            font-weight: bold;
            margin: 0 0 10px 0;
          }

          .toggle-field {
            margin: 0 0 10px 0;

            label {
              color: lighten(@gray, 6.5%);    // #666
              font-size: 1em;
              font-weight: normal;

              input {
                display: inline;
              }
            }
          }
        }

        // Restyle the appended input from bootstrap

        .input-group {
          margin: 0 0 9px 0;

          input {
            width: 463px;
            padding: 8px;
            margin-right: 8px;
            .border-radius(3px);
          }
        }

        .password-footer {
          margin-bottom: 20px;

          .password-strength-container {
            display: none;
            width: 100%;
            margin-top: 15px;
            margin-bottom: 27px;

            .password-strength-text {
              float: left;
              width: 100px;
              line-height: 10px;
            }

            .password-strength-meter {
              float: left;
              width: calc(~"100% - 100px");
            }
          }
        }
      }

      // Sharing Options

      &.sharing-options {
        width: 780px;

        .panel-default {
          .panel-heading {
            .hubs_share_icon {
              margin: 4px 10px 0 0;
            }

            .hubs_share_icon_label {
              padding: 0px 0 0 12px;
              font-size: 18px;
            }
          }

          .panel-body {
            .twitter-via-container {
              .control-label {
                float: left;
                width: 21px;
                margin: 0 5px 0 0;
                padding: 10px 3px 0 1px;
                font-weight: normal;
                color: #444;
              }

              input {
                width: 285px;
              }
            }

            textarea {
              width: 100%;
              height: 60px;
              min-height: 60px;
              max-height: 60px;
              margin: 0 0 6px 0;
              font-size: 13px;

              .border-radius(0);
            }

            .hubs_share_enable {
              margin: 10px 0 0 0;

              label {
                margin: 0;
                line-height: 20px;
                color: @uberGrayDarker; // #333
                font-size: 13px;
                font-weight: normal;
                cursor: pointer;
              }

              input[type="checkbox"] {
                margin-top: 4px;
              }
            }

            .note {
              margin-top: 12px;
            }
          }

          .hubs_share_icon {
            opacity: 0.5;

            &.facebook { background-position: -312px -10px; }

            &.twitter {
              background-position: center;
              background-image: url("/img/integrations/twitter_x_logo.svg");
              background-size: contain;
            }
            &.email { background-position: -421px -10px; }
            &.google { background-position: -476px -10px; }
            &.linkedin { background-position: -531px -10px; }
            &.pinterest { background-position: -580px -10px; }
          }

          .hubs_share_message {
            .label {
              padding: 0;
              margin-bottom: 0;
              color: #666;
              font-weight: normal;
              background-color: transparent;
            }
          }

          &.enabled {
            .hubs_share_message {
              &:before {
                display: none;
              }
            }

            .hubs_share_icon {
              opacity: 1;

              &.facebook { background-position: -312px -50px; }

              &.twitter {
                background-position: center;
                background-image: url("/img/integrations/twitter_x_logo.svg");
                background-size: contain;
              }
              &.email { background-position: -421px -50px; }
              &.google { background-position: -476px -50px; }
              &.linkedin { background-position: -531px -50px; }
              &.pinterest { background-position: -580px -50px; }
            }
          }
        }
      }

      .delete-hub {
        margin-left: 25px;

        input[type=checkbox] {
          margin-right: 10px;
        }

        .note {
          margin-left: 27px;
        }
      }
    }
  }

  // --------------------------------------------------------------------------------------------------
  // Change Domain

  .hubs-options-container.change-domain {
    span.btn-wrapper {
      cursor: not-allowed;

      .btn-default.disabled {
        pointer-events: none;
      }
    }
  }
}
