@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-custom-code-data-table .ufr-custom-code-name,
#ufr-custom-code-data-table .ufr-custom-code-description,
#ufr-custom-code-data-table .ufr-custom-code-privacy-name {
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  word-wrap: break-word;
}
#ufr-custom-code-data-table .ufr-modal-header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#ufr-custom-code-name-tooltip,
#ufr-custom-code-description-tooltip {
  max-width: 100%;
  word-break: break-all;
}
