@import '../styles/variables';

.inline-submit-btn {
  position: absolute;
  padding: 2px 0 0;
  font-size: 12px;
  cursor: pointer;
  background: none;
  border-radius: 3px;
  border: 1px solid @mediumGreyBorder;
  color: @iconGrey;
  margin-left: 8px;

  &:hover {
    background: @veryLightGrey;
  }

  &.submitted-state .inline-submit-btn {
    color: white;
    background: @uberPink;
    transition: all 300ms ease-in;
  }

  &.large-submit-btn {
    width: @largeInlineInputHeight;
    height: @largeInlineInputHeight;
    font-size: 17px;
  }

  &.small-submit-btn {
    width: @smallInlineInputHeight;
    height: @smallInlineInputHeight;
    font-size: 13px;
  }
}
