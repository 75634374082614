/*************************************************************************
 *  IBM Plex Sans
 *************************************************************************/

/* ibm-plex-sans-100 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-100italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100italic.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100italic.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100italic.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100italic.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-100italic.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-200 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-200italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-200italic.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-300 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-300italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-300italic.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-regular - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-regular.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-italic.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-500 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-500italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-500italic.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-600 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-600italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-600italic.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-700 - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700.svg#IBMPlexSans') format('svg');
}

/* ibm-plex-sans-700italic - vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.eot');
  src:
    local(''),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.woff') format('woff'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'),
    url('/fonts/ibm-plex-sans/ibm-plex-sans-v14-vietnamese_latin-ext_latin_greek_cyrillic-ext_cyrillic-700italic.svg#IBMPlexSans') format('svg');
}
