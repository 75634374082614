@import '../shared/styles/variables';
@import '../shared/styles/mixins';

html .ReactTable {
  .rt-tr-group {
    .rt-tr {
      position: relative;
      align-items: center;

      .rt-td {
        > * {
          vertical-align: middle;
        }
      }
    }
  }

  .rt-th {
    > div {
      color: #777;
      font-weight: 500;
    }

    &:hover > div {
      color: #333;
    }

    &.-sort-asc > div,
    &.-sort-desc > div {
      color: @uberPink;
    }
  }

  .rt-noData {
    text-align: center;
    color: @fontColorDark;
    background-color: initial;
    position: initial;
    transform: initial;
    padding: 40px 20px;
    pointer-events: initial;
  }

  .-loading {
    top: 28px;
    background: rgba(255, 255, 255, 1);

    .-loading-inner {
      top: 40%;
    }
  }

  .ufr-dt-buttons-cell {
    display: flex;
    justify-content: flex-end;
    min-height: 45px;
    padding-right: 15px;
    overflow: unset !important;
    text-overflow: unset !important;

    .ufr-btn-icon {
      opacity: 0;
    }

    .ufr-btn ~ .ufr-btn {
      margin-left: 10px;
    }
  }

  .rt-tr-group:hover {
    .ufr-dt-buttons-cell .ufr-btn-icon {
      opacity: 1;
    }
  }
}
