.ufr-page {
  padding: 0 40px 40px;
  margin-left: 250px;

  .alert-info {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1300px) {
  .ufr-page {
    margin-left: 60px;
  }
}
