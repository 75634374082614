.advanced-options-position {
  margin-left: 14px;
}
.advanced-options-position .advanced-options-seo-accordion {
  padding-left: 2px;
  display: inline-block;
  width: 39%;
  margin-top: 25px;
  border-bottom: 1px solid #d1d1d1;
}
.advanced-options-position .advanced-options-seo-accordion:hover {
  cursor: pointer;
  border-bottom: 1px solid #cf075c;
}
.advanced-options-position .advanced-options-seo-accordion .advanced-options-seo-label {
  float: left;
}
.advanced-options-position .advanced-options-seo-accordion span {
  float: right;
}
.advanced-options-position table.api-dt thead > tr > th:first-child {
  width: 230px;
}
