.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-input-inner {
  font-family: Roboto, sans-serif;
  /**
  Prefixes and postfixes
   */
}
.ufr-input-inner .ufr-input-component {
  height: 100%;
  width: 100%;
  display: table-cell;
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  background-color: white;
  background-image: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: #333;
  font-size: 16px;
}
.ufr-input-inner .ufr-input-component:focus,
.ufr-input-inner .ufr-input-component.ufr-input-error,
.ufr-input-inner .ufr-input-component.ufr-input-success {
  border-color: transparent;
  outline: 0;
}
.ufr-input-inner .ufr-input-component:focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-input-inner .ufr-input-component.ufr-input-error {
  box-shadow: 0 0 8px rgba(169, 68, 67, 0.6);
}
.ufr-input-inner .ufr-input-component.ufr-input-success {
  box-shadow: 0 0 8px rgba(60, 118, 61, 0.5);
}
.ufr-input-inner .ufr-input-component.ufr-input-component--disabled {
  background: #eee;
  color: #777;
}
.ufr-input-inner .ufr-input-component::placeholder {
  color: #cecece;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: normal;
}
.ufr-input-inner.ufr-prefixed-input,
.ufr-input-inner.ufr-postfixed-input {
  display: table;
}
.ufr-input-inner.ufr-prefixed-input .ufr-input-prefix,
.ufr-input-inner.ufr-postfixed-input .ufr-input-prefix {
  border-right: none;
  border-radius: 2px 0 0 2px;
}
.ufr-input-inner.ufr-prefixed-input .ufr-input-postfix,
.ufr-input-inner.ufr-postfixed-input .ufr-input-postfix {
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ufr-input-inner.ufr-prefixed-input.ufr-postfixed-input .ufr-input-component {
  border-radius: 0;
}
.ufr-input-inner.ufr-prefixed-input .ufr-input-component {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ufr-input-inner.ufr-postfixed-input .ufr-input-component {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ufr-input-inner .ufr-input-prefix,
.ufr-input-inner .ufr-input-postfix {
  display: table-cell;
  padding: 5px 10px;
  height: 100%;
  width: 1%;
  vertical-align: top;
  background: #eee;
  color: #555;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #ccc;
  text-align: center;
}
.ufr-input-inner.ufr-number-inner {
  height: 34px;
}
.ufr-input-inner.ufr-number-inner .ufr-input-postfix,
.ufr-input-inner.ufr-number-inner .ufr-input-prefix {
  width: initial;
}
.ufr-input-inner.ufr-number-inner.ufr-postfixed-input .ufr-input-component {
  border-right: none;
}
.ufr-input-inner.ufr-number-inner input[type='number'] {
  padding: 6px 0 6px 11px;
}
.ufr-input-inner.ufr-number-inner input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
  -webkit-appearance: none;
  background: #eee url('arrows.png') no-repeat 50% 50%;
  cursor: pointer;
  display: block;
  width: 22px;
  height: 34px;
  position: relative;
  right: 0;
}
.ufr-input-inner.ufr-number-inner input[type='number'].ufr-input-component--disabled::-webkit-inner-spin-button {
  background: none;
}
.ufr-input-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.ufr-input-wrapper .ufr-character-count {
  position: absolute;
  right: 0;
}
.ufr-input-wrapper textarea {
  vertical-align: top;
}
.ufr-input-wrapper p {
  margin-top: 5px;
}
