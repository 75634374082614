.ufr-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 26px;
  background: #fff;
  min-height: 62px;
}
@media all and (max-width: 450px) {
  .ufr-modal-footer {
    flex-direction: column;
  }
  .ufr-modal-footer * {
    text-align: center;
  }
  .ufr-modal-footer .modal-v3-footer {
    min-height: 94px;
  }
  .ufr-modal-footer .ufr-btn-back {
    margin: 0 auto;
  }
}
