.ufr-shout-spinner {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 150px auto;
  border-radius: 50%;
  font-size: 10px;
  background: pink;
  background: linear-gradient(to right, pink 10%, rgba(255, 255, 255, 0) 42%);
  animation: spinning 1s infinite linear;
}
.ufr-shout-spinner::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  margin: auto;
  background: #f9f9f9;
  content: '';
}
@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
