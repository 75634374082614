@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-enrichment-sources-import-errors-data-table .rt-tbody {
  padding-top: 15px;
}
#ufr-enrichment-sources-import-errors-data-table .rt-tr-group .rt-tr {
  min-height: 72px;
}
#ufr-enrichment-sources-import-errors-data-table .ufr-dt-enrichment-sources-import-errors-data-cell {
  white-space: inherit;
  padding-right: 100px;
}
#ufr-enrichment-sources-import-errors-data-table .ufr-dt-enrichment-sources-import-errors-data-cell .import-error-fields {
  font-weight: bold;
}
