@import '../shared/styles/mixins.less';

#sidenav .sidenav-header {
  width: 250px;
  height: 80px;
  padding: 15px;
  transition: padding 0.3s;

  > * {
    display: inline-block;
  }

  // Favicon
  .sidenav-header-image {
    height: 50px;
    width: 50px;
  }

  // Glyphicon
  > .react-icon {
    width: 50px;
    text-align: center;
    font-size: 40px;
    line-height: 47px;

    &.glyphicons-group {
      font-size: 38px;
    }
  }

  // Placeholder
  .empty-avatar-icon {
    border: 2px dashed #ddd;
    border-radius: 10px;
    width: 46px;
    height: 46px;
    margin: 2px;
    padding-top: 5px;
    text-align: center;

    .react-icon {
      font-size: 30px;
      color: #ddd;
    }
  }

  .sidenav-header-text {
    height: 50px;
    vertical-align: top;
    padding: 6px 0 0 15px;
    max-width: 170px;
    transition: 0.3s;

    .sidenav-header-title {
      line-height: 20px;
      font-size: 16px;
      font-weight: bold;
      max-width: 155px;
      .truncate;
    }

    .sidenav-header-subtitle,
    .sidenav-rss-link {
      display: inline-block;
      line-height: 15px;
      font-size: 11px;
      vertical-align: top;
    }

    .sidenav-rss-link {
      margin-left: 1px;
    }

    .sidenav-header-subtitle {
      max-width: 154px;
      .truncate;

      &.sidenav-subtitle-with-rss {
        max-width: 126px;
      }
    }
  }
}

// Narrow sidenav
@media (max-width: 1300px) {
  #sidenav:not(:hover) .sidenav-header {
    padding: 5px;
    transition: padding 0.3s;

    .sidenav-header-text {
      opacity: 0;
      transition: 0.3s;
    }

    // Glyphicon
    .react-icon {
      &.glyphicons-group {
        font-size: 24px;
        transition: 0.3s;
      }
    }
  }
}
