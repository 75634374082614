@import '../../shared/styles/variables';
@import '../../shared/styles/mixins';

// mixin
.chevron-after {
  content: '';
  position: absolute;
  top: 11px;
  width: 0;
  height: 0;
  margin-left: 8px;
  vertical-align: middle;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.ufr-react-table.ReactTable {
  margin-top: 25px;
  border: none;

  .rt-thead.-header {
    box-shadow: none;
    border-bottom: 1px solid #ccc;

    .rt-th {
      border: none;
      box-shadow: none;
      transition: none;
      flex: none;
      text-align: left;

      &.-cursor-pointer:hover {
        color: @uberPink;
      }

      .header-div {
        display: inline-block;
      }

      > div {
        .header-div;

        &:focus {
          .focus-outline;
        }
      }

      &.-sort-asc > div:first-child {
        &::after {
          .chevron-after;

          border-bottom: 7px dashed;
        }
      }

      &.-sort-desc > div:first-child {
        .header-div;

        &::after {
          .chevron-after;

          border-top: 7px dashed;
        }
      }
    }
  }

  .rt-tbody {
    .rt-td,
    .rt-tr-group {
      border: none;
    }

    .rt-tr-group {
      &:hover {
        background: @lightGrey;
      }
    }
  }
}
