@import './variables';

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-unstyle(@font-family: inherhit, @font-size: inherit, @line-height: inherit) {
  background: 0 0;
  border: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: @font-size;
  font-family: @font-family;
  font-weight: inherit;
  line-height: @line-height;

  &:active {
    box-shadow: none;
  }
}

.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px @uberBlueLight;
}

.ellipsis-at(@max-width) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: @max-width;
}
