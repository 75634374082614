.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-action-button {
  padding: 4px 8px;
  height: 41px;
  white-space: nowrap;
}
.ufr-action-button:not(:last-child) {
  border-right: 1px solid #ccc;
}
.ufr-action-button button {
  background: 0 0;
  border: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: Roboto, sans-serif;
  font-weight: inherit;
  line-height: 1;
  font-size: 13px;
  border-radius: 2px;
  padding: 4px;
  height: 32px;
}
.ufr-action-button button:active {
  box-shadow: none;
}
.ufr-action-button button .button-text {
  margin: 0 4px 0 10px;
}
.ufr-action-button button .button-text:only-child {
  margin-right: 10px;
}
.ufr-action-button button .react-icon {
  font-size: 12px;
  position: relative;
  top: 1px;
  margin: 0 3px 0 3px;
  vertical-align: initial;
}
.ufr-action-button button:focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-action-button button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
