@import '../shared/styles/variables';

.ufr-align-input-container {
  align-items: center;
  display: flex;

  .ufr-reset-setting-container {
    margin-left: 30px;

    .ufr-reset-setting {
      font-weight: normal !important;
    }
  }
}
