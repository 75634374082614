.inline-submit-btn {
  position: absolute;
  padding: 2px 0 0;
  font-size: 12px;
  cursor: pointer;
  background: none;
  border-radius: 3px;
  border: 1px solid #ccc;
  color: #8c8f91;
  margin-left: 8px;
}
.inline-submit-btn:hover {
  background: #e4e4e4;
}
.inline-submit-btn.submitted-state .inline-submit-btn {
  color: white;
  background: #ce0058;
  transition: all 300ms ease-in;
}
.inline-submit-btn.large-submit-btn {
  width: 42px;
  height: 42px;
  font-size: 17px;
}
.inline-submit-btn.small-submit-btn {
  width: 25px;
  height: 25px;
  font-size: 13px;
}
