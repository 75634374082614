@import "../../shared/styles/base";
@import "../../shared/styles/mixins";

#ufr-enrichment-sources-import-errors-data-table {
  .rt-tbody {
    padding-top: 15px;
  }

  .rt-tr-group .rt-tr {
    min-height: 72px;
  }

  .ufr-dt-enrichment-sources-import-errors-data-cell {
    white-space: inherit;
    padding-right: 100px;

    .import-error-fields {
      font-weight: bold;
    }
  }
}
