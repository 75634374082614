.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-stream-page-preview .preview-buttons {
  text-align: right;
  width: 200px;
}
.ufr-stream-page-preview .preview-buttons button {
  background: 0 0;
  border: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherhit;
  font-weight: inherit;
  line-height: inherit;
}
.ufr-stream-page-preview .preview-buttons button:active {
  box-shadow: none;
}
.ufr-stream-page-preview .preview-buttons .preview-action {
  padding: 5px 10px 0;
  border: 1px solid #b9b9b9;
  margin: 5px;
  border-radius: 3px;
}
.ufr-stream-page-preview .preview-buttons .preview-action .react-icon {
  overflow: visible;
}
.ufr-stream-page-preview .preview-buttons .preview-action:last-child {
  margin-right: 0;
}
.ufr-stream-page-preview .preview-buttons .preview-text {
  margin-right: 10px;
}
