.advanced-options-position {
  margin-left: 14px;

  .advanced-options-seo-accordion {
    padding-left: 2px;
    display: inline-block;
    width: 39%;
    margin-top: 25px;
    border-bottom: 1px solid #d1d1d1;

    &:hover {
      cursor: pointer;
      border-bottom: 1px solid #cf075c;
    }

    .advanced-options-seo-label {
      float: left;
    }

    span {
      float: right;
    }
  }

  table.api-dt {
    thead > tr > th {
      &:first-child {
        width: 230px;
      }
    }
  }
}
