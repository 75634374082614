@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.ufr-control-bar-search {
  position: relative;
  width: 100%;
}
.ufr-control-bar-search > div {
  margin: 0 10px;
}
.ufr-control-bar-search > div .react-icon {
  position: absolute;
  left: 36px;
  top: 13px;
  font-size: 16px;
  color: #777;
}
.ufr-control-bar-search > div input {
  width: 100%;
  height: 41px;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 13px 40px 12px 50px;
  font-size: 13px;
  font-family: Roboto, sans-serif;
}
.ufr-control-bar-search > div input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-control-bar-search > div input:disabled {
  background: #eee;
  cursor: not-allowed;
}
