@import url('../../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-streams-data-table .ufr-dt-streams-listing-stream-name-cell,
#ufr-archived-streams-data-table .ufr-dt-streams-listing-stream-name-cell,
#ufr-streams-data-table .ufr-dt-streams-listing-stream-owner-cell,
#ufr-archived-streams-data-table .ufr-dt-streams-listing-stream-owner-cell {
  display: flex;
  align-items: center;
}
#ufr-streams-data-table .ufr-dt-archived-streams-listing-buttons-cell,
#ufr-archived-streams-data-table .ufr-dt-archived-streams-listing-buttons-cell {
  justify-content: flex-start;
}
#ufr-streams-data-table .ufr-dt-archived-streams-listing-buttons-cell .ufr-btn-icon,
#ufr-archived-streams-data-table .ufr-dt-archived-streams-listing-buttons-cell .ufr-btn-icon {
  opacity: 1;
}
#ufr-streams-data-table .ufr-stream-internal-name a,
#ufr-archived-streams-data-table .ufr-stream-internal-name a {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}
#ufr-streams-data-table .ufr-stream-internal-name,
#ufr-archived-streams-data-table .ufr-stream-internal-name,
#ufr-streams-data-table .ufr-stream-owner,
#ufr-archived-streams-data-table .ufr-stream-owner {
  padding-left: 8px;
}
#ufr-streams-data-table .ufr-datatable-thumbnail,
#ufr-archived-streams-data-table .ufr-datatable-thumbnail {
  display: block;
}
#ufr-streams-data-table .ufr-datatable-thumbnail-hidden,
#ufr-archived-streams-data-table .ufr-datatable-thumbnail-hidden {
  background-color: rgba(51, 51, 51, 0.5);
  position: relative;
}
#ufr-streams-data-table .ufr-datatable-thumbnail-hidden > .ufr-datatable-thumbnail,
#ufr-archived-streams-data-table .ufr-datatable-thumbnail-hidden > .ufr-datatable-thumbnail {
  opacity: 0.5;
}
#ufr-streams-data-table .ufr-datatable-thumbnail-hidden:before,
#ufr-archived-streams-data-table .ufr-datatable-thumbnail-hidden:before {
  content: 'Hidden';
  background: #fff;
  padding: 0 3px;
  position: absolute;
  right: 0;
  z-index: 1;
  font-size: 10px;
  font-weight: 700;
  color: #555;
  line-height: 13px;
  margin: -1px;
}
#ufr-streams-data-table .ufr-stream-internal-name,
#ufr-archived-streams-data-table .ufr-stream-internal-name,
#ufr-streams-data-table .ufr-stream-title,
#ufr-archived-streams-data-table .ufr-stream-title {
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  word-wrap: break-word;
}
#ufr-streams-data-table .ufr-modal-header-title,
#ufr-archived-streams-data-table .ufr-modal-header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#ufr-streams-data-table #ufr-stream-dependencies-error,
#ufr-archived-streams-data-table #ufr-stream-dependencies-error {
  font-family: Roboto, sans-serif;
}
#ufr-streams-data-table #ufr-stream-dependencies-error .ufr-stream-dependency,
#ufr-archived-streams-data-table #ufr-stream-dependencies-error .ufr-stream-dependency {
  margin-bottom: 10px;
}
#ufr-streams-data-table #ufr-stream-dependencies-error .ufr-stream-dependency ul li,
#ufr-archived-streams-data-table #ufr-stream-dependencies-error .ufr-stream-dependency ul li {
  list-style: disc;
  margin-left: 24px;
}
#ufr-streams-data-table .rt-noData #ufr-no-data-create-stream-btn,
#ufr-archived-streams-data-table .rt-noData #ufr-no-data-create-stream-btn {
  margin-top: 22px;
}
.ufr-about-hub-modal .ufr-modal-body {
  overflow: hidden;
}
#ufr-internal-stream-name-tooltip,
#ufr-stream-title-tooltip {
  max-width: 100%;
  word-break: break-all;
}
