.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#sidenav .sidenav-actions {
  position: relative;
  height: 46px;
  text-align: center;
  padding: 12px;
  width: 250px;
}
#sidenav .sidenav-actions button {
  background: 0 0;
  border: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherhit;
  font-weight: inherit;
  line-height: inherit;
}
#sidenav .sidenav-actions button:active {
  box-shadow: none;
}
#sidenav .sidenav-actions .sidenav-action {
  display: inline-block;
  padding: 3px 6px;
  transition: 0.3s;
}
#sidenav .sidenav-actions .sidenav-action:nth-child(2) {
  margin-left: 20px;
}
#sidenav .sidenav-actions .sidenav-action .react-icon {
  line-height: 16px;
  font-size: 16px;
  color: #333;
  transition: color 0.1s;
  overflow: hidden;
}
#sidenav .sidenav-actions .sidenav-action:hover .react-icon,
#sidenav .sidenav-actions .sidenav-action:focus .react-icon {
  color: inherit !important;
}
#sidenav .sidenav-actions .narrow-sidenav-icon {
  opacity: 0;
  position: absolute;
  width: 60px;
  height: 46px;
  left: 0;
  top: 0;
  line-height: 16px;
  font-size: 26px;
  padding: 10px;
  transition: 0.3s;
}
#sidenav .sidenav-actions .narrow-sidenav-icon .glyphicons-eye-open {
  padding-top: 3px;
  font-size: 16px;
}
#sidenav .sidenav-actions.sidenav-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f9f9f9;
}
#sidenav .sidenav-actions.sidenav-footer .sidenav-action:nth-child(2) {
  margin-right: 20px;
}
#sidenav .sidenav-actions.sidenav-footer .sidenav-action .react-icon {
  overflow: visible;
}
@media (max-width: 1300px) {
  #sidenav:not(:hover) .sidenav-actions .sidenav-action {
    opacity: 0;
    transition: 0.3s;
  }
  #sidenav:not(:hover) .sidenav-actions .narrow-sidenav-icon {
    opacity: 1;
    transition: 0.3s;
  }
}
