// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// ----------------------------------------------------------------------------------------------------------
// Integrations Section
// ----------------------------------------------------------------------------------------------------------

.mgmt-container {
  .hubs-section-integrations {
    font-family: @roboto;
    padding: 0 20px 38px;

    // Black Navbar
    .navbar.integrations-navbar {
      margin-bottom: 20px;

      .glyphicons {
        font-size: 15px;

        &:before {
          padding: 0.5px 6px 0 11px;
        }
      }

      .navbar-nav > li {
        background-color: #eee;

        & > a {
          color: @uberGrayDarker;
          text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.25);

          &:hover {
            background: #ddd;
            color: @uberGrayDarker;
          }
        }

        &.active > a,
        &.active:not(.open) > a:not(:hover) {
          background-color: #eee;
          color: @uberPink;
        }
      }
    }

    .table:not(#configurable-hub-apps-table) {
      tr {
        td {
          padding: 0;
          width: 25%;

          .padded-cell {
            padding: 8px;

            &.action-buttons {
              // Disconnected by default
              .hide-when-connected { display: block; }
              .hide-when-disconnected { display: none; }

              // When Connected

              &.service-connected {
                .hide-when-connected { display: none; }
                .hide-when-disconnected { display: block; }
              }
            }
          }

          &:first-child {
            width: 30px;
            max-width: 30px;
          }

          &:last-child {
            width: 200px;
            max-width: 200px;
          }
        }
      }
    }

    #app-configurations-tab-content > div {
      margin-bottom: 20px;
    }

    .ufr-input-container {
      padding-top: 20px;
    }

    .ufr-form-section-header {
      div {
        border-bottom-color: #eee;
      }
    }
  }
}

/* integration logos (within modals) */

.integration-logo {
  text-align: center;

  span {
    margin: 0px auto;
    display: block;
    position: relative;
    height: 90px;
    width: 130px;
    text-indent: -99999px;
    background: transparent;
    background-repeat: no-repeat;
    background-image: url("/img/integrations/spritesheet.png");
    background-position: -99999px -99999px;

    &.disqus {
      background-position: -610px -340px;
    }

    &.bombora {
      background-position: -830px -605px;
    }

    &.google-analytics {
      background-position: -460px -120px;
    }

    &.pinterest {
      background-position: -10px -10px;
    }

    &.brightinfo {
      background-position: -1050px -340px;
    }

    &.optimizely {
      background-position: -1198px -15px;
    }

    &.google-tag-manager {
      background-position: -460px -120px;
    }

    &.hubspot {
      background-position: -400px -460px;
      width: 180px;
    }

    &.salesforce {
      background-position: -210px -598px;
      width: 180px;
      height: 100px;
    }
  }
}

ol.verification-steps li {
  line-height: 28px;
}
