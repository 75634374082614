.ufr-stream-page {
  display: flex;
}
.ufr-stream-page .ufr-stream-page-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.ufr-stream-page .ufr-stream-page-actions .ufr-stream-page-publish {
  margin-bottom: 16px;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header {
  flex-grow: 1;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-internal-stream-name {
  margin-top: 16px;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-internal-stream-name .ufr-stream-info {
  display: flex;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-internal-stream-name .ufr-stream-info .ufr-stream-published-status {
  padding-top: 2px;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-internal-stream-name .ufr-stream-info .ufr-stream-id {
  padding: 8px 0 0 8px;
  margin-right: 37px;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-internal-stream-name .editable-text-static-header {
  padding: 4px 0;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-internal-stream-name .ufr-page-header-title-internal {
  font-size: 26px;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-internal-stream-name .ufr-page-header-title-internal #input-title {
  font-size: inherit;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-external-stream-name {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 8px;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-external-stream-name .ufr-page-header-title {
  margin: 0;
  padding: 4px 0;
  font-size: 16px;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-external-stream-name .ufr-page-header-title .editable-text-static-header {
  padding: 4px 0;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-external-stream-name .ufr-page-header-subtitle {
  margin: 0;
  padding: 4px 0;
}
.ufr-stream-page #ufr-page-header.ufr-stream-page-header .ufr-external-stream-name .ufr-page-header-subtitle .editable-text-static-header {
  padding: 4px 0;
}
