@import "../shared/styles/base";
@import "../shared/styles/variables";
@import "../shared/styles/mixins";

@tileHeight: 200px;
@tileWidth: 170px;
@imageHeight: 107px;
@iconHeight: 24px;

.ufr-item-tile {
  position: relative;
  cursor: pointer;
  width: @tileWidth;
  height: @tileHeight;
  display: inline-block;
  margin: 0 20px 20px 0;
  text-align: left;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  .ufr-selected-icon {
    position: absolute;
    top: 7px;
    right: 6px;
    background: white;
  }

  .ufr-item-tile-image {
    width: 100%;
    height: @imageHeight;
    background-size: cover;

    ~ .ufr-item-tile-bottom-text {
      // so that it stands out from the background image
      box-shadow: @boxShadow;
    }
  }

  .ufr-service-icon {
    position: absolute;
    top: @imageHeight - @iconHeight;
    left: 0;
  }

  .ufr-item-tile-header {
    position: absolute;
    top: @imageHeight;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 6px;
    overflow: hidden;

    p {
      font-weight: 700;
      font-size: 12px;
      font-family: @roboto;
    }

    &:before {
      background: linear-gradient(transparent 170px, #fff);
    }
  }

  .ufr-item-tile-bottom-text {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 24px;
    text-align: left;
    padding: 5px 7px 0;
    background-color: @darkBackground;
    color: white;
    font-size: 10.5px;
    .truncate;
  }

  &.ufr-item-tile-disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:focus {
      outline: 0;
    }
  }
}
