.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group {
  border-right: 1px solid #ccc;
  padding: 4px 8px;
  height: 41px;
  white-space: nowrap;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .dropdown-toggle {
  box-shadow: none;
  background: 0 0;
  border: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: inherit;
  line-height: 1;
  border-radius: 2px;
  padding: 4px 4px 4px 8px;
  height: 32px;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .dropdown-toggle:active {
  box-shadow: none;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .dropdown-toggle .react-icon {
  font-size: 12px;
  position: relative;
  top: 2px;
  margin: 0 3px 0 13px;
  vertical-align: initial;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .dropdown-toggle:focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .dropdown-toggle:disabled {
  color: grey;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .ufr-filter-dropdown-menu {
  margin-top: -5px;
  left: 0;
  min-width: fit-content;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .ufr-filter-dropdown-menu li a {
  min-width: 100px;
  padding: 8px 12px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  line-height: 16px;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .ufr-filter-dropdown-menu li a:hover,
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .ufr-filter-dropdown-menu li a:active,
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .ufr-filter-dropdown-menu li a:focus {
  color: #333 !important;
  background: #fae6ee;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group .ufr-filter-dropdown-menu li a:focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group.open .dropdown-toggle {
  background: none;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group.open .dropdown-toggle:hover,
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group.open .dropdown-toggle:focus {
  border-color: transparent;
}
.ufr-dt-filter-dropdown-toggle.dropdown.btn-group.open .dropdown-menu {
  padding: 0;
  border-radius: initial;
}
