@import '../../shared/styles/base';
@import '../../shared/styles/mixins';

#ufr-custom-code-data-table {
  .ufr-custom-code-name,
  .ufr-custom-code-description,
  .ufr-custom-code-privacy-name {
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    word-wrap: break-word;
  }

  .ufr-modal-header-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

#ufr-custom-code-name-tooltip,
#ufr-custom-code-description-tooltip {
  max-width: 100%;
  word-break: break-all;
}
