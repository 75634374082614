@import url('../../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-items-data-table .ufr-search-by-tags {
  position: absolute;
  padding: 15px;
  height: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 800;
  overflow: hidden;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-search-by-tags-helper-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 50px;
  border: 2px solid #eee;
  margin-top: 10px;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-search-by-tags-helper-text p {
  margin-bottom: 0;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-multi-select-input__menu > div {
  height: 146px;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-multi-select-container {
  width: 500px;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-multi-select-input {
  z-index: 800;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-select-dropdown {
  position: relative;
  width: 500px;
  margin-bottom: 10px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-select-dropdown .ufr-dropdown-button {
  border-radius: 4px;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-select-dropdown .ufr-select-dropdown-container {
  background-color: white;
  width: 500px;
  z-index: 801;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-select-dropdown .ufr-dropdown-search-input input {
  width: 100%;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-select-dropdown .ufr-dropdown-search-input span {
  position: absolute;
  right: 18px;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-select-dropdown .ufr-select-dropdown-item-container {
  width: 100%;
  height: 146px;
}
#ufr-items-data-table .ufr-search-by-tags .ufr-search-by-tags-button-container {
  height: 85px;
  display: flex;
  justify-content: end;
  align-items: end;
}
#ufr-items-data-table .ufr-dt-items-listing-item-name-cell,
#ufr-items-data-table .ufr-dt-items-listing-item-author-cell {
  display: flex;
  align-items: center;
}
#ufr-items-data-table .ufr-dt-items-listing-item-author-cell .react-avatar {
  margin-right: 8px;
}
#ufr-items-data-table .ufr-item-author {
  overflow: hidden;
  text-overflow: ellipsis;
}
#ufr-items-data-table .ufr-datatable-thumbnail {
  display: block;
}
#ufr-items-data-table .ufr-item-name {
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  word-wrap: break-word;
}
#ufr-items-data-table a + .ufr-item-name {
  padding-left: 8px;
}
#ufr-items-data-table .ufr-item-name a,
#ufr-items-data-table .ufr-dt-items-listing-item-streams-count-cell a {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}
#ufr-item-name-tooltip {
  max-width: 100%;
  word-break: break-all;
}
