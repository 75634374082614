@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-dt-passphrases .ufr-dt-passphrases-owner-cell {
  display: flex;
  align-items: center;
}
#ufr-dt-passphrases .ufr-dt-passphrases-owner-cell .react-avatar {
  margin-right: 8px;
}
#ufr-dt-passphrases .ufr-passphrase-owner {
  overflow: hidden;
  text-overflow: ellipsis;
}
#ufr-dt-passphrases .ufr-datatable-thumbnail {
  display: block;
}
#ufr-dt-passphrases .ufr-passphrase,
#ufr-dt-passphrases .ufr-passphrase-description {
  padding-left: 8px;
  max-width: 150px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: normal;
}
#ufr-dt-passphrases .ufr-passphrase a,
#ufr-dt-passphrases .ufr-dt-passphrases-item-streams-count-cell a {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}
#ufr-passphrase-tooltip,
#ufr-passphrase-description-tooltip,
#ufr-passphrase-owner-tooltip {
  max-width: 100%;
  word-break: break-all;
}
