@import '../../shared/styles/base.less';
@import '../../shared/styles/variables.less';

/*
  Note: these styles implement the UF design system specifications.
  please consult the Zeplin UI design guide before making any changes @ app.zeplin.io
  Verify that all changes play nicely with all possible input states by using Storybook.
 */
.ufr-input-container {
  position: relative;
  width: @eightColumns;
  padding: 9px 0 36px;
  max-width: 100%;

  &.ufr-input-container-disabled:hover {
    input.ufr-input-component--disabled,
    button.ufr-input-component--disabled,
    textarea.ufr-input-component--disabled {
      cursor: not-allowed;
    }
  }

  // normalizing text
  .react-input-title,
  .react-input-description {
    font-family: @roboto;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .react-input-description {
    width: 86%;
    font-size: 13px;
    color: @fontColorMedium;
    display: block;
    margin-bottom: 20px;

    &:focus {
      outline: none;
    }
  }

  label.react-input-title {
    font-size: 16px;
    color: @fontColorDark;
    display: block;
    margin-bottom: 0;

    + label.react-input-description {
      margin-top: 9px;
      margin-bottom: 14px;
    }

    &:only-of-type {
      margin-bottom: 2px;
    }
  }
}
