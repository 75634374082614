.tabs-container {
  width: 100%;
}
.tabs-container .meatball-menu {
  position: absolute;
  margin-top: -90px;
  right: 16px;
}
.tabs-container .streams-tabs-wo-appearance {
  min-width: 920px;
}
.tabs-container .streams-tabs {
  min-width: 1166px;
}
.tabs-container .streams-tabs-w-sperf {
  min-width: 1340px;
}
.tabs-container .tabs-list-container {
  font-family: Roboto, sans-serif;
  position: relative;
}
.tabs-container .tabs-list-container .tabs-list ul {
  padding-left: 50px;
}
.tabs-container .tabs-list-container .tabs-list ul li {
  min-width: 123px;
  cursor: pointer;
}
.tabs-container .tabs-list-container .tabs-list ul li.active > a {
  font-weight: bold;
  color: #ce0058;
}
.tabs-container .tabs-list-container .tabs-list ul li:not(.active) > a:hover {
  background-color: rgba(206, 0, 88, 0.04);
  color: #333;
}
.tabs-container .tabs-list-container .tabs-list ul li a {
  padding: 13px;
  font-size: 13px;
  color: #333;
  text-align: center;
}
.tabs-container .tabs-list-container .tabs-list ul li.disabled {
  cursor: not-allowed;
}
.tabs-container .tabs-list-container .tabs-list ul li.disabled a {
  color: #ccc;
}
.tabs-container .tabs-list-container .tabs-list ul li:not(.active) a {
  border-color: lightgray;
}
.tabs-container .tabs-list-container .tabs-list ul li:not(.disabled).active a {
  color: #ce0058;
}
.tabs-container .tabs-list-container .tabs-list .tab-content {
  display: none;
}
.tabs-container .tabs-list-container #stream-tabs-content.tabs-list ul li.streams15-stream-info {
  position: absolute;
  right: 121px;
}
.tabs-container .tabs-list-container #stream-tabs-content.tabs-list ul li.streams15-options {
  position: absolute;
  right: -2px;
}
.tabs-container .tabs-list-container .react-dropdown {
  position: absolute;
  top: 6px;
  right: 6px;
}
@media screen and (max-width: 1650px) {
  .hubs-section-manage-content div[data-hypernova-key='TabsContainer'] {
    overflow-x: auto;
  }
}
