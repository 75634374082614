.ufr-modal-body {
  font-size: 13px;
  max-height: 50vh;
  overflow-y: auto;
  padding: 27px 26px 46px 26px;
  text-align: left;
  white-space: normal;

  .ufr-input-container:first-of-type {
    padding: 0;

    .react-input-title {
      margin-top: 0;
    }
  }

  .ufr-input-container {
    .react-input-title {
      text-align: left;
      font-size: 1.07em;
      line-height: 2em;
      padding-top: 0.5em;
      font-weight: 500;
    }
  }
}
