.ufr-modal.ufr-media-modal {
  margin: 0 24px;
  color: #364a87;
  overflow: visible;
  z-index: 1500;
}
.ufr-modal.ufr-media-modal .ufr-modal-dialog {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  height: 95%;
}
.ufr-modal.ufr-media-modal .ufr-modal-dialog .ufr-modal-content {
  overflow: hidden;
  display: flex;
  height: 95%;
}
.ufr-modal.ufr-media-modal .ufr-modal-dialog .ufr-modal-content .ufr-modal-body {
  width: 100%;
  display: flex;
  height: 100%;
  max-height: 100%;
  padding: 0;
  background-color: #fff;
  font-family: 'IBM Plex Sans', sans-serif;
}
.ufr-media-upload {
  display: none;
  width: 100%;
  height: 100%;
}
.ufr-media-upload.active {
  display: block;
}
