
.icon,
.left-nav > li > a {
  background-image: url("@{imageSprite1x}");
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Section Icons
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.left-nav > li.home > a,
.left-nav > li.menu-home > a {
  background-position: -224px 0;
}

.tile.docs .icon,
.left-nav > li.docs > a,
.left-nav > li.menu-docs > a {
  background-position: -224px -59px;
}

.tile.blogs .icon,
.left-nav > li.blogs > a,
.left-nav > li.menu-blogs > a {
  background-position: -224px -116px;
}

.tile.videos .icon,
.left-nav > li.videos > a,
.left-nav > li.menu-videos > a {
  background-position: -224px -176px;
}

.tile.social .icon,
.left-nav > li.social > a,
.left-nav > li.menu-social > a {
  background-position: -224px -233px;
}

.tile.custom .icon,
.left-nav > li.custom > a,
.left-nav > li.menu-custom > a {
  background-position: -331px 0px;
}

.related-container .tile.single .icon {
  display: none;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Generic Service Icons
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.tile.uberflip .icon,
.left-nav > li.uberflip > a {
  background-position: -224px -59px;
}

.tile.blogpost .icon,
.left-nav > li.blogpost > a {
  background-position: -224px -116px;
}

.tile.vimeo .icon,
.left-nav > li.vimeo > a {
  background-position: -224px -176px;
}

.tile.youtube .icon,
.left-nav > li.youtube > a {
  background-position: -224px -176px;
}

.tile.vidyard .icon,
.left-nav > li.youtube > a {
  background-position: -224px -176px;
}

.tile.twitter .icon,
.left-nav > li.twitter > a {
  background-position: -224px -233px;
}

.tile.instagram .icon,
.left-nav > li.instagram > a {
  background-position: -224px -233px;
}

.tile .icon.star {
  background-position: -282px -284px;
  .opacity(70);
  .border-radius(50%);
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Mini Service Icons
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

div.tile.single > a.view,
li.tile.single > a.view {
  padding-left: 45px;
  background-image: url("@{imageSprite1x}");
}

.tile.single.uberflip > a.view {
  background-position: -385px -5px;
}

.tile.single.twitter > a.view {
  background-position: -385px -55px;
}

.tile.single.instagram > a.view {
  background-position: -385px -505px;
}

.tile.single.youtube > a.view {
  background-position: -800px -800px;
}

.tile.single.vimeo > a.view {
  background-position: -385px -255px;
}

.tile.single.vidyard > a.view {
  background-position: -385px -753px;
}

.tile.single.brightcove > a.view {
  background-position: -385px -753px;
}

.tile.single.blogpost > a.view {
  background-position: -385px -305px;
}

.tile.single.pinterest > a.view {
  background-position: -385px -455px;
}

.tile.single.slideshare > a.view {
  background-position: -385px -587px;
}

.tile.single.facebook > a.view {
  background-position: -385px -105px;
}

.tile.single.wistiar > a.view {
  background-position: -385px -753px;
}
