input:not(.ufr-input-component--disabled):hover {
  cursor: pointer;
}
input.ufr-input-component--disabled:hover {
  cursor: not-allowed;
}
.ufr-input-container .ufr-slider > * {
  display: inline-block;
}
.ufr-input-container .ufr-slider .ufr-input-component--disabled:hover,
.ufr-input-container .ufr-slider .ufr-input-container--disabled:hover {
  cursor: not-allowed;
}
.ufr-input-container .ufr-slider input[type=range] {
  vertical-align: top;
  -webkit-appearance: none;
  margin: 13px 20px 13px 0;
  width: 294px;
}
.ufr-input-container .ufr-slider input[type=range]::-webkit-slider-runnable-track {
  width: 294px;
  height: 5px;
  animate: 0.2s;
  box-shadow: 0 0 0 #222, 0 0 0 #2f2f2f;
  background: #777;
  border-radius: 50px;
  border: 0 solid black;
}
.ufr-input-container .ufr-slider input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 0 #111, 0 0 0 #1e1e1e;
  border: 1px solid #777;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #f9f9f9;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
.ufr-input-container .ufr-slider input[type=range]::-moz-range-track {
  width: 294px;
  height: 5px;
  animate: 0.2s;
  box-shadow: 0 0 0 #222, 0 0 0 #2f2f2f;
  background: #777;
  border-radius: 50px;
  border: 0 solid black;
}
.ufr-input-container .ufr-slider input[type=range].ufr-input-component--disabled::-webkit-slider-thumb {
  background: #eee;
  cursor: not-allowed;
}
.ufr-input-container .ufr-slider input[type=range].ufr-input-component--disabled::-moz-range-thumb {
  background: #eee;
  cursor: not-allowed;
}
.ufr-input-container .ufr-slider input[type=range].ufr-input-component--disabled::-ms-thumb {
  background: #eee;
  cursor: not-allowed;
}
.ufr-input-container .ufr-slider input[type=range]::-ms-thumb {
  box-shadow: 0 0 0 #111, 0 0 0 #1e1e1e;
  border: 1px solid #777;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #f9f9f9;
  cursor: pointer;
}
.ufr-input-container .ufr-slider input[type=range]::-ms-fill-upper {
  background: #777;
  border: 0 solid black;
  border-radius: 100px;
  box-shadow: 0 0 0 #222, 0 0 0 #2f2f2f;
}
.ufr-input-container .ufr-slider input[type=range]::-ms-track {
  width: 294px;
  height: 5px;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 15px 0;
  color: transparent;
}
.ufr-input-container .ufr-slider input[type=range]::-ms-fill-lower {
  background: #777777;
  border: 0 solid black;
  border-radius: 100px;
  box-shadow: 0 0 0 #222, 0 0 0 #2f2f2f;
}
.ufr-input-container .ufr-slider input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 0 #111, 0 0 0 #1e1e1e;
  border: 1px solid #777;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #f9f9f9;
  cursor: pointer;
}
.ufr-input-container .ufr-slider input[type=range]:focus {
  outline: none;
}
.ufr-input-container .ufr-slider input[type=range]:focus::-ms-fill-lower {
  background: #777;
}
.ufr-input-container .ufr-slider input[type=range]:focus::-webkit-slider-runnable-track {
  background: #777777;
}
.ufr-input-container .ufr-slider input[type=range]:focus::-ms-fill-upper {
  background: #777777;
}
