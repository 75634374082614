.editable-text {
  width: 100%;
  overflow-wrap: anywhere;
}
.editable-text > * {
  display: inline-block;
}
.editable-text .editable-text-static-header {
  padding-left: 1px;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.editable-text.large-text .editable-text-static-header {
  line-height: 30px;
  padding: 6px 5px 6px 1px;
  max-width: calc(100% -  44px);
}
.editable-text.large-text.editing-mode {
  max-height: 42px;
}
.editable-text.medium-text .editable-text-static-header {
  line-height: 19px;
  padding: 6px 5px 6px 1px;
  max-width: calc(100% -  21px);
}
.editable-text.medium-text.editing-mode {
  max-height: 42px;
}
.editable-text.small-text .editable-text-static-header {
  line-height: 24px;
  padding: 4.5px 5px 4.5px 1px;
  max-width: calc(100% -  27px);
}
.editable-text.small-text.editing-mode {
  max-height: 25px;
}
