.salesforce-edit-modal .ufr-modal-body {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 26px;
}
.salesforce-edit-modal .sfi-fields {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.salesforce-edit-modal .sfi-field-section {
  border: none;
  font-family: Roboto, sans-serif;
  margin: 0 0 28px;
  padding: 0px;
  width: 448px;
}
.salesforce-edit-modal .sfi-field-section-header {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
}
.salesforce-edit-modal .sfi-field-section-body {
  margin-top: 10px;
  width: 100%;
}
.salesforce-edit-modal .sfi-field-description {
  color: #777;
  font-size: 13px;
}
.salesforce-edit-modal .dropdown-title {
  color: #555;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 0px;
}
.salesforce-edit-modal .ufr-btn.ufr-btn-primary {
  background: #5bc0de;
  border-color: #5bc0de;
}
.salesforce-edit-modal .ufr-btn.ufr-btn-primary:not(.disabled):hover {
  background: #2f96b4;
  border-color: #2f96b4;
}
.salesforce-edit-modal .ufr-btn.ufr-btn-primary:not(.disabled):active {
  background-color: #269abc;
  border-color: #1b6d85;
}
.salesforce-edit-modal .ufr-checkbox-input-container label.react-input-title {
  font-size: 13px;
}
.error {
  color: #d44545;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  margin: 5px;
}
#salesforce-edit-modal-button button {
  outline: none;
}
#salesforce-edit-modal-button span.halflings-edit {
  float: left;
  margin-right: 3px;
  position: relative;
  top: 2px;
}
#salesforce-edit-modal-button div.ufr-modal-outer {
  z-index: 200000;
}
a[data-service=Salesforce] + div[data-hypernova-key=ModalOpener],
#salesforce-edit-modal-button {
  display: inline;
}
