.topnav-dropdown {
  position: relative;
  display: inline-block;
  color: inherit;
}
.topnav-dropdown:hover .topnav-item-button:not(.topnav-item-button-active) {
  background: rgba(51, 51, 51, 0.15);
  color: white;
}
.topnav-dropdown .topnav-link-active .topnav-item-button-active,
.topnav-dropdown:active .topnav-link .topnav-item-button {
  background: rgba(51, 51, 51, 0.5);
  color: white;
}
.topnav-dropdown .topnav-dropdown-content {
  display: none;
  position: absolute;
  min-width: 100%;
  max-width: 300px;
  background: white;
  color: #333;
  border-color: #cdcdcd;
  border-width: 0 1px 1px;
  border-style: solid;
  padding: 0;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.topnav-dropdown.topnav-dropdown-right {
  float: right;
  min-width: 147px;
}
.topnav-dropdown.topnav-dropdown-right .topnav-dropdown-content {
  right: 0;
}
.topnav-dropdown.topnav-dropdown-right > .topnav-link {
  width: 100%;
}
.topnav-dropdown:hover .topnav-dropdown-content {
  display: block;
}
.topnav-dropdown li {
  margin-bottom: 0;
}
.topnav-dropdown .ufr-nav-item-disabled {
  pointer-events: none;
}
