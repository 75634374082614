#marketo-form {
  display: flex;
  align-items: center;

  #marketo-form-id-ufr-input-container.ufr-input-container {
    width: 50px;
  }

  .ufr-input-container {
    .ufr-input-inner.ufr-text-inner {
      .ufr-input-wrapper {
        input {
          width: 50px;
          padding-left: 3px;
          padding-right: 3px;
          text-align: center;
        }
      }
    }
  }

  .or-label {
    margin-left: 3px;
    margin-right: 3px;
  }

  .ufr-shout-spinner {
    height: 50px;
    width: 50px;
  }
}

.marketo-tooltip {
  > .tooltip-inner {
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
