// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

.filters-container {
  font-family: @roboto;
  min-width: 1000px;
  margin: 0 auto;
  display: table;

  .add-filter-container {
    margin-bottom: 20px;

    .add-filter-btn {
      font-size: @font-size-base + 1;
    }
  }

  table.table.filter-table {
    margin-top: 20px;

    thead > tr > th {
      &.sorting_asc,
      &.sorting_desc {
        -webkit-filter: grayscale(100%);
        color: #48484a;
      }

      &.sorting,
      &.sorting_disabled {
        color: #8c8f91;
      }

      &:last-child {
        min-width: 160px;
      }

      &.no-sort {
        background: transparent !important;
        cursor: default;
      }
    }

    tbody > tr {
      &:hover > td {
        &.filter-table-buttons > button {
          opacity: 1;
        }
      }
    }

    td {
      &.filter-desc {
        text-overflow: ellipsis;
        overflow: hidden;

        &.disabled {
          cursor: not-allowed;

          .edit-filter.name {
            pointer-events: none;
          }
        }
      }

      a.edit-filter {
        color: @uberGrayDarker;

        &.name {
          font-weight: bold;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &.filter-table-buttons {
        text-align: right;
        white-space: nowrap;

        > button {
          opacity: 0;
        }

        .halflings {
          color: #8c8f91;
        }

        .disabled {
          cursor: not-allowed;

          .halflings {
            opacity: 0.65;
          }
        }
      }

      &.text-center {
        padding-right: 40px;
      }
    }
  }

  .empty-filter-container {
    margin: 0 0 40px 0;

    .no-filters {
      display: block;
      margin-top: 150px;
      font-size: (@font-size-base + 48);
      color: lightgray;
    }

    .no-filters-text {
      margin-top: 20px;
      font-size: (@font-size-base + 4);
      font-weight: bold;
      color: lightgray;
    }
  }
}

.filter-edit-modal.disabled {
  .edit-actions-container {
    cursor: not-allowed;

    span {
      pointer-events: none;
    }
  }

  .search-criteria-value,
  .action-value {
    cursor: not-allowed;

    .edit-link {
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: @sidemenuCollapseWidth) {
  .filters-container {
    min-width: 100%;
  }
}
