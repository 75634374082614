.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
/* stylelint-disable value-list-comma-space-after no-descending-specificity*/
.ufr-page-control {
  display: inline-block !important;
  padding: 16px 0 !important;
  margin: 0 !important;
  float: right;
  font-family: Roboto, sans-serif;
}
.ufr-page-control .normalize-active-span {
  border-color: #e1e2e1;
  color: #333;
  border-bottom: 2px solid #ce0058;
}
.ufr-page-control li.active span {
  border-color: #e1e2e1;
  color: #333;
  border-bottom: 2px solid #ce0058;
  background: white;
  padding: 5px 10px 4px;
  font-size: 13px;
}
.ufr-page-control li.active span:hover {
  border-color: #e1e2e1;
  color: #333;
  border-bottom: 2px solid #ce0058;
  background: #fae6ee;
}
.ufr-page-control a[role=button] {
  font-size: 13px;
  color: #333;
}
.ufr-page-control a[role=button]:hover {
  background: #fae6ee;
}
.ufr-page-control a[role=button]:focus {
  z-index: 4;
  background: white;
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-page-control li.disabled span,
.ufr-page-control li.active.disabled span,
.ufr-count-dropdown select:disabled {
  color: #333;
  background: #eee;
  pointer-events: none;
  font-size: 13px;
}
