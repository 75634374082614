.ufr-editable-span {
  border: 1px solid #eee;
  padding: 3px 7px;
  background: #eee;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    border-color: #ccc;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
