.mp-drpDwn {
  display: inline-flex;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0.5rem;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  width: 180px;
  background: #fff;
  border-color: #e4e8ec;
  border-radius: 20px;
}
.mp-drpDwn-active {
  background: #dfebf2;
}
.mp-drpdwn-options {
  display: inline-block;
  padding-left: 1rem;
  /* 16px */
  padding-right: 1rem;
  /* 16px */
  margin-top: 5px;
  background: #fff;
  width: 180px;
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px #030d3626;
  position: absolute;
  z-index: 9;
}
.mp-drpdwn-link {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #364a87;
  background-color: transparent;
  border: none;
}
.mp-drpdwn-options li {
  padding-top: 10px;
  padding-bottom: 0.5rem;
  /* 8px */
  padding-left: 1rem;
  /* 16px */
  padding-right: 1rem;
  /* 16px */
  margin-top: 2px;
}
.mp-drpdwn-options li:hover {
  background: #ce005814;
  border-radius: 8px;
}
.mp-drpdwn-options li:hover > a {
  color: #cc0058;
}
.hidden-element {
  display: none;
}
.visible-element {
  visibility: visible;
}
.ufr-app-menu-background {
  display: block;
  height: 36px;
  padding: 6px;
  width: 36px;
  margin: 2px;
  border-radius: 4px;
  background: #f6eef1;
  cursor: pointer;
  border: 1px solid #8fa5b2;
}
#app-configuration-tab .ufr-app-menu-background,
.hubs-section-integrations .ufr-app-menu-background {
  display: none;
}
