@import "../../shared/styles/variables";

@track-color: #777;
@thumb-color: #f9f9f9;
@thumb-color-disabled: #eee;

@thumb-radius: 50%;
@thumb-height: 15px;
@input-margin-vertical: 13px;
@thumb-width: @thumb-height;
@thumb-shadow-size: 0;
@thumb-shadow-blur: 0;
@thumb-shadow-color: #111;
@thumb-border-width: 1px;
@thumb-border-color: #777;

@track-width: @fourColumns;
@track-height: 5px;
@track-shadow-size: 0;
@track-shadow-blur: 0;
@track-shadow-color: #222;
@track-border-width: 0;
@track-border-color: black;

@track-radius: 50px;
@contrast: 0%;

.shadow(@shadow-size, @shadow-blur, @shadow-color) {
  box-shadow: @shadow-size @shadow-size @shadow-blur @shadow-color, 0 0 @shadow-size lighten(@shadow-color, 5%);
}

.track() {
  width: @track-width;
  height: @track-height;
  animate: 0.2s;
}

input:not(.ufr-input-component--disabled):hover {
  cursor: pointer;
}

input.ufr-input-component--disabled:hover {
  cursor: not-allowed;
}

.thumb() {
  .shadow(@thumb-shadow-size, @thumb-shadow-blur, @thumb-shadow-color);

  border: @thumb-border-width solid @thumb-border-color;
  height: @thumb-height;
  width: @thumb-width;
  border-radius: @thumb-radius;
  background: @thumb-color;
  cursor: pointer;
}

.ufr-input-container .ufr-slider {
  > * {
    display: inline-block;
  }

  .ufr-input-component--disabled:hover,
  .ufr-input-container--disabled:hover {
    cursor: not-allowed;
  }

  input[type=range] {
    vertical-align: top;
    -webkit-appearance: none;
    margin: @input-margin-vertical 20px @input-margin-vertical 0;
    width: @track-width;

    &::-webkit-slider-runnable-track {
      .track();
      .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);

      background: @track-color;
      border-radius: @track-radius;
      border: @track-border-width solid @track-border-color;
    }

    &::-webkit-slider-thumb {
      .thumb();

      -webkit-appearance: none;
      margin-top: ((-@track-border-width * 2 + @track-height) / 2) - (@thumb-height / 2);
    }

    &::-moz-range-track {
      .track();
      .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);

      background: @track-color;
      border-radius: @track-radius;
      border: @track-border-width solid @track-border-color;
    }

    &.ufr-input-component--disabled::-webkit-slider-thumb {
      background: @thumb-color-disabled;
      cursor: not-allowed;
    }

    &.ufr-input-component--disabled::-moz-range-thumb {
      background: @thumb-color-disabled;
      cursor: not-allowed;
    }

    &.ufr-input-component--disabled::-ms-thumb {
      background: @thumb-color-disabled;
      cursor: not-allowed;
    }

    &::-ms-thumb {
      .thumb();
    }

    &::-ms-fill-upper {
      background: @track-color;
      border: @track-border-width solid @track-border-color;
      border-radius: @track-radius*2;
      .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
    }

    &::-ms-track {
      .track();

      background: transparent;
      border-color: transparent;
      border-width: @thumb-width 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: darken(@track-color, @contrast);
      border: @track-border-width solid @track-border-color;
      border-radius: @track-radius*2;
      .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
    }

    &::-moz-range-thumb {
      .thumb();
    }

    &:focus {
      outline: none;
    }

    &:focus::-ms-fill-lower {
      background: @track-color;
    }

    &:focus::-webkit-slider-runnable-track {
      background: lighten(@track-color, @contrast);
    }

    &:focus::-ms-fill-upper {
      background: lighten(@track-color, @contrast);
    }
  }
}
