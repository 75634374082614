@import "../styles/variables";
@import "../styles/mixins";

.ufr-upload-btn-container {
  display: inline-block;

  &:hover {
    cursor: not-allowed;
  }
}

.ufr-btn {
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: @roboto;

  &.disabled {
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: none;
  }

  &:not(.disabled):focus {
    .focus-outline;
  }

  + .ufr-btn {
    margin-left: 10px;

    @media (max-width: 450px) {
      margin: 10px 0px 0px;
    }
  }

  + .ufr-btn-block {
    margin: 10px 0;

    + .ufr-btn {
      margin-left: 0;
    }
  }

  &.ufr-btn-block {
    display: flex;
  }

  &.ufr-btn-primary {
    background: @uberPink;
    border-color: @uberPink;
    color: white;

    &:active {
      background: #98114b;
      border-color: #98114b;
    }

    &:not(.disabled):hover {
      background: #9c0042;
      border-color: #9c0042;
    }

    &:disabled {
      background: @disabledBtn !important;
      border-color: @disabledBtn;
      color: white;
      opacity: 1 !important;

      &:hover {
        background: @disabledBtnHover !important;
        cursor: not-allowed;
      }
    }

    > .glyphicons::before,
    > .halflings {
      color: white !important;
      padding-right: 4px;
    }
  }

  &.ufr-btn-icon {
    font-size: 12px;
    padding: 8px 0px 5px 0px;
    height: 30px;
    width: 30px;
    color: @iconGrey;

    &.disabled {
      opacity: 60%;
    }

    > .glyphicons::before,
    > .halflings {
      padding-right: 0px !important;
    }
  }

  &.ufr-btn-secondary {
    font-weight: bold;
    color: @uberPink;
  }

  &.ufr-btn-icon,
  &.ufr-btn-secondary {
    background: #fff;
    border-color: #ccc;

    &:not(.disabled):active {
      background: #bebebe;
      border-color: #9c0042;
    }

    &:not(.disabled):hover {
      background: #ce00580a;
    }

    > .glyphicons::before,
    > .halflings {
      color: @uberPink !important;
      padding-right: 4px;
    }
  }

  &.ufr-btn-back {
    color: @fontColorDark;
    border-color: transparent;
    background: transparent;
    border-radius: 0;
    padding-left: 0;

    &:not(.disabled):hover {
      color: @fontColorMedium;
    }

    &:before {
      content: '‹';
      font-size: 16px;
      margin-right: 3px;
    }
  }

  &.ufr-btn-card {
    background: #bebebe;
    border-color: #bebebe;
    color: white;
    font-size: 16px;
    height: 46px;
    padding: 13px 23px;

    &:active {
      background: #8c8c8c;
      border-color: #8c8c8c;
    }

    &:not(.disabled):hover {
      background-color: #a0a0a0;
      border-color: #a0a0a0;
    }
  }
}

.ufr-btn-text {
  color: @uberPink;
  padding: 3px 10px;

  &:hover {
    background-color: rgba(206, 0, 88, 0.04);
  }
}
