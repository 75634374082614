.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
html .ReactTable .rt-tr-group .rt-tr {
  position: relative;
  align-items: center;
}
html .ReactTable .rt-tr-group .rt-tr .rt-td > * {
  vertical-align: middle;
}
html .ReactTable .rt-th > div {
  color: #777;
  font-weight: 500;
}
html .ReactTable .rt-th:hover > div {
  color: #333;
}
html .ReactTable .rt-th.-sort-asc > div,
html .ReactTable .rt-th.-sort-desc > div {
  color: #ce0058;
}
html .ReactTable .rt-noData {
  text-align: center;
  color: #333;
  background-color: initial;
  position: initial;
  transform: initial;
  padding: 40px 20px;
  pointer-events: initial;
}
html .ReactTable .-loading {
  top: 28px;
  background: #ffffff;
}
html .ReactTable .-loading .-loading-inner {
  top: 40%;
}
html .ReactTable .ufr-dt-buttons-cell {
  display: flex;
  justify-content: flex-end;
  min-height: 45px;
  padding-right: 15px;
  overflow: unset !important;
  text-overflow: unset !important;
}
html .ReactTable .ufr-dt-buttons-cell .ufr-btn-icon {
  opacity: 0;
}
html .ReactTable .ufr-dt-buttons-cell .ufr-btn ~ .ufr-btn {
  margin-left: 10px;
}
html .ReactTable .rt-tr-group:hover .ufr-dt-buttons-cell .ufr-btn-icon {
  opacity: 1;
}
