@import url('../../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-privacy-group-data-table .rt-tr-group .ufr-privacy-group-name {
  font-weight: 700;
}
#ufr-privacy-group-data-table .rt-tr-group:hover .ufr-dt-privacy-groups-buttons-cell .ufr-btn-icon {
  opacity: 1;
}
#ufr-privacy-group-data-table .rt-tr-group .rt-tr {
  position: relative;
}
#ufr-privacy-group-data-table .ufr-dt-privacy-groups-buttons-cell {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  overflow: unset !important;
  text-overflow: unset !important;
}
#ufr-privacy-group-data-table .ufr-dt-privacy-groups-buttons-cell .ufr-delete-button {
  margin-left: 10px;
}
#ufr-privacy-group-data-table .ufr-dt-privacy-groups-buttons-cell .ufr-btn-icon {
  opacity: 0;
}
#ufr-privacy-group-data-table .ufr-dt-privacy-groups-buttons-cell ul#ufr-privacy-groups-dependencies {
  margin-left: 22px;
}
#ufr-privacy-group-data-table .ufr-dt-privacy-groups-buttons-cell ul#ufr-privacy-groups-dependencies li {
  list-style: disc;
}
