@import "../shared/styles/base";
@import "../shared/styles/variables";
@import "../shared/styles/mixins";

@tileHeight: 180px;
@tileWidth: 170px;
@headerHeight: 40px;
@cascadeHeight: 20px;

.ufr-stacked-tile {
  position: relative;
  cursor: pointer;
  width: @tileWidth;
  height: @tileHeight + @cascadeHeight;
  display: inline-block;
  margin: 0 20px 20px 0;
  text-align: left;

  .ufr-stacked-tile-main {
    overflow: hidden;
    z-index: 1; // cast a shadow on the cascade
    width: @tileWidth;
    height: @tileHeight;
    position: relative;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    .ufr-stacked-tile-title {
      height: @headerHeight;
      padding: 6px;
      overflow: hidden;

      p {
        font-weight: 700;
        font-size: 12px;
        font-family: @roboto;
      }

      .ufr-selected-icon {
        position: absolute;
        top: 7px;
        right: 6px;
        background: white;
      }
    }

    .ufr-stacked-tile-image {
      width: 100%;
      height: @tileHeight - @headerHeight;
      background-size: cover;

      ~ .ufr-service-icon,
      ~ .ufr-stacked-tile-bottom-text {
        // so that it stands out from the background image
        box-shadow: @boxShadow;
      }
    }

    .ufr-service-icon {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .ufr-stacked-tile-bottom-text {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 24px;
      left: 24px;
      text-align: left;
      padding: 5px 7px 0;
      background-color: @darkBackground;
      color: white;
      font-size: 10.5px;
      .truncate;
    }
  }

  .ufr-stacked-tile-cascade {
    width: @tileWidth;
    height: @cascadeHeight;
    position: absolute;
    bottom: 0;
  }

  &.ufr-stacked-tile-disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:focus {
      outline: 0;
    }
  }
}
