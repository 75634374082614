@import "../../shared/styles/base";
@import "../../shared/styles/mixins";
@import "../../shared/styles/variables";

#ufr-tag-tags-data-table {
  .ufr-control-bar-search > div {
    width: 278px;
    height: 41px;
    margin: 1px 0 0 16.5px;
    border-radius: 2px;
  }

  .ufr-dt-tags-listing-tag-name-cell,
  .ufr-dt-tags-listing-tag-author-cell {
    display: flex;
    align-items: center;
  }

  .ufr-tag-name {
    padding-left: 8px;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    word-wrap: break-word;
  }

  .ufr-tag-name a {
    color: inherit;
    font-weight: bold;
    text-decoration: none;
  }

  .tag-name {
    color: @uberGrayDarker;
    font-weight: bold;

    &:hover,
    &:active {
      color: @uberGrayDarker;
      text-decoration: underline;
    }
  }
}

.save-edit-tag-name {
  display: inline-block;

  span.save-tag-name {
    color: @uberPinkDark;
    padding-left: 8px;
    font-size: 16px;

    &:hover {
      cursor: pointer;
      color: @uberPink;
    }
  }
}

.input-edit-tag-name {
  border: @uberBlueLight;
  box-shadow: 0 0 7px @uberBlueLight;
  border-radius: 3px;
  font-size: 13px !important;
}

.editable-tag-name {
  &:hover {
    span.react-icon.edit-tag-name-icon {
      cursor: pointer;
      opacity: 1;
      color: @uberPinkDark;
      padding-left: 8px;
      font-size: 16px;
    }
  }
}

.edit-tag-name {
  display: inline-block;

  span.react-icon.edit-tag-name-icon {
    opacity: 0;

    &:hover {
      cursor: pointer;
      opacity: 1;
      color: @uberPink !important;
      padding-left: 8px;
      font-size: 16px;
    }
  }
}
