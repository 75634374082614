@import "../../shared/styles/base";
@import "../../shared/styles/mixins";

.ufr-privacy-group-selector {
  position: absolute;

  .ufr-privacy-group-select-dropdown__menu > div {
    max-height: 150px;
  }

  .ufr-privacy-group-select-dropdown__option--is-selected {
    background: none;
    color: #000;
  }

  .ufr-privacy-group-select-dropdown__option--is-focused {
    background-color: @lightGrey;
  }

  .ufr-privacy-group-select-dropdown__option--is-disabled {
    color: @uberPink;
  }
}
