.page-filter {
  min-width: 900px;
}
.page-filter .tabs-list ul {
  border-bottom: none;
}
.page-filter .tabs-list ul li {
  height: 45px;
  min-width: 98px;
  cursor: pointer;
}
.page-filter .tabs-list ul li a {
  padding: 13px;
  font-size: 13px;
  color: #333;
  text-align: center;
}
.page-filter .tabs-list ul li a:hover {
  background-color: #fff;
  color: #777;
  margin: 1px 3px 1px 1px;
  border: none;
  border-bottom: 3px solid rgba(255, 0, 88, 0.3);
}
.page-filter .tabs-list ul li.active a {
  margin: 1px 3px 1px 1px;
  border: none;
  border-bottom: 3px solid #ce0058;
}
.page-filter .tabs-list ul li.active a:hover {
  color: #333;
  cursor: pointer;
}
.page-filter .tabs-list .tab-content {
  display: none;
}
.page-filter .react-dropdown {
  position: absolute;
  top: 6px;
  right: 6px;
}
