@import "../../shared/styles/variables";
@import "../../shared/styles/mixins";

.ufr-dt-filter-dropdown-toggle.dropdown.btn-group {
  border-right: 1px solid @mediumGreyBorder;
  padding: 4px 8px;
  height: 41px;
  white-space: nowrap;

  .dropdown-toggle {
    box-shadow: none;
    .btn-unstyle(@font-family: @roboto, @font-size: 13px, @line-height: 1);

    border-radius: 2px;
    padding: 4px 4px 4px 8px;
    height: 32px;

    .react-icon {
      font-size: 12px;
      position: relative;
      top: 2px;
      margin: 0 3px 0 13px;
      vertical-align: initial;
    }

    &:focus {
      .focus-outline;
    }

    &:disabled {
      color: grey;
    }
  }

  .ufr-filter-dropdown-menu {
    margin-top: -5px;
    left: 0;
    min-width: fit-content;

    li {
      a {
        min-width: 100px;
        padding: 8px 12px;
        font-family: @roboto;
        font-size: 13px;
        line-height: 16px;

        &:hover,
        &:active,
        &:focus {
          color: @fontColorDark !important;
          background: @uberPinkLight;
        }

        &:focus {
          .focus-outline;
        }
      }
    }
  }

  &.open {
    .dropdown-toggle {
      background: none;

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }

    .dropdown-menu {
      padding: 0;
      border-radius: initial;
    }
  }
}
