.ufr-upload-btn-container {
  + .ufr-upload-btn {
    margin-right: 10px;
  }
}

.ufr-btn + .ufr-upload-btn-container {
  margin-left: 10px;

  .ufr-input-component--disabled {
    pointer-events: none;
    opacity: 0.65;
  }
}
