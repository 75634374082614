.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-select-dropdown {
  position: relative;
  width: 294px;
  border: 1px solid #ddd;
  font-family: Roboto, sans-serif;
}
.ufr-select-dropdown .ufr-dropdown-button {
  width: 100%;
  padding: 5px 3px 5px 10px;
  background-color: white;
  border: none;
  text-align: left;
  font-size: 16px;
}
.ufr-select-dropdown .ufr-dropdown-button .ufr-dropdown-selected-item {
  display: inline-block;
  font-size: 16px;
  max-width: 250px;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ufr-select-dropdown .ufr-dropdown-button:focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-select-dropdown .react-icon.glyphicons-chevron-down,
.ufr-select-dropdown .react-icon.glyphicons-chevron-up {
  margin: 7px 5px 5px 5px;
  float: right;
  font-size: 9px;
}
.ufr-select-dropdown .react-icon.glyphicons-chevron-down {
  color: #777;
}
.ufr-select-dropdown .react-icon.glyhicons-chevron-up {
  color: #333;
}
.ufr-select-dropdown .ufr-select-dropdown-container {
  position: absolute;
  top: 30px;
  left: -1px;
  border: 1px solid #ddd;
  border-top: 1px solid white;
  z-index: 15;
}
.ufr-select-dropdown .ufr-select-dropdown-container .ufr-dropdown-search-input {
  margin: 6px 5px 0 5px;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #fff;
  display: none;
}
.ufr-select-dropdown .ufr-select-dropdown-container .ufr-dropdown-search-input.show-search {
  display: block;
}
.ufr-select-dropdown .ufr-select-dropdown-container .ufr-dropdown-search-input > input {
  width: 250px;
  height: 32px;
  padding-left: 5px;
  border: 0;
  outline: none;
  font-size: 16px;
}
.ufr-select-dropdown .ufr-select-dropdown-container .ufr-dropdown-search-input .glyphicons-search {
  margin: 8px 0 0 8px;
  font-size: 16px;
  color: #333;
}
.ufr-select-dropdown .ufr-select-dropdown-container .ufr-select-dropdown-item-container {
  width: 292px;
  padding: 5px 0;
  border-top: 1px solid white;
  background-color: #fff;
  cursor: pointer;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ufr-select-dropdown .ufr-select-dropdown-container .ufr-select-dropdown-item-container .ufr-select-dropdown-item {
  width: 100%;
  padding: 13px 0 13px 10px;
  font-size: 16px;
  color: #333;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ufr-select-dropdown .ufr-select-dropdown-container .ufr-select-dropdown-item-container .ufr-select-dropdown-item.highlighted {
  color: #ce0058;
}
.ufr-select-dropdown .ufr-select-dropdown-container.show-search {
  top: 33px;
}
.ufr-select-dropdown.glyphicon-dropdown {
  width: 80px;
}
.ufr-select-dropdown.glyphicon-dropdown .ufr-dropdown-button {
  font-size: 24px;
}
.ufr-select-dropdown.glyphicon-dropdown .react-icon.glyphicons-chevron-down,
.ufr-select-dropdown.glyphicon-dropdown .react-icon.glyphicons-chevron-up {
  margin: 14px 5px;
}
.ufr-select-dropdown.glyphicon-dropdown .ufr-select-dropdown-container {
  top: 40px;
}
.ufr-select-dropdown.glyphicon-dropdown .ufr-select-dropdown-container .ufr-select-dropdown-item-container {
  width: 78px;
}
.ufr-select-dropdown.glyphicon-dropdown .ufr-select-dropdown-container .ufr-select-dropdown-item-container .ufr-select-dropdown-item {
  font-size: 24px;
  padding: 13px 0px;
  text-align: center;
}
.ufr-multi-select-container {
  font-family: Roboto, sans-serif;
}
.ufr-dropdown-disabled .ufr-select-dropdown:hover {
  cursor: not-allowed;
}
.ufr-dropdown-disabled .ufr-select-dropdown .ufr-input-component--disabled {
  pointer-events: none;
}
.ufr-dropdown-disabled .ufr-select-dropdown button {
  pointer-events: none;
  background: #eee;
  color: #777;
}
