
.react-avatar {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  text-align: center;
  border-radius: 25px;
  background-color: #4a90e2;
  color: white;
  text-transform: uppercase;

  &.react-avatar-default {
    padding-top: 7px;
    margin: -2px 0;
    width: 20px;
    min-width: 20px;
    height: 20px;
    font-size: 8px;
  }

  &.react-avatar-small {
    padding-top: 3.5px;
    margin: 0;
    width: 15px;
    height: 15px;
    font-size: 9px;
  }

  &.react-avatar-medium {
    padding-top: 10px;
    margin: 2px 3px;
    width: 44px;
    height: 44px;
    font-size: 25px;
  }
}
