.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.salesforce-dropdown.dropdown {
  width: 95%;
}
.salesforce-dropdown.dropdown .dropdown-toggle.btn.btn-default {
  align-items: center;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  width: 100%;
}
.salesforce-dropdown.dropdown .dropdown-toggle.btn.btn-default:focus {
  box-shadow: 0px 0px 4px 1px lightskyblue;
  border-radius: 4px;
}
.salesforce-dropdown.dropdown .dropdownError {
  box-shadow: 0px 0px 4px 1px rgba(169, 68, 67, 0.6);
}
.salesforce-dropdown.dropdown .dropdown-toggle.btn[disabled].btn-default {
  background: whitesmoke;
  color: #555;
}
.salesforce-dropdown.dropdown .dropdown-toggle.btn[disabled].btn-default:hover {
  background: whitesmoke;
  color: #555;
}
.salesforce-dropdown.dropdown .salesforce-dropdown-menu.dropdown-menu {
  min-width: 100%;
}
.salesforce-dropdown.dropdown .salesforce-dropdown-menu.dropdown-menu li {
  font-family: Roboto, sans-serif;
  font-size: 13px;
}
.salesforce-dropdown.dropdown .salesforce-dropdown-menu.dropdown-menu li a:hover {
  background: whitesmoke;
}
.salesforce-dropdown.dropdown .salesforce-dropdown-menu.dropdown-menu li a:hover span {
  color: #555;
}
.selected-item > a > span {
  color: #b70150;
}
.dropdown-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.loading-spinner {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid darkgrey;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
