.mailchimp-checkbox {
  display: flex;
  align-items: center;
}
.mailchimp-checkbox .ufr-input-container.ufr-checkbox-input-container {
  padding-bottom: 9px;
  width: auto;
}
.mailchimp-checkbox .ufr-input-container.ufr-checkbox-input-container .ufr-checkbox:checked {
  background-color: #ce0058;
  border-color: #ce0058;
}
.mailchimp-checkbox .ufr-input-container.ufr-checkbox-input-container .ufr-checkbox-wrapper label {
  font-weight: 500;
}
.mailchimp-checkbox .overlay-container {
  display: flex;
  align-items: center;
}
.mailchimp-checkbox .overlay-container > span {
  margin-left: 3px;
  font-size: 11px;
  color: #a4a4a4;
}
.content-to-follow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
}
.content-to-follow .content-to-follow-label {
  margin-right: 3px;
}
.content-to-follow #searchable-select-content-to-follow-dropdown-ufr-input-container {
  width: 321px;
}
.content-to-follow #searchable-select-content-to-follow-dropdown-ufr-input-container .ufr-select-dropdown {
  width: 321px;
}
.content-to-follow #searchable-select-content-to-follow-dropdown-ufr-input-container .ufr-select-dropdown-item-container {
  width: 319px;
}
.content-to-follow #searchable-select-content-to-follow-dropdown-ufr-input-container .ufr-select-dropdown-container .ufr-dropdown-search-input > input {
  width: 274px;
}
.weekly-schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
}
.weekly-schedule .content-to-follow-label {
  margin-right: 3px;
}
.weekly-schedule .content-to-follow-label-z {
  display: flex;
  align-items: center;
  gap: 5px;
}
.weekly-schedule #searchable-select-weekly-schedule-day-dropdown-ufr-input-container,
.weekly-schedule #searchable-select-weekly-schedule-time-dropdown-ufr-input-container {
  width: 137px;
}
.weekly-schedule #searchable-select-weekly-schedule-day-dropdown-ufr-input-container .ufr-select-dropdown,
.weekly-schedule #searchable-select-weekly-schedule-time-dropdown-ufr-input-container .ufr-select-dropdown {
  width: 137px;
}
.weekly-schedule #searchable-select-weekly-schedule-day-dropdown-ufr-input-container .ufr-select-dropdown-item-container,
.weekly-schedule #searchable-select-weekly-schedule-time-dropdown-ufr-input-container .ufr-select-dropdown-item-container {
  width: 135px;
}
.weekly-schedule #searchable-select-weekly-schedule-day-dropdown-ufr-input-container .ufr-select-dropdown-container .ufr-dropdown-search-input > input,
.weekly-schedule #searchable-select-weekly-schedule-time-dropdown-ufr-input-container .ufr-select-dropdown-container .ufr-dropdown-search-input > input {
  width: 90px;
}
.mailchimp-inputs hr {
  width: 570px;
  border-top: 1px solid #eee;
}
