@import '../shared/styles/variables';

.cta-edit-page .ufr-page {
  #ufr-page-header {
    .ufr-page-header-subtitle {
      margin-top: 0px;
      margin-bottom: 0px;
      color: black;
    }
  }

  .cta-edit-page-header {
    padding-top: 42px;

    .cta-edit-page-editable-text-container {
      .ufr-page-header-cta-internal-name .editable-text-static-header {
        font-size: 26px;
      }

      .ufr-page-header-cta-name .editable-text-static-header {
        font-size: 16px;
      }
    }
  }

  .cta-edit-additional-info {
    margin-bottom: 26px;
    color: #777;
  }

  .cta-edit-page-content {
    min-height: 350px;

    #hub-edit-cta-tab-integrations {
      height: 100%;
    }

    #hub-edit-cta-tab-appearance {
      height: 100%;
    }
  }

  .cta-edit-page-action-button {
    width: 100%;
    border-top: solid 1px #e1cfcf;
    margin-bottom: 145px;

    .cta-edit-page-action-button-container {
      display: flex;
      margin-top: 28px;

      button {
        margin-left: 10px;
      }

      .ufr-cta-preview-container {
        button {
          margin-left: 0px !important;
        }
      }

      .cta-edit-embed-icon {
        font-size: 18px;
        margin-left: -3px;
        margin-right: 3px;
        margin-bottom: 3px;
      }

      #ufr-cta-edit-button-next-btn {
        margin-left: auto;
      }
    }
  }
}

#hub-edit-cta-tab-placements {
  display: inline;
}
