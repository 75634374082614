.ufr-image-uploader .ufr-image-preview {
  position: relative;
  display: flex;
  height: 238px;
  background-color: #f9f9f9;
  border: solid 1px #ccc;
}
.ufr-image-uploader .ufr-image-preview.ufr-input-component--disabled:hover,
.ufr-image-uploader .ufr-image-preview.ufr-input-container--disabled:hover {
  cursor: not-allowed;
}
.ufr-image-uploader .ufr-image-preview .image-logo {
  align-self: center;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}
.ufr-image-uploader .ufr-image-preview .ufr-image-delete-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
