.toggle-dropdown {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-size: 13px;
}
.toggle-dropdown span.caret {
  opacity: 0.3;
}
.toggle-dropdown:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.toggle-dropdown:hover span.caret {
  opacity: 1;
}
