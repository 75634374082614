@import "../../shared/styles/base";
@import "../../shared/styles/mixins";

#ufr-lang-links-data-table {
  #make-default-ufr-input-container {
    padding-bottom: 0;
  }

  .ufr-link-title {
    display: inline-block;
    padding-left: 8px;
  }
}
