.cta-edit-page .ufr-page #ufr-page-header .ufr-page-header-subtitle {
  margin-top: 0px;
  margin-bottom: 0px;
  color: black;
}
.cta-edit-page .ufr-page .cta-edit-page-header {
  padding-top: 42px;
}
.cta-edit-page .ufr-page .cta-edit-page-header .cta-edit-page-editable-text-container .ufr-page-header-cta-internal-name .editable-text-static-header {
  font-size: 26px;
}
.cta-edit-page .ufr-page .cta-edit-page-header .cta-edit-page-editable-text-container .ufr-page-header-cta-name .editable-text-static-header {
  font-size: 16px;
}
.cta-edit-page .ufr-page .cta-edit-additional-info {
  margin-bottom: 26px;
  color: #777;
}
.cta-edit-page .ufr-page .cta-edit-page-content {
  min-height: 350px;
}
.cta-edit-page .ufr-page .cta-edit-page-content #hub-edit-cta-tab-integrations {
  height: 100%;
}
.cta-edit-page .ufr-page .cta-edit-page-content #hub-edit-cta-tab-appearance {
  height: 100%;
}
.cta-edit-page .ufr-page .cta-edit-page-action-button {
  width: 100%;
  border-top: solid 1px #e1cfcf;
  margin-bottom: 145px;
}
.cta-edit-page .ufr-page .cta-edit-page-action-button .cta-edit-page-action-button-container {
  display: flex;
  margin-top: 28px;
}
.cta-edit-page .ufr-page .cta-edit-page-action-button .cta-edit-page-action-button-container button {
  margin-left: 10px;
}
.cta-edit-page .ufr-page .cta-edit-page-action-button .cta-edit-page-action-button-container .ufr-cta-preview-container button {
  margin-left: 0px !important;
}
.cta-edit-page .ufr-page .cta-edit-page-action-button .cta-edit-page-action-button-container .cta-edit-embed-icon {
  font-size: 18px;
  margin-left: -3px;
  margin-right: 3px;
  margin-bottom: 3px;
}
.cta-edit-page .ufr-page .cta-edit-page-action-button .cta-edit-page-action-button-container #ufr-cta-edit-button-next-btn {
  margin-left: auto;
}
#hub-edit-cta-tab-placements {
  display: inline;
}
