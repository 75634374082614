// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// ----------------------------------------------------------------------------------------------------------
// Hubs Dashboard/Item Content Score Section
// ----------------------------------------------------------------------------------------------------------

@borderRadiusContentScore: 15px;

.top-box {
  min-height: 300px;
  margin-bottom: 20px;
}

.top-scores {
  background-color: @gray-lighter;

  .top-box();
  .border-radius(@borderRadiusContentScore);
}

.content-tips {
  background-color: @gray-lighter;

  .top-box();
  .border-radius(@borderRadiusContentScore);
}

.top-content {
  background-color: @gray-lighter;

  .border-radius(@borderRadiusContentScore);
}
