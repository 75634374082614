@import '../shared/styles/variables.less';

@visiblePosition: 60px;
@hiddenPosition: -@visiblePosition - 3;
@height: 40px;

.ufr-toaster {
  box-shadow: 0 0 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  right: 60px;
  z-index: 150001;
  padding: 9px 18px 9px 12px;
  margin: 0;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  font-family: @roboto;
  transition: bottom 0.3s;
  max-height: @height;
  bottom: @visiblePosition;
  font-family: @roboto;

  // component mounts, animation starting position
  &.ufr-toaster-is-animating-enter {
    bottom: @hiddenPosition;
  }

  // mounting animation end state
  &.ufr-toaster-is-animating-enter-done {
    bottom: @visiblePosition;
  }

  // component is about to unmount, animation starting position
  &.ufr-toaster-is-animating-exit {
    bottom: @visiblePosition;
  }

  // unmounting animation finish point, component then unmounts and is removed from the DOM
  &.ufr-toaster-is-animating-exit-active {
    bottom: @hiddenPosition;
  }

  p {
    font-size: inherit;
    margin: 0;
    margin-left: 5px;
  }

  &.success {
    &,
    p {
      color: @success-text-color;
    }

    background-color: @success-background-color;
    border-color: @success-border-color;
  }

  &.info,
  &.refresh {
    &,
    p {
      color: @info-text-color;
    }

    background-color: @info-background-color;
    border-color: @info-border-color;
  }

  &.error,
  &.failure {
    &,
    p {
      color: @error-text-color;
    }

    background-color: @error-background-color;
    border-color: @error-border-color;
  }
}
