.slider-container {
  position: relative;
  display: flex;
  width: 100%;
}
.slider-container .slider-bar {
  flex: 1;
  height: 1px;
  background-color: #ddd;
}
.slider-container .slider-dot {
  position: absolute;
  background-color: #888;
  border: 2px solid #fff;
  top: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  transition-property: left;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1);
}
.slider-container .slider-dot.override-animation {
  transition: none;
}
