@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-tag-tagGroups-data-table .ufr-control-bar-search > div {
  width: 278px;
  height: 41px;
  margin: 1px 0 0 16.5px;
  border-radius: 2px;
}
.ufr-dt-tag-listing-tag-items-count-cell > a {
  font-weight: bold;
  color: #ce0058;
  cursor: pointer;
}
