@import '../styles/variables';
@import '../styles/mixins';

.react-btn-dropdown.dropdown.btn-group,
.react-btn-dropdown.dropup.btn-group {
  cursor: pointer;

  &:not(.disabled):hover > .dropdown-toggle {
    background: @uberRubineDark;
    border-color: @uberRubineDark;
  }

  &.disabled > .dropdown-toggle {
    background: @disabledBtn !important;
    border-color: @disabledBtn;
    color: white;
    opacity: 1 !important;
  }

  &.disabled:hover > .dropdown-toggle {
    background: @disabledBtnHover !important;
    cursor: not-allowed;
  }

  .dropdown-toggle {
    height: 32px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 400;
    white-space: nowrap;
    touch-action: manipulation;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 3px;
    font-family: @roboto;
    background: @uberPink;
    border-color: @uberPink;
    color: white !important;

    &:first-child {
      margin-right: 3px;
    }

    &:nth-child(2) {
      height: 32px;
      width: 29px;
    }
  }

  .react-dropdown-menu {
    left: auto;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 4px;
    padding: 8px 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px hsl(0, 0%, 80%);
    background-color: white;

    li {
      a {
        display: flex;
        align-items: center;
        min-width: 180px;
        padding: 0 16px;
        font-size: 13px;
        height: 48px;
        color: @fontColorMedium;

        &:hover {
          background: #eaeaea !important;
          color: @uberPink !important;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }

        .menu-text {
          font-size: 13px;
        }
      }

      &.with-icon {
        a {
          padding-left: 12px;

          .menu-text {
            font-size: 13px;
            padding-left: 10px;
          }

          .react-icon {
            font-size: 14px;

            // Overriding our core css
            opacity: 1;
            line-height: 1;
            width: auto;
            left: 0;
            position: relative;
          }
        }
      }
    }
  }

  &.open {
    button {
      background: @uberRubineDark;
      border-color: @uberRubineDark;
      .focus-outline;
    }
  }
}
