.ufr-toaster {
  box-shadow: 0 0 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  right: 60px;
  z-index: 150001;
  padding: 9px 18px 9px 12px;
  margin: 0;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  transition: bottom 0.3s;
  max-height: 40px;
  bottom: 60px;
  font-family: Roboto, sans-serif;
}
.ufr-toaster.ufr-toaster-is-animating-enter {
  bottom: -63px;
}
.ufr-toaster.ufr-toaster-is-animating-enter-done {
  bottom: 60px;
}
.ufr-toaster.ufr-toaster-is-animating-exit {
  bottom: 60px;
}
.ufr-toaster.ufr-toaster-is-animating-exit-active {
  bottom: -63px;
}
.ufr-toaster p {
  font-size: inherit;
  margin: 0;
  margin-left: 5px;
}
.ufr-toaster.success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.ufr-toaster.success,
.ufr-toaster.success p {
  color: #468847;
}
.ufr-toaster.info,
.ufr-toaster.refresh {
  background-color: #f0f9fd;
  border-color: #d6e9c6;
}
.ufr-toaster.info,
.ufr-toaster.refresh,
.ufr-toaster.info p,
.ufr-toaster.refresh p {
  color: #2f96b4;
}
.ufr-toaster.error,
.ufr-toaster.failure {
  background-color: #f2dede;
  border-color: #eed3d7;
}
.ufr-toaster.error,
.ufr-toaster.failure,
.ufr-toaster.error p,
.ufr-toaster.failure p {
  color: #b94a48;
}
