abbr[title] {
  border: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#reset-display-order-modal {
  .reset-sort-field,
  .reset-sort-order {
    padding: 10px 0 10px 30px;
  }
}

#reset-display-order-modal h3 {
  font-size: 16px;
}

.stream-owner-avatar {
  float: left;
  width: 90px;
  height: 90px;
  margin: 0 20px 0 0;
  border-radius: 50%;
  box-sizing: border-box;

  &.item-level {
    width: 35px;
    height: 35px;
    margin: -6px 10px 0 0;
  }

  &.pic {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;

    &.item-level {
      background-size: 35px auto;
    }
  }

  &.text {
    line-height: 90px;
    font-size: 32px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    color: #fff;

    &.item-level {
      line-height: 35px;
      font-size: 17px;
      font-weight: 300;
    }
  }
}

// Embedded CTA iframe

body.hubs-embedded-iframe.cta-embed {
  overflow: hidden;
  background: transparent;

  > .main {
    margin-top: 0 !important;

    div.tile.cta {
      margin: 0;
      float: none;
      height: 330px;
      max-height: 330px;
    }
  }
}

.accent-button {
  position: relative;

  &:hover {
    .box-shadow(0px 2px 2px rgba(50, 50, 50, 0.3));

    text-shadow: 0px 1px 3px rbga(0, 0, 0, 0.9);

    &:before {
      display: block;
      .pos-abs(0, auto, auto, 0);     // T, R, B, L
      .square(100%);
      #gradient > .vertical(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65));

      content: "";
      .opacity(10);

      z-index: -1;
    }
  }
}
