@import "../../../shared/styles/base.less";
@import "../../../shared/styles/mixins.less";
@import "../../../shared/styles/variables.less";

@checkboxColor: rgb(45, 118, 346);

.ufr-form-fields-field {
  .ufr-input-container {
    padding: 0;
  }

  .react-input-description {
    margin: 0 !important;
  }

  .react-input-title {
    font-size: 14px !important;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ufr-checkbox-wrapper {
    margin: 2px 0 0 0 !important;
  }

  .ufr-checkbox:checked {
    background-color: @checkboxColor !important;
    border-color: @checkboxColor !important;
  }
}
