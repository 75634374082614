@import url('../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
* {
  box-sizing: border-box;
  margin: 0;
}
.responsive {
  height: 100%;
  width: 100%;
}
.carousel-container {
  margin: 10px 0 10px 0;
  position: relative;
  overflow: hidden;
}
.carousel-list {
  height: 100%;
  padding: 0;
}
.carousel-list-item {
  position: absolute;
  transition: all 0.3s;
}
.carousel-list-item.override-animation {
  transition: none !important;
}
.carousel-slider-container {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
}
.inactive-item {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.clickable-area {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 9999;
}
