@import "./mixins.less";

// Roboto and IBM Plex Sans
@import (css) url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import (css) url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');

// Normalizing
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  body {
    // Min width of app backend
    min-width: 700px;

    // we need to have responsive login screen for extensions iframes view
    &.layout-login {
      min-width: 100%;
    }

    input {
      font-size: 12.5px;
    }
  }
}
