@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.delete-visitor-analytics {
  width: 525px;
}
input.delete-visitor-analytics-uuid {
  float: left;
  margin-right: 15px;
  width: 60%;
}
input.delete-visitor-analytics-uuid-input-error {
  box-shadow: 0 0 8px rgba(169, 68, 67, 0.6);
}
.delete-visitor-analytics-error-message {
  color: #d44545;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  bottom: 10px;
  left: 0;
  font-family: Roboto, sans-serif;
  margin: 0;
}
