@import "../shared/styles/variables";

#topnav {
  min-width: 700px;

  *:not(.glyphicons) {
    font-family: @plex;
  }

  > ul {
    position: absolute;
    left: 250px;
    right: 0;
  }

  .glyphicons:before {
    padding: 0;
  }
}

@media screen and (max-width: 1300px) {
  #topnav > ul {
    left: 60px;
  }
}
