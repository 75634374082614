.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-count-dropdown {
  font-family: Roboto, sans-serif;
  position: relative;
  padding: 16px 20px;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
}
.ufr-count-dropdown select {
  height: 28px;
  margin: 0 8px;
  background: none;
  border-color: #ccc;
}
.ufr-count-dropdown select:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
