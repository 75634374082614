#topnav .topnav-logo {
  position: absolute;
  height: 21px;
  top: 11px;
  left: 20px;
}
#topnav .topnav-logo-image-desktop {
  height: 21px;
  width: 68px;
}
#topnav .topnav-logo-image-mobile {
  display: none;
  height: 24px;
  width: 24px;
}
@media screen and (max-width: 1300px) {
  #topnav > ul {
    left: 45px;
  }
  #topnav .topnav-logo {
    width: 24px;
    height: 24px;
    left: 18px;
    top: 8px;
    overflow: hidden;
  }
  #topnav .topnav-logo-image-desktop {
    display: none;
  }
  #topnav .topnav-logo-image-mobile {
    display: block;
  }
}
