// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************
// Hubs Preview Overlay

.hubs-preview-overlay,
.hubs-preview-wrapper {
  display: none;
  .fixed-center;

  bottom: @sidemenuFooterHeight;
  background: @white;
  opacity: 0.9;
}

.hubs-preview-wrapper {
  background: transparent;
  overflow: hidden;
  opacity: 1;
}

// ***************************************************************************************
// Hubs Preview Modal

.hubs-preview-modal {
  background: transparent url("/img/hubs_preview_deviceShells.png") no-repeat;
  overflow: hidden;

  .hubs-preview-address {
    height: 30px;
    line-height: 30px;
    background: @white;
    border-bottom: 1px solid darken(@gray-lighter, 13.5%);    // #ccc
    box-shadow: inset 0 -1px 11px @gray-lighter;    // #eee

    > span {
      margin: 0 10px;
    }
  }

  iframe {
    background: @gray;
  }

  &.desktop {
    .pos-abs(2%, auto, auto, 2%);       // T, R, B, L
    .square(96%);

    background: none;
    border: 3px solid @black;
    border-radius: 8px;

    iframe {
      .square(100%);

      margin: 0;
    }
  }

  &.tablet {
    .pos-abs(20px, auto, auto, 50%);    // T, R, B, L
    .size(1115px, 864px);

    margin-left: -557.5px;
    background-position: -384px 0;
    border: 0 none;

    .hubs-preview-address {
      .size(909px, 30px);

      margin: 88px 103px 0 103px;
    }

    iframe {
      .size(909px, 653px);

      margin: 0 103px 0 103px;
    }
  }

  &.phone {
    .pos-abs(20px, auto, auto, 50%);    // T, R, B, L
    .size(383px, 803px);

    margin-left: -181.5px;
    background-position: 0px 0;
    border: 0 none;

    .hubs-preview-address {
      .size(321px, 30px);

      margin: 118px 0 0 31px;
    }

    iframe {
      .size(321px, 543px);

      margin: 0 0 0 31px;
    }
  }
}

// ***************************************************************************************
// Hubs Preview Command Bar

.hubs-preview-command-bar {
  display: none;
  .pos-fix(auto, auto, 0, 0);         // T, R, B, L
  .size(100%, @sidemenuFooterHeight);

  background-color: transparent;
  pointer-events: none;

  .hubs-preview-command-bar-inner {
    position: relative;
    .size(auto, @sidemenuFooterHeight);

    margin-left: @sidemenuWidthOpened;
    padding-right: @sidemenuWidthOpened;
    background-color: #f9f9f9;
    text-align: center;
    pointer-events: auto;

    .btn {
      margin: 9px 0 0 0;
    }
  }
}

// ***************************************************************************************
// Media Query Adjustments
@media only screen and (max-width: @sidemenuCollapseWidth) {
  .hubs-preview-modal {
    &.tablet {
      left: 20px;
      margin-left: 0;
    }
  }

  .hubs-preview-command-bar {
    .hubs-preview-command-bar-inner {
      margin-left: @sidemenuWidthClosed;
      padding-right: @sidemenuWidthClosed;
    }
  }
}
