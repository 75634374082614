@import (reference) "../../shared/_variables.less";

a.cke_path_item {
  &,
  &:link,
  &:visited,
  &:active {
    color: @uberPink;
  }
}
