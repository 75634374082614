@import url('../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-item-tile {
  position: relative;
  cursor: pointer;
  width: 170px;
  height: 200px;
  display: inline-block;
  margin: 0 20px 20px 0;
  text-align: left;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.ufr-item-tile .ufr-selected-icon {
  position: absolute;
  top: 7px;
  right: 6px;
  background: white;
}
.ufr-item-tile .ufr-item-tile-image {
  width: 100%;
  height: 107px;
  background-size: cover;
}
.ufr-item-tile .ufr-item-tile-image ~ .ufr-item-tile-bottom-text {
  box-shadow: 0 1px 5px 0 rgba(51, 51, 51, 0.25);
}
.ufr-item-tile .ufr-service-icon {
  position: absolute;
  top: 83px;
  left: 0;
}
.ufr-item-tile .ufr-item-tile-header {
  position: absolute;
  top: 107px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px;
  overflow: hidden;
}
.ufr-item-tile .ufr-item-tile-header p {
  font-weight: 700;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}
.ufr-item-tile .ufr-item-tile-header:before {
  background: linear-gradient(transparent 170px, #fff);
}
.ufr-item-tile .ufr-item-tile-bottom-text {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 24px;
  text-align: left;
  padding: 5px 7px 0;
  background-color: #333;
  color: white;
  font-size: 10.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ufr-item-tile.ufr-item-tile-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.ufr-item-tile.ufr-item-tile-disabled:focus {
  outline: 0;
}
