@import "../../shared/styles/base";
@import "../../shared/styles/mixins";

#ufr-enrichment-sources-data-table {
  .ufr-dt-enrichment-sources-name {
    display: inline-block;
    padding-left: 7px;

    a {
      color: @fontColorDark;
    }
  }
}
