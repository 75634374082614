@import '../shared/styles/variables';

.quick-links {
  font-family: @roboto;
  z-index: 100;
  margin-top: 10px;
  margin-bottom: 32px;
  left: 0;

  button {
    text-transform: capitalize;
    font-size: 13px;
    padding: 5px 10px;
    border: none;
    background: white; /* safari defaults background to grey */
  }

  .quick-links-selected {
    border-bottom: 3px solid @uberPink;
  }
}
