.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#topnav .topnav-item-button {
  padding: 12px 12px 8px;
  cursor: pointer;
}
#topnav .topnav-item-button .topnav-item-icon {
  font-size: 13px;
  position: relative;
  top: 1px;
}
#topnav .topnav-item-button .topnav-button-text {
  line-height: 1.1;
  font-size: 13px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  padding-top: 1px;
  max-width: 128px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#topnav .topnav-item-button .topnav-button-text:first-child {
  margin-left: 0;
}
#topnav .topnav-item-button .topnav-item-chevron {
  font-size: 10px;
  margin-right: 5px;
  margin-left: 10px;
  position: relative;
  top: 3px;
}
#topnav .topnav-link:hover .topnav-item-button {
  background: rgba(51, 51, 51, 0.15);
  color: white;
}
#topnav .topnav-link:active .topnav-item-button,
#topnav .topnav-link.topnav-link-active .topnav-item-button-active {
  background: rgba(51, 51, 51, 0.5);
  color: white;
}
