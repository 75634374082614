.ufr-font-slider .ufr-input-container {
  width: 100%;
}
.ufr-font-slider .ufr-input-container label.react-input-title {
  font-size: 13px;
  font-weight: 500;
  color: #555;
}
.ufr-font-slider .ufr-input-container p.react-input-description {
  margin: 0;
}
.ufr-font-slider .ufr-input-container .ufr-slider {
  display: flex;
}
.ufr-font-slider .ufr-input-container .ufr-slider .ufr-slider-input {
  flex-grow: 1;
}
.ufr-font-slider .ufr-input-container .ufr-slider .ufr-slider-number-input {
  height: 32px;
}
.ufr-font-slider .ufr-input-container .ufr-slider .ufr-slider-number-input .ufr-input-inner.ufr-number-inner {
  height: 32px;
}
.ufr-font-slider .ufr-input-container .ufr-slider .ufr-slider-number-input .ufr-input-inner.ufr-number-inner .ufr-input-wrapper {
  height: 32px;
}
.ufr-font-slider .ufr-input-container .ufr-slider .ufr-slider-number-input .ufr-input-inner.ufr-number-inner .ufr-input-wrapper .ufr-input-component {
  width: 37px !important;
  height: 32px !important;
  padding: 7px 5px;
  font-size: 13px;
  text-align: center;
}
.ufr-font-slider .ufr-input-container .ufr-slider .ufr-slider-number-input .ufr-input-inner.ufr-number-inner .ufr-input-wrapper .ufr-input-component::-webkit-inner-spin-button {
  display: none;
}
