
.share-hub,
.share-item {
  display: none;
  position: fixed;
  top: 61px;
  right: 40px;
  padding: 0;
  margin: 0 128px 0 0;
  min-width: 140px;
  max-width: 230px;
  background-color: @white;
  text-transform: uppercase;
  .box-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));

  &.hover {
    display: block;
  }

  &:before {
    display: block;
    position: absolute;
    top: -20px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e5e5e5;
    border-left: 10px solid transparent;
    content: "";
  }

  li {
    display: block;
    float: left;
    color: #aaa;
    text-shadow: 1px 1px 2px #fff;
    font-size: 16px;
    height: 100%;
    line-height: 40px;
    text-align: center;

    a {
      display: block;
      width: 55px;
      height: 100%;
      background-image: url("@{imageSprite1x}");
      text-indent: -9999px;

      &.facebook {
        background-position: -55px -333px;

        &:hover,
        &.on { background-position: -55px -374px; }
      }

      &.twitter {
        background-position: -110px -333px;

        &:hover,
        &.on { background-position: -110px -374px; }
      }

      &.email {
        background-position: -165px -333px;

        &:hover,
        &.on { background-position: -165px -374px; }
      }

      &.linkedin {
        background-position: -275px -333px;

        &:hover,
        &.on { background-position: -275px -374px; }
      }

      &.on:hover {
        background-color: #efefef;
      }
    }

    &:first-child {
      display: block;
      width: 100%;
      padding: 0px !important;
      border: 0 none;
      background: #e5e5e5;
    }
  }
}

.share-social,
.share-twitter,
.share-slideshare {
  position: absolute;
  top: 11px;
  right: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;

  li {
    border-right: 1px solid #eee;
    border-left: 1px solid #fff;
    color: #a2a2a2;
    display: block;
    float: left;
    font-size: 16px;
    line-height: 40px;
    height: 100%;
    text-align: center;
    padding: 0;
    margin: 0;

    a {
      background-image: url("@{imageSprite1x}");
      display: block;
      height: 100%;
      text-indent: -9999px;
      width: 55px;

      &.facebook {
        background-position: -55px -333px;
        &:hover { background-position: -55px -373px; }
      }

      &.twitter {
        background-position: -110px -333px;
        &:hover { background-position: -110px -373px; }
      }

      &.email {
        background-position: -165px -333px;
        &:hover { background-position: -165px -373px; }
      }

      &.linkedin {
        background-position: -275px -333px;
        &:hover { background-position: -275px -373px; }
      }
    }

    &.share-text,
    &:first-child {
      border-left: 0;
      padding: 0px 14px;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.share-social,
.share-twitter {
  a {
    color: #a2a2a2;
    background-image: url("@{imageSprite1x}");

    &.view {
      text-indent: 0;
      background-image: none;
      width: auto;
    }

    &.reply {
      background-position: 0px -254px;
      &:hover { background-position: 0px -295px; }
    }

    &.retweet {
      background-position: -57px -254px;
      &:hover { background-position: -57px -293px; }
    }

    &.fav {
      background-position: -114px -256px;
      &:hover { background-position: -114px -295px; }
    }
  }
}

.share-single {
  display: block;
  position: absolute !important;
  width: 88% !important;
  height: 40px !important;
  bottom: 55px !important;
  margin: 0 6% !important;
  padding: 0px !important;
  .opacity(0);

  background-color: rgba(255, 255, 255, 0.9);
  .box-sizing(border-box);
  .transition(opacity .15s linear);

  &.four {
    width: 74% !important;
    margin: 0 13% !important;
    li { width: 25% !important; }
  }

  &.three {
    width: 60% !important;
    margin: 0 20% !important;
    li { width: 33.33333333333% !important; }
  }

  &.two {
    width: 40% !important;
    margin: 0 30% !important;
    li { width: 50% !important; }
  }

  &.one {
    width: 20% !important;
    margin: 0 40% !important;
    li { width: 100% !important; }
  }

  &.zero {
    display: none;
  }

  li {
    display: block !important;
    float: left !important;
    height: 100% !important;
    width: 20% !important;
    padding: 0px !important;
    margin: 0 !important;
    text-indent: -9999px !important;
    background: #fff !important;
    border-right: 1px solid #eee;
    border-left: 1px solid white;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    .box-sizing(border-box);

    &:first-child { border-left: 1px solid #eee; }
    &:last-child { border-right: 1px solid #eee; }

    a {
      display: block !important;
      height: 100% !important;
      text-indent: -9999px !important;

      &.facebook {
        background: url("/img/hubs/button-facebook.png") no-repeat center 0;
        &:hover { background-position: center -40px; }
      }

      &.twitter {
        background: url("/img/hubs/button-twitter.png") no-repeat center 0;
        &:hover { background-position: center -40px; }
      }

      &.email {
        background: url("/img/hubs/button-email.png") no-repeat center 0;
        &:hover { background-position: center -40px; }
      }

      &.linkedin {
        background: url("/img/hubs/button-linkedin.png") no-repeat center 0;
        &:hover { background-position: center -40px; }
      }

      &.reply {
        background: url("/img/hubs/button-reply.png") no-repeat center 0;
        &:hover { background-position: center -41px; }
      }

      &.retweet {
        background: url("/img/hubs/button-retweet.png") no-repeat center 0;
        &:hover { background-position: center -41px; }
      }

      &.fav {
        background: url("/img/hubs/button-fav.png") no-repeat center 0;
        &:hover { background-position: center -41px; }
      }
    }
  }
}
