@import '../../../shared/styles/base.less';
@import '../../../shared/styles/variables.less';

.ufr-linkables-modal {
  font-family: @roboto;

  .ufr-lang-linkables-area {
    margin-top: 30px;
    margin-right: -20px;
    min-height: 220px;

    .ufr-shout-spinner {
      margin: 0 auto;
      position: relative;
      top: 55px;
    }
  }

  .ufr-modal-infotext {
    font-size: 12px;
    color: @fontColorMedium;
    top: 10px;
    margin: 7px 10px 0;
  }
}

.ufr-linkable-items-modal {
  .ufr-control-bar-search {
    width: 60%;
    display: inline-block;
    position: relative;
    left: -10px;
  }

  #searchable-select-locale-ufr-input-container {
    width: 40%;
    display: inline-block;

    .react-input-description {
      display: none;
    }
  }
}
