.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.searchable-dropdown-container {
  margin-top: 1px;
}
.menu-item {
  color: #333;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  line-height: 20px;
  margin-bottom: 1px;
  padding: 7px 9px;
}
.menu-item:hover {
  background-color: whitesmoke;
  cursor: pointer;
}
.menu-item:hover .selected-item {
  color: #333;
}
.menu-item .selected-item {
  color: #b70150;
}
