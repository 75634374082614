
.app-external-page #content-container {
  position: relative;

  /* fix the content div to handle fluid-height iframe */
  #content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /*Make sure the flash message doesn't push the iframe down*/
    #flash_message {
      position: relative;
      z-index: 1;
      max-height: 61px;
    }

    #flash_message ~ #app-external-page {
      position: relative;
      bottom: 61px;
      margin-bottom: -61px;
    }

    /* Get the iframe to be 100% height */
    #app-external-page .content-container {
      padding: 0;
      position: relative;
    }

    #app-external-page iframe {
      border: none;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
