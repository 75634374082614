// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// ----------------------------------------------------------------------------------------------------------
// Custom menu item modal and icons
// ----------------------------------------------------------------------------------------------------------

.icon-preview {
  display: inline-block;
  .square(48px);

  margin: 0 10px 10px 0;
  background-image: url("/img/hubs/sprite-1x.png");
  background-color: #5bc0de;
  cursor: pointer;

  &.menu-none {
    border: 2px dashed #ccc;
    margin: 0 15px 0 0;
    background-color: initial;
    background-image: none;

    &:hover {
      border-color: #aaa;
    }
  }

  &.menu-home { background-position: -224px 0; }
  &.menu-docs { background-position: -224px -59px; }
  &.menu-social { background-position: -224px -233px; }
  &.menu-blogs { background-position: -224px -116px; }
  &.menu-custom { background-position: -331px 0; }
  &.menu-videos { background-position: -224px -176px; }
}

.menu-item-add-edit {
  .modal-body {
    .menu-item-upsert {
      padding-top: 20px;
    }

    .alert {
      width: 350px;
      margin: 10px auto 10px auto;
    }

    .menu-item-icons {
      display: none;
      text-align: center;

      .tile-container {
        display: inline-block;
        max-width: 400px;
      }
    }

    &.show-icons {
      .menu-item-icons {
        display: block;
      }

      .menu-item-upsert {
        display: none;
      }
    }

    .menu-item-attribute {
      padding-bottom: 20px;

      .menu-item-attribute-text {
        display: inline-block;
        width: 170px;
        padding-right: 50px;
        text-align: right;
        font-weight: bold;
      }

      input {
        &.menu-item-label,
        &.menu-item-url,
        &.menu-item-class-name {
          display: inline-block;
          width: auto;
        }
      }

      select {
        display: inline-block;
        width: 220px;
      }

      &.has-select-icon {
        display: table;

        div,
        span {
          display: table-cell;
          vertical-align: middle;
        }

        a {
          margin-left: 30px;
        }
      }

      &.change-type {
        div {
          display: inline-block;
          position: relative;
          left: -17px;

          .menu-item-type {
            display: inline;
            margin-left: 16px;
            margin-right: 2px;
          }
        }
      }
    }

    .add-to-menu {
      padding: 20px;
      text-align: center;

      a {
        font-size: 15px;
        padding: 9px 14px;
        font-weight: 500;
      }
    }

    .link,
    .collection,
    .section {
      display: none;
    }

    &.show-link {
      .link {
        display: block;
      }
    }

    &.show-collection {
      .collection {
        display: block;
      }
    }

    &.show-section {
      .section {
        display: block;
      }
    }
  }
}

// ----------------------------------------------------------------------------------------------------------
// Hubs Content Management Section
// ----------------------------------------------------------------------------------------------------------

.mgmt-container {
  .content-container.hubs-sections-appearance {

    // --------------------------------------------------------------------------------------------------
    // Navbar on all Appearance Pages

    .appearance-navbar .glyphicons {
      font-size: 15px;

      &:before {
        padding: 0px 6px 0 11px;
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Block Icon-Nav on Branding Section

    .nav-block-icon.nav-branding {
      .nav-block-icon-width(3);   // 3 Blocks in Nav

      margin: 0 auto 20px auto;

      > li {
        a {
          background-image: url("/img/hubs/backend/spritesheet.png");
          background-repeat: no-repeat;

          &.block-icon-text { background-position: -279px 4px; }
          &.block-icon-images { background-position: -360px 1px; }
          &.block-icon-colours { background-position: -440px 1px; }
        }
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Tab Panes for all Appearance Pages

    .tab-pane {
      margin: 0 auto;

      &#hubs-style-text {
        width: 600px;

        .hubs_font_option.disabled {
          pointer-events: none;
          background: #eee;
        }
      }

      &#hubs-style-images {
        width: auto;
        max-width: 1165px;

        input[disabled] + .btn {
          background: #bebebe;
          border-color: #bebebe;
          cursor: not-allowed;
        }
      }

      &#hubs-style-colour { width: 600px; }
    }

    // --------------------------------------------------------------------------------------------------
    // Fluid Rows for all Appearance Pages

    .row {
      .col-md-6 {
        text-align: center;
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Branding Tab

    .input-box-body {
      .add-on {
        margin-right: -2px;
        margin-top: 3px;
      }

      .hub-company-url-select-container {
        margin: 30px 0 0 0;
      }

      .company-url-target {
        width: 170px;
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Image Upload Tab
    @imageContainerWidth: 90%;
    @imageContainerHeight: 200px;
    /* stylelint-disable */
    .form-large [class^="image-container-"],
    .form-large [class*=" image-container-"] {
      /* stylelint-enable */
      margin-top: 20px;
      margin-bottom: 60px;
      text-align: center;
      .clearfix;

      > p {
        width: @imageContainerWidth;
        height: 5em;
        margin: 0 auto;
        font-size: (@font-size-base + 1);       // 12 + 1
        line-height: @line-height-computed - 3;
      }

      &.image-container-primary_logo,
      &.image-container-background_image {
        > p {
          height: 3.3em;
        }

        .input-group {
          margin-right: 10px;
        }
      }

      .uploaded-logo {
        position: relative;
        margin: 0 auto;
        border: 1px solid darken(@gray-lighter, 13.5%);  // #ccc
        background: transparent url("/img/page_editor/checkered.png") repeat 0 0;

        &.primary_logo,
        &.background_image {
          .size(@imageContainerWidth, @imageContainerHeight);    // width, height
        }

        &.secondary_logo,
        &.favicon,
        &.thumbnail {
          .size(@imageContainerWidth, @imageContainerHeight);    // width, height

          > .uploaded-logo-inner {
            margin-top: 30px;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &.primary_logo,
        &.background_image,
        &.thumbnail,
        &.secondary_logo {
          img {
            .absolute-center;
          }
        }

        .no-preview-item {
          display: inline-block;
          .absolute-center;
          .size(260px, 40px);

          background: @white;
          border: 1px solid darken(@gray-lighter, 13.5%);  // #ccc

          h4 {
            font-size: (@font-size-base + 1);
            color: lighten(@gray-light, 6.5%); // #888
            font-weight: normal;
            line-height: 40px;
          }
        }
      }

      .favicon-image-table,
      .favicon-caption-table {
        .display-cell {
          vertical-align: middle;
          text-align: center;
        }
      }

      .favicon-caption-table {
        width: @imageContainerWidth;
        margin: 5px auto;

        .display-row {
          color: lighten(@gray, 6.5%);  // #666
          background: @white;
          text-align: center;
          font-size: 11px;
        }
      }

      .image-dimensions {
        margin: 10px 0;
        text-align: center;
        color: lighten(@gray, 6.5%);        // #666
        font-size: (@font-size-base + 1);     // 12 + 1

        strong {
          color: @gray;
          white-space: nowrap;
        }
      }

      .button-row {
        .clearfix;

        .upload-button-container {
          display: inline-block;
          position: relative;
          width: 100px;

          input[type="file"] {
            width: 100%;
            height: 28px;
            margin-left: -18px;
            line-height: 28px;
          }
        }

        .remove-button-container {
          display: inline-block;
          position: relative;

          a {
            .icon-grey {
              opacity: 0.4;
            }
          }
        }

        .position-select-container {
          display: inline-block;
          position: relative;
          margin-left: 20px;

          select {
            margin-left: 0;
            max-width: 93px;
          }

          .input-prepend {
            margin-bottom: 0;
          }
        }
      }
    }

    .hubs-branding-colours {
      margin: 20px 0 0 0;

      p {
        color: lighten(@gray, 6.5%);  // #666
      }

      .sp-replacer {
        &.sp-disabled {
          cursor: not-allowed;
        }

        .sp-preview {
          width: 120px;
        }
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Custom Labels

    .custom-labels-container {
      position: relative;
      width: 780px;
      margin: 0 auto;

      .info-table {
        text-align: left;
        padding-bottom: 10px;
        margin: -20px 0 20px 0;
        background-color: lighten(@gray-lighter, 5%);
        border: 10px solid lighten(@gray-lighter, 5%);
        outline: 1px solid @gray-lighter;

        td {
          padding: 0px 5px;
          vertical-align: top;
        }

        p { margin: 3px 0 0 0; }
      }

      .filter-tabs {
        margin: 30px 0 15px;
      }

      .error,
      .warning,
      .info,
      .success,
      .default {
        .square(20px);

        padding: 0px 5px;
        background-color: @white;
        border: 1px solid #ddd;
      }

      .error,
      .table tbody tr.error > td { background-color: #f2dede; }

      .warning,
      .table tbody tr.warning > td { background-color: #fcf8e3; }

      .info,
      .table tbody tr.info > td { background-color: #d9edf7; }

      .success,
      .table tbody tr.success > td { background-color: #dff0d8; }

      .input-prepend,
      .input-append {
        margin: 0;
      }

      .table {
        tbody {
          tr {
            > td {
              .input-group {
                float: right;
                width: 100%;

                .input-group-addon {
                  cursor: default;
                  background: @gray-lighter;

                  em { display: none; }
                }

                input {
                  z-index: 0;
                  &:focus { position: static; }
                }
              }
            }

            &.success {
              > td {
                .input-group {
                  .input-group-addon {
                    em {
                      display: inline;
                      cursor: pointer;
                      font-style: normal;
                      font-size: @font-size-base + 4; // 16px
                      color: #a33;
                    }
                  }
                }
              }
            }
          }
        }

        label {
          padding: 5px 0;
          margin: 0;
          font-weight: normal;
        }
      }
    }

    .custom-labels-footer {
      position: relative;
      width: 100%;
      padding: 9px 0;
      margin-bottom: 0;
      text-align: center;
      background-color: #666;

      .custom-labels-footer-inner {
        .btn {
          margin-right: 20px;
        }
      }

      &.fixed {
        .pos-fix(auto, auto, 0, 0);     // T, R, B, L

        .custom-labels-footer-inner {
          margin-left: @sidemenuWidthOpened;
        }
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Hubs Lab

    .hubs-lab-container {
      font-family: @roboto;
      position: relative;
      width: 780px;
      margin: 0 auto;

      .alert {
        margin-left: 0;
      }

      .hubs-option-block {
        margin-bottom: 20px;

        label {
          padding-left: 18px;
          color: #444;
          font-weight: normal;
          line-height: 1.2em;
        }

        .note {
          margin-left: 22px;

          p { color: inherit; }
        }
      }
    }

    &.hubs-menu-container {
      .toggle-switch-container.disabled,
      .toggle-switch-container.disabled .toggle-switch-text {
        cursor: not-allowed;

        .toggle-switch {
          pointer-events: none;
        }
      }

      .upgrade-alert {
        width: 238px;
        margin: 50px auto 0 auto;
        padding-right: 14px;
        text-align: center;
      }

      h3 {
        margin-bottom: 8px;
      }

      .menu-top-row-attributes {
        width: 500px;
        margin: 0 auto;
        overflow: hidden;

        .menu-settings-container {
          float: left;
        }

        .menu-color-container {
          position: relative;
          float: right;
          box-sizing: content-box;

          .sp-replacer.sp-disabled {
            cursor: not-allowed;
          }
        }
      }

      .custom-text,
      .default-text {
        display: none;
      }

      .menu-bottom-row-attributes {
        padding-top: 50px;
      }

      .menu-layout-container {
        font-family: @roboto;
        padding-top: 70px;

        .new-item.disabled,
        .btn.disabled {
          pointer-events: none;
        }

        .create-default-container {
          text-align: center;
          padding-top: 100px;
        }

        .menu-layout {
          max-width: 700px;
          padding-bottom: 50px;
          margin: 0 auto;
          text-align: right;

          &.disabled {
            pointer-events: none;
          }

          .menu-item {
            position: relative;
            padding-top: 10px;
            cursor: pointer;

            .icon-resize-vertical {
              margin-top: 6px;
            }

            .icon-preview {
              position: absolute;
              left: 0;
              display: inline-block;
              margin: 0 15px 0 0;
            }

            .menu-empty {
              background-color: transparent;
              background-image: none;
              cursor: default;
            }

            span {
              font-size: 16px;
            }

            .menu-item-sprite {
              .square(20px);
            }

            .menu-item-info {
              display: inline-block;
              width: 87%;
              padding: 9px;
              background-color: #ccc;
              text-align: left;

              .menu-item-label {
                display: inline-block;
                position: relative;
                top: 6px;
                max-width: 395px;
                color: white;
                font-weight: bold;
                .text-overflow();
              }

              .menu-item-type {
                color: #555;
                font-style: italic;
              }

              .menu-item-action {
                border: 0;
                background: transparent;
                display: inline-block;
                padding: 6px;

                span {
                  color: @white;
                  font-size: @font-size-base; // 12px
                }

                &.edit-action {
                  margin-left: 21px;
                  margin-right: 8px;
                }

                &:hover {
                  background-color: #bbb;
                }
              }

              .menu-item-type {
                padding: 6px;
              }
            }

            .children {
              padding-left: 70px;
              overflow: hidden;
            }

            .halflings-resize-vertical {
              color: @uberGrayDark;
              font-size: @font-size-base;
            }
          }

          .new-item {
            float: right;
            width: 85%;
            padding: 10px 12px;
            margin: 10px 15px 0 0;
            border: #ccc dashed;
            text-align: left;
            cursor: pointer;
            font-weight: bold;
            font-size: 26px;
            color: @uberBlueLight;

            > span {
              color: #fff;
              font-size: 14px;
              font-weight: 300;
              position: relative;
              top: -3px;
            }

            &:hover {
              border-color: #888;
              color: @uberBlue;

              > span {
                color: #999;
              }
            }
          }
        }

        &.default-menu {
          display: none;

          .new-item {
            display: none;
          }

          .menu-item {
            cursor: initial;

            i {
              opacity: 0;
            }

            .icon-preview {
              cursor: default;
            }

            .menu-item-info {
              .menu-item-action {
                display: none;
              }
            }

            .menu-item-resize {
              visibility: hidden;
            }
          }
        }

        &.custom-menu {
          display: none;
        }

        &.custom-menu.disabled {
          cursor: default;

          .menu-item-resize {
            visibility: hidden;
          }

          .menu-item .menu-item-info .menu-item-delete,
          .menu-item .menu-item-info .menu-item-edit,
          .new-item,
          .create-default-container {
            display: none;
          }
        }
      }

      &.show-custom {
        .custom-menu {
          display: block;
        }
      }

      &.show-default {
        .default-menu {
          display: block;
        }
      }
    }
  }
}

body.ace-editor-full-screen {
  overflow: hidden;
}

@media only screen and (max-width: @sidemenuCollapseWidth) {
  .mgmt-container {
    .content-container.hubs-section-appearance {
      .custom-labels-footer {
        &.fixed {
          .custom-labels-footer-inner {
            margin-left: @sidemenuWidthClosed;
          }
        }
      }
    }
  }
}

.ie8 {
  .mgmt-container {
    .content-container.hubs-sections-appearance {
      .nav-block-icon.nav-branding {
        width: 300px;
      }
    }
  }
}
