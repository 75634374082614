@import "../../shared/styles/variables";
@import "../../shared/styles/mixins";
@import "../../shared/styles/base";

.delete-visitor-analytics {
  width: 525px;

  input&-uuid {
    float: left;
    margin-right: 15px;
    width: 60%;

    &-input-error {
      box-shadow: 0 0 8px rgba(169, 68, 67, 0.6);
    }
  }

  &-error-message {
    color: #d44545;
    font-size: 13px;
    line-height: 16px;
    position: absolute;
    bottom: 10px;
    left: 0;
    font-family: @roboto;
    margin: 0; // normalize uf css on <p> elements
  }
}
