.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#sidenav .sidenav-header {
  width: 250px;
  height: 80px;
  padding: 15px;
  transition: padding 0.3s;
}
#sidenav .sidenav-header > * {
  display: inline-block;
}
#sidenav .sidenav-header .sidenav-header-image {
  height: 50px;
  width: 50px;
}
#sidenav .sidenav-header > .react-icon {
  width: 50px;
  text-align: center;
  font-size: 40px;
  line-height: 47px;
}
#sidenav .sidenav-header > .react-icon.glyphicons-group {
  font-size: 38px;
}
#sidenav .sidenav-header .empty-avatar-icon {
  border: 2px dashed #ddd;
  border-radius: 10px;
  width: 46px;
  height: 46px;
  margin: 2px;
  padding-top: 5px;
  text-align: center;
}
#sidenav .sidenav-header .empty-avatar-icon .react-icon {
  font-size: 30px;
  color: #ddd;
}
#sidenav .sidenav-header .sidenav-header-text {
  height: 50px;
  vertical-align: top;
  padding: 6px 0 0 15px;
  max-width: 170px;
  transition: 0.3s;
}
#sidenav .sidenav-header .sidenav-header-text .sidenav-header-title {
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
  max-width: 155px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#sidenav .sidenav-header .sidenav-header-text .sidenav-header-subtitle,
#sidenav .sidenav-header .sidenav-header-text .sidenav-rss-link {
  display: inline-block;
  line-height: 15px;
  font-size: 11px;
  vertical-align: top;
}
#sidenav .sidenav-header .sidenav-header-text .sidenav-rss-link {
  margin-left: 1px;
}
#sidenav .sidenav-header .sidenav-header-text .sidenav-header-subtitle {
  max-width: 154px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#sidenav .sidenav-header .sidenav-header-text .sidenav-header-subtitle.sidenav-subtitle-with-rss {
  max-width: 126px;
}
@media (max-width: 1300px) {
  #sidenav:not(:hover) .sidenav-header {
    padding: 5px;
    transition: padding 0.3s;
  }
  #sidenav:not(:hover) .sidenav-header .sidenav-header-text {
    opacity: 0;
    transition: 0.3s;
  }
  #sidenav:not(:hover) .sidenav-header .react-icon.glyphicons-group {
    font-size: 24px;
    transition: 0.3s;
  }
}
