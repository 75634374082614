@import "../shared/styles/variables";

.ufr-image-uploader {
  .ufr-image-preview {
    position: relative;
    display: flex;
    height: 238px;
    background-color: @lightGrey;
    border: solid 1px #ccc;

    &.ufr-input-component--disabled:hover,
    &.ufr-input-container--disabled:hover {
      cursor: not-allowed;
    }

    .image-logo {
      align-self: center; // for IE11
      margin: auto;
      max-height: 100%;
      max-width: 100%;
    }

    .ufr-image-delete-btn {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}
