@import '../../../shared/styles/variables';

/*
  Note: these styles implement the UF design system specifications.
  consult the Zeplin UI design guide before making any changes @ app.zeplin.io
 */
.ufr-input-container {
  .ufr-input-error-message {
    color: #d44545;
    font-size: 13px;
    line-height: 16px;
    position: absolute;
    bottom: 10px;
    left: 0;
    font-family: @roboto;
    margin: 0; // normalize uf css on <p> elements
  }

  .ufr-character-count + .ufr-input-error-message {
    bottom: 2px;
  }
}
