/* stylelint-disable no-descending-specificity */

@import '../../shared/styles/variables';

#sidenav {
  .sidenav-menu {
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 80px;
    border-top: 1px solid @lightGreyBorder;

    .panel-group {
      position: relative;
      margin-bottom: 40px;

      .panel {
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        background: transparent;
        border: 1px solid @lightGreyBorder;
        cursor: pointer;

        a,
        a:hover,
        a:focus {
          text-decoration: none;
          color: @fontColorDark;
        }

        a:hover {
          background: @lightGreyBorder;
        }
      }
    }

    .panel-heading {
      position: relative;
      background: transparent;
      height: 36px;
      padding: 0;

      .panel-title {
        line-height: 15px;
        height: 100%;

        .panel-title-wrapper {
          position: absolute;
          left: 0;
          right: 0;
          padding: 10px 17px 11px 45px;

          .react-icon {
            position: absolute;
            font-size: 17px;
            left: 18px;
            transition: 0.3s;
          }

          .panel-label {
            display: inline-block;
            font-size: 13px;
            white-space: nowrap;
            transition: 0.3s;
          }

          .btn-expand {
            position: absolute;
            font-size: 11px;
            right: 18px;
            transition: 1s;
            line-height: initial;
            line-height: unset;
            white-space: nowrap;

            &:before {
              padding: 0px;
            }
          }
        }
      }
    }

    .panel-collapse {
      position: relative;

      .panel-body {
        padding: 0;
        border-top-color: @lightGreyBorder;

        .sidenav-menu-child {
          height: 28px;
          white-space: nowrap;

          a {
            position: absolute;
            left: 0;
            right: 0;
            padding: 6px 15px 6px 45px;

            .narrow-sidenav-icon {
              position: absolute;
              font-size: 27px;
              line-height: 9px;
              height: 7px;
              width: 8px;
              border-radius: 5px;
              overflow: hidden;
              left: 24px;
              top: 10px;
              opacity: 0;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }

  /*
  Getting the height right, given what other elements are shown.
   */
  .sidenav-header + .sidenav-menu {
    // height of sidenav header
    top: 80px;
  }

  .sidenav-actions + .sidenav-menu {
    // header plus 46px height actions bar
    top: 126px;
  }

  .sidenav-menu.has-footer {
    bottom: 46px;
    border-bottom: 1px solid @lightGreyBorder;
  }
}

// Narrow sidenav
@media (max-width: 1300px) {
  #sidenav:not(:hover) .sidenav-menu {
    .panel-heading .panel-title .panel-title-wrapper {
      .react-icon {
        left: 21px;
        transition: 0.3s;
      }

      .panel-label {
        opacity: 0;
        transition: 0.3s;
      }

      .btn-expand {
        opacity: 0;
        transition: 0.1s;
      }
    }

    .panel-collapse .panel-body .sidenav-menu-child a {
      .narrow-sidenav-icon {
        opacity: 1;
        transition: 0.3s;
      }

      span:last-child {
        opacity: 0;
        transition: 0.3s;
      }
    }
  }
}
