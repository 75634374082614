@import '../shared/styles/variables';

.ufr-cta-preview-container {
  margin: 0;

  .btn-link,
  .btn,
  button.btn {
    color: #333;
    font-size: 13px;
    line-height: 1;
    padding: 7px 8.5px;

    &:active,
    &:focus {
      outline: none;
      background-color: transparent;
    }

    &:active {
      color: @uberPink;
    }

    &:focus {
      color: #333;
    }

    &:hover {
      color: @uberRubineDark;
    }
  }
}

.ufr-cta-preview-popover {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.27);
  margin-left: 8px;
  max-width: 290px;
  padding: 16px;
  position: absolute;
  z-index: 1000;

  .arrow {
    display: none;
  }
}
