@import '../../../shared/styles/variables';

.toggle-dropdown {
  width: 100%;
  background-color: #fff;
  border: 1px solid @mediumGreyBorder;
  border-radius: 3px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @fontColorDark;
  font-size: 13px;

  span.caret {
    opacity: 0.3;
  }

  &:hover {
    color: @fontColorDark;
    background-color: #e6e6e6;
    border-color: #adadad;

    span.caret {
      opacity: 1;
    }
  }
}
