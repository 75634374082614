.ufr-input-container .ufr-textarea-inner {
  line-height: 0;
}
.ufr-input-container .ufr-textarea-inner * {
  line-height: normal;
}
.ufr-input-container .ufr-textarea-inner .ufr-input-component {
  width: 100%;
}
.ufr-input-container .ufr-textarea-inner .ufr-character-count {
  width: auto;
  min-width: 50px;
}
