.inline-edit-btn {
  padding: 2px 0 0 4px;
  font-size: 12px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 0;
}
.inline-edit-btn .inline-edit-btn-icon {
  color: #ccc;
}
.inline-edit-btn:hover .inline-edit-btn-icon {
  color: #333;
}
.inline-edit-btn.large-inline-edit-btn {
  width: 25px;
  height: 25px;
  font-size: 17px;
}
.inline-edit-btn.small-inline-edit-btn {
  width: 25px;
  height: 25px;
  font-size: 12px;
}
