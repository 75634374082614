@import '../styles/variables';

.inline-editable-text-input {
  display: inline-block;
  box-shadow: 0 0 8px @uberBlueLight;
  border-radius: 2px;
  outline: 0;

  input {
    resize: horizontal;
    margin: -1px 0 0;
    color: @fontColorDark;
    font-size: inherit;
    font-weight: inherit;
    border: none;
    background: none;
    max-width: 100%;
  }

  .input-width-control {
    position: absolute;
    white-space: nowrap;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
  }

  &.large-inline-input {
    height: @largeInlineInputHeight;

    // Make room for the edit button to float to the right
    max-width: calc(~"100% - "@largeInlineInputHeight + 2);

    input {
      padding: 0 8px;
      font-size: @pageTitleMediumFontSize;
      height: @largeInlineInputHeight;
    }
  }

  &.small-inline-input {
    height: @smallInlineInputHeight;

    // Make room for the edit button to float to the right
    max-width: calc(~"100% - "@smallInlineInputHeight + 2);

    input {
      padding: 0 8px;
      font-size: @pageSubtitleFontSize;
      height: @smallInlineInputHeight;
    }
  }

  .ufr-character-count {
    margin-top: 8px;
    margin-left: auto;
  }
}

@media all and (max-width: 1000px) {
  .inline-editable-text-input {
    input {
      max-width: 600px;
    }
  }
}
