.ufr-checkbox-modal {
  &__description {
    margin-bottom: 20px;
  }
}

.ufr-checkbox-question {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
