.tagsTransferListModal {
  .ufr-modal-dialog {
    max-width: 860px !important;

    .ufr-modal-content {
      .ufr-modal-body {
        max-height: 520px !important;
      }
    }
  }
}
