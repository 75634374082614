.ufr-align-input-container {
  align-items: center;
  display: flex;
}
.ufr-align-input-container .ufr-reset-setting-container {
  margin-left: 30px;
}
.ufr-align-input-container .ufr-reset-setting-container .ufr-reset-setting {
  font-weight: normal !important;
}
