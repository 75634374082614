@import "../../../shared/styles/base";
@import "../../../shared/styles/mixins";

#ufr-items-data-table {
  .ufr-search-by-tags {
    position: absolute;
    padding: 15px;
    height: auto;
    border: 1px solid @mediumGreyBorder;
    background-color: @white;
    z-index: 800;
    overflow: hidden;

    .ufr-search-by-tags-helper-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 500px;
      height: 50px;
      border: 2px solid @lightGreyBorder;
      margin-top: 10px;

      p {
        margin-bottom: 0;
      }
    }

    .ufr-multi-select-input__menu > div {
      height: 146px;
    }

    .ufr-multi-select-container {
      width: 500px;
    }

    .ufr-multi-select-input {
      z-index: 800;
    }

    .ufr-select-dropdown {
      position: relative;
      width: 500px;
      margin-bottom: 10px;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      border-color: rgb(204, 204, 204);

      .ufr-dropdown-button {
        border-radius: 4px;
      }

      .ufr-select-dropdown-container {
        background-color: white;
        width: 500px;
        z-index: 801;
      }

      .ufr-dropdown-search-input {
        input {
          width: 100%;
        }

        span {
          position: absolute;
          right: 18px;
        }
      }

      .ufr-select-dropdown-item-container {
        width: 100%;
        height: 146px;
      }
    }

    .ufr-search-by-tags-button-container {
      height: 85px;
      display: flex;
      justify-content: end;
      align-items: end;
    }
  }

  .ufr-dt-items-listing-item-name-cell,
  .ufr-dt-items-listing-item-author-cell {
    display: flex;
    align-items: center;
  }

  .ufr-dt-items-listing-item-author-cell .react-avatar {
    margin-right: 8px;
  }

  .ufr-item-author {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ufr-datatable-thumbnail {
    display: block;
  }

  .ufr-item-name {
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    word-wrap: break-word;
  }

  a + .ufr-item-name {
    padding-left: 8px;
  }

  .ufr-item-name a,
  .ufr-dt-items-listing-item-streams-count-cell a {
    color: inherit;
    font-weight: bold;
    text-decoration: none;
  }
}

#ufr-item-name-tooltip {
  max-width: 100%;
  word-break: break-all;
}
