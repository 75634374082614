@import '../styles/variables';

.inline-edit-btn {
  padding: 2px 0 0 4px;
  font-size: 12px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 0;

  .inline-edit-btn-icon {
    color: #ccc;
  }

  &:hover .inline-edit-btn-icon {
    color: @fontColorDark;
  }

  &.large-inline-edit-btn {
    width: 25px;
    height: 25px;
    font-size: 17px;
  }

  &.small-inline-edit-btn {
    width: @smallInlineInputHeight;
    height: @smallInlineInputHeight;
    font-size: 12px;
  }
}
