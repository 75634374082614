.quick-links {
  font-family: Roboto, sans-serif;
  z-index: 100;
  margin-top: 10px;
  margin-bottom: 32px;
  left: 0;
}
.quick-links button {
  text-transform: capitalize;
  font-size: 13px;
  padding: 5px 10px;
  border: none;
  background: white;
  /* safari defaults background to grey */
}
.quick-links .quick-links-selected {
  border-bottom: 3px solid #ce0058;
}
