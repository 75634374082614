@import '../../../shared/styles/base.less';
@import '../../../shared/styles/mixins.less';
@import '../../../shared/styles/variables.less';

#ufr-form-field-accordion {
  .ufr-form-field-body {
    .ufr-accordion-title {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .ufr-opt-in {
      .react-input-description,
      .react-input-title {
        display: none;
      }

      .ufr-input-container {
        padding: 0;
        display: inline;
        width: 90%;

        #opt-in-url {
          margin-left: 10px;
        }

        .ufr-checkbox-wrapper,
        .ufr-input-inner,
        .ufr-input-wrapper {
          display: inline;
        }
      }

      .ufr-opt-in-fields {
        display: flex;
        align-items: center;

        .ufr-checkbox-input-container {
          width: auto;
        }
      }
    }

    .ufr-progressive-profiling {
      .ufr-input-container {
        padding: 0;

        .react-input-description {
          display: none;
        }

        .react-input-title {
          color: @fontColorPaleGrey;
        }
      }
    }

    .ufr-form-field-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      margin: 20px 0 5px 0;

      a.manage-form-fields {
        color: @uberPink;
        line-height: 16px;
      }

      a.manage-form-fields:hover,
      a.manage-form-fields:active,
      a.manage-form-fields:focus {
        outline: 0;
        text-decoration: none;
        color: @uberRubineDark;
      }

      p {
        margin: auto 0 auto 0;
      }
    }

    .ufr-form-field-fields {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-right: 2%;
    }

    .ufr-field-title {
      font-size: 13px;
      font-weight: 500;
      color: @fontColorMediumDark;
    }
  }
}
