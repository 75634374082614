@import "../../shared/styles/variables";
@import "../../shared/styles/mixins";

.ufr-checkbox-input-container {
  &&&#sfi-uf-activity-ufr-input-container {
    padding-top: 0;
  }

  .ufr-checkbox-wrapper {
    align-items: center;
    display: flex;
    margin: 10px 0 5px;
  }

  .ufr-input-wrapper {
    position: static;
  }

  &&& label.react-input-title {
    margin-bottom: 0;
    margin-left: 14px;
  }

  .ufr-checkbox-inner {
    display: inline-block;
  }

  .ufr-checkbox {
    margin: 5px 0 0 0;
    padding: 0;
    -webkit-appearance: none;
    width: 13px;
    height: 13px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    position: relative;

    &:checked {
      background-color: @uberBlueLight;
      border-color: @uberBlueLight;
    }

    &:checked:after {
      font-family: "Glyphicons Regular", sans-serif;
      content: "\E207";
      font-size: 9px;
      position: absolute;
      top: -1px;
      left: 1px;
      color: white;
    }

    &.ufr-color-picker-input--disabled {
      opacity: 0.6;
    }

    &:focus {
      .focus-outline;
    }
  }

  &--modal {
    label.react-input-title {
      font-size: 13px;
    }

    .ufr-checkbox {
      top: 1px;
    }
  }
}
