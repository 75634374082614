#topnav {
  .topnav-logo {
    position: absolute;
    height: 21px;
    top: 11px;
    left: 20px;
  }

  .topnav-logo-image-desktop {
    height: 21px;
    width: 68px;
  }

  .topnav-logo-image-mobile {
    display: none;
    height: 24px;
    width: 24px;
  }

  @media screen and (max-width: 1300px) {
    > ul {
      left: 45px;
    }

    .topnav-logo {
      width: 24px;
      height: 24px;
      left: 18px;
      top: 8px;
      overflow: hidden;
    }

    .topnav-logo-image-desktop {
      display: none;
    }

    .topnav-logo-image-mobile {
      display: block;
    }
  }
}
