@import '../../shared/styles/variables';

.ufr-font-slider {
  .ufr-input-container {
    width: 100%;

    label.react-input-title {
      font-size: 13px;
      font-weight: 500;
      color: @fontColorMediumDark;
    }

    p.react-input-description {
      margin: 0;
    }

    .ufr-slider {
      display: flex;

      .ufr-slider-input {
        flex-grow: 1;
      }

      .ufr-slider-number-input {
        height: 32px;

        .ufr-input-inner.ufr-number-inner {
          height: 32px;

          .ufr-input-wrapper {
            height: 32px;

            .ufr-input-component {
              width: 37px !important;
              height: 32px !important;
              padding: 7px 5px;
              font-size: 13px;
              text-align: center;

              &::-webkit-inner-spin-button {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
