@import "../../shared/styles/variables";
@import "../../shared/styles/mixins";

/* stylelint-disable value-list-comma-space-after no-descending-specificity*/
.ufr-page-control {
  display: inline-block !important;
  padding: 16px 0 !important;
  margin: 0 !important;
  float: right;
  font-family: @roboto;

  .normalize-active-span {
    border-color: #e1e2e1;
    color: @fontColorDark;
    border-bottom: 2px solid @uberPink;
  }

  li.active span {
    .normalize-active-span;

    background: white;
    padding: 5px 10px 4px;
    font-size: 13px;

    &:hover {
      .normalize-active-span;

      background: @uberPinkLight;
    }
  }

  a[role=button] {
    font-size: 13px;
    color: @fontColorDark;

    &:hover {
      background: @uberPinkLight;
    }

    &:focus {
      z-index: 4;
      background: white;
      .focus-outline;
    }
  }
}

.ufr-page-control li.disabled span,
.ufr-page-control li.active.disabled span,
.ufr-count-dropdown select:disabled {
  color: @fontColorDark;
  background: #eee;
  pointer-events: none;
  font-size: 13px;
}
