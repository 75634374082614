.ufr-image-uploader {
  width: 609px;
  font-family: Roboto, sans-serif;
  position: relative;
}
.ufr-image-uploader .ufr-input-error-message {
  color: #d44545;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  font-family: Roboto, sans-serif;
}
.ufr-image-uploader .ufr-upload-control-left,
.ufr-image-uploader .ufr-upload-control-right {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.ufr-image-uploader .ufr-upload-control-right > span {
  margin-right: 15px;
}
.ufr-image-uploader .ufr-upload-control-right .ufr-clearbit-search-box {
  display: inline-block;
  width: 275px;
}
.ufr-image-uploader .ufr-footer-text {
  margin-top: 15px;
  font-size: 14px;
}
