@import url('../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-stacked-tile {
  position: relative;
  cursor: pointer;
  width: 170px;
  height: 200px;
  display: inline-block;
  margin: 0 20px 20px 0;
  text-align: left;
}
.ufr-stacked-tile .ufr-stacked-tile-main {
  overflow: hidden;
  z-index: 1;
  width: 170px;
  height: 180px;
  position: relative;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.ufr-stacked-tile .ufr-stacked-tile-main .ufr-stacked-tile-title {
  height: 40px;
  padding: 6px;
  overflow: hidden;
}
.ufr-stacked-tile .ufr-stacked-tile-main .ufr-stacked-tile-title p {
  font-weight: 700;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}
.ufr-stacked-tile .ufr-stacked-tile-main .ufr-stacked-tile-title .ufr-selected-icon {
  position: absolute;
  top: 7px;
  right: 6px;
  background: white;
}
.ufr-stacked-tile .ufr-stacked-tile-main .ufr-stacked-tile-image {
  width: 100%;
  height: 140px;
  background-size: cover;
}
.ufr-stacked-tile .ufr-stacked-tile-main .ufr-stacked-tile-image ~ .ufr-service-icon,
.ufr-stacked-tile .ufr-stacked-tile-main .ufr-stacked-tile-image ~ .ufr-stacked-tile-bottom-text {
  box-shadow: 0 1px 5px 0 rgba(51, 51, 51, 0.25);
}
.ufr-stacked-tile .ufr-stacked-tile-main .ufr-service-icon {
  position: absolute;
  bottom: 0;
  left: 0;
}
.ufr-stacked-tile .ufr-stacked-tile-main .ufr-stacked-tile-bottom-text {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 24px;
  left: 24px;
  text-align: left;
  padding: 5px 7px 0;
  background-color: #333;
  color: white;
  font-size: 10.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ufr-stacked-tile .ufr-stacked-tile-cascade {
  width: 170px;
  height: 20px;
  position: absolute;
  bottom: 0;
}
.ufr-stacked-tile.ufr-stacked-tile-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.ufr-stacked-tile.ufr-stacked-tile-disabled:focus {
  outline: 0;
}
