@import "../../shared/styles/variables";
@import "../../shared/styles/mixins";

.ufr-count-dropdown {
  font-family: @roboto;
  position: relative;
  padding: 16px 20px;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;

  select {
    height: 28px;
    margin: 0 8px;
    background: none;
    border-color: @mediumGreyBorder;

    &:focus {
      border-color: transparent;
      .focus-outline;
    }
  }
}
