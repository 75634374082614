.ufr-image-uploader .ufr-image-cropper {
  margin-bottom: 15px;
  border: 1px solid #eee;
  text-align: right;
}
.ufr-image-uploader .ufr-image-cropper > .ufr-crop-area {
  text-align: center;
  background: #f9f9f9;
}
.ufr-image-uploader .ufr-image-cropper .ufr-btn + .ufr-btn {
  margin: 15px;
}
