// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// ----------------------------------------------------------------------------------------------------------
// Hubs Content Management Section
// ----------------------------------------------------------------------------------------------------------

.mgmt-container {
  .content-container.hubs-section-schedule {

    // -----------------------------------------------------------------------------------------
    // Schedule Table

    table.table.standard-data-table {
      tbody {
        td {
          line-height: 18px;

          .stream-title {
            font-weight: bold;
            text-transform: uppercase;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    .manage-schedule-btn {
      font-size: @font-size-base + 1; // 13px
    }
  }
}

// Manage Schedule Modal

.manage-schedule-modal {
  width: 700px;
  height: 100%;

  .header {
    width: 700px;

    h1 {
      margin: 20px 0 5px 0;
      font-size: 26px;
      text-align: center;
      font-weight: 400;
    }
  }

  .body {
    width: 700px;
    height: 100%;
    overflow: hidden;

    .schedule-tab {
      width: 700px;
      height: 100%;

      .divider {
        position: relative;
        height: 32px;
        background: #aaa;

        h2 {
          width: 560px;
          color: #fff;
          font-size: 14px;
          text-align: center;
          line-height: 32px;
          margin: 0 auto 12px auto;
          font-weight: normal;
          .text-overflow();

          em {
            font-style: normal;
          }
        }
      }

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // Common Elements

      .schedule-split-panel {
        width: 600px;
        margin: 30px auto;
        .clearfix;

        .schedule-panel-left {
          float: left;
          width: 250px;
          margin-right: 45px;
        }

        .schedule-panel-right {
          float: left;
          width: 300px;
        }

        &.no-margin {
          .schedule-panel-left {
            margin-right: 0;
          }

          .schedule-panel-right {
            width: 345px;
          }
        }
      }

      .target-view {
        .tile.single {
          margin: 5px;

          .img {
            opacity: 1;
          }

          &.uberflip {
            .description {
              height: 125px !important;
            }
          }

          // Prevent Hover Effects on Tile

          &:hover {
            > a.view {
              text-decoration: none !important;
              background-color: #fcfcfc !important;

              &:after { background-position: 0px 0 !important; }
            }

            .description h3 {
              color: #555 !important;
            }
          }
        }

        &.bordered {
          .tile,
          .collection-tile {
            margin: 0;
            border: 1px solid #ddd;
            .box-shadow(none);
          }
        }
      }

      .tile.single.cta {
        .cta-field-sprite-container {
          top: 29px;
        }
      }

      .stream-search {
        margin-left: 350px;

        .input-group {
          input {
            padding-right: 25px;
          }

          .input-group-addon {
            a {
              color: @gray-dark;    // #333
            }
          }
        }

        .stream-search-clear {
          display: none;
          overflow: hidden;

          &.active {
            display: block;
            .pos-abs(8px, 44px);    // T, R, B, L
            .square(15px);

            line-height: 15px;
            font-size: 16px;
            padding: 0;
            text-align: center;
            cursor: pointer;
            z-index: 3;
            color: #bbb;
            background: white;
            border: 1px solid #ddd;
            .border-radius(4px);
          }

          &:hover {
            color: white;
            background: #333;
            border: 1px solid white;
            .box-shadow(0 0 1px 1px #aaa);
          }
        }
      }

      .back-arrow {
        .pos-abs(4px, auto, auto, 30px);  // T, R, B, L
        .square(24px);

        color: #fff;
        background: transparent;
        font-size: 36px;
        line-height: 20px;
        text-align: center;
        .border-radius(12px);
        .transition(all 200ms linear);

        cursor: pointer;

        &:hover {
          color: #aaa;
          background: #fff;
        }
      }

      .empty-group {
        margin: 30px 0;
        padding: 25px;
        text-align: center;
        background: #f9f9f9;

        .empty-group-text {
          width: 80%;
          margin: 15px auto;
          font-size: 1.25em;
          text-align: center;
        }
      }

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // Step 1 Elements

      &.schedule-type {
        .schedule-type-buttons {
          width: 250px;
          margin: 70px auto 0 auto;

          p {
            margin-bottom: 15px;
          }

          .btn-schedule-type-selection {
            width: 220px;
            padding: 14px;
            font-size: 14px;
            text-transform: uppercase;
            text-shadow: none;
            background-color: darken(@gray-lighter, 6.7%);    // #ddd

            &:hover {
              color: #fff;
              background-color: @uberBlueLight;
              border-color: @uberBlueLight;
              border-bottom-color: darken(@uberBlueLight, 10%);
            }
          }
        }
      }

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // Step 2 Elements

      &.schedule-target {
        .schedule-target-selection {
          width: 600px;
          margin: 0 auto;

          .stream-search {
            padding: 12px 0;
            .clearfix;
          }

          .cta-container {
            padding-top: 30px;

            #addCtaCarousel {
              position: relative;
              line-height: normal;
              margin: 6px 35px;
            }

            .tile.single.cta {
              display: block;
              float: none !important;
              margin: 0 auto !important;
              .transform(scale(0.8,0.8));
            }

            .cta-selectbox {
              text-align: center;
            }

            .select-cta-container {
              text-align: center;
              padding: 0;

              a {
                width: 172px;
                padding: 14px;
                font-weight: bold;
              }
            }

            a:link.carousel-control {
              color: #555;
              background: transparent;
              border: 0 none;
            }
          }

          .stream-container {
            max-height: 370px;
            padding: 2px;
            border: 1px solid #ddd;
            overflow: auto;

            ul.stream-listing {
              padding: 0;

              li {
                font-weight: bold;
                margin: 0;
                cursor: pointer;

                &.no-streams {
                  display: none;
                  padding: 20px 6px;
                  font-style: italic;
                }

                a {
                  display: block;
                  color: #555;
                  padding: 6px;
                  font-size: 12px;

                  em {
                    display: inline-block;
                    width: 75%;
                    vertical-align: middle;
                    font-style: normal;
                    text-transform: uppercase;
                    .text-overflow();

                    span {
                      padding-left: 5px;
                      font-weight: 300;
                      text-decoration: none;
                      opacity: 0.75;
                      font-size: 10px;
                    }
                  }

                  span {
                    margin-right: 10px;

                    &.arrow {
                      display: inline-block;
                      float: right;
                      .square(34px);

                      margin-top: 7px;
                      background: transparent url("/img/hubs/backend/miscicons.png") no-repeat -87px -16px;
                    }
                  }

                  &:hover {
                    color: #fff;
                    background-color: @uberBlueLight;
                  }
                }
              }
            }
          }
        }
      }

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // Step 3 Elements

      &.schedule-target-item {
        .select-item-container {
          width: 600px;
          padding: 20px 0;
          margin: 0 auto;

          #item-selector {
            max-width: 600px;

            .item-selector-header {
              margin-bottom: 15px;
              .clearfix;

              .input-group {
                float: right;
                width: 300px;

                .input-group-addon {
                  a {
                    color: @gray-dark;    // #333
                  }
                }

                .search-clear {
                  display: none;
                  overflow: hidden;

                  &.active {
                    display: block;
                    .pos-abs(8px, 44px);    // T, R, B, L
                    .square(15px);

                    line-height: 15px;
                    font-size: 16px;
                    padding: 0;
                    text-align: center;
                    cursor: pointer;
                    z-index: 3;
                    color: #bbb;
                    background: white;
                    border: 1px solid #ddd;
                    .border-radius(4px);
                  }

                  &:hover {
                    color: white;
                    background: #333;
                    border: 1px solid white;
                    .box-shadow(0 0 1px 1px #aaa);
                  }
                }
              }
            }
          }

          .item-selector-item-container {
            max-height: 380px !important;
            border: 1px solid #ddd;

            .item-group {
              margin-left: 19px;
            }
          }
        }
      }

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // Step 4 Elements

      &.schedule-select {
        .schedule-selection {
          border: 1px solid #ddd;

          .schedule-listing-container {
            height: 330px;
            max-height: 330px;
            background: #eee;
            overflow: auto;

            .schedule-listing {
              background: #fff;

              li {
                display: block;
                color: #555;
                padding: 10px 20px;
                margin: 0;
                font-size: 12px;
                line-height: 34px;
                .transition(~"background-color 250ms linear");

                border-bottom: 1px solid #ddd;

                span {
                  &.action {
                    display: block;
                    float: left;
                    width: 90px;
                    font-weight: bold;
                    text-transform: uppercase;
                    .text-overflow();
                  }

                  &.when {
                    display: inline-block;
                    width: 128px;
                  }
                }

                .edit-btn,
                .remove-btn {
                  display: block;
                  float: right;
                  .square(26px);

                  margin: 3px 0 0 6px;
                  color: #fff;
                  border: 1px solid #fff;
                  border-radius: 14px;
                  text-align: center;
                  vertical-align: middle;
                  .transition(~"border 250ms linear");

                  .halflings {
                    visibility: hidden;
                    top: -3px;
                  }
                }

                .add-new {
                  display: block;
                  color: #555;
                  font-weight: bold;
                  text-align: center;
                  text-decoration: none;
                  text-transform: uppercase;
                }

                &:hover {
                  color: #fff;
                  background: @uberBlueLight;

                  .edit-btn,
                  .remove-btn {
                    border: 1px solid @uberBlueLight;

                    .halflings {
                      visibility: visible;
                    }

                    &:hover {
                      border: 1px solid #fff;
                    }
                  }

                  .add-new {
                    color: #fff;
                  }
                }

                &.flashy {
                  color: #fff;
                  background-color: @uberBlueLight; // @uberGreen;

                  .edit-btn,
                  .remove-btn {
                    border: 1px solid @uberBlueLight;
                  }
                }
              }
            }
          }
        }
      }

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // Step 5 Elements

      &.schedule-action {
        .schedule-configuration {
          label {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
          }

          select {
            width: 300px;
            margin-bottom: 20px;

            option {
              font-weight: bold;
            }
          }

          .datefield {
            background-color: #fff;
          }

          .button-container {
            margin: 30px 0;
            text-align: right;
          }
        }

        .cta .hidden-cta-fields .cta-field-section .cta-field-sprite-container {
          top: 29px;
        }

        .manage-schedule-link {
          padding: 10px 60px;
        }
      }

      .tags-wrapper {
        margin-bottom: 20px;
      }
    }
  }
}

.iframe #flash_message {
  position: absolute;
  bottom: -70px;
  width: 90%;
  padding: 10px 14px;
  margin: 0 20px;
  .close { display: none; }
}
