@import '../shared/styles/variables';
@import '../shared/styles/mixins';

.ufr-stream-page-preview {
  .preview-buttons {
    text-align: right;
    width: 200px;

    button {
      .btn-unstyle;
    }

    .preview-action {
      padding: 5px 10px 0;
      border: 1px solid #b9b9b9;
      margin: 5px;
      border-radius: 3px;

      .react-icon {
        overflow: visible;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .preview-text {
      margin-right: 10px;
    }
  }
}
