.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.searchable-menu {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.searchable-menu a {
  color: #555;
  padding: 7px 9px;
}
.searchable-menu-input.form-control {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
