.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.react-btn-dropdown.dropdown.btn-group,
.react-btn-dropdown.dropup.btn-group {
  cursor: pointer;
}
.react-btn-dropdown.dropdown.btn-group:not(.disabled):hover > .dropdown-toggle,
.react-btn-dropdown.dropup.btn-group:not(.disabled):hover > .dropdown-toggle {
  background: #9c0042;
  border-color: #9c0042;
}
.react-btn-dropdown.dropdown.btn-group.disabled > .dropdown-toggle,
.react-btn-dropdown.dropup.btn-group.disabled > .dropdown-toggle {
  background: #777 !important;
  border-color: #777;
  color: white;
  opacity: 1 !important;
}
.react-btn-dropdown.dropdown.btn-group.disabled:hover > .dropdown-toggle,
.react-btn-dropdown.dropup.btn-group.disabled:hover > .dropdown-toggle {
  background: #555 !important;
  cursor: not-allowed;
}
.react-btn-dropdown.dropdown.btn-group .dropdown-toggle,
.react-btn-dropdown.dropup.btn-group .dropdown-toggle {
  height: 32px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: Roboto, sans-serif;
  background: #ce0058;
  border-color: #ce0058;
  color: white !important;
}
.react-btn-dropdown.dropdown.btn-group .dropdown-toggle:first-child,
.react-btn-dropdown.dropup.btn-group .dropdown-toggle:first-child {
  margin-right: 3px;
}
.react-btn-dropdown.dropdown.btn-group .dropdown-toggle:nth-child(2),
.react-btn-dropdown.dropup.btn-group .dropdown-toggle:nth-child(2) {
  height: 32px;
  width: 29px;
}
.react-btn-dropdown.dropdown.btn-group .react-dropdown-menu,
.react-btn-dropdown.dropup.btn-group .react-dropdown-menu {
  left: auto;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  padding: 8px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #cccccc;
  background-color: white;
}
.react-btn-dropdown.dropdown.btn-group .react-dropdown-menu li a,
.react-btn-dropdown.dropup.btn-group .react-dropdown-menu li a {
  display: flex;
  align-items: center;
  min-width: 180px;
  padding: 0 16px;
  font-size: 13px;
  height: 48px;
  color: #777;
}
.react-btn-dropdown.dropdown.btn-group .react-dropdown-menu li a:hover,
.react-btn-dropdown.dropup.btn-group .react-dropdown-menu li a:hover {
  background: #eaeaea !important;
  color: #ce0058 !important;
}
.react-btn-dropdown.dropdown.btn-group .react-dropdown-menu li a:focus,
.react-btn-dropdown.dropup.btn-group .react-dropdown-menu li a:focus {
  box-shadow: none;
  outline: none;
}
.react-btn-dropdown.dropdown.btn-group .react-dropdown-menu li a .menu-text,
.react-btn-dropdown.dropup.btn-group .react-dropdown-menu li a .menu-text {
  font-size: 13px;
}
.react-btn-dropdown.dropdown.btn-group .react-dropdown-menu li.with-icon a,
.react-btn-dropdown.dropup.btn-group .react-dropdown-menu li.with-icon a {
  padding-left: 12px;
}
.react-btn-dropdown.dropdown.btn-group .react-dropdown-menu li.with-icon a .menu-text,
.react-btn-dropdown.dropup.btn-group .react-dropdown-menu li.with-icon a .menu-text {
  font-size: 13px;
  padding-left: 10px;
}
.react-btn-dropdown.dropdown.btn-group .react-dropdown-menu li.with-icon a .react-icon,
.react-btn-dropdown.dropup.btn-group .react-dropdown-menu li.with-icon a .react-icon {
  font-size: 14px;
  opacity: 1;
  line-height: 1;
  width: auto;
  left: 0;
  position: relative;
}
.react-btn-dropdown.dropdown.btn-group.open button,
.react-btn-dropdown.dropup.btn-group.open button {
  background: #9c0042;
  border-color: #9c0042;
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
