// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.mgmt-container {
  .content-container {
    .alert {
      margin-left: 20px;
    }

    .alert.hubs-collection-hidden-note {
      margin-bottom: 40px;
    }

    &.hubs-section-manage-content {
      padding: 0 20px 38px 20px;
    }

    div.upgrade-bubble {
      margin: 0 auto;
      text-align: center;
      width: 400px;
      border: 1px solid #ccc;
      padding: 20px;
    }

    .navbar {
      width: auto;

      .container {
        text-align: center;

        .nav > li {
          display: inline-block;
          float: none;
          text-align: left;
        }
      }
    }

    .create-hub-btn {
      margin-top: 20px;
    }

    .domain-notice {
      margin-bottom: 15px;
    }

    // --------------------------------------------------------------------------------------------------
    // Large Textarea for Custom CSS

    .custom-script-wrapper {
      margin: 0 auto 100px auto;
      max-width: 1400px;

      label {
        line-height: 1.2em;

        &.radio input[type="radio"],
        &.checkbox input[type="checkbox"] {
          float: none;
          display: inline-block;
        }
      }

      .ace-editor {
        height: 422px;
        padding: 10px 4.5px;
        border: 1px solid @customCodeBorderColor;
        box-sizing: border-box;
      }

      .alert-info {
        margin-left: 0;

        a {
          color: @uberBlue;
          text-decoration: underline;
        }
      }

      .toggle-custom-script-wrapper {
        margin-bottom: 40px;

        label {
          display: inline-block;
        }
      }

      .custom-script-container {
        margin-top: 40px;

        .custom-script-header {
          padding: 10px;
          background: #999;
          color: #fff;

          .clearfix;

          .dev-note {
            > strong {
              font-size: 14px;
            }

            > span {
              padding-left: 15px;
              color: #fff;
            }
          }

          .badge {
            padding: 1px 9px 0;
            margin-right: 6px;
            background: #888;
          }
        }

        .custom-script-tag {
          background: #ddd;
          border: 1px solid #ccc;
          border-width: 0 1px;

          > span {
            display: block;
            padding: 8px;
            color: #888;
            font-size: 14px;
          }
        }

        textarea {
          .size(100%, 430px);

          color: darken(@gray-lighter, 6.7%);      // #ddd
          font-size: (@font-size-base - 0.5);       // 12 - 0.5;
          line-height: (@line-height-computed - 7);     // 20 - 7
          background: darken(@gray-darker, 6.5%);  // #111
        }

        .custom-save {
          padding: 12px;
          margin-top: 0;
          border: 1px solid @customCodeBorderColor;
          border-top: none;
          background-color: @gray-lighter;
        }

        div.note {
          margin-bottom: 7px;

          &:hover {
            background-color: @gray-lighter;
          }

          a:hover {
            color: @uberBlue;
          }
        }
      }

      .custom-css-support {
        width: 19%;
        background: #f9f9f9;
        height: 480px;

        > div {
          padding: 0px 20px;

          > h3 {
            color: @uberPink;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 17px;
            line-height: 20px;
            margin: 20px 0 10px;
          }

          > p {
            font-size: 12px;
            line-height: 18px;

            > a {
              color: @uberBlue;
              font-weight: bold;
            }
          }
        }
      }

      .editor-note {
        margin-bottom: 10px;
      }

      .custom-script-container.ace-editor-full-screen {
        height: auto;
        width: auto;
        position: fixed !important;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        border: 0;
      }
    }

    // --------------------------------------------------------------------------------------------------
    // Block Icon-Nav on Custom Code Section

    .nav-block-icon.nav-code {
      .nav-block-icon-width(3);   // 3 Blocks in Nav

      margin: 0 auto;

      > li {
        a {
          padding: 0;
          color: @white;
          line-height: 80px;
          text-align: center;
          font-size: 16px;

          > span {
            display: inline;
            border: 1px solid #fff;
            padding: 2px 7px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.1);
            .transition(all 500ms);
          }

          &.unsaved {
            > span {
              background: @uberBlueLight;
              .box-shadow(0 0 15px rgba(255, 255, 255, 0.6) );
            }
          }
        }

        &.active {
          a,
          a.unsaved {
            > span {
              background: rgba(0, 0, 0, 0.3);
              .box-shadow(none);
            }
          }
        }
      }
    }
  }
}

// ----------------------------------------------------------------------------------------------------------
// Bootstrap Sprite Additions
// ----------------------------------------------------------------------------------------------------------

.icon-share-new,
.icon-cta,
.icon-embed-new {
  background-image: url("/img/hubs/backend/spritesheet.png");
}

.icon-share-new { background-position: -414px -174px; }

.icon-embed-new {
  background-position: -51px -453px;
  width: 19px;
}

.icon-cta {
  background-position: -452px -173px;
  margin-right: 2px;
}

// ----------------------------------------------------------------------------------------------------------
// Hubs Shared Content Elements
// ----------------------------------------------------------------------------------------------------------

.autosuggest {
  text-align: left;
}

.layout-bs3 {
  .import-container {
    .import-title,
    .import-action {
      line-height: 24px;
    }

    .import-title {
      padding: 23px 0 23px 15px;

      .halflings {
        top: 3px;
        margin-right: 10px;
      }
    }

    .import-action {
      padding: 23px 15px 23px 0;
    }
  }

  .import-type-container {
    .checkbox {
      label {
        margin-top: 30px;
      }
    }
  }

  .exclaim-container {
    .halflings {
      top: 2px;
      opacity: 0.5;
    }
  }
}

// --------------------------------------------------------------------
// Standard Data-Table Style

table.table.standard-data-table {
  margin-top: 20px;

  thead {
    tr {
      th {
        padding: 6px 18px 10px 10px;
        white-space: nowrap;

        &.sorting_asc,
        &.sorting_desc {
          -webkit-filter: grayscale(100%);
        }

        &:last-child {
          width: 80px;
          max-width: 80px;
          text-align: right;
        }

        &.no-sort {
          background: transparent;
          cursor: default;
        }
      }
    }
  }

  tbody {
    td {
      vertical-align: middle;

      &.no-wrap {
        white-space: nowrap;
      }
    }
  }
}

// Override Data-Table Styles

table.dataTable.standard-data-table {
  tbody {
    tr {
      &.odd,
      &.even { background-color: #fff; }

      &:hover {
        > td { background: #eee; }
      }
    }
  }
}
// Standard Data-Table Wrapper

.dataTables_wrapper {
  .row {
    width: auto;
    padding: 0;

    &:last-child {
      margin-top: 20px;
    }

    label {
      color: #666;
      font-weight: normal;
    }
  }

  .dataTables_filter {
    input {
      margin-left: 6px;
    }
  }

  div.dataTables_info {
    padding-top: 8px;
  }

  .dataTables_empty {
    padding: 10px;
  }
}

// --------------------------------------------------------------------
// Drop Down Menus

ul.hubs-dropdown {
  li {
    a {
      color: darken(@gray-light, 10%);         // #808080

      .icon-white { display: none; }
    }

    &.active {
      a,
      a:hover {
        color: @uberPink !important;
        background: darken(@white, 2.5%) !important;  // #f9f9f9
      }
    }
  }

  > li {
    > a {
      &:hover {
        color: @uberBlue;
        background: darken(@white, 2.5%);    // #f9f9f9
      }
    }
  }
}

.collection-search-container {
  float: right;
  width: auto;

  .collection-search-title,
  .collection-search-progress,
  .input-group {
    float: right;
  }

  .input-group {
    width: 220px;

    a {
      color: @gray-dark;    // #333
    }

    input {
      padding-right: 24px;
    }

    .close-float {
      .pos-abs(0, 45px);      // T, R, B, L

      font-size: 20px;
      cursor: pointer;
      z-index: 4;
      .opacity(0);
      .transition(opacity 0.5s ease-in-out);
    }
  }
}

.collection-search-title {
  padding: 2px 10px 0 0;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
  .clearfix;

  .section-tally {
    float: left;

    .badge {
      padding: 1px 9px;
      font-size: 10px;
      .border-radius(2px);
    }

    .caption {
      display: block;
      text-align: center;
    }
  }

  .result-tally {
    float: left;
    padding: 0px 0 0 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
  }
}

.collection-search-progress {
  display: none;
  padding: 3px 8px;

  img { width: 20px; }
}

.hubs_load_more {
  clear: both;
  margin: 8px;
  padding-top: 10px;
  text-align: center;

  .loading-lock-state {
    display: none;
  }

  .locked {
    .load-more-text {
      display: none;
    }

    .loading-lock-state {
      display: inline;
    }
  }
}

.collection-item-holder {
  .clearfix;

  .no-deleted-items {
    text-align: center;
    font-size: 20px;
  }
}

.toggle {
  .hubs_section_header {
    &.hover {
      cursor: pointer;

      .header_section_buttons {
        background: @white;
      }
    }
  }
}

// --------------------------------------------------------------------
// Hubs Sections

.hubs_section_wrapper {
  margin-bottom: 30px;
  margin-top: 30px;

  .hubs_section_header {
    height: 46px;
    margin: 0;
    .clearfix;

    .hubs_section_header_title {
      float: left;
      margin-top: 16px;
      margin-left: 15px;
    }

    .section_title {
      color: @uberPink;
      font-weight: bold;
      font-size: 1.2em;
    }

    .hubs_section_tally {
      color: darken(@gray-lighter, 14%); // #cacaca
      font-size: 1.2em;
    }

    .hubs_section_description {
      color: lighten(@gray, 6.5%);    // #666
      font-size: (@font-size-base - 3);
      margin-left: 8px;
    }

    .header_section_buttons {
      height: 100%;

      span.btn-group {
        display: inline-block;
        vertical-align: bottom;
        .caret { margin: 6px 0 0 3px; }
      }
    }

    input.inline_edit_input {
      .size(240px, 16px);

      margin: -8px 0 0 -8px;
    }
  }

  .empty-group {
    margin: 30px 0;
    padding: 25px;
    text-align: center;
    background: #f9f9f9;

    .empty-group-text {
      width: 80%;
      margin: 15px auto;
      font-size: 1.25em;
      text-align: center;
    }
  }

  //hidden collection alert
  .hubs-collection-hidden-note {
    margin: 0 0 40px 0 !important;
  }
}

// --------------------------------------------------------------------
// Section Content

.hubs_section_block,
.hubs_featured_section_block,
.hubs_collection_section_block {
  min-width: 500px;
  background-color: @white !important;
  .clearfix;
}

.hubs_section_block {
  &.limit-height {
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.hubs_collection_section_block {
  padding: 0px 0 15px 0;

  .hub-collection-options {
    position: relative;
    margin: 40px 0 0 0;

    .manage-deleted-items {
      position: absolute;
      right: 0;
    }
  }

  // This is due to display: block being set in js to the style attribute
  // List view should never have the CTA's showing

  &.list-view {
    .item-cta {
      display: none !important;
    }
  }

  .item-cta:not(.cta-15) .cta {
    height: 293px;
  }

  .item-cta:not(.first-cta) {
    .cta {
      height: 293px;

      .update-cta-placement-wrapper {
        display: none;
      }
    }

    .collection-item-controls {
      display: none;
    }
  }
}

.hubs_featured_section_block {
  &.limit-height {
    max-height: 350px;
    overflow: auto;
  }
}

.collection-wrapper,
.hubs_section_wrapper {
  &.hidden {
    .hubs_collection_section_block {
      opacity: 0.75;
    }
  }
}

// --------------------------------------------------------------------
// Item Selector

.item-selector-item-container {
  .item-group {
    float: left;
    clear: none;
    margin: 20px 0 10px 15px;

    &.item-hidden {
      opacity: 0.5;
      .filter(grayscale(100%));

      &:hover {
        opacity: 0.8;
      }

      &:before {
        content: "Hidden Item";
        background: @white;
        background: rgba(255, 255, 255, 0.8);
        padding: 1px 6px;
        position: absolute;
        font-size: 10px;
        margin: 5px;
        .border-radius(3px);
      }
    }

    &:hover {
      .box-shadow(0 0 10px lighten(@gray-light, 6.5%));  // #aaa
    }
  }
}

// --------------------------------------------------------------------
// Collection Item Controls

.collection-item-controls {
  height: 33px;
  background-color: @white;
  border: 1px solid darken(@gray-lighter, 13.5%); // #ccc
  .box-sizing(border-box);

  a {
    color: darken(@gray-light, 10%);          // #808080
    background: lighten(@gray-lighter, 4.5%); // #f9f9f9
    font-size: 0.9em;
    line-height: 0.9em;
    font-weight: normal;
    border-bottom-width: 1px;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  .left {
    display: inline-block;
    margin: 5px 0 0 5px;
  }

  .right {
    display: inline-block;
    margin: 5px 5px 0 0;
  }

  [class^="icon-"],
  [class*=" icon-"],
  .halflings {
    height: 14px;
    margin-top: -1px;
    font-size: @font-size-base;
  }

  .dropdown-menu {
    li {
      a {
        background: transparent;

        &:hover {
          color: lighten(@gray, 6.5%); // #666
          background: @gray-lighter;
        }
      }
    }
  }
}

// --------------------------------------------------------------------
// Collection Group

.collection-group {
  display: inline-block;
  width: 228px;
  margin: 15px 0 0 0.75%;
  vertical-align: top;
  font-weight: bold;
  border: 1px solid #ddd;
  .box-sizing(border-box);

  &.add-collection-bt {
    text-align: center;
    background: rgba(255, 255, 255, 0.5);

    a.btn {
      margin: 67px auto;
      font-weight: normal;
    }

    &:hover { background: rgb(255, 255, 255); }
    &.hidden { visibility: hidden; }
  }

  &.hidden { opacity: 0.5; }

  .collection-item-controls {
    border: none;

    a.btn:hover {
      border-color: #ddd;
    }
  }

  &:hover {
    .box-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));

    .collection-placeholder > .tile > div.description {
      background-color: #333;
    }
  }
}

// --------------------------------------------------------------------
// Collection Placeholders

.collection-placeholder {
  width: 100%;
  height: 132px;
  background-color: darken(@gray-lighter, 15.5%); // #c6c6c6
  cursor: pointer;
  overflow: hidden;

  .tile {
    position: relative;
    width: 100%;
    height: 132px;
    max-height: none;
    min-height: 0;
    max-width: none;
    min-width: 0;

    div.description {
      min-height: 48px;
      background: #666;

      h3 {
        margin: 16px 8px 0px 50px !important;
        font-size: 14px !important;
        .text-overflow();
      }

      .icon {
        margin: 0;
        background-color: transparent;
        opacity: 0.7;
        -webkit-transform: scale(0.7);
      }
    }
  }
}

.collection-preview-bg {
  position: relative;
  height: 132px;
  margin-top: -132px;
  background: transparent url("/img/hubs/backend/preview_bg.png") no-repeat top right;
  cursor: pointer;
}

// --------------------------------------------------------------------
// Collection Tiles

.collection-tile {
  position: relative;
  float: left;
  width: 250px;
  height: 330px;
  padding: 0;
  margin: 0;
  line-height: 1.5em;
  background: #fff;
  .box-shadow(0 1px 5px rgba(0, 0, 0, 0.2));

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  overflow: hidden;

  .collection-tile-service-bg {
    .pos-abs(0, 0, 0, 0);         // T, R, B, L
  }

  &.has-thumb {
    .collection-tile-service-bg {
      opacity: 0.75;
    }
  }

  .collection-tile-service-icon {
    display: inline-block;
    .pos-abs(50%, auto, auto, 50%);         // T, R, B, L
    .square(60px);

    line-height: 60px;
    margin: -70px 0 0 -30px;
    vertical-align: middle;
    background-color: transparent;
    background-image: url("/img/hubs/servicetiles.png");
    background-repeat: no-repeat;
    background-position: 0px 0;
  }

  .description {
    .pos-abs(auto, auto, 0, 0);         // T, R, B, L

    width: 250px !important;
    height: auto;
    min-height: 50px;
    line-height: 16px;
    padding: 10px 20px;
    border-top: 1px solid #ddd;
    background-color: #fff !important;

    h4 {
      color: #555 !important;
      margin: 10px 0 !important;
      font-weight: bold !important;
    }

    .timeago {
      color: #888;
    }
  }

  &.service-twitter {
    .collection-tile-service-bg { background-color: #79caee; }
    .collection-tile-service-icon { background-position: -2px -4px; }
  }

  &.service-blogpost {
    .collection-tile-service-bg { background-color: #f7931d; }
    .collection-tile-service-icon { background-position: -72px -4px; }
  }

  &.service-facebook {
    .collection-tile-service-bg { background-color: #415f9f; }
    .collection-tile-service-icon { background-position: -142px -4px; }
  }

  &.service-youtube {
    .collection-tile-service-bg { background-color: #f00; }
    .collection-tile-service-icon { background-position: -212px -4px; }
  }

  &.service-uberflip,
  &.service-uberflip-ui {
    .collection-tile-service-bg { background-color: #cd1c5a; }
    .collection-tile-service-icon { background-position: -282px -4px; }
  }

  &.service-vimeo {
    .collection-tile-service-bg { background-color: #7bcfdb; }
    .collection-tile-service-icon { background-position: -352px -4px; }
  }

  &.service-instagram {
    .collection-tile-service-bg { background-color: #e4405f; }
    .collection-tile-service-icon { background-position: -424px -4px; }
  }

  &.service-slideshare {
    .collection-tile-service-bg { background-color: #00a7a9; }
    .collection-tile-service-icon { background-position: -492px -4px; }
  }

  &.service-wistiar {
    .collection-tile-service-bg { background-color: #1678be; }
    .collection-tile-service-icon { background-position: -562px -4px; }
  }

  &.service-vidyard {
    .collection-tile-service-bg { background-color: #499744; }
    .collection-tile-service-icon { background-position: -632px -4px; }
  }

  &.service-custom {
    .collection-tile-service-bg { background-color: #cd1c5a; }
    .collection-tile-service-icon { background-position: -701px -4px; }
  }

  &.service-brightcove {
    .collection-tile-service-bg { background-color: #f7931d; }
    .collection-tile-service-icon { background-position: -775px -4px; }
  }
}

// --------------------------------------------------------------------
// Item Group

.item-group {
  display: inline-block;
  float: left;
  clear: none;
  width: 225px;
  margin: 20px 0 10px 15px;
  font-weight: bold;
  position: relative;

  .item-copy {
    margin: 0 20px 0 0;
    border-bottom: 1px solid darken(@gray-lighter, 13.5%); // #ccc

    &:hover {
      .box-shadow(none);
    }
  }

  .circle-icon,
  .select-item {
    display: none;
  }

  .publish-status {
    display: inline-block;
    padding: 1px 5px;
    margin: 10px 0 5px 20px;
    font-size: 10px;
    line-height: 16px;
    color: @white;
    background: lighten(@gray-light, 13.2%);
    .border-radius(2px);

    &.draft {
      text-transform: uppercase;
    }

    &.scheduled {
      .icon-time {
        margin-top: -1px;
      }
    }
  }

  .top-padding-25 {
    padding-top: 25px;
  }

  .left-margin-35 {
    margin-left: 35px;
  }

  .knob-container {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0;
    .transition(all 0.8s ease-in-out);

    cursor: pointer;

    &.active {
      opacity: 1;
    }

    .knob-overlay {
      position: absolute;
      top: 3px;
      right: 2px;
      width: 27px;
      height: 26px;
      .border-radius(99px);

      background-color: black;
      opacity: 0.6;
    }

    .dial .circle-text {
      font-size: 12px !important;
      height: 13px !important;
      margin-left: -3px !important;
      margin-top: -2px !important;
      color: rgb(135, 206, 235);
    }

    input {
      cursor: pointer;
      top: -17px;
      right: 5px;
    }
  }

  &.item-hidden {
    opacity: 0.5;
    .filter(grayscale(100%));

    .item-placeholder.tile.single.no-img {
      .description {
        .friendly-timestamp {
          margin-top: 20px;
        }

        .publish-status {
          margin-top: 30px;
        }
      }
    }

    .item-feature-link { display: none; }

    .item-feature-link-new { display: none; }

    &:hover {
      opacity: 0.8;
    }

    &:before {
      content: "Hidden Item";
      background: @white;
      background: rgba(255, 255, 255, 0.8);
      padding: 0px 6px;
      position: absolute;
      font-size: 10px;
      margin: 1px;
      border: 1px solid #ccc;
      border-top: 0;
      border-left: 0;
    }
  }

  &.item-hidden.item-cta {
    opacity: 0.5;
    .filter(grayscale(0%));

    &:before {
      content: "Hidden CTA Placement";
    }
  }

  &.sortable-moving {
    opacity: 0.5;
  }

  // Hide the deleted toolbar for revival unless the item is deleted

  .collection-item-controls.deleted-toolbar {
    display: none;
  }

  &.item-deleted {
    .collection-item-controls.alive-toolbar {
      display: none;
    }

    .collection-item-controls.deleted-toolbar {
      display: block;
    }
  }

  .collection-item-controls {
    background-color: #ddd;
    border-color: #ddd;
    position: relative;

    a {
      background: transparent;
      border-color: transparent;
      font-weight: normal;
      padding: 2px;
      text-shadow: none;

      &:hover { background: @gray; }

      &:hover,
      &:focus { color: @white; }
    }

    i.nb-icon {
      display: inline-block;
      .square(14px);

      background-image: url("/img/hubs/backend/spritesheet.png");

      &.nb-integrations {
        .pos-rel(3px);      // T, R, B, L
        .square(15px);

        margin: 0 6px 0 5px;
        background-position: -133px -453px;
      }
    }

    .more-item-options {
      display: inline-block;
      height: 0;

      ul {
        position: absolute;
        display: none;
        left: 0;
        bottom: 100%;
        width: 100%;
        list-style: none;
        background: @uberGrayLight;

        li {
          line-height: 33px;

          &:hover {
            background-color: #aaa;

            a {
              color: #555;
              text-decoration: none;
              background-color: #aaa;

              i {
                background-image: url("/img/glyphicons-halflings.png");
                opacity: 0.6;

                &.nb-icon {
                  background-image: url("/img/hubs/backend/spritesheet.png");
                  background-position: -172px -452px;
                }
              }
            }
          }

          a {
            color: white;
            font-size: 12px;
            line-height: 33px;
            width: 100%;
            padding: 0;
            display: inline-block;

            span {
              padding: 7px;

              i {
                margin-right: 7px;
              }
            }
          }
        }
      }

      .up-arrow {
        position: relative;
        display: none;
        top: -26px;
        left: 5px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid @uberGrayLight;
      }

      &.open {
        ul {
          display: block;
        }

        .up-arrow {
          display: block;
        }
      }
    }
  }

  &:hover {
    .box-shadow(0 0 10px lighten(@gray-light, 6.5%));  // #aaa

    .collection-item-controls {
      background: #333;
      border-color: #333;

      a {
        &:hover {
          background: #666;
          border-color: #666;
        }
      }
    }
  }

  > a.add-custom-item-link,
  > a.add-items-to-section-link {
    display: block;
    width: 225px;
    height: 291px;
    text-align: center;
    background: #fff url("/img/icon_128/plus.png") no-repeat center center;
    background-size: 100px;
    border: 1px solid #ccc;
    color: #fff;
    line-height: 423px;
    opacity: 0.6;
    .transition(all 150ms ease-in-out);

    &:hover {
      opacity: 1;
      color: #666;
      background-position: center 90px;
    }
  }
}

.sortable-item-helper {
  width: 225px;
  height: 293px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}

@-moz-document url-prefix() {
  .item-group {
    .knob-container {
      input {
        top: -16px;
      }
    }
  }
}

// --------------------------------------------------------------------
// Stream Sections

.reorder-stream-sections.reordering {
  background: @uberTurqoise;
  border-color: @uberTurqoise;

  &:not(.disabled):hover,
  &:active {
    background: #0e90d2 !important;
    border-color: #0e90d2 !important;
  }
}

.collection-section {
  .collection-section-header {
    float: left;
    width: 100%;
    padding: 6px 0 0 15px;
    font-size: 17px;
    font-weight: bold;

    & .collection-section-name {
      display: inline-block;
      padding: 6px;
    }

    & .collection-section-name-input {
      font-size: 17px;
    }

    & .edit-collection-section-name,
    & .delete-collection-section {
      background: none;
      border: none;

      span {
        color: #ccc;

        &:hover {
          color: @fontColorDark;
        }
      }
    }

    & hr {
      border-top: 1px solid darken(@gray-lighter, 13.5%);
      margin: 6px 0;
    }
  }

  .collection-section-items {
    float: left; // without this, .collection-section-items have no height and jQuery.sortable({ connectWith: ... }) doesn't work
    width: 100%;
  }

  .reordering-sections & {
    .collection-section-header {
      cursor: move;
    }

    .collection-section-items {
      display: none;
    }
  }

  // ensure .collection-section elements have height
  &::after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
  }
}

// --------------------------------------------------------------------
// Sortable Collection Tiles

.hubs_collection_item_placeholder {
  display: inline-block;
  .size(24%, 172px);

  margin: 15px 0 0 0.75%;
  visibility: visible;
  border: 2px dashed darken(@gray-lighter, 13.5%); // #ccc
  background: lighten(@gray-lighter, 4.5%);        // #f9f9f9
  .box-sizing(border-box);
}

.hubs_item_placeholder {
  float: left;
  display: inline-block;
  .size(225px, 293px);

  margin: 20px 0 10px 15px;
  visibility: visible;
  border: 2px dashed darken(@gray-lighter, 13.5%); // #ccc
  background: lighten(@gray-lighter, 4.5%);        // #f9f9f9
  .box-sizing(border-box);
}

.stream_section_placeholder {
  display: inline-block;
  height: 42px;
  width: 100%;
  margin: 10px 0 0 10px;
  visibility: visible;
  border: 2px dashed darken(@gray-lighter, 13.5%); // #ccc
  background: lighten(@gray-lighter, 4.5%);        // #f9f9f9
  .box-sizing(border-box);
}

.ui-sortable {
  .item-group,
  .collection-group {
    &.rearranging {
      cursor: move;
    }
  }
}

// Collection Wrapper

.collection-wrapper {
  display: none;

  &.active {
    display: block;
  }
}

// --------------------------------------------------------------------
// Collection Details

.collection_service_data {
  margin: 50px 0 0 0;
  padding: 15px;
  color: lighten(@gray, 6.5%);  // #666
  background: lighten(@gray-lighter, 4.5%); // #f9f9f9
  border: 1px solid @gray-lighter;
  .text-overflow();

  span.info-sign {
    .pos-rel(-12px, auto, auto, -12px); // T, R, B, L

    color: lighten(@gray-light, 13.2%);
  }

  .small {
    margin-top: 8px;
    padding-top: 4px;
    border-top: 1px solid darken(@gray-lighter, 6.7%); // #ddd
  }

  a {
    color: darken(@gray, 6.5%) !important; // #444
    text-decoration: underline;
  }
}

.item-group {
  .overlay-options {
    display: none;
  }
}

.item-group.with-options {
  .item-action-controls {
    width: 100%;
    .pos-abs(0, auto, auto, 0);

    .circle-icon {
      position: relative;
      float: left;
      display: inline-block;
      .square(25px);

      margin: 7px 0 0 7px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      .border-radius(50%);

      text-align: center;
      line-height: 25px;
      background-color: rgba(0, 0, 0, 0.25);
      cursor: pointer;

      &.is-gated {
        background-color: @uberGreen;
        border-color: @white;
      }

      &:hover {
        background-color: #fff;

        .bh-icon-lock-cta.icon-white {
          background-position: -534px -574px;
        }
      }
    }

    .knob-container ~ .circle-icon {
      right: 40px;
    }

    .select-item {
      display: inline-block;
      position: relative;
      float: right;
      height: 25px;
      width: 25px;
      margin: 7px;
      background: #fff;
      border-radius: 50%;
      border: 2px solid #666;
      line-height: 25px;
      cursor: pointer;

      .halflings-ok {
        opacity: 0;
        margin-left: 4px;
        top: -1px;
      }
    }
  }

  &.selected .select-item .halflings-ok {
    opacity: 1;
  }

  .halflings-item-tag {
    display: inline-block;
    background: url("/img/hubs/backend/spritesheet.png") -14px -54px;
    width: 13px;
    height: 13px;

    i {
      display: block;
      font-family: @font-family-base;
      position: absolute;
      right: -7px;
      top: -7px;
      width: 12px;
      height: 12px;
      padding-right: 2px;
      padding-top: 1px;
      font-weight: 800;
      font-size: 9px;
      font-style: normal;
      text-align: center;
      color: #fff;
    }

    &.empty {
      background-position: -14px -13px;

      i {
        display: none;
      }
    }
  }
}

.item-group.with-options:hover {
  .item-placeholder.tile.single {
    border: 1px solid #000;
  }

  .overlay-options {
    .pos-abs(0, auto, auto, 0);

    display: block;
    .square(100%);

    background-color: @black;
    opacity: 0.7;

    &.holding-options {
      background-color: initial;
      opacity: 1;
      color: @white;

      .option-box {
        .pos-rel(125px);

        display: block;
        width: 120px;
        margin: 0 auto;
        padding: 10px 0;
        border: 1px solid @white;
        .border-radius(4px);

        text-align: center;
        cursor: pointer;
        color: @white;
        font-family: @font-family-sans-serif;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }

        .option-background {
          position: absolute;
          top: 0;
          left: 0;
          .square(100%);
        }
      }
    }
  }
}

// --------------------------------------------------------------------
// Item Placeholder

.item-placeholder,
.item-placeholder.tile,
.item-placeholder.tile.single {
  float: none !important;
  width: 100%;
  height: 260px;
  min-height: 260px;
  margin: 0 !important;
  overflow: hidden;
  background-color: #ebe8e1;
  border: 1px solid #ddd;
  .box-shadow(none);
  .box-sizing(border-box);
}

.item-placeholder.tile,
.item-placeholder.tile.single {
  .img img {
    width: 100% !important;
  }

  .description {
    h3 {
      font-size: 17px !important;
      font-weight: normal !important;
    }

    h4 {
      font-size: 12px !important;
      line-height: 17px !important;
    }
  }
}

.item-placeholder.tile {
  .img img.shorter {
    height: 100% !important;
    width: auto !important;
  }
}

.item-placeholder.tile.single {
  .description {
    img.avatar {
      height: 20px !important;
      margin-top: 5px !important;
    }

    h3.avatar {
      margin-top: 0 !important;
    }
  }
}

.item-preview-bg {
  position: relative;
  .size(225px, 260px);

  margin-top: -260px;
  background: transparent url("/img/hubs/backend/preview_bg.png") no-repeat top right;
}

// --------------------------------------------------------------------
// Item Lock Icon

.lock-item {
  position: relative;
  .size(220px, 255px);

  margin-top: -255px;
  cursor: pointer;

  &.open-lock { background: transparent url("/img/lock_open.gif") no-repeat top right; }
  &.closed-lock { background: transparent url("/img/lock_closed.gif") no-repeat top right; }

  &.fade-lock {
    background: transparent url("/img/lock_closed.gif") no-repeat top right;
    opacity: 0.5;
  }
}

// --------------------------------------------------------------------
// Share Icon

.hubs_share_icon {
  float: left;
  clear: none;
  .size(22px, 20px);

  background: transparent url("/img/hubs/backend/spritesheet2.png") no-repeat 0 0;
  opacity: 0.5;

  &.facebook { background-position: -312px -10px; }

  &.twitter {
    background-position: center;
    background-image: url("/img/integrations/twitter_x_logo.svg");
    background-size: contain;
  }
  &.email { background-position: -421px -10px; }
  &.google { background-position: -476px -10px; }
  &.linkedin { background-position: -531px -10px; }
  &.pinterest { background-position: -580px -10px; }
}

// --------------------------------------------------------------------
// Hubs Sharing from Backend Application

.hub-share-modal {
  .modal-body {
    p {
      width: 100%;
      font-size: 18px;
      text-align: center;
      margin-bottom: 16px;
    }

    .share-item {
      display: block;
      position: relative;
      top: auto;
      right: auto;
      padding: 0;
      margin: 0 auto 30px auto;
      background-color: transparent;
      text-transform: uppercase;
      .box-shadow(none);
      .clearfix;

      &:before {
        display: none;
      }

      li {
        &:first-child {
          width: auto;
          background: transparent;
        }

        a {
          &.on {
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

//FIXME Hack: force shareHub modal to fit popupmodal2. refactor sharehub once sidenav is reactified.
.collection-share-modal {
  > .modal-dialog > .modal-content {
    width: 620px;
    border: none;
    background: none;

    .modal-dialog {
      margin: 0;
    }
  }
}

#itemShareModal,
#collectionShareModal,
.collection-share-modal {
  .modal-dialog {
    width: 620px;

    .modal-body {
      .share-options {
        margin: 5px auto 0;
        width: 330px;
      }

      .cloned-item-holder {
        width: 225px;
        display: inline-block;

        // Hide lock icon on Share Modal

        .circle-icon.gate-item {
          display: none;
        }

        + div.share-options {
          display: inline-block;
          width: 330px;
          margin-left: 20px;
          margin-top: 27px;
          vertical-align: top;
        }
      }
    }
  }
}

// --------------------------------------------------------------------
// Block Images

.hubs-block-image {
  display: block;
  .square(180px);

  margin: 0 auto 20px auto;
  padding: 12px;
  cursor: pointer;
  background: @white url("/img/hubs/addons-block-bg.jpg") no-repeat 0 0;
  border: 1px solid #d5d5d5;
  .box-shadow(1px 1px 3px #eee);
  .border-radius(5px);

  &.google-analytics { background-position: 0px -600px; }
  &.commenting { background-position: 0px -800px; }
  &.sharing { background-position: 0px -1000px; }
  &.hubs-lab { background-position: 0px -1200px; }
}

//---------------------------------------------------------------------
// Section Icons (grey)

.section-icon {
  display: inline-block;
  .size(22px, 20px);

  vertical-align: text-top;
  line-height: 20px;
  background: transparent url("/img/hubs/backend/spritesheet2.png") no-repeat;

  &.home { background-position: -494px -156px; }
  &.docs { background-position: -256px -156px; }
  &.social { background-position: -303px -156px; }
  &.videos { background-position: -350px -156px; }
  &.blogs { background-position: -395px -156px; }
  &.featured { background-position: -443px -156px; }
  &.custom { background-position: -549px -156px; }
}

// ----------------------------------------------------------------------------------------------------------
// Hubs Modal Elements
// ----------------------------------------------------------------------------------------------------------

// Add Item to Marketing Collection
// ----------------------------------------------------------------------------

.modal .modal-body {
  .custom-collection-list {
    > li {
      > a {
        font-size: (@font-size-base + 2);
        padding-left: 5px;
        color: lighten(@gray, 6.5%);              // #666

        > .check-container {
          padding: 2px 3px;
          margin-right: 6px;
          background: @white;
          border-radius: 50%;
          border: 2px solid darken(@gray-lighter, 13.5%); // #ccc

          > .halflings { opacity: 0; }
        }
      }

      &.active {
        > a {
          color: @uberBlue;
          background: @gray-lighter;

          > .check-container {
            color: lighten(@gray, 6.5%);              // #666
            border-color: lighten(@gray, 6.5%);  // #666

            > .halflings { opacity: 1; }
          }
        }
      }
    }
  }
}

// --------------------------------------------------------------------------------------------------
// Authors modal

form.author-form {
  .uploaded-avatar {
    .uploaded-avatar-inner {
      .square(150px);

      img.uploaded-image {
        width: 100%;
        .border-radius(50%);
      }
    }

    .upload-button-container {
      width: 85px;
      margin-top: -35px;
      margin-left: 36px;
      opacity: 0;
      .transition(opacity 200ms ease-in-out);
    }

    &:hover {
      .upload-button-container {
        opacity: 1;
      }
    }
  }
}

.ie9 {
  form.author-form {
    .progress {
      background: transparent url("/img/loading_small.gif") no-repeat center;
      opacity: 1;

      .bar { display: none; }
    }
  }
}

@media only screen and (min-width: @sidemenuCollapseWidth) and (max-width: (@sidemenuCollapseWidth + 50)) {
  .hubs_collection_search {
    input {
      width: 90px;
      &:focus { width: 130px; }
    }
  }
}

// --------------------------------------------------------------------------------------------------
// Item scores and navigating scores (used on dashboard screen and in item score modal so far)

.navigate-period,
.sort-performing-content {
  span {
    display: inline-block;
    margin: 5px 18px 60px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      border-bottom: 6px solid #eee;
    }

    &.active {
      border-bottom: 6px solid @uberBlueLight;
      font-weight: bold;
    }

    &.missing-data {
      color: #ccc;
    }
  }

  &.sales-activity {
    span {
      padding: 16px 16px 13px 16px;
      margin: 5px 0 30px;
      text-transform: capitalize;
      color: #333;

      &:hover:not(.active) {
        color: #777;
        border-bottom: none;
      }

      &.active {
        border-bottom: 3px solid @uberPink;
      }
    }
  }
}

.performing-item-container {
  display: block;
  text-align: left;

  .no-data-available {
    width: 819px;
    height: 300px;
    font-size: 32px;
    line-height: 253px;
    color: #333;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
  }

  .performing-item {
    &:hover .stat {
      background: #fff;
    }

    .item-wrapper {
      display: inline-block;
      position: relative;
      float: left;

      .no-item-info {
        display: inline-block;
        float: left;
        clear: none;
        width: 223px;
        height: 293px;
        margin: 20px 0 10px 15px;
        text-align: center;
        background-color: @white;
        border: 1px solid #ccc;

        .unknown {
          font-size: 200px;
          font-weight: bold;
          color: #ddd;
        }

        .no-item-text {
          margin-top: 85px;
          color: #999;
          font-weight: bold;
        }
      }

      .content-score {
        position: absolute;
        display: block;
        top: 20px;
        left: 16px;
        height: 293px;
        width: 225px;
        text-align: center;
        opacity: 1;
        .transition(all 0.3s ease-in-out);

        input {
          margin-top: 39px !important;
        }

        > div {
          position: absolute;
          top: 38px;
          left: 18px;
        }

        .circle-text {
          color: #5bc0de;
          color: rgb(135, 206, 235);
          font-size: 30px;
          font-weight: bold;
          top: -11px;
          letter-spacing: 1px;
        }

        .overlay-content {
          position: absolute;
          display: block;
          top: 54px;
          left: 33px;
          height: 160px;
          width: 160px;
          .border-radius(50%);

          background-color: @black;
          opacity: 0.5;
        }

        .enter-dial {
          display: inline-block;
          position: absolute;
          top: 143px;
          right: 31px;
          width: 166px;
          font-size: 18px;
          line-height: 17px;
          color: @white;
          text-transform: uppercase;

          span {
            display: block;
          }
        }
      }

      &:hover {
        .content-score {
          opacity: 0;
        }
      }
    }

    .item-stats {
      float: left;
      margin-top: 20px;
      margin-left: 25px;
      width: 556px;

      > div {
        .stat {
          float: left;
          width: 268px;
          height: 88px;
          padding: 10px;
          margin: 0px 10px 15px 0px;
          border: 1px solid #ccc;
          text-transform: uppercase;

          &.selected {
            border-color: @uberBlueLight;
            background: @white;
          }

          .stat-type,
          .stat-number {
            display: inline-block;
            height: 100%;
            padding: 25px 0 10px 7px;
            vertical-align: middle;
            text-align: center;
          }

          .stat-img {
            margin: 8px 1px;
            font-size: 39px;
            color: #828282;
            padding: 0px 6px;
          }

          .stat-type {
            width: 89px;
            color: #555;

            &.two-lines {
              padding: 15px 0 10px 7px;
            }
          }

          .stat-number {
            width: 75px;
            padding: 20px 5px 10px 0;
            text-align: right;
            font-size: 18px;
            color: @uberBlueLight;
          }

          &.full {
            width: 524px;

            .stat-type {
              width: 213px;
              font-size: 21px;
              margin-top: 12px;
              margin-left: 71px;
            }

            .stat-number {
              font-size: 25px;
              width: 147px;
            }
          }
        }
      }
    }
  }
}

@-moz-document url-prefix() {
  .performing-item-container {
    .performing-item {
      .item-wrapper {
        .content-score {
          input {
            margin-top: 90px !important;
          }
        }
      }
    }
  }
}

.item-feature-link-new {
  display: inline-block;
  position: relative;
  float: left;
  height: 25px;
  width: 25px;
  margin: 7px 0 0 7px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.25);
  text-align: left;
  cursor: pointer;
  transition: 0.2s all ease-in;
  color: #fff;
  overflow: hidden;

  &:hover:not(.active-animate) {
    color: #444;
    background-color: #fff;
  }

  i {
    margin-left: 4.5px;
    margin-top: -2px;
    top: 0;
    transition: 0.1s all ease-out;
  }

  &.active i {
    color: #fbc02d;
    will-change: transform;
    animation: featuredstar 1s cubic-bezier(0.17, 0.89, 0.32, 1.45);
  }

  &.active-animate {
    transition: 1s all;
    animation-iteration-count: 1;
    animation: widen 3s ease-out;
    animation-fill-mode: forwards;
  }

  span {
    position: relative;
    top: -2px;
    opacity: 0;
    color: #fff;
    .user-select(none);

    &.span-animate {
      animation-iteration-count: 1;
      animation: fadeInOut 3s ease-in-out;
      animation-fill-mode: forwards;
    }
  }

  @keyframes featuredstar {
    0%,
    17.5% {
      transform: scale(0);
    }
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
      left: 10px;
    }

    10% {
      opacity: 1;
      left: 15px;
    }

    85% {
      opacity: 1;
      left: 15px;
    }

    95% {
      opacity: 0;
      left: 10px;
    }
  }

  @keyframes widen {
    8% {
      width: 100px;
    }

    90% {
      width: 100px;
    }

    100% {
      width: 25px;
    }
  }
}

.featuring-disabled,
.featuring-disabled:hover {
  color: @uberGrayLighter;
  background-color: @uberGrayLight;
  cursor: not-allowed;
}

// --------------------------------------------------------------------------------------------------
// Add item to streams modal

.item-to-streams-modal-wrapper {
  .selected-item-details {
    display: inline-block;
    margin: 10px 0 20px;

    .bg-thumb {
      float: left;
      margin-right: 10px;
      .clearfix;
    }
  }

  span {
    &.color-box-service-icon {
      margin-right: -3px;
    }

    &.item-title,
    &.source-stream-hub {
      display: block;
      margin-top: 1px;
      font-size: 12px;
      width: 650px;
    }

    &.item-title {
      font-weight: bold;
    }

    &.source-stream-hub {
      color: @grayLight;

      .title {
        font-weight: bold;
      }
    }
  }

  .inner-addon {
    .halflings {
      position: absolute;
      padding: 10px;
      pointer-events: none;
    }

    input {
      padding-left: 30px;
      font-size: 14px;
    }
  }

  .selected-streams-label {
    font-size: 14px;
    font-weight: bold;
  }

  .stream-list-container,
  .stream-list-selected-container {
    width: 100%;
    height: 300px;
    padding: 5px;
    border: 1px solid #ccc;
    overflow-y: auto;
    background-color: #fff;

    table {
      width: 100%;

      tr:hover {
        cursor: pointer;
        background-color: #f2f2f2;
      }

      td {
        padding: 5px 0;

        &.thumb {
          width: 36%;
        }

        &.title {
          padding-left: 5px;
        }
      }
    }
  }

  .stream-list-container {
    tr {
      .glyphicons-remove {
        display: none;
      }
    }
  }

  .stream-list-selected-container {
    tr {
      .glyphicons-arrow-right {
        display: none;
      }
    }
  }

  .item-thumb {
    width: 84px;
    height: 40px;
    vertical-align: middle;
    background-size: cover;
    background-position: 50%;
    display: inline-block;
  }
}

// --------------------------------------------------------------------------------------------------
// Stream Delete modal

.modal {
  &.reco-rules-modal,
  &.delete-stream-widget-error-modal {
    ul {
      padding: 0 20px;

      li {
        margin-left: 1.1em;
        list-style: initial;
      }
    }
  }
}
