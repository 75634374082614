#place-cta-modal .ufr-modal-body {
  max-height: 65vh;
}
#place-cta-modal .ufr-modal-body .glyphicons:before {
  color: #999;
}
#place-cta-modal .search-input {
  float: left;
}
#place-cta-modal .search-input .glyphicons-search {
  position: relative;
  left: 29px;
  top: 38px;
  display: block;
  z-index: 1000;
  color: #999;
  width: 30px;
}
#place-cta-modal .cta {
  height: 293px;
  width: 225px;
}
#place-cta-modal .cta .hidden-cta-fields {
  top: 100%;
}
#place-cta-modal .cta > a {
  width: 65%;
}
#place-cta-modal .no-ctas-found {
  margin: 150px;
  text-align: center;
  font-weight: bold;
}
#place-cta-modal .carousel-container {
  margin: 100px auto 0;
}
#place-cta-modal .selected-cta-info {
  width: 225px;
  margin: 10px auto 0;
}
#place-cta-modal .selected-cta-info .name {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  margin: 15px 0 5px 0;
}
#place-cta-modal .selected-cta-info .modified {
  color: #333;
  margin-bottom: 5px;
}
#place-cta-modal .selected-cta-info .id {
  color: #333;
  margin-bottom: 0;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container {
  width: 180px;
  margin: 0;
  padding: 0;
  float: left;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container label {
  font-size: 12px;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container #searchable-select-place-cta-types-input-description {
  display: none;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container .ufr-dropdown-selected-item {
  font-size: 14px;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container .ufr-dropdown-button {
  height: 33px;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container .ufr-dropdown-button .glyphicons {
  margin-top: 3px;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container .ufr-select-dropdown {
  width: 182px;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container .ufr-select-dropdown-item-container {
  width: 180px;
}
#place-cta-modal #searchable-select-place-cta-types-ufr-input-container .ufr-select-dropdown-item-container .ufr-select-dropdown-item {
  font-size: 14px;
}
#place-cta-modal #place-cta-search-ufr-input-container {
  width: 350px;
  margin: 11px 0 0 20px;
  padding: 0;
  float: left;
}
#place-cta-modal #place-cta-search-ufr-input-container #place-cta-search-input-description {
  display: none;
}
#place-cta-modal #place-cta-search-ufr-input-container #place-cta-search {
  width: 100%;
  font-size: 14px;
  padding-left: 30px;
}
