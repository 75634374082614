@import '../shared/styles/mixins';
@import './variables.less';

#topnav {
  .topnav-item-button {
    padding: 12px 12px 8px;
    cursor: pointer;

    .topnav-item-icon {
      font-size: 13px;
      position: relative;
      top: 1px;
    }

    .topnav-button-text {
      line-height: 1.1;
      font-size: 13px;
      margin-left: 8px;
      display: inline-block;
      vertical-align: top;
      padding-top: 1px;
      max-width: 128px;
      .truncate;
    }

    .topnav-button-text:first-child {
      margin-left: 0;
    }

    .topnav-item-chevron {
      font-size: 10px;
      margin-right: 5px;
      margin-left: 10px;
      position: relative;
      top: 3px;
    }
  }

  .topnav-link {
    &:hover .topnav-item-button {
      background: @hoverBackground;
      color: white;
    }

    &:active .topnav-item-button,
    &.topnav-link-active .topnav-item-button-active {
      background: @activeBackground;
      color: white;
    }
  }
}

