// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// ----------------------------------------------------------------------------------------------------------
// Hubs Content Management Section
// ----------------------------------------------------------------------------------------------------------

html.layout-bs3 {
  .mgmt-container {
    .content-container.hubs-section-cta {
      font-family: @roboto;

      // --------------------------------------------------------------------------------------------------
      // Navbar on all Appearance Pages

      .cta-navbar {
        span.nb-icon {
          i {
            background-image: url("/img/hubs/backend/spritesheet.png");
          }

          &.nb-all i { background-position: -404px -285px; }
          &.nb-link i { background-position: -323px -285px; }
          &.nb-form i { background-position: -365px -285px; }
        }

        .nav {
          .active > a:not(:hover) {
            .nb-all i { background-position: -404px -323px; }
            .nb-link i { background-position: -323px -322px; }
            .nb-form i { background-position: -365px -323px; }
          }
        }
      }

      .cta-selection-tab-content {
        .tab-pane {
          .page-header {
            padding-bottom: 0;
            margin-bottom: 0;

            .icon-edit-large {
              .square(24px);

              margin-left: 10px;
              margin-top: 5px;
              background: transparent url("/img/hubs/backend/spritesheet.png") no-repeat -528px -90px;
            }
          }
        }
      }

      .show-cta-id {
        position: relative;
        top: -5px;
      }
    }
  }
}

// ----------------------------------------------------------------------------------------------------------
// Hubs Common CTA Elements
// ----------------------------------------------------------------------------------------------------------

.hubs-cta-accordion {
  min-width: 372px;

  .accordion-toggle {
    cursor: pointer;

    .hide-when-collapsed { display: inline-block; }
    .show-when-collapsed { display: none; }

    &.collapsed {
      .hide-when-collapsed { display: none; }
      .show-when-collapsed { display: inline-block; }
    }
  }

  .accordion-inner {
    position: relative;
    border-top: none;
    font-size: 0.9em;

    .disabled-placement-overlay {
      .pos-abs(0, auto, auto, 0);     // T, R, B, L
      .square(100%);

      background-color: rgba(255, 255, 255, 0.75);
    }
  }

  .enabled {
    .accordion-inner {
      .disabled-placement-overlay {
        display: none;
      }
    }
  }
}

.cta-placement-all-selected {
  .note {
    width: 300px;
    margin: 0 auto;
  }
}

.placement-select-items-button {
  float: none;
  margin-left: 10px;
  margin-top: -2px;
}

.num-item-selected {
  display: inline-block;
  height: 24px;
  line-height: 30px;
  margin-left: 10px;
  font-size: 14px;

  > span {
    font-weight: bold;
  }
}

.block-collection-config,
.block-item-config {
  margin: 10px 0 20px 20px;
  padding-left: 20px;
  border-left: 2px solid #eee;

  .hubs_cta_position_label { width: 80px; }
}

.item-block-property {
  margin-top: 5px;
}

.optional-container {
  margin-left: -7px;
}

// ----------------------------------------------------------------------------------------------------------
// Hubs CTA Items  (Displayed within collections of items)
// ----------------------------------------------------------------------------------------------------------

.item-cta {
  .item-cta-bg {
    position: relative;
    .size(225px, 260px);

    margin-top: -260px;
    background: transparent url("/img/hubs/backend/cta_bg.png") no-repeat top right;
  }

  .cta {
    .hidden-cta-fields {
      .cta-field-section {
        .preview-form-field {
          height: 18px;
        }

        .cta-field-name {
          font-size: (@font-size-base - 1);
        }

        .cta-field-sprite-container {
          top: 24px;
        }
      }
    }

    > a.accent-button {
      font-size: (@font-size-base + 2);
      padding: 16px;
    }
  }
}

// ----------------------------------------------------------------------------------------------------------
// Hubs CTA Positioning Elements
// ----------------------------------------------------------------------------------------------------------

// CTA Position Selection
//  - For choosing the CTA position in relation to items
//     (will appear after item #)
// ----------------------------------------------------------------------------

.hubs_cta_position {
  margin-bottom: 5px;
  padding: 6px 15px;

  .hubs_cta_position_label {
    display: inline-block;
    width: 140px;
    margin: 9px 6px 0 0;
    color: @gray-dark;
    font-weight: bold;

    &:last-child {
      width: 100px;
    }
  }

  .hubs_cta_position_input {
    display: inline-block;
    padding-top: 6px;

    &.item_level_options {
      position: relative;
      left: -3px;

      input[type=radio] {
        margin: 3px 5px 0 0;
      }
    }

    input[type=text] {
      display: inline-block;
      .size(32px, 24px);

      margin: 0;
      text-align: center;
    }

    input[type=checkbox] {
      margin: 4px 7px 0 0;
    }

    a {
      color: lighten(@gray, 6.5%);    // #666
      margin: 0 0 0 5px;

      &:hover {
        color: @gray-dark;    // #333
      }
    }
  }

  .repeat-cta-container {
    margin-top: -10px;
  }

  > div.alert {
    margin: 10px 0;
  }
}

@-moz-document url-prefix() { // Firefox

  .hubs_cta_position {
    .hubs_cta_position_label {
      display: inline;
    }

    .hubs_cta_position_input {
      display: inline;
    }
  }
}

// CTA Position Display
//   - For displaying an example of the chosen CTA position in relation to items
// ----------------------------------------------------------------------------

.example-positioning-group {
  float: right;
  .size(110px, 50px);

  margin-top: -5px;
  opacity: 0.4;

  .item-example-tile {
    .size(30px, 45px);

    margin: 5px 2px 0 2px;
    padding-top: 15px;
    color: @white;
    background-color: lighten(@gray-light, 13.2%);
    text-align: center;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.4em;

    &.item-CTA {
      .size(35px, 55px);

      margin-top: 0;
      padding-top: 15px;
      font-size: 1em;
      color: lighten(@gray, 6.5%);                    // #666
      background: lighten(@gray-lighter, 4.5%);        // #f9f9f9
      border: 1px solid darken(@gray-lighter, 13.5%);  // #ccc
      .box-shadow(0 0 10px #fff5d6);
    }
  }
}

.enabled {
  .example-positioning-group {
    opacity: 1;
  }
}

// ----------------------------------------------------------------------------------------------------------
// Hubs CTA Management Section
// ----------------------------------------------------------------------------------------------------------

a.manage-form-fields {
  line-height: 34px;
  cursor: pointer;
}

a.manage-form-fields.disabled {
  pointer-events: none;
  color: grey;
}

.hubs_cta_info,
.hubs_cta_action,
.hubs_cta_design,
.hubs_cta_preview,
.hubs_cta_info,
.hubs_cta_config,
.hubs_cta_integration {
  max-width: 650px;
  margin: 0 auto;
}

.hubs_cta_action {
  .clearfix;

  .link-action-container {
    width: 98%;

    .form-control {
      display: inline-block;
    }

    .link-type {
      position: relative;
      top: -2px;
      width: 16%;
      height: 32px;
      margin-right: 0;
    }

    .link-input-container {
      width: 83%;
      display: inline-block;

      > input {
        width: 100%;
      }
    }
  }

  &.form-cta {
    max-width: 750px;

    > div {
      float: left;
      width: 42%;
      padding: 0px 0 20px 20px;

      &:first-child {
        padding: 0px 20px 20px 0;
        border-right: 1px solid darken(@gray-lighter, 13.5%);  // #ccc
      }
    }
  }

  .cta-success-link-content,
  .never-hide-cta-action,
  .custom-form-handler-url {
    padding-left: 12px;
    border-left: 2px solid #eee;
    margin: 5px 0 20px 12px;
  }

  .never-hide-cta-action {
    overflow: hidden;

    .cta-field-radio-title {
      input[type="radio"] {
        display: inline;
        margin-right: 5px;
      }
    }
  }

  label.launch-item-label {
    float: left;
  }

  .launch-item-selection {
    float: right;
    margin-right: 5px;
  }

  .success-message + div {
    padding-top: 10px;
  }

  .success-message + div.note {
    padding-top: 5px;
  }

  .progressive-profiling-container {
    margin-bottom: 10px;

    .progressive-text {
      margin-left: 10px;
    }

    input[type="text"].progressive-max-fields {
      display: inline-block;
      .square(30px);

      margin: 0 1px 0 3px;
    }
  }

  input[type="checkbox"].opt-in-toggle {
    float: left;
    margin: 9px 10px 0 0;
  }

  input[type="text"].opt-in-label {
    width: 180px;
    width: calc(~"95% - 30px");
    margin-left: 10px;
  }
}

.hubs_cta_action,
.hubs_cta_integration {
  label.pull-left {
    margin: 8px 5px 20px 0;
  }
}

.hubs_cta_design {
  max-width: 320px;
  text-align: center;

  .slider {
    width: 210px !important; /*hack*/
  }

  label { text-align: center; }

  .cta_font_size {
    width: 40px;
    margin-left: 10px;
  }

  div.left {
    width: 140px;
    margin: 0 20px 12px 0;
  }

  .font-size-slider {
    margin-top: 30px;

    &.disabled {
      pointer-events: none;
    }
  }

  .cta-bg-upload-controls {
    margin-top: 30px;

    input[type="file"] {
      width: 100px;
      margin: 0 auto;
      border: 1px solid red;
      text-align: center;
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      cursor: pointer;
    }

    #remove-cta-bg {
      font-size: 0.9em;
      margin-top: 6px;
      font-weight: bold;
    }
  }
}

.hubs_cta_info,
.hubs_cta_action,
.hubs_cta_integration {
  input[type="text"] {
    width: 98%;
    margin-bottom: 6px;

    &.cta-list-id {
      max-width: 50px;
      margin: 0;
      text-align: center;
    }
  }

  .cta-list-id-container {
    line-height: 28px;
    .clearfix;

    span,
    .form-control,
    .btn {
      float: left;
    }
  }
}

.hubs_cta_action {
  input[type="text"] {
    width: 98%;
  }
}

.hubs_cta_config {
  max-width: none;
  margin: 0;

  .accordion {
    .cta-placement-container,
    .variation-items-container {
      width: 99%;
      width: calc(~"100% - 3px");

      .is-list-view {
        text-align: center;
      }
    }

    .accordion-group {
      background-color: @white;
      margin-bottom: 0;
      border: 0 none;
      border-bottom: 1px solid #e5e5e5;
      .border-radius(0);

      .accordion-heading {
        .accordion-toggle {
          padding: 15px;
        }
      }

      &:first-child {
        border-top: 1px solid #e5e5e5;
      }
    }
  }

  .font-size-slider {
    margin-top: 30px;

    &.disabled {
      pointer-events: none;
    }
  }

  .add-new-placement-btn,
  .add-new-variation-btn {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;

    &.disabled {
      display: none;
    }
  }
}

.hubs_cta_action textarea {
  width: 98%;
  height: 65px;
  min-height: 65px;
  max-height: 65px;
}

.cta-preview-switch {
  display: inline-block;
  font-size: (@font-size-base - 2);         // 12 - 2
  line-height: (@line-height-computed - 6);     // 20 - 6
  font-weight: bold;
}

.placement-footer {
  padding: 7px;
  margin-top: 5px;
  border-top: 1px solid darken(@gray-lighter, 6.7%);  // #ddd
  background-color: @gray-lighter;
}

[class^="step-icon-"],
[class*=" step-icon-"] {
  display: inline-block;
  .square(26px);

  line-height: 26px;
  vertical-align: text-top;
  background-image: url("/img/hubs/backend/spritesheet.png");
  background-position: 0px 0;
  background-repeat: no-repeat;
  margin-top: -4px;
  margin-right: 10px;
}
.step-icon-integration { background-position: -407px -368px; }
.step-icon-layout { background-position: -448px -369px; }
.step-icon-design { background-position: -487px -369px; }
.step-icon-placements { background-position: -527px -370px; }
.step-icon-variations { background-position: -527px -407px; }

.hubs_cta_section_block_wrapper {
  position: relative;
  display: table;
  width: 100%;
  margin-bottom: 40px;
  box-sizing: border-box;
  border: 1px solid darken(@gray-lighter, 13.5%); // #ccc

  .hubs_cta_section_block {
    display: table-row;
    width: 100%;
    min-width: 500px;
    background-color: darken(@white, 2.5%);    // #f9f9f9

    &:before,
    &:after {
      display: none;
    }

    > .tab-content { width: 95%; }
  }

  .hubs_cta_subsection {
    display: table-cell;
    width: 40%;
    height: 100%;
    vertical-align: top;

    &:first-child { width: 60%; }

    hr {
      border-top: 1px dashed darken(@gray-lighter, 13.5%); // #ccc
      margin-top: 20px;
    }

    label {
      color: @gray-dark;
      font-size: 0.9em;
      margin-top: 6px;
      font-weight: bold;
    }

    .hubs_cta_subsection_inner_wrapper {
      position: relative;
      min-height: 400px;
      margin-right: -3px;

      .cta-action-tabs-wrapper,
      .cta-action-tabs-nav {
        padding: 20px 0;
        text-align: center;
        background: rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid darken(@gray-lighter, 13.5%); // #ccc
      }

      .cta-action-tabs-wrapper {
        padding: 0;

        .cta-show-tab-container {
          display: block;
          padding: 0;
          margin: 0;

          .btn {
            position: relative;
            width: 33.3%;
            height: 40px;
            line-height: 30px;
            border: 0 none;
            color: #eee;
            background: #999;
            text-shadow: none;
            .border-radius(0);
            .box-shadow(none);
            .box-sizing(border-box);

            outline: 0 none;
            z-index: initial;

            .arrow {
              display: block;
              .pos-abs(0, -40px);     // T, R, B, L
              .triangle_right(#eee, 20px);

              content: "";
              z-index: 5;

              &:after {
                display: block;
                .pos-abs(-20px, -19px);     // T, R, B, L
                .triangle_right(#999, 20px);

                content: "";
                z-index: 6;
              }
            }

            &:hover {
              background: #888;

              .arrow {
                &:after {
                  .triangle_right(#888, 20px);
                }
              }
            }

            &.active {
              background: #666;

              .arrow {
                &:after {
                  .triangle_right(#666, 20px);
                }
              }
            }
          }

          &.step-count-4 {
            .btn {
              width: 25%;
            }
          }

          &.step-count-5 {
            .btn {
              width: 20%;
            }
          }
        }
      }

      .cta-tab-content {
        min-height: 390px;
        padding: 20px 0;
        vertical-align: top;

        h3 {
          margin-bottom: 20px;
          padding: 0px 10px;
          text-align: center;
        }

        .hubs_cta_integration[data-integration-type="form_cta_type"] {
          margin-top: 60px;
        }

        .integration-tab,
        .action-tab,
        .design-tab {
          padding: 0px 20px;
        }

        .integration-tab {
          .row {
            margin-top: 10px;
            margin-bottom: 5px;
            line-height: 32px;
          }
        }
      }

      .cta-action-tabs-nav {
        height: 46px;
        padding: 8px 0;
        border-top: 1px solid 1px solid darken(@gray-lighter, 13.5%); // #ccc
        border-bottom: 0;

        > div {
          margin: 0 20px;
        }

        a.btn {
          &:not(.btn-info) {
            color: #fff;
            background: #999;
            text-shadow: none;

            &:hover {
              background: #666;
            }
          }

          i {
            display: inline-block;
            .square(13px);

            line-height: 13px;
            vertical-align: text-top;
            background-image: url("/img/hubs/backend/spritesheet.png");
            background-position: 0px 0;
            background-repeat: no-repeat;
            margin-top: 2px;

            &.previous { background-position: -492px -174px; }
            &.next { background-position: -531px -174px; }
          }
        }
      }
    }

    &.hubs_cta_preview {
      background: @white url("/img/page_editor/checkered.png") repeat;
      border-left: 1px solid darken(@gray-lighter, 13.5%); // #ccc

      h3 {
        height: 40px;
        line-height: 40px;
        margin: 0 0 15px 0;
        background: #ddd;
        text-align: center;
      }

      .cta-preview-switch-container {
        margin: 0 auto;
        text-align: center;
      }

      .tile.single {
        .cta-field-sprite-container {
          top: 29px !important;
        }
      }

      .hub_cta_preview_element {
        width: 250px;
        margin: 5px auto;
        background-color: darken(@gray-lighter, 14%); // #cacaca

        .tile.single {
          .hidden-cta-fields {
            &.fields-revealed {
              .smaller {
                .cta-field-sprite-container {
                  bottom: -1px !important;
                  top: initial !important;
                }
              }
            }

            .cta-field-section.one-line {
              span {
                line-height: 24px;
              }
            }
          }
        }

        .cta-activate-button-container {
          padding-bottom: 10px;
          padding-top: 0;
          width: 100%;
        }

        > .tile.single.cta.cta-preview { width: 250px !important; }
      }
    }
  }

  .cta-show-tab-container {
    display: inline-block;
    padding-top: 10px;
    margin: auto 12px;
    text-align: center;

    .btn { min-width: 80px; }

    &:before,
    &:after {
      display: none;
    }
  }
}

.variations-container {
  .hubs_cta_icon_label {
    max-width: 80%;
  }

  .accordion-inner {
    font-size: 1em;
  }

  .variation-image {
    float: left;
    width: 100px;
    height: 45px;
    margin-left: 15px;
    border: 1px solid darken(@gray-lighter, 6.7%);    // #ddd
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .hubs_cta_variation {
    position: relative;

    .variation-colors {
      width: 130px;
      padding-left: 40px;
    }

    .variation-options {
      overflow: hidden;
      padding: 0px 20px 20px 20px;

      > div {
        margin-bottom: 15px;
      }

      .launch-item-selection {
        position: relative;
        bottom: -5px;
        right: -10px;
      }

      .variation-font-size-slider {
        width: 210px;
      }

      .link-action-container {
        .form-control {
          display: inline-block;
        }

        .link-type {
          width: 16%;
          height: 32px;
        }

        .link-input-container {
          position: relative;
          right: -10px;
          overflow: hidden;
          padding-right: 10px;
        }
      }
    }

    .variation-options,
    .variation-colors {
      textarea {
        width: 100%;
        height: 65px;
      }

      input {
        width: 100%;
      }
    }
  }

  .empty-variation {
    padding-top: 100px;
    min-height: 220px;
    text-align: center;
    color: lighten(@gray-light, 13.2%);
    border-top: 1px solid @ctaAccordionBorderColor;
    border-bottom: 1px solid @ctaAccordionBorderColor;
  }
}

@media only screen and (max-width: 1650px) {
  .variations-container {
    .collapsed .hubs_cta_icon_label {
      max-width: 65%;
    }

    .hubs_cta_icon_label {
      max-width: 55%;
    }
  }
}

@media only screen and (min-device-width: 1210px) and (max-width: 1400px) {
  .variations-container {
    .collapsed .hubs_cta_icon_label {
      max-width: 60%;
    }

    .hubs_cta_icon_label {
      max-width: 45%;
    }
  }
}

// IE 8, 9

.oldie {
  .hubs_cta_section_block_wrapper {
    .hubs_cta_subsection {
      .hubs_cta_subsection_inner_wrapper {
        .cta-action-tabs-wrapper {
          .cta-show-tab-container {
            .btn {
              .arrow {
                .size(21px, 40px);

                right: -20px;
                border: 0 none;
                background: transparent url("/img/hubs/backend/step_arrows.png") no-repeat 0 0;
                &:after { display: none; }
              }

              &:hover {
                .arrow {
                  background-position: 0px -40px;
                }
              }

              &.active {
                .arrow {
                  background-position: 0px -80px;
                }
              }
            }
          }
        }
      }
    }
  }
}
// IE 10, 11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hubs_cta_section_block_wrapper {
    .hubs_cta_subsection {
      .hubs_cta_subsection_inner_wrapper {
        .cta-action-tabs-wrapper {
          .cta-show-tab-container {
            .btn {
              overflow: visible;
              z-index: auto;

              .arrow {
                z-index: 100;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px), only screen and (min-width: 1201px) and (max-width: 1350px) {
  .hubs_cta_section_block_wrapper {
    .hubs_cta_subsection {
      .hubs_cta_subsection_inner_wrapper {
        .cta-action-tabs-wrapper {
          .cta-show-tab-container {
            .btn {
              .txt { display: none; }

              [class^="step-icon-"],
              [class*=" step-icon-"] {
                margin-right: -15px;
              }
            }
          }
        }
      }
    }
  }

  .hubs_cta_action {
    input[type="text"] {
      width: 95%;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .hubs_cta_section_block_wrapper {
    .hubs_cta_subsection {
      .hubs_cta_subsection_inner_wrapper {
        .cta-action-tabs-wrapper {
          .cta-show-tab-container.step-count-5 {
            .btn {
              .txt { display: none; }

              [class^="step-icon-"],
              [class*=" step-icon-"] {
                margin-right: -15px;
              }
            }
          }
        }
      }
    }
  }
}

// --------------------------------------------------------------------
// CTA Icon

.hubs_cta_icon {
  float: left;
  clear: none;
  .size(22px, 20px);

  background: transparent url("/img/hubs/backend/spritesheet2.png") no-repeat;
  opacity: 0.5;

  &.home { background-position: -494px -156px; }
  &.docs { background-position: -256px -156px; }
  &.social { background-position: -303px -156px; }
  &.videos { background-position: -350px -156px; }
  &.blogs { background-position: -395px -156px; }
  &.featured { background-position: -443px -156px; }
  &.custom { background-position: -549px -156px; }
}

.hubs_cta_icon_label {
  float: left;
  clear: none;
  max-width: 40%;
  padding: 2px 2px 0 6px;
  font-size: 1.2em;
  color: lighten(@gray-light, 13.2%);
  .text-overflow();
}

.enabled {
  .hubs_cta_icon {
    opacity: 1;
  }

  .hubs_cta_icon_label {
    color: @gray-dark;
  }
}

.collapsed {
  .hubs_cta_icon_label {
    max-width: 80%;
  }
}

// ----------------------------------------------------------------------------------------------------------
// Inline Add CTA Section
// ----------------------------------------------------------------------------------------------------------

.add_inline_cta_section {
  hr {
    border-top: 1px solid darken(@gray-lighter, 13.5%); // #ccc
    margin: 5px 0 5px 0;
  }

  .add_cta_header {
    .add_cta_header_title {
      float: left;
      padding-top: 10px;
      font-size: 1.25em;
    }

    .cancel-button {
      float: right;
    }
  }

  .add_cta_body {
    .add_cta_inner_body {
      .hubs_cta_inline_preview {
        display: inline-block;
        width: 370px;
        margin: 0;
        background: @white;

        .place_cta_btn {
          margin-left: 80px;
        }
      }
    }
  }

  .step1 {
    padding: 10px;
    background-color: #e7e7e2;
    .box-shadow(inset 0 -10px 20px #d4d4cf);

    .add_cta_body {
      width: 99%;
      margin: 0 auto;
      padding: 10px 0;
      overflow: hidden;

      .cta_scroll {
        display: inline-block;
        .size(4%, 240px);

        color: lighten(@gray-light, 13.2%);
        text-align: middle;
        background-color: @white;
        cursor: pointer;
        opacity: 0.65;
        .transition(opacity .15s linear);

        div {
          .size(15px, 46px);

          margin: 100px auto 0 auto;
          vertical-align: middle;
          background: url("/img/hubs/backend/spritesheet2.png") no-repeat;
        }

        .scroll_left {
          float: left;
          div { background-position: -153px -212px; }
        }

        .scroll_right {
          float: right;
          margin-left: 4px;
          div { background-position: -135px -212px; }
        }

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      .cta_item_group {
        display: inline-block;
        float: left;
        .size(88%, 220px);

        margin: 0 5px;
        padding: 10px 5px;
        overflow: auto;
        white-space: nowrap;
        text-align: center;

        .hub_cta_add_element {
          display: inline-block;

          .tile {
            margin-top: 18px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .step2 {
    padding: 10px;
    margin: 0 auto;
    background-color: #e7e7e2;
    .box-shadow(inset 0 -10px 20px #d4d4cf);

    .add_cta_body {
      width: 100%;
      padding: 10px 0;
      text-align: middle;

      .add_cta_inner_body {
        width: 600px;
        margin: 0 auto;

        .hub_cta_add_element {
          display: block;
        }
      }
    }

    .hubs_cta_inline_preview {
      width: 300px;

      .accordion-inner {
        padding: 9px;

        .hubs_cta_position {
          padding-top: 0;

          .hubs_cta_position-group {
            width: 100%;
            height: 40px;
            margin: 0 auto;

            .hubs_cta_position_label,
            .hubs_cta_position_input {
              margin-right: 2px;
            }

            .example-positioning-group {
              display: block;
              float: left;
              width: 75px;
              margin: 0 auto;
              opacity: 1;

              .item-example-tile {
                .square(20px);

                margin-top: 4px;
                padding-top: 10px;

                &.item-CTA { font-size: 0.6em; }
              }
            }
          }
        }
      }
    } // .hubs_cta_inline_preview
  } // .step2

  .hub_cta_add_element {
    &.preview { width: 100%; }
  }

  .add-cta-note {
    margin-top: 2px;
    font-size: 0.9em;
  }
}

// ----------------------------------------------------------------------------------------------------------
// Hubs CTA Form Elements
// ----------------------------------------------------------------------------------------------------------

// Checkbox

label.cta-field-checkbox-title {
  display: inline;
  font-weight: normal;

  input[type=checkbox] { display: inline; }

  .cta-field-checkbox {
    display: inline-block;
    width: 30px !important;
  }
}

.cta-edit-form-fields {
  margin-right: 2%;
  width: 48%;
  .text-overflow();

  &.field-type-hidden {
    > label {
      color: lighten(@gray-light, 13.2%);
      font-weight: normal;
    }
  }

  // All Inputs

  input {
    box-shadow: none;
  }
}

select#cta-selection {
  font-size: (@font-size-base + 4);     // 12 + 4
}

.cta-list-dropdown {
  width: 300px;
}

// MailChimp Specific

.cta-follow-select {
  max-width: 169px;
  font-size: 0.9em;

  &.cta-follow-day,
  &.cta-follow-time {
    max-width: 85px;
    padding: 6px 4px;
  }
}

// No Longer Used??

/*

.cta-form-integrations {
    margin-right: 20px
 }

.side-note {
    color: darken(@gray-lighter, 6.7%);  // #ddd
 }

.integration-test > .cta-service-success {
    font-weight: bold;
    color: green
 }

.cta-integration-list {
    .text-overflow();
 }

*/

// ----------------------------------------------------------------------------------------------------------
// Hubs CTA Page Overlay
// ----------------------------------------------------------------------------------------------------------

.cta-overlay {
  display: none;
  .pos-abs(0, 0, 0, 0);  // T, R, B, L
  .square(100%);

  text-align: center;

  &.overlay-message {
    > div {
      .pos-rel(15%);  // T, R, B, L

      width: 50%;
      padding: 40px;
      margin: 0 auto;
      text-align: center;
      background: @white;
      .box-shadow(0 0 14px lighten(@gray-light, 20%));        // #aaa

      > h3 {
        > span {
          color: lighten(@gray, 6.5%);                    // #666
          margin: 0 6px;
          padding: 7px;
          border: 1px solid darken(@gray-lighter, 6.7%);   // #ddd
          font-size: @font-size-base;
        }
      }
    }

    .missing-marketo-fields {
      margin: 0 auto;
      margin-top: 20px;

      > div {
        &:first-child {
          padding: 5px 10px;
          font-size: (@font-size-base + 1);         // 12 + 1
          font-weight: bold;
          background: @gray-lighter;
          border-bottom: 1px solid darken(@gray-lighter, 13.5%); // #ccc
        }

        &:last-child {
          > div {
            padding: 7px;
            text-align: center;

            &:nth-child(even) {
              background-color: @gray-lighter;
            }

            &.exists { opacity: 0.5; }

            &.error {
              color: #b94a48;

              &:hover {
                background: lighten(@gray-light, 20%) !important;   // #aaa
                color: @white !important;
              }
            }
          }
        }
      }

      .name,
      .apiName {
        display: inline-block;
        width: 36%;
      }

      .type {
        display: inline-block;
        width: 15%;
      }

      .status {
        display: inline-block;
        width: 8%;
      }
    }

    &.hide-marketo-message {
      top: -50px;

      > div {
        width: 850px;
        top: -25px;
      }
    }
  }

  &.overlay-body {
    z-index: 999;
    opacity: 0.8;
    -moz-opacity: 0.8;
    filter: alpha(opacity=80);
    background: #fff;
  }

  &.hide-cta-container { display: block; }
}

// ----------------------------------------------------------------------------------------------------------
// Hubs CTA Modal Elements
// ----------------------------------------------------------------------------------------------------------

// Add Placement Modal
// ----------------------------------------------------------------------------

.cta-add-placement-modal {
  .modal-body {
    .heading {
      text-align: center;

      .new-cta-placement {
        font-size: (@font-size-base + 6);
      }
    }

    .cta-items {
      .clearfix;

      .cta-carousel {
        line-height: normal;
        margin: 6px 35px;

        .carousel-control {
          top: 50%;
        }
      }

      .cta-add-placement-singular {
        margin-top: 6px;
      }

      .tile.single.cta {
        float: none !important;
        margin: 0 auto !important;
        display: block;

        .hidden-cta-fields {
          .cta-field-section {
            .cta-field-sprite-container {
              top: 29px;
            }
          }
        }
      }
    }

    .hubs_cta_position {
      text-align: center;
      margin-bottom: 20px;

      .hubs_cta_position_label {
        width: auto;
      }

      .example-positioning-group {
        float: none;
        display: inline-block;
        height: 35px;
        margin-top: 20px;
        opacity: 1;
      }
    }
  }

  .modal-footer {
    text-align: center;
  }
}

// Add CTA Modal
// ----------------------------------------------------------------------------

.add-cta-modal {
  .modal-dialog {
    width: 640px;
  }

  .modal-body {
    position: relative;
    .size(auto, 500px);

    form {
      padding-left: 30px;
      padding-top: 15px;
      .clearfix;
    }

    .step-2 {
      .step-2-header {
        text-align: center;
        padding-bottom: 30px;
        padding-top: 50px;
        font-size: @font-size-base;
      }

      .form-element {
        margin: 0 auto 20px;
        width: 450px;
      }

      .select2-container .select2-choice {
        height: 36px;
        line-height: 32px;
        text-align: left;
      }

      .select2-container .select2-choice i.icon-large,
      .select2-result-label i.icon-large {
        vertical-align: middle;
        margin-right: 10px;
      }

      .note {
        display: inline-block;
        margin-top: 40px;
      }
    }

    .step-3 {
      .step-3-header {
        text-align: center;
        padding-bottom: 30px;
        padding-top: 50px;
        font-size: (@font-size-base - 2);
      }

      .new-cta-title-input {
        margin: 10px auto 30px auto;
        color: #555;
      }

      .cta-integration-type-preview {
        margin-top: 30px;
        position: relative;
        .size(35%, 80px);

        text-align: center;
        display: inline-block;
        cursor: default;

        &:hover {
          border-color: lighten(@gray-light, 13.2%);
        }

        .integration-setup-sprite {
          margin: auto;
        }
      }
    }

    .step-4 {
      padding-top: 30px;
    }

    .step-5 {
      .copy-cta-title-input {
        width: 80%;
        height: 50px;
        margin: 30px auto 30px auto;
        padding-left: 15px;
        color: #555;
        font-size: 2em;
      }

      .copy-cta-description {
        font-size: 13px;
        padding: 0px 30px 30px 50px;
      }
    }

    .add-cta-type-section {
      width: 250px;

      .new-cta-type {
        .size(250px, 350px);

        padding: 0px 20px 20px 20px;
        cursor: pointer;
        border: 1px solid @white;
        opacity: 0.5;
        .transition(opacity 100ms ease-in-out);

        label {
          text-align: center;
          padding: 12px;
          margin: 0;
          line-height: @line-height-computed;
        }

        &:hover {
          border-color: darken(@gray-lighter, 6.7%);  // #ddd
          opacity: 1;
        }
      }

      .add-cta-type-section-text {
        padding-top: 25px;
        text-align: center;

        span { text-align: center; }

        .integration-icon-container {
          margin-top: 6px;

          .integration-setup-sprite {
            display: inline-block;
          }
        }

        &.add-cta-type-left-text {
          padding-left: 40px;
          padding-right: 40px;
        }
      }

      &.add-cta-type-section-right {
        .pos-rel(-5px);     // T, R, B, L
      }
    }

    .add-cta-middle-section {
      .size(5px, 500px);

      margin-right: 40px;

      .add-cta-line {
        .size(1px, 140px);

        margin: 0 auto;
        border-right: 1px solid darken(@gray-lighter, 6.7%);  // #ddd
      }

      .add-cta-middle-section-container {
        .pos-abs(120px, auto, auto, 313px);   // T, R, B, L

        .add-cta-middle-section-text {
          text-align: center;
          padding-top: 2px;
          padding-bottom: 2px;

          span {
            color: lighten(@gray-light, 6.5%);    // #888
            font-size: (@font-size-base + 4);     // 12 + 4
          }
        }
      }
    }

    .clear-background {
      .pos-abs(0, auto, auto, 0);  // T, R, B, L
      .square(100%);
    }

    .integration-setup-sprite {
      position: relative;
      top: 10px;

      &.mailchimp-text {
        position: relative;
        top: 20px;
      }
    }
  }
}

.cta-integration-dropdown {
  .select2-result-label i.icon-large {
    vertical-align: middle;
    margin-right: 10px;
  }
}

// No Lists Modal
// ----------------------------------------------------------------------------

#no-lists-modal {
  .no-lists-body {
    padding: 40px 60px 0 60px;
  }

  .error-container {
    height: 128px;
    margin-bottom: 10px;
    text-align: center;
  }

  .error-header {
    color: @uberPink;
    font-size: (@font-size-base + 6);      // 12 + 6
    text-align: center;
  }

  .no-lists-label {
    color: darken(@gray-light, 13.5%);   // #777
    font-size: @font-size-base;
  }

  .no-lists-link {
    color: @uberBlue;
    font-size: (@font-size-base + 1);     // 12 + 1
    text-decoration: underline;
  }

  .no-lists-button-container {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 80px;
  }
}

// Upgrade Modal
// ----------------------------------------------------------------------------

#upgrade-modal {
  .lock-container {
    padding: 30px;
    text-align: center;
  }
}

// Manage Fields Modal
// ----------------------------------------------------------------------------

.manage-fields-modal {
  .container {
    width: 400px;
  }

  .modal-body {
    max-height: 500px;
  }

  .new-field-icon {
    img {
      margin: -3px;
      .size(18px, 18px);
    }
  }

  .edit-field-mode {
    .upload-overlay {
      width: 538px;
      position: relative;

      .upload-overlay-progress {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.7);

        .upload-overlay-progress-spinner {
          padding: 125px 0 0 210px;
        }
      }
    }
  }

  .new-field-button.new-field-icon {
    margin-left: 8px;
    margin-right: 8px;
  }

  .halflings-info-sign {
    margin-left: 5px;
    margin-top: 9px;
  }

  .field-settings-container {
    padding-bottom: 10px;
    text-align: center;
  }

  .enter-comment {
    font-size: (@font-size-base + 3);     // 12 + 3
    padding-bottom: 9px;
  }

  .edit-field-mode .field-settings-container {
    text-align: left;

    label.edit-section {
      text-align: center;
      padding-bottom: 16px;
      font-size: 16px;
      font-weight: bold;
    }

    > div {
      padding-bottom: 7px;
      margin: 10px;
      .clearfix;

      span.field-info {
        display: inline-block;
        width: 100px;
        height: 100%;
        font-size: (@font-size-base + 3);     // 12 + 3
      }

      select {
        display: inline-block;
        width: 158px;
      }

      &.search-map-fields-container {
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;

        #form-field-autosuggest {
          vertical-align: top;
          width: 423px;
        }

        .btn {
          width: 74px;
        }
      }

      &.api-name-container {
        .tooltip-message {
          vertical-align: top;
          margin-left: 6px;
        }
      }

      input[type=text] {
        display: inline-block;
        width: 242px;
      }

      .halflings-info-sign {
        opacity: 0.4;
        margin-left: 5px;
      }
    }

    .tooltip-message {
      display: inline-block;
    }

    .hidden-value {
      border-left: 1px solid #ddd;
      padding: 15px 0 15px 20px;

      input {
        width: 223px;
      }

      > div {
        margin-bottom: 10px;

        &.label-value {
          overflow: auto;
        }

        &.query-string-info {
          margin-bottom: 15px;

          .field-info {
            font-size: 12px;
            width: 257px;
          }

          input {
            width: 14px;
          }
        }
      }

      label.edit-section {
        padding-top: 16px;
      }
    }
  }

  .enter-note {
    padding-top: 15px;
  }

  .fill-out-field-label {
    display: none;

    .new-field-button {
      display: block;
      position: static;
      margin: 15px auto 0 auto;
      padding: 0px 15px;
      line-height: 32px;
    }
  }

  .icon-remove,
  .icon-resize-vertical {
    margin-top: 1px;
    margin-right: 5px;
    margin-left: 5px;
    opacity: 0.2;
  }

  .existing-field-container {
    padding-top: 20px;

    .field-value-list-upload {
      position: relative;
      display: inline-block;
      width: 77%;
      margin-left: 10px;

      .upload-btn {
        position: relative;
      }

      .delete-btn {
        position: absolute;
        right: 0;
      }

      input[type="file"] {
        width: 62px;
        height: 100%;
        margin: 0 auto;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      .tooltip-message {
        display: inline-block;
        margin-left: 2px;
        vertical-align: top;

        .halflings-info-sign {
          opacity: 0.4;
        }
      }
    }
  }

  .existing-title {
    font-size: (@font-size-base + 4);   // 12 + 4
    font-weight: bold;
  }

  .existing-fields {
    padding-top: 15px;
  }

  .field-values {
    padding-top: 5px;

    .existing-field {
      .halflings-remove {
        margin: 3px 0 3px 7px;
      }
    }
  }

  .existing-field {
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 3px;
    font-size: (@font-size-base + 3);   // 12 + 3
    background: @gray-lighter;
    cursor: pointer;
    .border-radius(1px);
    .transition(background-color 0.3s linear);

    .glyphicons-git-branch {
      font-size: 13px;
      margin: 1px 15px 0 0;
    }

    .halflings-remove,
    .halflings-pencil {
      margin: 7px 15px 3px 0;
      font-size: @font-size-base;
    }

    .halflings-remove,
    .halflings-resize-vertical {
      opacity: 0.3;
    }

    .halflings-resize-vertical {
      margin: 5px 5px 5px 10px;
    }

    &:hover {
      background: darken(@gray-lighter, 6.7%);  // #ddd

      .halflings-remove,
      .halflings-resize-vertical {
        opacity: 1;
      }
    }

    .field-name {
      display: inline-block;
      cursor: pointer;
      width: 323px;
      line-height: 16px;
      .text-overflow();

      > .field-type-label {
        color: lighten(@gray-light, 13.2%);
        margin-left: 3px;
      }
    }
  }

  .existing-field-placeholder {
    height: 18px;
    margin-bottom: 3px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: (@font-size-base + 3);   // 12 + 3
    background: @white;
    border: 1px solid @gray-lighter;
    .border-radius(1px);
  }

  .newly-added {
    background: darken(@gray-lighter, 6.7%);   // #ddd

    .icon-remove,
    .icon-resize-vertical {
      opacity: 1;
    }
  }

  .field-value-titles {
    font-weight: bold;
    margin: 15px 0 0 0;

    .label-title {
      margin: 0 204px 0 42px;
    }
  }

  .new-field-display-name {
    display: inline-block;
    width: 220px;
    margin: 6px 0 0 36px;
  }

  .new-field-value {
    display: inline-block;
    width: 220px;
    margin: 0 0 0 2px;
  }

  .add-new-field-value {
    display: inline-block;
    margin-top: -6px;
  }

  .field-conditions-container {
    padding-top: 30px;

    > span {
      display: inline-block;
      font-size: 15px;
      vertical-align: middle;
      padding-right: 6px;
    }

    select {
      display: inline-block;
      width: 123px;
    }

    button {
      margin-top: -2px;
      display: inline-block;
    }

    .field-conditions {
      border-radius: 1px;
      padding-top: 20px;

      div,
      span {
        display: inline-block;
        font-size: 15px;
        vertical-align: middle;
      }

      .halflings-remove {
        font-size: 12px;
        margin: 3px 0 3px 101px;
        cursor: pointer;
      }

      .field-condition {
        width: 524px;
        cursor: default;
        padding-left: 6px;

        .condition-label {
          padding: 6px 6px 6px 20px;
          width: 487px;
        }
      }
    }

    .new-field-condition-container {
      margin-top: 6px;

      > span {
        font-size: 15px;
        padding-left: 21px;
        padding-right: 10px;
      }
    }
  }

  .edit-field-mode { display: none; }

  &.edit-field-mode {
    .edit-field-mode { display: block; }
    .manage-fields-mode { display: none; }

    .fill-out-field-name {
      &.edit-field-mode {
        .existing-field-container { display: none; }
      }
    }

    &.is-select {
      .fill-out-field-name {
        &.edit-field-mode {
          .existing-field-container {
            width: 524px;
            display: block;
          }
        }
      }
    }
  }

  .fill-out-field-name {
    div.text-center > span.note { display: inline-block; }
  }

  .value-label {
    display: inline-block;
    width: 224px;
  }

  .value-value {
    display: inline-block;
    width: 180px;
    text-align: center;
  }
}

// Crop CTA background Modal
// ----------------------------------------------------------------------------
#cta-cropper {
  .cr-boundary {
    height: 90%;
  }
}

.value-value {
  display: inline-block;
  width: 160px;
}

.manage-fields-modal .typeahead.dropdown-menu a {
  width: 232px;
}

.typeahead.dropdown-menu a {
  .text-overflow();

  color: #666;
  text-align: left;
}

.typeahead.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.typeahead.dropdown-menu .active > a:hover {
  background-color: @uberBlue;
  color: #fff;
}

textarea.embed-code {
  width: 98%;
  height: 80px;
  margin-bottom: 10px;
}
