@import '../shared/styles/variables';
@import '../shared/styles/mixins';

#sidenav {
  .sidenav-actions {
    position: relative;
    height: 46px;
    text-align: center;
    padding: 12px;
    width: 250px;

    button {
      .btn-unstyle;
    }

    .sidenav-action {
      display: inline-block;
      padding: 3px 6px;
      transition: 0.3s;

      &:nth-child(2) {
        margin-left: 20px;
      }

      .react-icon {
        line-height: 16px;
        font-size: 16px;
        color: @fontColorDark;
        transition: color 0.1s;
        overflow: hidden;
      }

      &:hover .react-icon,
      &:focus .react-icon {
        color: inherit !important;
      }
    }

    .narrow-sidenav-icon {
      opacity: 0;
      position: absolute;
      width: 60px;
      height: 46px;
      left: 0;
      top: 0;
      line-height: 16px;
      font-size: 26px;
      padding: 10px;
      transition: 0.3s;

      .glyphicons-eye-open {
        padding-top: 3px;
        font-size: 16px;
      }
    }

    &.sidenav-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: @lightGrey;

      .sidenav-action {
        &:nth-child(2) {
          margin-right: 20px;
        }

        .react-icon {
          overflow: visible;
        }
      }
    }
  }
}

// Narrow sidenav
@media (max-width: 1300px) {
  #sidenav:not(:hover) .sidenav-actions {
    .sidenav-action {
      opacity: 0;
      transition: 0.3s;
    }

    .narrow-sidenav-icon {
      opacity: 1;
      transition: 0.3s;
    }
  }
}
