@import '../shared/styles/base';
@import '../shared/styles/variables';
@import '../shared/styles/mixins';

// Page Header styles
#ufr-page-header {
  padding: 0;
  font-family: @roboto;

  .ufr-page-header-title {
    position: relative;
    padding-top: 42px;
    font-size: @pageTitleFontSize;
    font-weight: @pageTitleFontWeight;
    color: @fontColorDark;

    > * {
      display: inline-block;
    }

    .uf-service-icon,
    .uf-app-icon {
      margin: 8px 6px 8px 0;
      vertical-align: top;

      + .editable-text {
        // make room for the service icon to float to the left
        max-width: calc(~"100% - 30px");
      }
    }
  }

  .ufr-page-header-subtitle {
    position: relative;
    margin-top: 16px;
    margin-bottom: 40px;
    font-size: @pageSubtitleFontSize;
    font-weight: @pageTitleFontWeight;
    color: @fontColorMedium;
  }
}
