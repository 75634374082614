#tile-design-accordion #tile-design-accordion-content {
  padding: 0 5px 0 5px;
}
#tile-design-accordion #tile-design-button-label-text-area-ufr-input-container .react-input-title {
  margin: 3px 38px 4px 0;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555;
}
#tile-design-accordion #tile-design-button-label-text-area-ufr-input-container .react-input-description {
  margin: 0;
}
#tile-design-accordion #tile-design-button-label-text-area-ufr-input-container .ufr-input-wrapper .ufr-input-component {
  width: 100%;
}
#tile-design-accordion #tile-design-background-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0 10px 0;
}
#tile-design-accordion #tile-design-background-image > label {
  display: flex;
  align-items: center;
  margin: 3px 0 4px 0;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555;
}
#tile-design-accordion #tile-design-background-image > div {
  display: flex;
  align-items: center;
}
#tile-design-accordion #tile-design-background-image #ufr-tile-design-background-image-upload-file-btn {
  color: #333;
  background: white;
  border-color: #979797;
  margin-left: 2px;
}
#tile-design-accordion #tile-design-background-image #ufr-tile-design-background-image-upload-file-btn:hover {
  background-color: rgba(206, 0, 88, 0.04);
}
#tile-design-accordion #tile-design-background-image #ufr-tile-design-background-image-remove-btn {
  color: #333;
  background: white;
  border-color: #979797;
  margin-left: 2px;
}
#tile-design-accordion #tile-design-background-image #ufr-tile-design-background-image-remove-btn:hover {
  background-color: rgba(206, 0, 88, 0.04);
}
#tile-design-accordion .ufr-color-picker-label-cta {
  display: flex;
  align-items: center;
}
