@import '../../shared/styles/variables';

.mailchimp-checkbox {
  display: flex;
  align-items: center;

  .ufr-input-container.ufr-checkbox-input-container {
    padding-bottom: 9px;
    width: auto;

    .ufr-checkbox {
      &:checked {
        background-color: @uberPink;
        border-color: @uberPink;
      }
    }

    .ufr-checkbox-wrapper {
      label {
        font-weight: 500;
      }
    }
  }

  .overlay-container {
    display: flex;
    align-items: center;

    > span {
      margin-left: 3px;
      font-size: 11px;
      color: #a4a4a4;
    }
  }
}

.content-to-follow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;

  .content-to-follow-label {
    margin-right: 3px;
  }

  #searchable-select-content-to-follow-dropdown-ufr-input-container {
    width: 321px;

    .ufr-select-dropdown {
      width: 321px;
    }

    .ufr-select-dropdown-item-container {
      width: 319px;
    }

    .ufr-select-dropdown-container .ufr-dropdown-search-input > input {
      width: 274px;
    }
  }
}

.weekly-schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;

  .content-to-follow-label {
    margin-right: 3px;
  }

  .content-to-follow-label-z {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  #searchable-select-weekly-schedule-day-dropdown-ufr-input-container,
  #searchable-select-weekly-schedule-time-dropdown-ufr-input-container {
    width: 137px;

    .ufr-select-dropdown {
      width: 137px;
    }

    .ufr-select-dropdown-item-container {
      width: 135px;
    }

    .ufr-select-dropdown-container .ufr-dropdown-search-input > input {
      width: 90px;
    }
  }
}

.mailchimp-inputs {
  hr {
    width: 570px;
    border-top: 1px solid #eee;
  }
}
