/*
  Note: these styles implement the UF design system specifications.
  consult the Zeplin UI design guide before making any changes @ app.zeplin.io
 */
.ufr-input-container .ufr-input-error-message {
  color: #d44545;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  bottom: 10px;
  left: 0;
  font-family: Roboto, sans-serif;
  margin: 0;
}
.ufr-input-container .ufr-character-count + .ufr-input-error-message {
  bottom: 2px;
}
