.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-button-cta-accordion hr {
  margin-top: 0;
}
#ufr-button-cta-accordion #button-color-picker + hr {
  margin-top: 20px;
}
#ufr-button-cta-accordion .ufr-input-container > .react-input-title {
  font-size: 13px;
  font-weight: 500;
  color: #555;
}
#ufr-button-cta-accordion .ufr-input-container .ufr-textarea-inner .ufr-input-component {
  width: 100%;
}
#ufr-button-cta-accordion #banner-color-picker {
  padding-bottom: 10px;
}
