@import '../../../../../html/src/less/shared/_variables.less';

.tab-container {
  display: flex !important;
  height: 450px;
  padding: 0;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: @uberGrayLight;
    border-radius: 10px;
  }

  .cta-accordions {
    width: 50%;
    min-width: 330px;
    padding: 10px 15px 0px 10px;
    margin: 50px;
    overflow-y: auto;
  }

  .preview-container {
    width: 50%;
    margin: 50px;
    justify-content: center;
  }

  .preview-text {
    margin: 0;
    color: @uberGrayDark;
    padding-bottom: 10px;
    font-size: 16px;
    line-height: @baseLineHeight;
    font-weight: 400;
  }
}
