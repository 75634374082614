.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-upload-btn-container {
  display: inline-block;
}
.ufr-upload-btn-container:hover {
  cursor: not-allowed;
}
.ufr-btn {
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: Roboto, sans-serif;
}
.ufr-btn.disabled {
  cursor: not-allowed;
}
.ufr-btn:hover {
  text-decoration: none;
}
.ufr-btn:not(.disabled):focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-btn + .ufr-btn {
  margin-left: 10px;
}
@media (max-width: 450px) {
  .ufr-btn + .ufr-btn {
    margin: 10px 0px 0px;
  }
}
.ufr-btn + .ufr-btn-block {
  margin: 10px 0;
}
.ufr-btn + .ufr-btn-block + .ufr-btn {
  margin-left: 0;
}
.ufr-btn.ufr-btn-block {
  display: flex;
}
.ufr-btn.ufr-btn-primary {
  background: #ce0058;
  border-color: #ce0058;
  color: white;
}
.ufr-btn.ufr-btn-primary:active {
  background: #98114b;
  border-color: #98114b;
}
.ufr-btn.ufr-btn-primary:not(.disabled):hover {
  background: #9c0042;
  border-color: #9c0042;
}
.ufr-btn.ufr-btn-primary:disabled {
  background: #777 !important;
  border-color: #777;
  color: white;
  opacity: 1 !important;
}
.ufr-btn.ufr-btn-primary:disabled:hover {
  background: #555 !important;
  cursor: not-allowed;
}
.ufr-btn.ufr-btn-primary > .glyphicons::before,
.ufr-btn.ufr-btn-primary > .halflings {
  color: white !important;
  padding-right: 4px;
}
.ufr-btn.ufr-btn-icon {
  font-size: 12px;
  padding: 8px 0px 5px 0px;
  height: 30px;
  width: 30px;
  color: #8c8f91;
}
.ufr-btn.ufr-btn-icon.disabled {
  opacity: 60%;
}
.ufr-btn.ufr-btn-icon > .glyphicons::before,
.ufr-btn.ufr-btn-icon > .halflings {
  padding-right: 0px !important;
}
.ufr-btn.ufr-btn-secondary {
  font-weight: bold;
  color: #ce0058;
}
.ufr-btn.ufr-btn-icon,
.ufr-btn.ufr-btn-secondary {
  background: #fff;
  border-color: #ccc;
}
.ufr-btn.ufr-btn-icon:not(.disabled):active,
.ufr-btn.ufr-btn-secondary:not(.disabled):active {
  background: #bebebe;
  border-color: #9c0042;
}
.ufr-btn.ufr-btn-icon:not(.disabled):hover,
.ufr-btn.ufr-btn-secondary:not(.disabled):hover {
  background: #ce00580a;
}
.ufr-btn.ufr-btn-icon > .glyphicons::before,
.ufr-btn.ufr-btn-secondary > .glyphicons::before,
.ufr-btn.ufr-btn-icon > .halflings,
.ufr-btn.ufr-btn-secondary > .halflings {
  color: #ce0058 !important;
  padding-right: 4px;
}
.ufr-btn.ufr-btn-back {
  color: #333;
  border-color: transparent;
  background: transparent;
  border-radius: 0;
  padding-left: 0;
}
.ufr-btn.ufr-btn-back:not(.disabled):hover {
  color: #777;
}
.ufr-btn.ufr-btn-back:before {
  content: '‹';
  font-size: 16px;
  margin-right: 3px;
}
.ufr-btn.ufr-btn-card {
  background: #bebebe;
  border-color: #bebebe;
  color: white;
  font-size: 16px;
  height: 46px;
  padding: 13px 23px;
}
.ufr-btn.ufr-btn-card:active {
  background: #8c8c8c;
  border-color: #8c8c8c;
}
.ufr-btn.ufr-btn-card:not(.disabled):hover {
  background-color: #a0a0a0;
  border-color: #a0a0a0;
}
.ufr-btn-text {
  color: #ce0058;
  padding: 3px 10px;
}
.ufr-btn-text:hover {
  background-color: rgba(206, 0, 88, 0.04);
}
.cta-placement-font-styling,
#edit-cta-placement-tab .cta-placement .cta-placement-title .cta-placement-title-name,
#edit-cta-placement-tab .cta-placement .cta-placement-title .cta-placement-title-type {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555;
}
#edit-cta-placement-tab {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 32px 9px 0 9px;
}
#edit-cta-placement-tab .cta-placement-tab-title {
  margin: 0 0 39px 0;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555;
}
#edit-cta-placement-tab .cta-placement-tab-container {
  min-height: 0;
  overflow-y: auto;
  margin-bottom: 39px;
}
#edit-cta-placement-tab .ufr-shout-spinner {
  height: 30px;
  width: 30px;
  margin: auto;
}
#edit-cta-placement-tab .cta-placement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
#edit-cta-placement-tab .cta-placement > * {
  flex: 1;
}
#edit-cta-placement-tab .cta-placement:last-child {
  margin-bottom: 39px;
}
#edit-cta-placement-tab .cta-placement .cta-placement-title {
  display: flex;
  flex-direction: row;
}
#edit-cta-placement-tab .cta-placement .cta-placement-title .sprite-container {
  overflow: hidden;
  padding-top: 2px;
  margin-right: 6px;
}
#edit-cta-placement-tab .cta-placement .cta-placement-title .cta-placement-title-name {
  font-size: 13px;
}
#edit-cta-placement-tab .cta-placement .cta-placement-title .cta-placement-title-type {
  font-size: 11px;
  font-weight: normal;
}
#edit-cta-placement-tab .cta-placement .cta-placement-remove-container {
  display: flex;
}
#edit-cta-placement-tab .cta-placement .cta-placement-remove-container .cta-placement-remove {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  color: #ce0058;
  outline: none;
}
#edit-cta-placement-tab .cta-placement .cta-placement-remove-container .cta-placement-remove:hover {
  cursor: pointer;
  color: #b70150;
}
#edit-cta-placement-tab .cta-placement .cta-placement-remove-container .cta-placement-remove > .glyphicons {
  margin-right: 2px;
}
#edit-cta-placement-tab .add-new-placement-btn a {
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: Roboto, sans-serif;
  background: #ce0058;
  border-color: #ce0058;
  color: white;
}
#edit-cta-placement-tab .add-new-placement-btn a.disabled {
  cursor: not-allowed;
}
#edit-cta-placement-tab .add-new-placement-btn a:hover {
  text-decoration: none;
}
#edit-cta-placement-tab .add-new-placement-btn a:not(.disabled):focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#edit-cta-placement-tab .add-new-placement-btn a + .ufr-btn {
  margin-left: 10px;
}
@media (max-width: 450px) {
  #edit-cta-placement-tab .add-new-placement-btn a + .ufr-btn {
    margin: 10px 0px 0px;
  }
}
#edit-cta-placement-tab .add-new-placement-btn a + .ufr-btn-block {
  margin: 10px 0;
}
#edit-cta-placement-tab .add-new-placement-btn a + .ufr-btn-block + .ufr-btn {
  margin-left: 0;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-block {
  display: flex;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-primary {
  background: #ce0058;
  border-color: #ce0058;
  color: white;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-primary:active {
  background: #98114b;
  border-color: #98114b;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-primary:not(.disabled):hover {
  background: #9c0042;
  border-color: #9c0042;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-primary:disabled {
  background: #777 !important;
  border-color: #777;
  color: white;
  opacity: 1 !important;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-primary:disabled:hover {
  background: #555 !important;
  cursor: not-allowed;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-primary > .glyphicons::before,
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-primary > .halflings {
  color: white !important;
  padding-right: 4px;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon {
  font-size: 12px;
  padding: 8px 0px 5px 0px;
  height: 30px;
  width: 30px;
  color: #8c8f91;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon.disabled {
  opacity: 60%;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon > .glyphicons::before,
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon > .halflings {
  padding-right: 0px !important;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-secondary {
  font-weight: bold;
  color: #ce0058;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon,
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-secondary {
  background: #fff;
  border-color: #ccc;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon:not(.disabled):active,
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-secondary:not(.disabled):active {
  background: #bebebe;
  border-color: #9c0042;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon:not(.disabled):hover,
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-secondary:not(.disabled):hover {
  background: #ce00580a;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon > .glyphicons::before,
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-secondary > .glyphicons::before,
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-icon > .halflings,
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-secondary > .halflings {
  color: #ce0058 !important;
  padding-right: 4px;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-back {
  color: #333;
  border-color: transparent;
  background: transparent;
  border-radius: 0;
  padding-left: 0;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-back:not(.disabled):hover {
  color: #777;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-back:before {
  content: '‹';
  font-size: 16px;
  margin-right: 3px;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-card {
  background: #bebebe;
  border-color: #bebebe;
  color: white;
  font-size: 16px;
  height: 46px;
  padding: 13px 23px;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-card:active {
  background: #8c8c8c;
  border-color: #8c8c8c;
}
#edit-cta-placement-tab .add-new-placement-btn a.ufr-btn-card:not(.disabled):hover {
  background-color: #a0a0a0;
  border-color: #a0a0a0;
}
#edit-cta-placement-tab .add-new-placement-btn a:active {
  background: #98114b;
  border-color: #98114b;
}
#edit-cta-placement-tab .add-new-placement-btn a:not(.disabled):hover {
  background: #9c0042;
  border-color: #9c0042;
}
#edit-cta-placement-tab .add-new-placement-btn a:disabled {
  background: #777 !important;
  border-color: #777;
  color: white;
  opacity: 1 !important;
}
#edit-cta-placement-tab .add-new-placement-btn a:disabled:hover {
  background: #555 !important;
  cursor: not-allowed;
}
#edit-cta-placement-tab .add-new-placement-btn a > .glyphicons::before,
#edit-cta-placement-tab .add-new-placement-btn a > .halflings {
  color: white !important;
  padding-right: 4px;
}
