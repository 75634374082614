// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// Hubs-Shared Includes

html.layout-bs3 {
  .opacity(@opacity) {
    opacity: @opacity / 100;
  }

  // Share Icons
  @import "../../hubs/_share.less";

  // Category Icons
  @import "../../hubs/_icons.less";

  // Tile Elements
  @import "../../hubs/_tile.less";

  // CTA Elements
  @import "../../hubs/_cta.less";

  // Content Elements
  @import "../../hubs/_content.less";

  // Component Z-Indexes
  @import "../../hubs/_zindex.less";
}

// ***************************************************************************************
// Hubs-Backend Includes

@import "_content.less";
@import "_embed.less";
@import "_manage.less";
@import "_cta.less";
@import "_appearance.less";
@import "_integrations.less";
@import "_hub_options.less";
@import "_content_score.less";
@import "_preview.less";
@import "_item_editor.less";
@import "_schedule.less";
@import "_sprites.less";
@import "_tag.less";
@import "_filters.less";
@import "_app_external_page.less";
@import "_create_stream.less";
@import "_ckeditor.less";

// Data Tables
@import "../_apiDataTables.less";

// ***************************************************************************************
// Component Z-Indexes
//  **note: all z-indexes are grouped here for easy management

.hubs_manage_cta { z-index: 0; }
.lock-item { z-index: 8; }
.cta-form-field-container { z-index: 10; }
.run-away { z-index: 11 !important; }
.item-cta-bg { z-index: 12; }
.overlay-message { z-index: 1000; }

.hubs-preview-overlay { z-index: 30000; }
.hubs-preview-wrapper { z-index: 30010; }
.hubs-preview-command-bar { z-index: 30020; }

.select2-drop {
  &.select2-with-searchbox {
    z-index: 999999;
  }
}

.mgmt-container {
  .content-container {
    .custom-script-wrapper {
      .custom-script-container {
        &.ace-editor-full-screen {
          z-index: 10000;
        }
      }
    }
  }
}

.item-selector-item-container {
  .item-group {
    &.item-hidden {
      &:before {
        z-index: 1;
      }
    }
  }
}

.collection-placeholder {
  .tile {
    z-index: 0;
  }
}

.collection-preview-bg {
  z-index: 7;
}

.item-group {
  .knob-container {
    z-index: 25;

    .knob-overlay {
      z-index: -1;
    }
  }

  &.item-hidden {
    &:before {
      z-index: 1;
    }
  }

  .collection-item-controls {
    .more-item-options {
      ul {
        z-index: 24;
      }
    }
  }

  &.with-options {
    .item-action-controls {
      z-index: 26;
    }

    &:hover {
      .overlay-options {
        z-index: 24;

        &.holding-options {
          .option-box {
            .option-background {
              z-index: -1;
            }
          }
        }
      }
    }
  }
}

.item-preview-bg {
  z-index: 7;
}

.performing-item-container {
  .performing-item {
    .item-wrapper {
      .content-score {
        z-index: 7;
      }

      &:hover {
        .content-score {
          z-index: -1;
        }
      }
    }
  }
}

.author-btn-wrapper {
  display: inline-block;
}
