@import '../shared/styles/variables';
@import '../shared/styles/mixins';

#breadcrumbs {
  padding: 4px 0;
  margin-top: 24px;

  ol {
    display: flex;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: 16px;
    font-size: 13px;
    max-width: 100%;
    .truncate;

    .crumb {
      flex: 0 1 auto;
      display: inline-block;
      position: relative;
      white-space: nowrap;
      margin: 0 26px 0 0;

      a {
        display: block;
        border-bottom: 1px solid transparent;
        transition: 250ms;
        max-width: 300px;
        .truncate;
      }

      &:after {
        content: '/';
        position: absolute;
        right: -16px;
        top: 0;
      }

      a,
      a:hover,
      a:focus {
        text-decoration: none;
        color: @fontColorDark;
      }

      &:hover a {
        border-bottom: 1px solid @fontColorDark;
      }

      &.current-crumb {
        flex: auto;
        margin: 0;
        .truncate;

        &:after {
          content: '';
        }
      }
    }
  }
}

.mgmt-container {
  .content-container:not(.website-widget-section-edit-rule):not(.website-widget-section-edit-template):not(.hubs-section-manage-content) {
    #breadcrumbs {
      margin-top: -8px;
    }
  }
}

.settings_wrapper {
  #breadcrumbs {
    margin-top: 18px;
  }
}
