@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
/*
  Note: these styles implement the UF design system specifications.
  please consult the Zeplin UI design guide before making any changes @ app.zeplin.io
  Verify that all changes play nicely with all possible input states by using Storybook.
 */
.ufr-input-container {
  position: relative;
  width: 609px;
  padding: 9px 0 36px;
  max-width: 100%;
}
.ufr-input-container.ufr-input-container-disabled:hover input.ufr-input-component--disabled,
.ufr-input-container.ufr-input-container-disabled:hover button.ufr-input-component--disabled,
.ufr-input-container.ufr-input-container-disabled:hover textarea.ufr-input-component--disabled {
  cursor: not-allowed;
}
.ufr-input-container .react-input-title,
.ufr-input-container .react-input-description {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.ufr-input-container .react-input-description {
  width: 86%;
  font-size: 13px;
  color: #777;
  display: block;
  margin-bottom: 20px;
}
.ufr-input-container .react-input-description:focus {
  outline: none;
}
.ufr-input-container label.react-input-title {
  font-size: 16px;
  color: #333;
  display: block;
  margin-bottom: 0;
}
.ufr-input-container label.react-input-title + label.react-input-description {
  margin-top: 9px;
  margin-bottom: 14px;
}
.ufr-input-container label.react-input-title:only-of-type {
  margin-bottom: 2px;
}
