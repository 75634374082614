.ufr-item-page #breadcrumbs ol .crumb:not(.current-crumb) a {
  color: #ce0058;
}
.ufr-item-page .ufr-input-label {
  display: inline-block;
}
.ufr-item-page .ufr-item-info {
  display: flex;
}
.ufr-item-page #ufr-page-header .ufr-page-header-title {
  padding-top: 0;
}
