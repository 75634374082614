
.ufr-input-container .ufr-textarea-inner {
  line-height: 0;

  * {
    line-height: normal;
  }

  .ufr-input-component {
    width: 100%;
  }

  .ufr-character-count {
    width: auto;
    min-width: 50px;
  }
}
