@import "../shared/styles/variables";

.page-filter {
  min-width: 900px;

  .tabs-list {
    ul {
      border-bottom: none;

      li {
        height: 45px;
        min-width: 98px;
        cursor: pointer;

        a {
          padding: 13px;
          font-size: 13px;
          color: #333;
          text-align: center;

          &:hover {
            background-color: #fff;
            color: #777;
            margin: 1px 3px 1px 1px;
            border: none;
            border-bottom: 3px solid rgba(260, 0, 88, 0.3);
          }
        }

        &.active {
          a {
            margin: 1px 3px 1px 1px;
            border: none;
            border-bottom: 3px solid @uberPink;

            &:hover {
              color: #333;
              cursor: pointer;
            }
          }
        }
      }
    }

    .tab-content {
      display: none;
    }
  }

  .react-dropdown {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
