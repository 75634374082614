@import url('../../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.ufr-tag-modal .ufr-modal-header {
  padding-bottom: 15px;
}
.ufr-tag-modal .ufr-modal-header .ufr-modal-header-title {
  margin-bottom: 10px;
}
.ufr-tag-modal .ufr-input-container .ufr-input-inner .ufr-input-component {
  width: 100%;
}
.ufr-tag-modal .ufr-input-container .ufr-input-inner .ufr-input-component::placeholder {
  color: #999;
}
.ufr-tag-modal .ufr-input-container .ufr-textarea-inner .ufr-input-component {
  width: 100%;
}
