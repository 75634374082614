.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.react-dropdown.dropdown.btn-group button {
  padding: 6px;
}
.react-dropdown.dropdown.btn-group button .react-icon {
  font-size: 27px;
  padding: 0 5px;
}
.react-dropdown.dropdown.btn-group button:focus > .react-icon {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.react-dropdown.dropdown.btn-group button,
.react-dropdown.dropdown.btn-group button:focus {
  border: 0;
  background: none;
  box-shadow: none;
}
.react-dropdown.dropdown.btn-group .react-dropdown-menu {
  right: 12px;
  left: auto;
  margin-top: 4px;
}
.react-dropdown.dropdown.btn-group .react-dropdown-menu li a {
  min-width: 180px;
  padding: 8px 38px 8px 34px;
  font-size: 13px;
  line-height: 16px;
}
.react-dropdown.dropdown.btn-group .react-dropdown-menu li a:hover {
  background: rgba(51, 51, 51, 0.15);
}
.react-dropdown.dropdown.btn-group .react-dropdown-menu li a:active,
.react-dropdown.dropdown.btn-group .react-dropdown-menu li a:focus {
  color: white;
  background: rgba(51, 51, 51, 0.5);
}
.react-dropdown.dropdown.btn-group .react-dropdown-menu li a .menu-text {
  font-size: 13px;
}
.react-dropdown.dropdown.btn-group .react-dropdown-menu li.with-icon a {
  padding-left: 12px;
}
.react-dropdown.dropdown.btn-group .react-dropdown-menu li.with-icon a .menu-text {
  font-size: 13px;
  padding-left: 10px;
}
.react-dropdown.dropdown.btn-group .react-dropdown-menu li.with-icon a .react-icon {
  font-size: 14px;
  opacity: 1;
  line-height: 1;
  width: auto;
  left: 0;
  position: relative;
}
.react-dropdown.dropdown.btn-group.open button {
  box-shadow: none;
  background: none;
}
.react-dropdown.dropdown.btn-group.open button:hover,
.react-dropdown.dropdown.btn-group.open button:focus {
  background: none;
  border-color: transparent;
}
.react-dropdown.dropdown.btn-group.open button span.glyphicons {
  color: #ce0058;
}
.react-dropdown.dropdown.btn-group.open .dropdown-menu {
  padding: 0;
  border-radius: initial;
}
