#topnav .topnav-link {
  position: relative;
  display: inline-block;
  color: inherit;
}
#topnav .topnav-link#back-to-admin:before {
  content: ' ';
  position: absolute;
  top: 7px;
  left: 0;
  height: 26px;
  border-left: 1px solid white;
}
#topnav .topnav-link-no-dropdown > a {
  color: inherit;
}
#topnav .topnav-link-right {
  float: right;
}
#topnav.topnav-whitelabelled #back-to-admin:before {
  border-left-color: #333;
}
