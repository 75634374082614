.date-picker-container,
.single-date-picker-container {
  font-family: Roboto, sans-serif;
  position: relative;
}
.date-picker-container .title,
.single-date-picker-container .title {
  font-weight: 400;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
  line-height: 1em;
}
.date-picker-container .description,
.single-date-picker-container .description {
  font-weight: 400;
  font-size: 13px;
  color: #777;
  margin-bottom: 0px;
}
.date-picker-container .date-range-wrapper,
.single-date-picker-container .date-range-wrapper {
  position: relative;
  width: 254px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #ccc;
}
.date-picker-container .date-range-wrapper .date-range,
.single-date-picker-container .date-range-wrapper .date-range {
  margin: 5px 10px;
}
.date-picker-container .date-range-wrapper button,
.single-date-picker-container .date-range-wrapper button {
  float: left;
  position: absolute;
  right: 0;
  width: 40px;
  height: 100%;
  border: transparent;
  border-left: 1px solid #ccc;
  background: #eee;
}
.date-picker-container .date-range-wrapper:after,
.single-date-picker-container .date-range-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.date-picker-container .date-range-wrapper p,
.single-date-picker-container .date-range-wrapper p {
  float: left;
}
.date-picker-container .calendarFooter,
.single-date-picker-container .calendarFooter {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px 20px;
}
.date-picker-container .uf-month-header,
.single-date-picker-container .uf-month-header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
}
.DateRangePicker,
.SingleDatePicker {
  font-family: Roboto, sans-serif;
  top: 10px;
  border: #ccc;
}
.DateRangePicker .DateInput_input__focused,
.SingleDatePicker .DateInput_input__focused {
  border-bottom: none;
}
.DateRangePicker .DateInput,
.SingleDatePicker .DateInput {
  width: 105px;
}
.DateRangePicker .DateInput .DateInput_input,
.SingleDatePicker .DateInput .DateInput_input {
  font-size: 13px;
  font-weight: 400;
  color: #333;
  padding: 0 0 0 10px;
  border-bottom: 0px;
}
.DateRangePicker .DateRangePickerInput_arrow,
.SingleDatePicker .DateRangePickerInput_arrow {
  margin-left: 10px;
}
.DateRangePicker .DateRangePickerInput__withBorder,
.SingleDatePicker .DateRangePickerInput__withBorder {
  border: 1px solid #ccc;
}
.DateRangePicker .DateRangePickerInput_calendarIcon,
.SingleDatePicker .DateRangePickerInput_calendarIcon,
.DateRangePicker .SingleDatePickerInput_calendarIcon,
.SingleDatePicker .SingleDatePickerInput_calendarIcon {
  padding: 8px 13px;
  margin: 0;
  color: #333;
  background: #eee;
  margin: 0 0 0 25px;
  border-left: 1px solid #ccc;
}
.DateRangePicker .DateRangePickerInput_calendarIcon .DateRangePickerInput_calendarIcon_svg,
.SingleDatePicker .DateRangePickerInput_calendarIcon .DateRangePickerInput_calendarIcon_svg,
.DateRangePicker .SingleDatePickerInput_calendarIcon .DateRangePickerInput_calendarIcon_svg,
.SingleDatePicker .SingleDatePickerInput_calendarIcon .DateRangePickerInput_calendarIcon_svg,
.DateRangePicker .DateRangePickerInput_calendarIcon .SingleDatePickerInput_calendarIcon_svg,
.SingleDatePicker .DateRangePickerInput_calendarIcon .SingleDatePickerInput_calendarIcon_svg,
.DateRangePicker .SingleDatePickerInput_calendarIcon .SingleDatePickerInput_calendarIcon_svg,
.SingleDatePicker .SingleDatePickerInput_calendarIcon .SingleDatePickerInput_calendarIcon_svg {
  fill: #333;
  height: 12px;
  width: 12px;
}
.DateRangePicker .joiningWord,
.SingleDatePicker .joiningWord {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 13px;
}
.DateRangePicker .DateInput_fang,
.SingleDatePicker .DateInput_fang {
  display: none;
}
.DateRangePicker .SingleDatePicker_picker,
.SingleDatePicker .SingleDatePicker_picker {
  top: 32px !important;
  margin-top: 8px;
}
.DateRangePicker .DayPicker__withBorder,
.SingleDatePicker .DayPicker__withBorder {
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: none;
  border-radius: 0;
}
.DateRangePicker .DayPicker__withBorder .CalendarMonthGrid,
.SingleDatePicker .DayPicker__withBorder .CalendarMonthGrid {
  height: 100%;
  background: transparent;
}
.DateRangePicker .DayPicker__withBorder .CalendarMonth,
.SingleDatePicker .DayPicker__withBorder .CalendarMonth {
  background: transparent;
}
.DateRangePicker .DayPicker__withBorder .CalendarDay__default,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__default {
  border: 2px solid white;
  font-size: 13px;
  margin: 4px;
}
.DateRangePicker .DayPicker__withBorder .CalendarDay__default:hover,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__default:hover {
  border: 2px solid white;
}
.DateRangePicker .DayPicker__withBorder .CalendarDay__default:focus,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__default:focus {
  outline: 1px #ce0058 solid;
}
.DateRangePicker .DayPicker__withBorder .CalendarDay__hovered_span,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__hovered_span {
  background: #ccc;
  color: black;
  border: 2px solid white;
}
.DateRangePicker .DayPicker__withBorder .CalendarDay__selected_start,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__selected_start,
.DateRangePicker .DayPicker__withBorder .CalendarDay__selected,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__selected,
.DateRangePicker .DayPicker__withBorder .CalendarDay__selected_span,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__selected_span,
.DateRangePicker .DayPicker__withBorder .CalendarDay__default:active,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__default:active,
.DateRangePicker .DayPicker__withBorder .CalendarDay__default:hover,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__default:hover,
.DateRangePicker .DayPicker__withBorder .CalendarDay__selected:active,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__selected:active,
.DateRangePicker .DayPicker__withBorder .CalendarDay__selected:hover,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__selected:hover,
.DateRangePicker .DayPicker__withBorder .CalendarDay__selected_span:active,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__selected_span:active,
.DateRangePicker .DayPicker__withBorder .CalendarDay__selected_span:hover,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__selected_span:hover,
.DateRangePicker .DayPicker__withBorder .CalendarDay__hovered_span:hover,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__hovered_span:hover,
.DateRangePicker .DayPicker__withBorder .CalendarDay__hovered_span:active,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__hovered_span:active {
  background: #ebccd9;
  color: black;
  border: 2px solid white;
}
.DateRangePicker .DayPicker__withBorder .CalendarDay__blocked_out_of_range:active,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__blocked_out_of_range:active,
.DateRangePicker .DayPicker__withBorder .CalendarDay__blocked_out_of_range:hover,
.SingleDatePicker .DayPicker__withBorder .CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  color: #cacccd;
}
.DateRangePicker .DayPicker__withBorder .DateInput_fang .DateInput_fang_1,
.SingleDatePicker .DayPicker__withBorder .DateInput_fang .DateInput_fang_1 {
  display: none;
}
.DateRangePicker .DayPicker__withBorder .datepicker-tooltip,
.SingleDatePicker .DayPicker__withBorder .datepicker-tooltip {
  font-size: 13px;
  padding: 5px 10px 0 0;
  float: left;
  color: #ce0058;
}
.DateRangePicker .DayPicker__withBorder .DayPickerKeyboardShortcuts_show__bottomRight,
.SingleDatePicker .DayPicker__withBorder .DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
.DateRangePicker .DayPicker__withBorder .DayPickerKeyboardShortcuts_show__bottomRight::before,
.SingleDatePicker .DayPicker__withBorder .DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: #ce0058;
}
.DateRangePicker .DayPicker__withBorder .DayPickerKeyboardShortcuts_show__bottomRight:hover::before,
.SingleDatePicker .DayPicker__withBorder .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right-color: #cacccd;
}
.DateRangePicker .DayPicker__withBorder .DayPicker_weekHeader,
.SingleDatePicker .DayPicker__withBorder .DayPicker_weekHeader {
  font-weight: 600;
  font-size: 13px;
  color: #333;
  top: 42px;
}
.DateRangePicker .DayPicker__withBorder .uf-month-header p,
.SingleDatePicker .DayPicker__withBorder .uf-month-header p {
  font-size: 13px;
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;
}
.DateRangePicker .DayPicker__withBorder .ufr-btn,
.SingleDatePicker .DayPicker__withBorder .ufr-btn {
  font-size: 13px;
  height: 22px;
  padding: 0 10px;
  margin: 0 0 0 10px;
}
.DateRangePicker button.with-icon,
.SingleDatePicker button.with-icon {
  font-size: 11px;
  font-family: 'Glyphicons Halflings', sans-serif;
  border: none;
  height: 15px;
  padding: 0;
  background: transparent;
  position: absolute;
  top: 22px;
}
.DateRangePicker button.with-icon.prev-month,
.SingleDatePicker button.with-icon.prev-month {
  left: 22px;
}
.DateRangePicker button.with-icon.prev-month::before,
.SingleDatePicker button.with-icon.prev-month::before {
  content: "\e079";
}
.DateRangePicker button.with-icon.next-month,
.SingleDatePicker button.with-icon.next-month {
  right: 22px;
}
.DateRangePicker button.with-icon.next-month::before,
.SingleDatePicker button.with-icon.next-month::before {
  content: "\e080";
}
.DateRangePicker .CalendarMonth_caption,
.SingleDatePicker .CalendarMonth_caption {
  position: relative;
}
