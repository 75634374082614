@import '../../shared/styles/variables';
@import '../../shared/styles/base';

ul.ufr.ufr-dt-control-bar {
  position: relative;
  display: table;
  height: 55px;
  margin: 10px 0;
  padding: 6px 0;
  background: @lightGrey;
  border: 1px solid @mediumGreyBorder;
  width: 100%;
  list-style: none;

  > * {
    display: table-cell;
    vertical-align: top;
    position: relative;
  }

  .ufr-control-bar-dropdown-toggle > .react-dropdown {
    position: relative;
  }
}
