.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-input-inner {
  font-family: Roboto, sans-serif;
  /**
  Prefixes and postfixes
   */
}
.ufr-input-inner .ufr-input-component {
  height: 100%;
  width: 100%;
  display: table-cell;
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  background-color: white;
  background-image: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: #333;
  font-size: 16px;
}
.ufr-input-inner .ufr-input-component:focus,
.ufr-input-inner .ufr-input-component.ufr-input-error,
.ufr-input-inner .ufr-input-component.ufr-input-success {
  border-color: transparent;
  outline: 0;
}
.ufr-input-inner .ufr-input-component:focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-input-inner .ufr-input-component.ufr-input-error {
  box-shadow: 0 0 8px rgba(169, 68, 67, 0.6);
}
.ufr-input-inner .ufr-input-component.ufr-input-success {
  box-shadow: 0 0 8px rgba(60, 118, 61, 0.5);
}
.ufr-input-inner .ufr-input-component.ufr-input-component--disabled {
  background: #eee;
  color: #777;
}
.ufr-input-inner .ufr-input-component::placeholder {
  color: #cecece;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: normal;
}
.ufr-input-inner.ufr-prefixed-input,
.ufr-input-inner.ufr-postfixed-input {
  display: table;
}
.ufr-input-inner.ufr-prefixed-input .ufr-input-prefix,
.ufr-input-inner.ufr-postfixed-input .ufr-input-prefix {
  border-right: none;
  border-radius: 2px 0 0 2px;
}
.ufr-input-inner.ufr-prefixed-input .ufr-input-postfix,
.ufr-input-inner.ufr-postfixed-input .ufr-input-postfix {
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ufr-input-inner.ufr-prefixed-input.ufr-postfixed-input .ufr-input-component {
  border-radius: 0;
}
.ufr-input-inner.ufr-prefixed-input .ufr-input-component {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ufr-input-inner.ufr-postfixed-input .ufr-input-component {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ufr-input-inner .ufr-input-prefix,
.ufr-input-inner .ufr-input-postfix {
  display: table-cell;
  padding: 5px 10px;
  height: 100%;
  width: 1%;
  vertical-align: top;
  background: #eee;
  color: #555;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #ccc;
  text-align: center;
}
.ufr-input-inner.ufr-number-inner {
  height: 34px;
}
.ufr-input-inner.ufr-number-inner .ufr-input-postfix,
.ufr-input-inner.ufr-number-inner .ufr-input-prefix {
  width: initial;
}
.ufr-input-inner.ufr-number-inner.ufr-postfixed-input .ufr-input-component {
  border-right: none;
}
.ufr-input-inner.ufr-number-inner input[type='number'] {
  padding: 6px 0 6px 11px;
}
.ufr-input-inner.ufr-number-inner input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
  -webkit-appearance: none;
  background: #eee url('../input/arrows.png') no-repeat 50% 50%;
  cursor: pointer;
  display: block;
  width: 22px;
  height: 34px;
  position: relative;
  right: 0;
}
.ufr-input-inner.ufr-number-inner input[type='number'].ufr-input-component--disabled::-webkit-inner-spin-button {
  background: none;
}
.ufr-input-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.ufr-input-wrapper .ufr-character-count {
  position: absolute;
  right: 0;
}
.ufr-input-wrapper textarea {
  vertical-align: top;
}
.ufr-input-wrapper p {
  margin-top: 5px;
}
.ufr-autosuggest-input-container {
  font-family: Roboto, sans-serif;
}
.ufr-autosuggest-input-container .react-autosuggest__container {
  position: relative;
}
.ufr-autosuggest-input-container .react-autosuggest__input {
  position: relative;
  width: 100%;
  height: 32px;
  border-radius: 2px;
  border: none;
  padding: 6px 12px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: white;
  background-image: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: #333;
  font-size: 16px;
  box-shadow: 0 0 4px 1px rgba(109, 114, 120, 0.28);
}
.ufr-autosuggest-input-container .react-autosuggest__input::placeholder {
  font-size: 13px;
  color: #ccc;
}
.ufr-autosuggest-input-container .react-autosuggest__input--focused {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
  z-index: 2;
}
.ufr-autosuggest-input-container .react-autosuggest__suggestions-container {
  display: none;
}
.ufr-autosuggest-input-container .react-autosuggest__suggestions-container--open {
  width: 100%;
  border: solid 1px #ccc;
  display: block;
  position: absolute;
  top: 32px;
  margin-left: 1px;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  background-clip: padding-box;
  max-height: 225px;
  overflow-y: scroll;
  z-index: 1;
}
.ufr-autosuggest-input-container .react-autosuggest__suggestions-container--open::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: #f5f5f5;
}
.ufr-autosuggest-input-container .react-autosuggest__suggestions-container--open::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #aaa;
}
.ufr-autosuggest-input-container .react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 13px;
  color: #333;
}
.ufr-autosuggest-input-container .react-autosuggest__suggestion {
  cursor: pointer;
  padding: 12px 10px 13px;
}
.ufr-autosuggest-input-container .react-autosuggest__suggestion span {
  font-size: 13px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Roboto, sans-serif;
}
.ufr-autosuggest-input-container .react-autosuggest__suggestion--highlighted {
  background-color: #f9f9f9;
}
.ufr-autosuggest-input-container .ufr-autosuggest-no-suggestions {
  width: 100%;
  position: relative;
}
.ufr-autosuggest-input-container.ufr-autosuggest-active .no-suggestions-active {
  padding: 23px 12px 20px;
  background-color: #fff;
  border: solid 1px #ccc;
  background-clip: padding-box;
  position: absolute;
  color: #777;
  font-size: 13px;
  border-radius: 0;
  line-height: normal;
  display: block;
  z-index: 2;
  left: 0;
  right: 0;
}
.ufr-autosuggest-input-container.ufr-autosuggest-active .no-suggestions-active p {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  margin: 0;
  white-space: pre-wrap;
  text-transform: none;
}
.ufr-autosuggest-input-container.ufr-autosuggest-inactive .no-suggestions-active {
  display: none;
}
.ufr-autosuggest-input-container.ufr-autosuggest-inactive.no-suggestions .react-autosuggest__input {
  box-shadow: 0 0 8px rgba(169, 68, 67, 0.6);
}
#ufr-enrichment-source-fields-data-table .rt-tr-group:nth-last-child(1) .react-autosuggest__suggestions-container,
#ufr-enrichment-source-fields-data-table .rt-tr-group:nth-last-child(2) .react-autosuggest__suggestions-container,
#ufr-enrichment-source-fields-data-table .rt-tr-group:nth-last-child(3) .react-autosuggest__suggestions-container,
#ufr-enrichment-source-fields-data-table .rt-tr-group:nth-last-child(4) .react-autosuggest__suggestions-container {
  bottom: 32px;
  top: auto;
  border-radius: 4px 4px 0 0;
}
#ufr-enrichment-source-fields-data-table .rt-tr-group:nth-last-child(1) .no-suggestions-active,
#ufr-enrichment-source-fields-data-table .rt-tr-group:nth-last-child(2) .no-suggestions-active,
#ufr-enrichment-source-fields-data-table .rt-tr-group:nth-last-child(3) .no-suggestions-active,
#ufr-enrichment-source-fields-data-table .rt-tr-group:nth-last-child(4) .no-suggestions-active {
  bottom: 32px;
  top: auto;
}
