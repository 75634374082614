@import '../../shared/styles/variables';

.link-input {
  @dropdown-width: 80px;

  display: flex;
  gap: 4px;

  .ufr-input-container {
    width: @dropdown-width;

    .ufr-select-dropdown {
      width: @dropdown-width;
      height: 32px;
      border: none;

      .ufr-dropdown-button {
        width: @dropdown-width;
        height: 32px;
        border: solid 1px #ccc;
        border-radius: 3px;
        display: flex;
        align-items: center;

        .ufr-dropdown-selected-item {
          font-size: 13px;
        }
      }

      .ufr-select-dropdown-container {
        left: 0px;
        border: solid 1px #ccc;
        border-top: 1px solid white;

        .ufr-select-dropdown-item-container {
          width: 78px;

          .ufr-select-dropdown-item {
            width: 78px;
            font-size: 13px;
          }
        }
      }
    }

    .react-input-description {
      margin: 0;
    }
  }

  #link-url-ufr-input-container {
    flex-grow: 1;
  }

  .ufr-input-container .ufr-text-inner {
    height: 32px;
  }

  .ufr-input-inner .ufr-input-component {
    font-size: 13px;
    width: 100%;
  }
}

.link-input-label {
  font-size: 13px;
  font-weight: 500;
  color: @fontColorMediumDark;
}
