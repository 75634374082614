@import "../shared/styles/variables";

.ufr-image-uploader {
  .ufr-clearbit-search-box {
    margin-bottom: 13px;
    position: relative;

    .search-icon {
      position: absolute;
      top: 8px;
      left: 12px;
      opacity: 0.6;
    }

    .search-input {
      height: 29px;
      width: 100%;
      font-size: 13px;
      text-indent: 32px;
      border-radius: 2px;
      background-color: #fff;
      border: solid 1px #ccc;

      &.ufr-input-component--disabled {
        background: #eee;
        color: #777;
      }
    }

    .dropdown-menu {
      width: 100%;
      max-width: 100%;
      display: block;
      top: 28px;

      img {
        height: 25px;
        width: 25px;
        margin-right: 12px;
      }

      > li > a {
        padding: 6px 12px;

        .menu-text {
          font-size: 13px;
        }
      }
    }
  }
}
