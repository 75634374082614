.integration-label {
  display: flex;
}
.integration-label .overlay-container {
  display: flex;
  align-items: center;
}
.integration-label .overlay-container > span {
  margin-left: 3px;
  font-size: 11px;
  color: #a4a4a4;
}
.integration-tooltip > .tooltip-inner {
  max-width: none;
}
.ufr-input-container {
  padding-bottom: 9px;
}
.ufr-input-container .react-input-description {
  margin: 0;
}
.spinner-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.integration-setup-sprite {
  background: transparent url('/img/hubs/cta-setup-spritesheet.png') no-repeat 0 0;
  margin-top: 20px;
  margin-bottom: 15px;
}
.integration-setup-sprite.eloqua-text {
  width: 155px;
  height: 65px;
  background-position: 0 -130px;
}
.integration-setup-sprite.hubspot-text {
  width: 150px;
  height: 55px;
  background-position: 0 -75px;
}
.integration-setup-sprite.marketo-text {
  width: 116px;
  height: 54px;
  background-position: 0 -192px;
}
.integration-setup-sprite.mailchimp-text {
  width: 160px;
  height: 39px;
  background-position: 0 -36px;
}
.integration-setup-sprite.pardot-text {
  width: 150px;
  height: 66px;
  background-position: 0 -252px;
}
.integration-setup-sprite.form_cta_type-text {
  width: 74px !important;
  height: 62px;
  background-position: 0 -377px;
}
.integration-container[data-loading='true'] {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}
.integration-container[data-loading='true'] .ufr-shout-spinner {
  height: 50px;
  width: 50px;
  margin: 0;
}
.integration-container label {
  font-weight: 500;
}
