@import url('../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-page-header {
  padding: 0;
  font-family: Roboto, sans-serif;
}
#ufr-page-header .ufr-page-header-title {
  position: relative;
  padding-top: 42px;
  font-size: 26px;
  font-weight: 400;
  color: #333;
}
#ufr-page-header .ufr-page-header-title > * {
  display: inline-block;
}
#ufr-page-header .ufr-page-header-title .uf-service-icon,
#ufr-page-header .ufr-page-header-title .uf-app-icon {
  margin: 8px 6px 8px 0;
  vertical-align: top;
}
#ufr-page-header .ufr-page-header-title .uf-service-icon + .editable-text,
#ufr-page-header .ufr-page-header-title .uf-app-icon + .editable-text {
  max-width: calc(100% - 30px);
}
#ufr-page-header .ufr-page-header-subtitle {
  position: relative;
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 13px;
  font-weight: 400;
  color: #777;
}
