@import "../shared/styles/variables";

.tabs-container {
  width: 100%;

  .meatball-menu {
    position: absolute;
    margin-top: -90px;
    right: 16px;
  }

  .streams-tabs-wo-appearance {
    min-width: 920px;
  }

  .streams-tabs {
    min-width: 1166px;
  }

  .streams-tabs-w-sperf {
    min-width: 1340px;
  }

  .tabs-list-container {
    font-family: @roboto;
    position: relative;

    .tabs-list {
      ul {
        padding-left: 50px;

        li {
          min-width: 123px;
          cursor: pointer;

          &.active > a {
            font-weight: bold;
            color: @uberPink;
          }

          &:not(.active) > a:hover {
            background-color: @uberPinkBlush;
            color: @fontColorDark;
          }

          a {
            padding: 13px;
            font-size: 13px;
            color: #333;
            text-align: center;
          }

          &.disabled {
            cursor: not-allowed;

            a {
              color: #ccc;
            }
          }

          &:not(.active) {
            a {
              border-color: lightgray;
            }
          }

          &:not(.disabled) {
            &.active {
              a {
                color: @uberPink;
              }
            }
          }
        }
      }

      .tab-content {
        display: none;
      }
    }

    #stream-tabs-content.tabs-list {
      ul {
        li {
          &.streams15-stream-info {
            position: absolute;
            right: 121px;
          }

          &.streams15-options {
            position: absolute;
            right: -2px;
          }
        }
      }
    }

    .react-dropdown {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}

@media screen and (max-width: 1650px) {
  .hubs-section-manage-content {
    div[data-hypernova-key='TabsContainer'] {
      overflow-x: auto;
    }
  }
}

