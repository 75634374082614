@import '../../shared/styles/variables';

.ufr-modal-header {
  padding: 15px 10px 5px 26px;
  background: #fff;
  background-clip: padding-box;
  text-align: left;

  .ufr-modal-header-title {
    color: #333;
    margin-bottom: 0;
    font-family: @roboto;
    font-size: 16px;
  }

  p.ufr-modal-header-description {
    color: #777;
    margin-bottom: 0;
    font-family: @roboto;
    font-size: 13px;
  }

  button.close {
    padding-top: 2px;
    opacity: 1 !important;

    span {
      padding: 0 6px 2px;
      font-weight: bold !important;
      font-size: 22px !important;
      color: #777;
    }
  }
}
