@import '../styles/variables';
@import '../styles/mixins';

.ufr-accordion {
  .ufr-accordion-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    // border
    border-bottom: 1px solid #d1d1d1;

    // behavior
    &:hover {
      cursor: pointer;
      background-color: #00000004;
      border-bottom: 1px solid @uberPink;
    }

    .ufr-accordion-title {
      color: @uberPink;
      font-size: 16px;
      margin: 15px 0 3px 3px;
      font-weight: 600;
    }
  }
}
