.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#breadcrumbs {
  padding: 4px 0;
  margin-top: 24px;
}
#breadcrumbs ol {
  display: flex;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 16px;
  font-size: 13px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#breadcrumbs ol .crumb {
  flex: 0 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin: 0 26px 0 0;
}
#breadcrumbs ol .crumb a {
  display: block;
  border-bottom: 1px solid transparent;
  transition: 250ms;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#breadcrumbs ol .crumb:after {
  content: '/';
  position: absolute;
  right: -16px;
  top: 0;
}
#breadcrumbs ol .crumb a,
#breadcrumbs ol .crumb a:hover,
#breadcrumbs ol .crumb a:focus {
  text-decoration: none;
  color: #333;
}
#breadcrumbs ol .crumb:hover a {
  border-bottom: 1px solid #333;
}
#breadcrumbs ol .crumb.current-crumb {
  flex: auto;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#breadcrumbs ol .crumb.current-crumb:after {
  content: '';
}
.mgmt-container .content-container:not(.website-widget-section-edit-rule):not(.website-widget-section-edit-template):not(.hubs-section-manage-content) #breadcrumbs {
  margin-top: -8px;
}
.settings_wrapper #breadcrumbs {
  margin-top: 18px;
}
