@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-enrichment-source-fields-data-table .rt-td {
  padding: 16px 12px 16px;
}
#ufr-enrichment-source-fields-data-table .rt-td .placeholder {
  color: #777;
}
#ufr-enrichment-source-fields-data-table .rt-th {
  padding-left: 12px;
  padding-right: 12px;
}
#ufr-enrichment-source-fields-data-table .ufr-react-table {
  margin-top: 25px;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-visitor-profile-api-code-header,
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-visitor-profile-api-code-cell,
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-format-header,
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-format-cell {
  padding-left: 16px;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-category-cell,
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-enrichment-field-cell,
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-format-cell,
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-visitor-profile-field-type-cell {
  text-transform: capitalize;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-category-cell {
  font-weight: bold;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-is-mapped-cell {
  font-size: 18px;
  text-align: center;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-is-mapped-cell .glyphicons-link {
  color: #ccc;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-is-mapped-cell .is-mapped {
  color: #ce0058;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-visitor-profile-field-name-cell {
  padding-right: 40px;
  overflow: visible;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-visitor-profile-field-name-cell input {
  font-size: 13px;
}
#ufr-enrichment-source-fields-data-table .ufr-dt-enrichment-source-fields-buttons-cell {
  text-align: right;
  padding-right: 20px;
}
#ufr-enrichment-source-fields-data-table .rt-tbody,
#ufr-enrichment-source-fields-data-table .rt-table {
  overflow: visible;
}
#reset-field-mapping-tooltip {
  pointer-events: none;
}
