@import "../../shared/styles/base";
@import "../../shared/styles/mixins";

#ufr-dt-passphrases {
  .ufr-dt-passphrases-owner-cell {
    display: flex;
    align-items: center;
  }

  .ufr-dt-passphrases-owner-cell .react-avatar {
    margin-right: 8px;
  }

  .ufr-passphrase-owner {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ufr-datatable-thumbnail {
    display: block;
  }

  .ufr-passphrase,
  .ufr-passphrase-description {
    padding-left: 8px;
    max-width: 150px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: normal;
  }

  .ufr-passphrase a,
  .ufr-dt-passphrases-item-streams-count-cell a {
    color: inherit;
    font-weight: bold;
    text-decoration: none;
  }
}

#ufr-passphrase-tooltip,
#ufr-passphrase-description-tooltip,
#ufr-passphrase-owner-tooltip {
  max-width: 100%;
  word-break: break-all;
}
