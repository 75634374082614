@import "../../shared/styles/variables";
@import "../../shared/styles/mixins";

.ufr-action-button {
  padding: 4px 8px;
  height: 41px;
  white-space: nowrap;

  &:not(:last-child) {
    border-right: 1px solid @mediumGreyBorder;
  }

  button {
    .btn-unstyle(@font-family: @roboto, @font-size: 13px, @line-height: 1);

    font-size: 13px;
    border-radius: 2px;
    padding: 4px;
    height: 32px;

    .button-text {
      margin: 0 4px 0 10px;
    }

    .button-text:only-child {
      margin-right: 10px;
    }

    .react-icon {
      font-size: 12px;
      position: relative;
      top: 1px;
      margin: 0 3px 0 3px;
      vertical-align: initial;
    }

    &:focus {
      .focus-outline;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
