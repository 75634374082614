// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

@import "_glyphicons.less";

// ***************************************************************************************

// ----------------------------------------------------------------------------------------------------------
// The modal for viewing an item's content score
// ----------------------------------------------------------------------------------------------------------

#item-score-modal {
  .modal-dialog {
    width: 852px;
  }

  .navigate-period span {
    margin: 20px;
    text-transform: capitalize;
  }

  .performing-item-container {
    .item-wrapper {
      .content-score {
        height: 260px;
      }
    }

    .item-group {
      .item-placeholder {
        border-bottom-width: 2px;
      }

      .collection-item-controls {
        display: none;
      }

      &:hover {
        .box-shadow(none);
      }
    }

    .performing-item .item-stats {
      margin-left: 10px;

      > div .stat {
        height: 77px;

        .stat-type {
          padding-top: 20px;
        }

        .stat-number {
          padding-top: 15px;
        }
      }
    }
  }

  .no-data-available {
    padding: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
  }

  .loading-scores {
    padding: 56px;
    font-size: 20px;
    text-align: center;
    color: #999;

    .loading-sprite {
      margin-bottom: 41px;
    }
  }
}

// ----------------------------------------------------------------------------------------------------------
// The modal for editing marketing software item parameters
// ----------------------------------------------------------------------------------------------------------

#item-params-modal {
  .typeahead.dropdown-menu a {
    width: 244px;
  }

  .knob-container {
    display: none;
  }

  .integrations {
    padding-bottom: 10px;

    .integration-setup-sprite {
      display: inline-block;
    }
  }

  .item-params-item-container {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #eee;
    text-align: center;

    > .cloned-item-holder {
      display: inline-block;
      text-align: left;
      width: 225px;
    }
  }

  .container-fluid {
    text-align: center;

    .item-param-note {
      padding-bottom: 20px;
      text-align: left;
    }

    .item-param-row {
      width: 420px;
      margin: 0 auto;
      padding-bottom: 5px;

      &.item-param-new {
        margin-top: 20px;
      }

      &.item-param-title {
        div {
          font-weight: bold;
        }
      }

      .item-param-id {
        display: inline-block;
        width: 130px;

        input {
          width: 100%;
        }
      }

      .item-param-value {
        padding-left: 15px;
        display: inline-block;
        width: 145px;

        input {
          width: 100%;
        }
      }

      .item-param-options {
        padding-left: 15px;
        display: inline-block;
        width: 105px;

        a {
          width: 74px;
          margin-top: -5px;
        }
      }
    }
  }
}

// ----------------------------------------------------------------------------------------------------------
// Data Tables
// This should be gerericized and moved to some shared table less
// ----------------------------------------------------------------------------------------------------------

.mgmt-container {
  .hubs-section-tags,
  .account-section-upgrade-requests {
    .table.collection-table {
      margin-top: 20px;

      thead > tr > th {
        padding: 8px 0;
        white-space: nowrap;
        border-color: #ccc;

        &.sorting_asc,
        &.sorting_desc {
          -webkit-filter: grayscale(100%);
          color: #48484a;
        }

        &.sorting {
          color: #8c8f91;
        }

        &:last-child {
          min-width: 160px;
        }

        &.no-sort {
          background: transparent !important;
          cursor: default;
        }

        &.collection-table-items {
          text-align: right;
          padding-right: 20px;
        }
      }

      tbody > tr {
        &.odd {
          background: @white;
        }

        &:hover > td {
          background: #f9f9f9 !important;
          color: @black;

          &.collection-table-buttons > a {
            opacity: 1;
          }
        }
      }

      td {
        vertical-align: middle;
        padding: 0;

        &.dataTables_empty {
          padding: 10px;
        }

        .halflings,
        .glyphicons {
          color: #8c8f91;

          span {
            display: none;
          }
        }

        .glyphicons {
          vertical-align: baseline;

          &:before {
            padding: 0;
          }
        }

        &.collection-table-type {
          text-align: center;

          > .section-icon {
            opacity: 0.5;
          }

          > div {
            display: none;
          }
        }

        &.collection-table-img {
          padding: 3px 20px 3px 3px;
          width: 100px;
          color: #fff;

          > div {
            width: 100px;
            height: 50px;
            overflow: hidden;

            > a img {
              width: 100%;
            }
          }
        }

        &.stream-hidden {
          > div {
            > a img {
              opacity: 0.6;
            }

            &:before {
              content: "Hidden";
              background: @white;
              background: rgba(255, 255, 255, 0.8);
              padding: 0px 3px;
              position: absolute;
              z-index: 1;
              font-size: 10px;
              font-weight: 700;
              border: 1px solid @gray-lighter;
              color: @gray;
              line-height: 13px;
              margin: -1px;
            }
          }
        }

        &.collection-table-title {
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
          white-space: nowrap;
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;

          &.hidden {
            color: lighten(@gray-light, 13.2%);
          }

          span.color-box-service-icon {
            margin: -3px 8px 0 0;
          }
        }

        &.collection-table-items {
          text-align: right;
          padding-right: 20px;
        }

        &.collection-table-buttons {
          width: 180px;
          text-align: center;

          > a {
            opacity: 0;
          }
        }

        &.text-center {
          padding-right: 40px;
        }

        span.l-padding-25 {
          padding-left: 25px;
        }
      }

      div.loader-container {
        height: 540px;
        position: relative;

        .ajax-loader {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }

  // REQUESTS.thtml

  .account-section-upgrade-requests {
    .request-table-img {
      padding: 3px;
      width: 50px;
      height: 50px;

      .avatar-wrapper {
        height: 25px;
        padding-top: 8px;
        padding-left: 20px;
      }
    }

    .content-element {
      padding-left: 13px;
    }

    .request-table-message {
      padding: 5px 0 5px 13px;
    }

    table.table.collection-table {
      tr > th.remove-request:last-child {
        min-width: 30px;
      }
    }
  }

  #stream-options-tab {
    .options-wrapper {
      table.table.api-dt {
        thead > tr > th {
          &.sorting_asc,
          &.sorting_desc {
            color: @uberPink;
          }

          &:after {
            color: @uberPink;
          }
        }
      }
    }

    .dataTables_controls {
      display: none;
    }
  }

  #stream-metadata-tab {
    height: 620px;

    .select-container {
      min-height: 50px;

      .select2-container {
        width: 100%;

        .select2-choice {
          height: 32px;
          background: #fff;
          padding-top: 3px;
          border: 1px solid #ccc;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          border-radius: 3px;

          .select2-arrow {
            background: none;
            border: none;
            padding-top: 2px;
          }
        }

        &.select2-dropdown-open .select2-choice {
          border: 1px solid #5897fb;
          border-radius: 3px;
        }
      }
    }

    #collection-metadata {
      .seo-options-fields {
        width: 60%;
      }
    }
  }

  #stream-appearance-tab,
  #stream-authentication-tab {
    margin-top: 20px;

    #ufr-dynamic-id-authentication-toggle-banner-ufr-input-container {
      p:last-of-type {
        margin-bottom: 20px;
      }
    }

    #addAuthModal {
      .nav-action {
        span::before {
          padding-left: 5px;
        }

        &:hover {
          color: @uberPink;

          span::before {
            color: @uberPink;
          }
        }
      }
    }
  }

  #stream-appearance-tab,
  #app-configuration-tab {
    margin-top: 30px;

    #searchable-select-template-list-ufr-input-container,
    #searchable-select-app-configuration-ufr-input-container {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .ufr-appearance-options,
    .ufr-app-configuration-options {
      .ufr-form-section-header {
        padding: 0px;

        div {
          margin-top: 30px;
        }
      }
    }

    #ufr-dynamic-id-prospect_email-ufr-input-container {
      padding-top: 0px;

      label {
        padding-top: 24px;
      }
    }
  }

  #stream-info-tab {
    margin-bottom: 50px;

    label {
      color: #333;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
    }

    #eligible-owners-dropdown {
      display: none;
    }

    #clear-owner-select {
      position: absolute;
      margin-top: 3px;
      cursor: pointer;
    }

    #current-owner {
      height: 50px;
      padding-top: 8px;
    }

    #current-owner-name {
      display: inline-block;
    }

    #edit-owner span {
      margin-top: 2px;
    }

    .select-container {
      min-height: 50px;

      .select2-container {
        width: 100%;

        .select2-choice {
          height: 32px;
          background: #fff;
          padding-top: 3px;
          border: 1px solid #ccc;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          border-radius: 3px;

          .select2-arrow {
            background: none;
            border: none;
            padding-top: 2px;
          }
        }

        &.select2-dropdown-open .select2-choice {
          border: 1px solid #5897fb;
          border-radius: 3px;
        }
      }
    }

    .stream-type,
    #current-owner-name {
      color: #9c9c9c;
    }

    .source-desc {
      margin-left: 0px !important;
    }

    .panel-input-wrapper {
      width: 328px;
    }

    #stream-info-footer {
      margin-top: 200px;
      border-top: solid 1px #d1d1d1;

      .stream-info-footer-content {
        margin-top: 20px;

        label {
          font-family: Roboto !important;
          font-size: 13px !important;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #333;
        }

        label:last-child {
          float: right;
        }
      }
    }
  }
}

// ----------------------------------------------------------------------------------------------------------
// Data Table Top Nav
// ----------------------------------------------------------------------------------------------------------

.collection-nav-bar,
.privacy-groups-bar,
.generic-api-dt-util-bar {
  display: table;
  list-style: none;
  background-color: @gray-lighter; // #eee
  border: 1px solid darken(@gray-lighter, 13.5%); // #ccc

  .glyphicons {
    &:before {
      padding: 4px 1px;
      color: #989898;
    }

    // hamburger menu icon

    &.glyphicons-menu-hamburger {
      font-size: 18px;
    }

    // reset display order icon

    &.glyphicons-display {
      font-size: 18px;
      vertical-align: middle;
      margin-top: -2px;
    }

    // hack for a pseudo RSS icon

    &.glyphicons-wifi {
      transform: rotate(45deg);
    }
  }

  .set-display-order .glyphicons:before {
    color: @uberBlue;
  }

  // menu toggle

  > li:first-child {
    border-right: 1px solid #ccc;
    padding: 2px 11px 0;

    &:not(.btn-passphrase) {
      text-align: center;
    }
  }

  > li {
    display: table-cell;
    padding: 6px 18px;
    vertical-align: middle;

    > ul {
      display: none;
    }

    .btn-group ul.dropdown-menu {
      margin-left: 8px;
    }

    :hover:not(.hubs_collection_search, .nav-action) {
      .glyphicons:before {
        color: @uberBlue;
      }
    }

    .nav-action.disabled,
    .nav-action.disabled:hover {
      .glyphicons:before {
        color: @uberGrayLighter;
      }

      color: @uberGrayLighter;
      cursor: not-allowed;
    }

    .nav-label {
      cursor: pointer;
      font-size: @font-size-base + 1; // 13px
      vertical-align: middle;
    }

    &.hubs_collection_search,
    &.with-secondary-search {
      position: relative;
      width: 100%;
      padding: 6px 15px;

      .halflings {
        .pos-abs(8px, auto, auto, 12px); // T, R, B, L

        opacity: 0.5;
      }

      input.main-search-input {
        .size(100%, 29px);

        font-size: @font-size-base + 1; // ~13px
        text-indent: 26px;

        &:focus {
          box-shadow: none;
          border-color: @uberBlueLight;
        }
      }

      input::-ms-clear {
        display: none;
      }

      .close-float {
        display: none;
        overflow: hidden;
        .pos-abs(13px, 55px); // T, R, B, L
        .square(15px);

        line-height: 16px;
        font-size: (@font-size-base + 2);
        padding: 0;
        text-align: center;
        cursor: pointer;
        .border-radius(4px);

        background: #999;
        color: @white;
        font-weight: bold;
        z-index: 3;

        &.active {
          display: block;
        }

        &:hover {
          color: @white;
          background: @black;
        }
      }

      .advanced-search-button {
        display: block;
        width: 14px;
        height: 18px;
        .pos-abs(11px, 25px);

        color: @uberGrayLight;
        font-size: 10px;

        &:hover {
          color: @uberGrayDark;
        }

        &:after {
          font-family: 'Glyphicons Halflings';
          content: '\e252';
        }
      }

      .advanced-search-close-button {
        .pos-abs(15px, 23px);

        font-size: (@font-size-base * 1.5);
        font-weight: @close-font-weight;
        line-height: 1;
        color: @close-color;
        text-shadow: @close-text-shadow;
        .opacity(.2);

        &:hover,
        &:focus {
          color: @close-color;
          text-decoration: none;
          cursor: pointer;
          .opacity(.5);
        }
      }

      .search-form-wrapper {
        position: relative;

        .advanced-search-wrapper {
          width: 100%;
          padding: 20px;
          position: absolute;
          top: 28px;
          left: 1px;
          z-index: 1000;
          background-color: @white;
          border: 1px solid #ccc;

          .search-title {
            font-size: 14px;
            color: #404041;
            font-weight: 500;
          }

          label {
            margin-top: 2px;
            padding-right: 5px;
            font-size: 13px;
            color: #808284;
            font-weight: inherit;
          }

          input {
            font-size: (@font-size-base + 2);
            width: 100%;
          }

          .advanced-search-submit {
            font-size: 13px;
          }
        }
      }

      .result-tally {
        position: absolute;
        color: #ccc;
        .pos-abs(11px, 80px);
      }

      .section-tally {
        right: 16px;
      }
    }

    a.nav-action,
    button.nav-action {
      color: #686868;
      white-space: nowrap;
      border: none;
      background: transparent;

      &:hover {
        color: @uberBlue;
      }
    }

    &.btn-group.open {
      .nav-action {
        box-shadow: none;
      }

      .dropdown-menu {
        .border-radius(0);
      }
    }

    .dropdown-menu {
      background-color: #eee;
      border: 1px solid #ccc;
      .box-shadow(none);

      margin-top: 5px !important;

      li a {
        opacity: 0.8;
      }

      li a:hover {
        opacity: 1;
        background-color: @uberBlueLight !important;
        color: white;
      }
    }

    &.ufr-action-button {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

// ----------------------------------------------------------------------------------------------------------
// Data Table Navbar Button modals
// ----------------------------------------------------------------------------------------------------------

.create-item-modal {
  .modal-body {
    .form-group {
      .help-block {
        display: none;
      }

      &.has-error {
        .help-block {
          display: block;
        }
      }
    }
  }
}

.modal.place-cta-modal {
  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    overflow-x: hidden;
    padding: 0px !important;
    background: @white;

    .badge {
      .border-radius(3px);

      cursor: default;

      &.stream-level-on {
        display: inline-block;
        width: 36px;
        text-align: center;
      }
    }

    .select-cta {
      height: 428px;
      background-color: lighten(@gray-lighter, 5%);
      border-top: 1px solid darken(@gray-lighter, 6.7%);
      border-bottom: 1px solid darken(@gray-lighter, 6.7%);

      .cta {
        cursor: pointer;
      }

      .create-cta {
        display: none;
      }

      &.no-available-ctas {
        .scroll-ctas {
          display: none;
        }

        i.icon-cog {
          display: none;
        }

        .create-cta {
          display: block;
          .pos-rel(34px, auto, auto, 194px);
          .size(200px, 300px);

          border: 2px dashed #aaa;
          cursor: pointer;

          span.add-icon {
            display: block;
            .pos-rel(109px, auto, auto, 62px);
            .square(80px);

            background-color: #777;
            .border-radius(50%);

            color: @white;
            font-size: 77px;
            font-weight: bold;
            text-align: center;
            line-height: 66px;
          }

          span.add-text {
            display: none;
          }

          &:hover {
            span.add-icon {
              background-color: #444;
            }

            span.add-text {
              display: inline-block;
              .pos-rel(120px, auto, auto, 75px);

              color: #444;
              font-weight: bold;
            }
          }
        }
      }

      .scroll-ctas {
        position: relative;
        .size(110px, 330px);

        margin: 0 auto;

        .scroll-cta-wrapper {
          position: relative;
          margin-left: -25px;

          li.no-result {
            .pos-rel(82px, auto, auto, -25px);
            .size(156px, 223px);

            font-size: 16px;
            color: #939597;
            background: url("/img/icon_128/search_faded.png") no-repeat 40px 0;
            white-space: nowrap;

            span {
              .pos-rel(152px, auto, auto, 48px);
            }
          }
        }

        .tile.single.cta {
          margin-left: -160px;
          .scale(0.4);
          .user-select(none);

          &.active {
            margin-left: -110px;
            margin-right: 70px;
            .scale(0.8);

            &:active {
              .scale(0.82);
              .box-shadow("0 0 10px #fff");
            }
          }

          &:first-child {
            margin-left: -94px;
          }

          &:first-child.active {
            margin-left: -49px;
          }
        }

        .cta-activate-button-container {
          padding-bottom: 10px;
        }
      }

      .search-ctas {
        position: relative;
        display: inline-block;
        width: 300px;
        margin: 10px 0 0 0;

        i {
          .pos-abs(6px, auto, auto, 0);

          opacity: 0.5;
        }

        input {
          width: 197px;
          margin-left: 15px;
          border: 0;
          font-size: 12px;
          color: #808284;
          background-color: inherit;
          .box-shadow(none);
        }

        span.clear-search {
          display: none;
        }

        &.active {
          span.clear-search {
            display: block;
            .pos-abs(5px, 55px);
            .square(15px);

            font-size: 10px;
            text-align: center;
            line-height: 14px;
            color: #fff;
            .border-radius(50%);

            background: #ccc;
            cursor: pointer;
          }
        }
      }

      .btn-group {
        display: inline-block;
        width: 167px;
        margin: 13px 10px 10px 10px;
        border-right: 1px solid #ddd;

        &.open {
          > a .halflings {
            &:before {
              content: "\e113";
            }
          }
        }

        > a {
          display: inline-block;
          width: 158px;
          font-size: 14px;

          &:hover {
            background-color: transparent !important;
          }
        }

        a {
          .box-shadow(none);

          color: #58585b;
          .clearfix;

          > span {
            float: left;
          }

          &:hover {
            background: inherit;
            background-color: #f1f1f1;
            color: #58585b !important;
          }

          [class^="bh-icon-"] {
            .pos-rel(3px);

            float: right;
          }

          .bh-icon-link-cta {
            .pos-rel(auto, auto, auto, 3px);
          }

          .bh-icon-stream-cta {
            .pos-rel(auto, auto, auto, 4px);
          }
        }

        .halflings {
          .pos-rel(2px);

          float: right;
          margin-right: 9px;
          opacity: 0.6;
        }
      }

      .edit-cta-link {
        .pos-abs(15px, 21px);

        color: @gray-dark; // #333
        opacity: 0.8;
        cursor: pointer;
      }

      .cta-info-container {
        .pos-abs(357px);

        width: 100%;
        text-align: center;

        .cta-info {
          display: inline-block;
          margin: 0 auto;

          p {
            white-space: nowrap;

            .cta-info-name {
              font-weight: bold;
              padding-right: 10px;
              color: #666;
            }

            .badge {
              background-color: #a7a9ab;
            }
          }
        }
      }

      .iScrollHorizontalScrollbar {
        .pos-abs(auto, auto, -29px, -216px);
        .size(541px, 1px);

        background-color: #ddd;
        overflow: visible !important;
      }

      .iScrollIndicator {
        .pos-abs(-9px);
        .square(16px) !important;
        .border-radius(50%);

        background-color: #888;
        border: 2px solid #fff;
        cursor: pointer;
      }
    }

    .edit-cta-placement {
      &.gate-container {
        height: 89px;
        text-align: center;

        .gate-cta {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          text-transform: uppercase;
          padding: 9px 0;
          width: 150px;
        }
      }

      &.stream-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
      }

      .no-form-ctas {
        line-height: 78px;
        color: #555;
      }

      &.fade {
        opacity: 0.2;
      }

      .cta-placement-type {
        .size(295px, 95px);

        padding: 34px 34px 0 34px;

        &.item-level {
          opacity: 0.6;

          .placement-body {
            padding: 9px;
            font-size: 10px;
            line-height: 11px;
          }
        }

        &.stream-level.disable {
          opacity: 0.6;

          .placement-body .placement-row.not-editable {
            opacity: 1;
          }
        }

        .placement-header {
          padding-bottom: 4px;
          border-bottom: 1px solid #ddd;
          margin-bottom: 5px;

          label {
            display: inline-block;
            font-size: 12px;
          }

          span.badge {
            float: right;
            background-color: #a7a9ab;

            &.placement-on {
              background-color: @uberGreen;
            }
          }

          .circle-icon {
            display: inline-block;
            float: right;
            .square(19px);

            margin-right: 3px;
            border: 1px solid #333;
            .border-radius(50%);

            text-align: center;
          }
        }

        .placement-body {
          .placement-row {
            position: relative;
            margin-bottom: 5px;

            .prevent-interaction {
              display: none;
              .pos-abs(0, auto, auto, 0);
              .square(100%);

              z-index: 99;
            }

            &.not-editable {
              opacity: 0.6;

              .prevent-interaction {
                display: block;
              }
            }

            input,
            span {
              display: inline-block;
            }

            input.placement-on {
              margin-right: 13px;
            }

            span.placement-description {
              width: 106px;
              margin-right: 15px;
            }

            input.placement-number {
              .size(35px, 26px);

              margin-right: 6px;
              text-align: center;
            }

            .change-number {
              margin-right: 4px;
              cursor: pointer;
              .user-select(none);
            }
          }
        }
      }
    }
  }
}

.stream-option-wrapper {
  .stream-option {
    margin: 10px 0 10px 30px;
  }
}

#stream-custom-labels-tab,
#stream-lang-links-tab {
  margin-top: 32px;
}

#single-stream-performance-tab {
  iframe {
    height: 800px;
    width: 100%;
    border: none;
  }
}

.no-search .select2-search {
  display: none;
}

.select2-chosen .user-avatar {
  margin-top: 2px;
}

.l-padding-25 {
  padding-left: 25px;
}
