// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

i[class^="bh-icon-"] {
  display: inline-block;
  .square(14px);

  line-height: 14px;
  vertical-align: text-top;
  background-image: url("/img/hubs/backend/spritesheet.png");
}

i.bh-icon-beside-cta {
  background-position: -533px -533px;
}

i.bh-icon-lock-cta {
  width: 13px;
  background-position: -534px -574px;

  &.icon-white {
    background-position: -493px -492px;
  }
}

i.bh-icon-cta {
  background-position: -174px -573px;
}

i.bh-icon-form-cta {
  background-position: -452px -573px;
}

i.bh-icon-link-cta {
  background-position: -495px -573px;
}

i.bh-icon-stream-cta {
  width: 20px;
  background-position: -410px -573px;
}
