input[type=text].ufr-custom-labels-input {
  width: 348px;
  max-width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #333;
  padding: 6px 12px;
}
input[type=text].ufr-custom-labels-input[disabled] {
  background: #eee;
}
input[type=text].ufr-custom-labels-input[disabled]:hover {
  cursor: not-allowed;
}
