@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.sb-show-main #ufr-calls-to-action-data-table,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table {
  font-family: Roboto, sans-serif;
  font-size: 13px;
}
.sb-show-main #ufr-calls-to-action-data-table .ReactTable.ufr-react-table,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .ReactTable.ufr-react-table {
  min-height: initial;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-table,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-table,
.sb-show-main #ufr-calls-to-action-data-table .rt-tbody,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tbody {
  overflow: visible;
}
.sb-show-main #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-name-header,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-name-header {
  font-weight: 500;
}
.sb-show-main #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-tagline-header,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-tagline-header,
.sb-show-main #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-tagline-cell,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-tagline-cell {
  padding-left: 19px;
}
.sb-show-main #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-id-header,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-id-header,
.sb-show-main #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-id-cell,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-id-cell {
  padding-left: 27px;
}
.sb-show-main #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-status-header,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-status-header,
.sb-show-main #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-status-cell,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .ufr-dt-cta-listing-cta-status-cell {
  padding-left: 19px;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-cta-name-cell,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-cta-name-cell {
  display: flex;
  align-items: center;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-cta-name-cell a,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-cta-name-cell a {
  color: #333;
  font-weight: 500;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header {
  padding: 12px 17px 12px 26px;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header h4,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header h4 {
  line-height: 30px;
  margin-top: 8px;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header button,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header button {
  font-family: 'Glyphicons Regular', serif;
  font-size: 11px;
  line-height: 11px;
  padding: 5px;
  opacity: 1;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header button:hover:before,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header button:hover:before {
  color: #333;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header button:before,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header button:before {
  content: '\E208';
  color: #ccc;
  display: block;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header button span,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-header button span {
  display: none;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-body,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-body {
  padding: 29px 47px 18px 23px;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-body p,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-body p {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  line-height: 19px;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-body .cta-name-highlight,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-body .cta-name-highlight {
  font-weight: 500;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-footer,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-footer {
  padding-right: 20px;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-footer .ufr-btn + .ufr-btn,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-dt-cta-listing-buttons-cell .ufr-modal-footer .ufr-btn + .ufr-btn {
  margin-left: 8px;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-tr-group .ufr-cta-truncated-cell-value,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-tr-group .ufr-cta-truncated-cell-value {
  width: auto;
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-noData .no-data-create-cta,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-noData .no-data-create-cta {
  font-weight: 500;
}
.sb-show-main #ufr-calls-to-action-data-table .rt-noData #ufr-no-data-create-cta-btn,
div[data-hypernova-key='CtaListingPage'] #ufr-calls-to-action-data-table .rt-noData #ufr-no-data-create-cta-btn {
  margin-top: 22px;
}
.sb-show-main .ufr-pagination select,
div[data-hypernova-key='CtaListingPage'] .ufr-pagination select {
  border: 1px solid #ccc;
  border-radius: 3px;
}
.sb-show-main .ufr-toaster p,
div[data-hypernova-key='CtaListingPage'] .ufr-toaster p {
  font-family: Roboto, sans-serif;
  font-size: 16px;
}
div[id^='full-value-name-'],
div[id^='full-value-tagline-'] {
  max-width: 100%;
  word-break: break-all;
}
