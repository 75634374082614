
table.table.api-dt,
table.api-dt {
  min-width: 800px;
  margin-top: 20px;
  font-family: @roboto;

  thead > tr > th {
    white-space: nowrap;

    &.api-data-table-counts {
      text-align: right;
      padding-right: 20px;
    }

    &.sorting_asc,
    &.sorting_desc {
      color: #48484a;
    }

    &.sorting {
      color: #8c8f91;
    }

    &.sorting_disabled {
      color: #8c8f91;
      cursor: default;
    }
  }

  // Table rows
  tbody > tr {
    &:hover > td {
      background: #f9f9f9;

      &.api-data-table-buttons {
        opacity: 1;
      }
    }

    td {
      vertical-align: middle;

      &.api-data-table-counts {
        text-align: right;
        padding-right: 20px;

        .priority-text {
          border: 1px solid #eee;
          padding: 1px 5px;
          background: #eee;
          border-radius: 2px;
          cursor: pointer;

          &:hover {
            border: 1px solid #ccc;
          }
        }
      }

      &.api-data-table-buttons {
        width: 185px;
        min-width: 185px;
        opacity: 0;
        text-align: right;

        &.single-button {
          width: auto;
          opacity: 1;
          text-align: left;

          span {
            font-size: 16px;
          }
        }

        > a {
          margin: 0 2px;
        }

        .btn {
          display: inline;
        }

        .halflings,
        .glyphicons {
          color: #8c8f91;
          vertical-align: middle;
          margin-top: -3px;

          span {
            display: none;
          }

          &:before {
            padding: 0;
          }
        }
      }

      &.api-dt-thumbnail {
        width: 100px;
        height: 40px;
        padding-right: 0;
        padding-left: 0;

        div > .data-table-thumb {
          width: 84px;
          height: 40px;
          vertical-align: middle;
          background-size: cover;
          background-position: center center;
          display: inline-block;
        }

        div.thumbnail-hidden {
          background-color: rgba(51, 51, 51, 0.5);
          position: relative;

          > .data-table-thumb {
            opacity: 0.5;
          }

          &:before {
            content: "Hidden";
            background: white;
            padding: 0 3px;
            position: absolute;
            right: 0;
            z-index: 1;
            font-size: 10px;
            font-weight: 700;
            border: 1px solid @gray-lighter;
            color: @gray;
            line-height: 13px;
            margin: -1px;
          }
        }
      }

      &.api-data-table-avatar {
        width: 50px;

        .l-padding-25 {
          padding-left: 25px;
        }

        .user-avatar-tiny + div {
          line-height: 20px;
        }
      }

      a {
        color: #000;
        font-weight: bold;
        text-decoration: none;
      }

      &.api-data-table-link {
        a {
          border-bottom: none;

          &:hover {
            border-bottom: 1px solid #888;
          }
        }
      }

      &.api-data-table-date {
        width: 150px;
        white-space: nowrap;
      }

      &.inline-edit-text {
        input {
          border: 1px solid #ccc;
          border-radius: 4px;
          text-align: right;
          padding: 2px 0;

          &:focus {
            background: #fff;
          }
        }
      }
    }
  }
}

.generic-api-dt-util-bar {
  width: 100%;
  display: table;
  list-style: none;
  background-color: @gray-lighter; // #eee
  border: 1px solid darken(@gray-lighter, 13.5%); // #ccc
  padding: 0;

  > li:first-child {
    padding: 11px 14px;
    vertical-align: middle;

    &.add-border {
      border-right: 1px solid darken(@gray-lighter, 13.5%); // #ccc
    }
  }

  > li {
    display: table-cell;
    padding: 6px 18px;
    vertical-align: middle;

    a.nav-action {
      color: black;
      white-space: nowrap;
      opacity: 0.5;

      &:hover {
        color: @uberBlue;
      }
    }

    .nav-action.disabled {
      color: grey;
      white-space: nowrap;
      opacity: 0.5;
    }
  }
}

.api-dt-util-bar {
  min-width: 800px;
}

.dataTables_controls {
  margin-top: 20px;

  label {
    font-size: 12.5px;
  }
}

.api-table-search {
  position: relative;
  width: 100%;
  padding: 6px 15px;

  .halflings {
    .pos-abs(8px, auto, auto, 12px);       // T, R, B, L

    opacity: 0.5;
  }

  input.main-search-input {
    .size(100%, 29px);

    font-size: @font-size-base + 1;   // ~13px
    text-indent: 26px;

    &:focus {
      outline: 0;
    }
  }

  input::-ms-clear {
    display: none;
  }

  .search-form-wrapper {
    position: relative;
  }
}

.api-dt-toggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;
  margin: 0;
  vertical-align: middle;

  /* Hide default HTML checkbox */
  input {
    display: none;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #7db33e;
  }

  input:focus + .slider {
    box-shadow: 0 0 8px @uberBlueLight;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
}

#user-activity-table {
  tbody {
    tr {
      td {
        vertical-align: top;

        &.activity-message {
          a {
            color: #000;
            font-weight: bold;
            border-bottom: 1px dotted #ccc;
            text-decoration: none;

            &:hover {
              border-bottom: 1px solid #888;
            }
          }
        }

        &:not(:first-of-type) {
          padding-top: 13px;
        }

        &.truncate {
          max-width: 175px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        div.note {
          font-size: 0.9em;
          line-height: 1.7em;

          > span.edit-from,
          > span.edit-to {
            padding: 2px;
            margin: 0 4px 0 0;
          }

          > span.edit-from {
            background-color: rgb(255, 243, 246);
          }

          > span.edit-to {
            background-color: rgb(241, 255, 238);
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
