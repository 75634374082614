@import '../../shared/styles/variables';
@import '../../shared/styles/mixins';
@import '../input/input';

.ufr-autosuggest-input-container {
  font-family: @roboto;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    position: relative;
    width: 100%;
    height: 32px;
    border-radius: 2px;
    border: none;
    padding: 6px 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: white;
    background-image: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    color: @fontColorDark;
    font-size: 16px;
    box-shadow: 0 0 4px 1px rgba(109, 114, 120, 0.28);

    &::placeholder {
      font-size: @pageSubtitleFontSize;
      color: @mediumGreyBorder;
    }
  }

  .react-autosuggest__input--focused {
    .focus-outline;

    z-index: 2;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    width: 100%;
    border: solid 1px #ccc;
    display: block;
    position: absolute;
    top: 32px;
    margin-left: 1px;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
    max-height: 225px;
    overflow-y: scroll;
    z-index: 1;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #aaa;
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: @pageSubtitleFontSize;
    color: @darkBackground;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 12px 10px 13px;

    span {
      font-size: @pageSubtitleFontSize;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: @roboto;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #f9f9f9;
  }

  .ufr-autosuggest-no-suggestions {
    width: 100%;
    position: relative;
  }

  &.ufr-autosuggest-active {
    .no-suggestions-active {
      padding: 23px 12px 20px;
      background-color: #fff;
      border: solid 1px #ccc;
      background-clip: padding-box;
      position: absolute;
      color: @fontColorMedium;
      font-size: @pageSubtitleFontSize;
      border-radius: 0;
      line-height: normal;
      display: block;
      z-index: 2;
      left: 0;
      right: 0;

      p {
        font-family: @roboto;
        font-size: @pageSubtitleFontSize;
        margin: 0;
        white-space: pre-wrap;
        text-transform: none;
      }
    }
  }

  &.ufr-autosuggest-inactive {
    .no-suggestions-active {
      display: none;
    }

    &.no-suggestions {
      .react-autosuggest__input {
        box-shadow: 0 0 8px rgba(169, 68, 67, 0.6);
      }
    }
  }
}

#ufr-enrichment-source-fields-data-table {
  .rt-tr-group:nth-last-child(1),
  .rt-tr-group:nth-last-child(2),
  .rt-tr-group:nth-last-child(3),
  .rt-tr-group:nth-last-child(4) {
    .react-autosuggest__suggestions-container {
      bottom: 32px;
      top: auto;
      border-radius: 4px 4px 0 0;
    }

    .no-suggestions-active {
      bottom: 32px;
      top: auto;
    }
  }
}
