#topnav {
  min-width: 700px;
}
#topnav *:not(.glyphicons) {
  font-family: 'IBM Plex Sans', sans-serif;
}
#topnav > ul {
  position: absolute;
  left: 250px;
  right: 0;
}
#topnav .glyphicons:before {
  padding: 0;
}
@media screen and (max-width: 1300px) {
  #topnav > ul {
    left: 60px;
  }
}
