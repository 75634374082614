.uf-app-icon {
  text-align: center;
  line-height: 1;
  display: inline-block;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
  }
}

