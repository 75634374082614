.ufr-form-section-header {
  min-height: 32px;
  margin: 24px 0 8px 0;
}
.ufr-form-section-header div {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
  color: #333;
  font-family: Roboto, sans-serif;
}
