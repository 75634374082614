.ufr-editable-span {
  border: 1px solid #eee;
  padding: 3px 7px;
  background: #eee;
  border-radius: 2px;
  cursor: pointer;
}
.ufr-editable-span:hover {
  border-color: #ccc;
}
.ufr-editable-span.disabled {
  cursor: not-allowed;
}
