.ufr-modal.ufr-modal-hidden-modal {
  display: none;
}

.ufr-modal {
  display: block;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1080;
  -webkit-overflow-scrolling: touch;

  .ufr-modal-dialog {
    max-width: 620px;
    margin: 0 auto;
    position: relative;
    top: 29px;

    .ufr-modal-content {
      position: relative;
      background-color: #f9f9f9;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
      background-clip: padding-box;
      overflow: hidden;
    }
  }

  .ufr-modal-overlay {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}

// Prevent scrolling when modal is open
body.ufr-modal-is-open {
  overflow: hidden;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    top: 0px;
  }

  100% {
    opacity: 1;
    top: 29px;
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    top: 29px;
  }

  100% {
    opacity: 0;
    top: 0px;
  }
}

.slide-in {
  animation: slide-in;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  transition: opacity 0.15s;
}

.slide-out {
  animation: slide-out;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

