.tile {
  position: relative @important;
  float: left @important;
  width: 250px;
  height: 330px;
  padding: 0px @important;
  margin: 0 @important;
  margin-right: 20px @important;
  margin-bottom: 20px @important;
  line-height: 1.5em @important;
  background: @white;
  .box-shadow(0 1px 5px rgba(0, 0, 0, 0.2));

  overflow: hidden @important;

  .img {
    float: none @important;
    position: absolute @important;
    left: 0 @important;
    top: 0 @important;
    width: 100% @important;
    height: 41.09947644% @important;
    padding: 0px @important;
    margin: 0 @important;
    .opacity(0);
    .transition(opacity 400ms);

    &.visible {
      .opacity(100);
    }

    .duration {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: rgb(0, 0, 0) transparent;
      background: rgba(0, 0, 0, 0.6);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#99000000', endColorstr='#99000000');
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
      border-radius: 4px;
      color: white;
      font-size: 13px;
      padding: 5px;
      line-height: 6px;
    }

    img {
      float: none @important;
      width: 100% @important;
      height: auto @important;
      max-width: none;
      padding: 0px @important;
      margin-top: 0; // May be changed inline to center vertically
      margin-left: 0; // May be changed inline to center horizontally
      margin-right: 0 @important;
      margin-bottom: 0 @important;

      &.shorter {
        height: 100% @important;
        width: auto @important;
      }
    }
  }

  .img-for-croppie {
    position: relative;
    width: 100%;
    height: 41.09947644%;
  }

  &.single {
    .icon {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 48px;
      height: 48px;
      background-color: @black;
      background-color: rgba(0, 0, 0, 0.5);
    }

    > a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==) no-repeat 0 0;

      &.view {
        top: auto;
        bottom: 0;
        width: 100%;
        height: 40px;
        color: @uberGrayDarker;
        font-size: 16px;
        line-height: 40px;
        padding-left: 20px;
        background-color: #fcfcfc;
        border-top: 1px solid #eee;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &:before {
          display: block;
          width: 100%;
          height: 20px;
          margin-left: -40px;
          padding-right: 20px;
          margin-top: -21px;
          #gradient > .vertical(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));

          background-color: transparent;
          content: "";
          z-index: 1;
        }

        &:after {
          display: block;
          position: absolute;
          top: 13px;
          right: 17px;
          width: 16px;
          height: 16px;
          background: transparent url("@{imageSprite1x}") left top no-repeat;
          content: "";
        }
      }
    }

    .description {
      background-color: @white @important;
      height: 58.90052356% @important;

      h3 {
        color: @gray @important;
        font-weight: bold @important;
        margin: 5px 20px 5px 20px @important;
        font-size: 19px @important;

        &.no-timestamp {
          padding-top: 12px;

          &.avatar {
            padding-top: 0;
          }
        }
      }

      h4 {
        color: @grayLight @important;
        font-size: 18px @important;
        font-weight: 400 @important;
        margin: 20px @important;

        em {
          font-style: normal @important;
        }
      }

      h3 + h4 {
        margin: 0 20px @important;
        font-weight: 300 @important;
      }

      div.h3like + h4 {
        margin: 0 20px @important;
        font-weight: 300 @important;
      }

      div.h3like {
        color: @gray @important;
        font-weight: bold @important;
        margin: 5px 20px 5px 20px @important;
        font-size: 19px @important;
        line-height: 1.3 @important;

        &.no-timestamp {
          padding-top: 12px;

          &.avatar {
            padding-top: 0;
          }
        }

        &.avatar {
          margin-top: 10px @important;
          margin-bottom: 10px @important;
          font-size: 18px @important;
          font-weight: normal @important;
          line-height: 25px @important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .friendly-timestamp {
        color: #bbb;
        margin-left: 20px;
        margin-top: 10px;
        font-size: 11px;
        font-weight: normal;
      }

      img.avatar {
        float: left;
        width: auto;
        height: 25px;
        margin-left: 20px;
        margin-top: 10px;
        margin-right: 5px;
        .border-radius(4px);
      }

      h3.avatar {
        margin-top: 10px @important;
        margin-bottom: 10px @important;
        font-size: 18px @important;
        font-weight: normal @important;
        line-height: 25px @important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: normal;
      }

      h4.avatar {
        font-size: 16px @important;
        line-height: 18px @important;
      }
    }

    &.no-img {
      .description {
        height: 100% @important;

        h3 {
          font-size: 24px @important;
          &.avatar { font-size: 18px @important; }
        }

        h4 {
          color: lighten(@grayDark, 20%) @important;
          font-size: 20px @important;
          line-height: 24px @important;
        }
      }

      &.social {
        h4 {
          font-size: 26px @important;
          font-weight: 300 @important;
          line-height: 30px @important;
        }
      }
    }

    &.with-img {
      .description h4.long-h3 {
        display: none;
      }
    }

    &.uberflip.portrait:not(.list) {
      .description {
        height: 110px @important;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        background: rgba(255, 255, 255, 0.95);
        .box-shadow(0 -2px 30px rgba(150, 150, 150, 0.1));

        .friendly-timestamp {
          font-size: 11px @important;
          height: 15px @important;
          &.blank { height: 5px; }
        }

        h3 {
          font-size: 16px @important;
          line-height: 20px @important;
          font-weight: 600 @important;
          color: #555 @important;
          .text-overflow();
        }

        .share-single {
          bottom: 60px;
        }
      }

      .img {
        height: 88% @important;

        img.taller {
          height: auto @important;
          width: 100% @important;
        }
      }

      a.view:before {
        background: none;
      }
    }

    &.youtube,
    &.vimeo,
    &.instagram.media-type-video,
    &.wistiar,
    &.vidyard,
    &.brightcove {
      .img {
        &:before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("/img/hubs/video-play.png") no-repeat center;
          background-size: 47px;
          content: "";
        }
      }
    }

    &.instagram:not(.list),
    &.pinterest:not(.list) {
      .description {
        height: 44% @important;
      }

      .img {
        height: 56% @important;
      }
    }

    &.slideshare {
      > .img.visible:after {
        width: 100%;
        height: 50px;
        margin-top: -40px;
        position: relative;
        display: block;
        #gradient > .vertical(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.07));

        background-color: transparent;
        content: "";
      }
    }

    .hidden-cta-fields.fields-revealed {
      .cta-field-sprite-container {
        top: initial @important;
        bottom: 13px;
      }

      &.fields-revealed {
        .cta-field-sprite-container {
          bottom: 6px;
        }
      }
    }

    &:hover {
      .description h3 {
        color: @black @important;
      }

      > a.view {
        text-decoration: underline;
        background-color: #f8f8f8;
        &:after { background-position: -16px 0; }
      }
    }
  }

  &.links {
    > .description > h4 {
      position: absolute;
      width: 85%;
      width: ~'calc(100% - 40px)';
      z-index: 5;
    }
  }

  &.list {
    width: 100%;
    float: none;
    height: 110px;
    overflow: hidden;
    margin-bottom: 15px;
    background: #fdfdfd;

    > div.img {
      width: 25%;
      float: left;
      clear: none;
      height: 100% !important;
      overflow: hidden;
      background-color: #ddd;
      position: relative;

      > img {
        width: 100%;
        height: auto;
      }

      span.duration {
        font-size: 11px;
        bottom: 6px;
        right: 6px;
      }
    }

    > div.description {
      float: left;
      clear: none;
      width: 75%;
      overflow: hidden;
      height: 100%;
      position: relative;

      > .friendly-timestamp {
        margin-top: 5px;
        margin-left: 35px;
      }

      > img.avatar {
        margin-top: 2px;
      }

      h3,
      .h3like {
        margin-right: 45px;
        margin-bottom: 2px;

        &.avatar {
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }

      h4 {
        margin-right: 45px;
        font-size: 16px;
        line-height: 21px;
        max-height: 42px;
        overflow: hidden;

        &.avatar {
          line-height: 18px;
          max-height: 36px;
        }
      }

      &:after {
        display: block;
        height: 100%;
        width: 50px;
        position: absolute;
        right: 12px;
        top: 0;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAACACAYAAAD03Gy6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAsdJREFUeNrs3G1u2kAUheEQdRtdhXfUtLtIC7TbqPqxIC+hW6k7SEaKohLA4/E59847kuUoCCy9Ty6Df4TdNE0PS9c4jof5x8NDp2sYhqrnv6uMv3/xq24RatbjSvH3AGw0ASX+sZy+/OehPZPQeALeiM8ktAa4IT4IrQDuiA/C2ntAif+1nD4veG32hNoJqIjPJNQCrBAfhKUAK8YH4d49oMT/Vk7PDa7FnnBtAhrGZxKuAWwQH4RLAPN7/vOG1wXh1QT8Kcffja8NwhlgGIbf5fQBBOEeAILBpyAQDG7EQDC4EwZBDACCAQAIBgAgGACAYAAAggEACAYAIBgAvEB4AkEEMCP8AkEIAIIBwLxAEAOAYAAAggEACAYAIBgAgGAAAIIBAAgGACAYAJwRPoKgAzitnyBoAUAwAADBAAAEAwAQDABAMADoHuHRZBK7RXAB6BbBCaBLBDeA7hAcAc4In8oxZUdwBTitH/MkpEZwBugCwR0gPUIEgNQIUQDSIkQCSIkQDSAdQkSAVAhRAdIgRAZIgRAdIDxCBoDQKwPA6T82v5djt/F1j0xA8PjRAcLHjwyQIn5UgDTxIwKkih8NIF38SAAp40cBSBs/AkDq+O4A6eM7A3QR3xWgm/iOAF3FdwPoLr4TQJfxXQC6je8A0HV8NUD38ZUAxBcCEF8IQHwhAPGFAMQXAhBfCEB8IQDxhQDEFwIQXwhAfCEA8YUAxBcCEF8FMI4j8VUAxBcCEF8IQHwhAPGFAMQXAhBfCEB8IQDxhQDEFwIQXwhAfCEA8YUAxBcCEF8/Ae+JLwQYhuE4ByG+ag8oCIeNEIh/6VPQBgjEv3Yf0BCB+LfeCTdAIP49ACsjEH8JwEoIxK8BqEQg/hoACxGIvybAnQjEbwFwIwLxWwJcQSD+FgAXEIi/cO2mafnX7o/jeI7ebfzyx1j1/H8CDAARKo7z21s+vgAAAABJRU5ErkJggg==");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 50%;
        content: "";
        .opacity(30);
      }
    }

    &.with-img {
      > div.description {
        h4.long-h3 {
          display: block;
        }
      }
    }

    &.no-img {
      .description {
        width: 100%;

        > h3,
        > .h3like {
          font-size: 19px;
        }

        > h4 {
          font-size: 16px;
          line-height: 18px;
          color: #999;
          max-height: 36px;
          overflow: hidden;
        }
      }

      a.item-link.view {
        left: 0;
      }
    }

    a.item-link.view {
      text-indent: -9999px;
      width: 45px;
      border: 0;
      background-color: transparent;
      left: 25%;
      top: -3px;
      .opacity(50);
      .transform(scale(0.7));

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    a.item-link:not(.view) {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &.links {
      > .description > h4 {
        width: 87%;
        width: ~'calc(100% - 65px)';
      }
    }

    &:hover {
      .box-shadow(1px 1px 5px rgba(0, 0, 0, 0.2));

      > .description {
        h4 {
          color: #333;
        }
      }
    }
  }

  &.cta {
    .update-cta-placement-wrapper {
      display: none;
      position: absolute;
      bottom: 0;
      z-index: 1000;
      background: white;
      height: 100px;
      font-size: 11px;
      width: 100%;

      .done-cta-update {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }

      .repeat-after-active-wrapper {
        position: absolute;
        top: 15px;
        left: 10px;
        display: block;
        width: 135px;
        height: 30px;

        input[name='repeat-after-active'] {
          position: absolute;
        }

        label[for='repeat-after-active'] {
          position: absolute;
          left: 20px;
          top: 3px;
          width: 135px;
          display: block;
        }
      }

      .repeat-after-wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;

        .repeat-after {
          width: 28px;
          font-size: 13px;
        }

        .decrease-repeat-after,
        .increase-repeat-after {
          padding: 5px 5px;
          font-size: 13px;
          cursor: pointer;
          width: 20px;
          height: 30px;
          display: block;
        }
      }
    }
  }
}

html.silk {
  .tile {
    margin: 20px 0 0 10px;
  }
}

div.description {
  position: absolute @important;
  bottom: 0 @important;
  width: 100% @important;
  min-height: 70px;
  background-color: @darkBackground;

  h3 {
    color: @white @important;
    font-size: 18px @important;
    font-weight: 400 @important;
    margin: 17px 10px 3px 65px @important;
    line-height: 1.3 @important;
    word-wrap: break-word @important;
    text-transform: none @important;
  }

  h4 {
    color: rgba(255, 255, 255, 0.5) @important;
    font-weight: 300 @important;
    font-size: 16px @important;
    line-height: 1.2 @important;
    margin: 0 10px 15px 65px @important;
    word-wrap: break-word @important;
    text-transform: none @important;
  }

  .icon {
    background-color: @darkBackground;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 48px;
    margin: 15px 10px;

    &.spacer {
      display: none;
    }
  }
}

.no-img {
  div.description {
    .icon {
      &.spacer {
        display: block;
        position: relative;
        float: right;
        margin: 0;
        width: 80px;
        height: 80px;
        background: transparent @important;
      }
    }
  }
}

/* Items in Carousel should have a fixed size */

.carousel-inner {
  .tile,
  .tile.highlight {
    width: 250px !important;
    height: 330px !important;
    margin-right: 20px !important;
    margin-bottom: 0 !important;
  }
}

/* Hub/Stream Embed Tiles */

#embed-tile {
  &.tile {
    .box-shadow(none);

    &.single {
      width: 248px;
      height: 303px;
      border: 1px solid lightgrey;
    }
  }

  .tile-description {
    position: absolute;
    width: 230px;
    height: 30px;
    padding: 20px 10px 20px 10px;
    font-size: 14px;
  }

  .tile-description h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: grey;
    font-weight: 500;
    line-height: normal;
  }

  .tile-img {
    position: absolute;
    top: 25%;
    width: 248px;
    height: 230px;
    background-size: cover;
    background-position: center;
  }
}
