@import '../../shared/styles/variables';
@import '../../shared/styles/mixins';

.ufr-input-inner {
  // affect text input, number input, and textarea
  font-family: @roboto;

  .ufr-input-component {
    height: 100%;
    width: 100%;
    display: table-cell;
    border: solid 1px #ccc;
    border-radius: 4px;
    padding: 6px 12px;
    background-color: white;
    background-image: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    color: @fontColorDark;
    font-size: 16px;

    &:focus,
    &.ufr-input-error,
    &.ufr-input-success {
      border-color: transparent;
      outline: 0;
    }

    &:focus {
      .focus-outline;
    }

    &.ufr-input-error {
      box-shadow: 0 0 8px rgba(169, 68, 67, 0.6);
    }

    &.ufr-input-success {
      box-shadow: 0 0 8px rgba(60, 118, 61, 0.5);
    }

    &.ufr-input-component--disabled {
      background: #eee;
      color: @fontColorMedium;
    }
  }

  .ufr-input-component::placeholder {
    color: @fontColorPaleGrey;
    font-size: 16px;
    font-family: @roboto;
    font-weight: normal;
  }

  /**
  Prefixes and postfixes
   */
  &.ufr-prefixed-input,
  &.ufr-postfixed-input {
    display: table;

    .ufr-input-prefix {
      border-right: none;
      border-radius: 2px 0 0 2px;
    }

    .ufr-input-postfix {
      border-left: none;
      border-radius: 0 2px 2px 0;
    }
  }

  &.ufr-prefixed-input.ufr-postfixed-input {
    .ufr-input-component {
      border-radius: 0;
    }
  }

  &.ufr-prefixed-input {
    .ufr-input-component {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.ufr-postfixed-input {
    .ufr-input-component {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .ufr-input-prefix,
  .ufr-input-postfix {
    display: table-cell;
    padding: 5px 10px;
    height: 100%;
    width: 1%; // make the div shrink to fit content
    vertical-align: top;
    background: #eee;
    color: #555;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #ccc;
    text-align: center;
  }

  &.ufr-number-inner {
    height: 34px;

    .ufr-input-postfix,
    .ufr-input-prefix {
      width: initial;
    }

    &.ufr-postfixed-input .ufr-input-component {
      border-right: none;
    }

    input[type='number'] {
      padding: 6px 0 6px 11px;
    }

    input[type='number']::-webkit-inner-spin-button {
      opacity: 1;
      -webkit-appearance: none;
      background: #eee url('arrows.png') no-repeat 50% 50%;
      cursor: pointer;
      display: block;
      width: 22px;
      height: 34px;
      position: relative;
      right: 0;
    }

    input[type='number'].ufr-input-component--disabled::-webkit-inner-spin-button {
      background: none;
    }
  }
}

.ufr-input-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  .ufr-character-count {
    position: absolute;
    right: 0;
  }

  textarea {
    vertical-align: top; // remove ghost space
  }

  p {
    margin-top: 5px;
  }
}
