.inline-editable-text-input {
  display: inline-block;
  box-shadow: 0 0 8px #5bc0de;
  border-radius: 2px;
  outline: 0;
}
.inline-editable-text-input input {
  resize: horizontal;
  margin: -1px 0 0;
  color: #333;
  font-size: inherit;
  font-weight: inherit;
  border: none;
  background: none;
  max-width: 100%;
}
.inline-editable-text-input .input-width-control {
  position: absolute;
  white-space: nowrap;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}
.inline-editable-text-input.large-inline-input {
  height: 42px;
  max-width: calc(100% -  44px);
}
.inline-editable-text-input.large-inline-input input {
  padding: 0 8px;
  font-size: 16px;
  height: 42px;
}
.inline-editable-text-input.small-inline-input {
  height: 25px;
  max-width: calc(100% -  27px);
}
.inline-editable-text-input.small-inline-input input {
  padding: 0 8px;
  font-size: 13px;
  height: 25px;
}
.inline-editable-text-input .ufr-character-count {
  margin-top: 8px;
  margin-left: auto;
}
@media all and (max-width: 1000px) {
  .inline-editable-text-input input {
    max-width: 600px;
  }
}
