@import url('../../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-privacy-group-app-functionality-data-table .rt-table {
  overflow: unset;
}
#ufr-privacy-group-app-functionality-data-table .rt-table .rt-tbody {
  overflow: unset;
}
#ufr-privacy-group-app-functionality-data-table .rt-tr {
  align-items: inherit;
}
#ufr-privacy-group-app-functionality-data-table .rt-tr-group {
  margin-top: 15px;
}
#ufr-privacy-group-app-functionality-data-table .rt-tr-group .ufr-dt-privacy-groups-app-functionality-listing-app-name-cell .ufr-app {
  display: flex;
  align-items: center;
}
#ufr-privacy-group-app-functionality-data-table .rt-tr-group .ufr-dt-privacy-groups-app-functionality-listing-app-name-cell .ufr-app-image {
  background-size: cover;
  width: 24px;
  height: 24px;
  margin-right: 20px;
}
#ufr-privacy-group-app-functionality-data-table .rt-tr-group .ufr-dt-privacy-groups-app-functionality-listing-app-name-cell .ufr-app-name {
  max-width: 90%;
}
#ufr-privacy-group-app-functionality-data-table .rt-tr-group .ufr-dt-privacy-groups-app-functionality-listing-privacy-group-select-cell {
  padding-top: 0;
}
#ufr-privacy-group-app-functionality-data-table .rt-tr-group .ufr-dt-privacy-groups-app-functionality-listing-privacy-group-select-cell .ufr-privacy-group-selector {
  width: 300px;
}
