@import url('../../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-form-fields-field .ufr-input-container {
  padding: 0;
}
.ufr-form-fields-field .react-input-description {
  margin: 0 !important;
}
.ufr-form-fields-field .react-input-title {
  font-size: 14px !important;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ufr-form-fields-field .ufr-checkbox-wrapper {
  margin: 2px 0 0 0 !important;
}
.ufr-form-fields-field .ufr-checkbox:checked {
  background-color: #2d76ff !important;
  border-color: #2d76ff !important;
}
