.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-accordion .ufr-accordion-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d1;
}
.ufr-accordion .ufr-accordion-button:hover {
  cursor: pointer;
  background-color: #00000004;
  border-bottom: 1px solid #ce0058;
}
.ufr-accordion .ufr-accordion-button .ufr-accordion-title {
  color: #ce0058;
  font-size: 16px;
  margin: 15px 0 3px 3px;
  font-weight: 600;
}
