@import "../shared/styles/variables";

.ufr-image-uploader .ufr-image-cropper {
  margin-bottom: 15px;
  border: 1px solid @lightGreyBorder;
  text-align: right;

  > .ufr-crop-area {
    text-align: center;
    background: @lightGrey;
  }

  .ufr-btn + .ufr-btn {
    margin: 15px;
  }
}
