.tab-container {
  display: flex !important;
  height: 450px;
  padding: 0;
}
.tab-container ::-webkit-scrollbar {
  width: 8px;
}
.tab-container ::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}
.tab-container .cta-accordions {
  width: 50%;
  min-width: 330px;
  padding: 10px 15px 0px 10px;
  margin: 50px;
  overflow-y: auto;
}
.tab-container .preview-container {
  width: 50%;
  margin: 50px;
  justify-content: center;
}
.tab-container .preview-text {
  margin: 0;
  color: #666;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}
