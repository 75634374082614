@import '../shared/styles/variables';
@import "../../../../html/src/less/backend/hubs/hubs_mgmt.less";

#sidenav {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  width: 250px;
  z-index: 27;
  background: @lightGrey;
  box-shadow: @boxShadow;
  color: @fontColorDark;
  font-size: 13px;
  font-family: @roboto;
  overflow: hidden;
  transition: 0.3s;

  [role='button'] {
    cursor: pointer;
  }

  // Normalizing the css in core
  li,
  a {
    font-family: @plex;
    font-size: 13px;
    line-height: normal;
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .panel-heading {
    font-family: @plex;
  }
}

// Narrow sidenav
@media (max-width: 1300px) {
  #sidenav:not(:hover) {
    width: 60px;
    transition: 0.3s;
  }
}

// todo : make global
.uberpink-tooltip.tooltip {
  > .tooltip-inner {
    background-color: @uberPink;
  }

  &.top > .tooltip-inner,
  &.top .tooltip-arrow {
    border-top-color: @uberPink;
  }

  &.bottom > .tooltip-inner,
  &.bottom .tooltip-arrow {
    border-bottom-color: @uberPink;
  }

  &.left > .tooltip-inner,
  &.left .tooltip-arrow {
    border-left-color: @uberPink;
  }

  &.right > .tooltip-inner,
  &.right .tooltip-arrow {
    border-right-color: @uberPink;
  }
}
