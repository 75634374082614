@import "../shared/styles/variables";

.ufr-image-uploader {
  width: @eightColumns;
  font-family: @roboto;
  position: relative;

  .ufr-input-error-message {
    color: #d44545;
    font-size: 13px;
    line-height: 16px;
    position: absolute;
    font-family: @roboto;
  }

  .ufr-upload-control-left,
  .ufr-upload-control-right {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .ufr-upload-control-right {
    > span {
      margin-right: 15px;
    }

    .ufr-clearbit-search-box {
      display: inline-block;
      width: 275px;
    }
  }

  .ufr-footer-text {
    margin-top: 15px;
    font-size: 14px;
  }
}
