@import "../../shared/styles/base";
@import "../../shared/styles/mixins";

#ufr-enrichment-sources-import-summary-data-table {
  .rt-tbody {
    padding-top: 15px;
  }

  .rt-td {
    padding: 16px 0;
  }

  .rt-tr-group .rt-tr {
    min-height: 72px;
  }

  .ufr-dt-enrichment-sources-import-summary-leadsCreated-cell {
    white-space: inherit;

    .import-summary-title {
      padding-bottom: 10px;
    }

    .import-summary-prefix {
      font-weight: bold;
    }

    .import-summary-list {
      padding-left: 25px;

      li {
        list-style: disc;
      }
    }
  }
}
