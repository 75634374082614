@import '../../shared/styles/base';
@import '../../shared/styles/mixins';
@import '../../shared/styles/variables';

#ufr-tag-tagGroups-data-table {
  .ufr-control-bar-search > div {
    width: 278px;
    height: 41px;
    margin: 1px 0 0 16.5px;
    border-radius: 2px;
  }
}

.ufr-dt-tag-listing-tag-items-count-cell > a {
  font-weight: bold;
  color: @uberPink;
  cursor: pointer;
}
