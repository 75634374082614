.ufr-image-uploader .ufr-clearbit-search-box {
  margin-bottom: 13px;
  position: relative;
}
.ufr-image-uploader .ufr-clearbit-search-box .search-icon {
  position: absolute;
  top: 8px;
  left: 12px;
  opacity: 0.6;
}
.ufr-image-uploader .ufr-clearbit-search-box .search-input {
  height: 29px;
  width: 100%;
  font-size: 13px;
  text-indent: 32px;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #ccc;
}
.ufr-image-uploader .ufr-clearbit-search-box .search-input.ufr-input-component--disabled {
  background: #eee;
  color: #777;
}
.ufr-image-uploader .ufr-clearbit-search-box .dropdown-menu {
  width: 100%;
  max-width: 100%;
  display: block;
  top: 28px;
}
.ufr-image-uploader .ufr-clearbit-search-box .dropdown-menu img {
  height: 25px;
  width: 25px;
  margin-right: 12px;
}
.ufr-image-uploader .ufr-clearbit-search-box .dropdown-menu > li > a {
  padding: 6px 12px;
}
.ufr-image-uploader .ufr-clearbit-search-box .dropdown-menu > li > a .menu-text {
  font-size: 13px;
}
