.ufr-cta-preview-container {
  margin: 0;
}
.ufr-cta-preview-container .btn-link,
.ufr-cta-preview-container .btn,
.ufr-cta-preview-container button.btn {
  color: #333;
  font-size: 13px;
  line-height: 1;
  padding: 7px 8.5px;
}
.ufr-cta-preview-container .btn-link:active,
.ufr-cta-preview-container .btn:active,
.ufr-cta-preview-container button.btn:active,
.ufr-cta-preview-container .btn-link:focus,
.ufr-cta-preview-container .btn:focus,
.ufr-cta-preview-container button.btn:focus {
  outline: none;
  background-color: transparent;
}
.ufr-cta-preview-container .btn-link:active,
.ufr-cta-preview-container .btn:active,
.ufr-cta-preview-container button.btn:active {
  color: #ce0058;
}
.ufr-cta-preview-container .btn-link:focus,
.ufr-cta-preview-container .btn:focus,
.ufr-cta-preview-container button.btn:focus {
  color: #333;
}
.ufr-cta-preview-container .btn-link:hover,
.ufr-cta-preview-container .btn:hover,
.ufr-cta-preview-container button.btn:hover {
  color: #9c0042;
}
.ufr-cta-preview-popover {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.27);
  margin-left: 8px;
  max-width: 290px;
  padding: 16px;
  position: absolute;
  z-index: 1000;
}
.ufr-cta-preview-popover .arrow {
  display: none;
}
