.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#topnav .topnav-dropdown-item {
  position: relative;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}
#topnav .topnav-dropdown-item:hover {
  background: rgba(51, 51, 51, 0.15);
}
#topnav .topnav-dropdown-item:active,
#topnav .topnav-dropdown-item.topnav-item-active {
  color: #ce0058;
}
#topnav .topnav-dropdown-item > a {
  color: inherit;
  text-decoration: none;
}
#topnav .topnav-dropdown-item > a:hover {
  text-decoration: none;
}
#topnav .topnav-dropdown-item > a .react-avatar {
  position: absolute;
  left: 12px;
  top: 8px;
}
#topnav .topnav-dropdown-item > a .topnav-icon {
  position: absolute;
  left: 12px;
  top: 8px;
  font-size: 13px;
}
#topnav .topnav-dropdown-item > a .topnav-item-favicon {
  position: absolute;
  left: 12px;
  top: 8px;
  height: 14px;
  width: 14px;
}
#topnav .topnav-dropdown-item > a .topnav-dropdown-item-text {
  padding: 9px 12px 0 34px;
  height: 100%;
  line-height: 1.1;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#topnav .topnav-dropdown-item > a .topnav-dropdown-item-text.no-icon {
  padding-left: 12px;
}
#topnav .topnav-dropdown-item > a .topnav-icon-right + .topnav-dropdown-item-text {
  padding-right: 34px;
}
#topnav .topnav-dropdown-item > a .topnav-icon-right .react-icon {
  position: absolute;
  right: 8px;
  top: 7px;
  line-height: 1;
  font-size: 14px;
}
#topnav .topnav-dropdown-item.topnav-item-top-divider {
  border-top: 1px solid #cdcdcd;
}
#topnav.topnav-whitelabelled .topnav-dropdown-item:active,
#topnav.topnav-whitelabelled .topnav-dropdown-item.topnav-item-active {
  color: #2f96b4;
}
