@import '../shared/styles/variables';

.ufr-stream-page {
  display: flex;

  .ufr-stream-page-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .ufr-stream-page-publish {
      margin-bottom: 16px;
    }
  }

  #ufr-page-header.ufr-stream-page-header {
    flex-grow: 1;

    .ufr-internal-stream-name {
      margin-top: 16px;

      .ufr-stream-info {
        display: flex;

        .ufr-stream-published-status {
          padding-top: 2px;
        }

        .ufr-stream-id {
          padding: 8px 0 0 8px;
          margin-right: 37px;
        }
      }

      .editable-text-static-header {
        padding: 4px 0;
      }

      .ufr-page-header-title-internal {
        font-size: @pageTitleFontSize;

        #input-title {
          font-size: inherit;
        }
      }
    }

    .ufr-external-stream-name {
      margin-top: 16px;
      margin-bottom: 16px;
      padding-bottom: 8px;

      .ufr-page-header-title {
        margin: 0;
        padding: 4px 0;
        font-size: @pageTitleMediumFontSize;

        .editable-text-static-header {
          padding: 4px 0;
        }
      }

      .ufr-page-header-subtitle {
        margin: 0;
        padding: 4px 0;

        .editable-text-static-header {
          padding: 4px 0;
        }
      }
    }
  }
}
