@import url('../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-privacy-group-selector {
  position: absolute;
}
.ufr-privacy-group-selector .ufr-privacy-group-select-dropdown__menu > div {
  max-height: 150px;
}
.ufr-privacy-group-selector .ufr-privacy-group-select-dropdown__option--is-selected {
  background: none;
  color: #000;
}
.ufr-privacy-group-selector .ufr-privacy-group-select-dropdown__option--is-focused {
  background-color: #f9f9f9;
}
.ufr-privacy-group-selector .ufr-privacy-group-select-dropdown__option--is-disabled {
  color: #ce0058;
}
