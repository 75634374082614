@import url('../../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.ufr-linkables-modal {
  font-family: Roboto, sans-serif;
}
.ufr-linkables-modal .ufr-lang-linkables-area {
  margin-top: 30px;
  margin-right: -20px;
  min-height: 220px;
}
.ufr-linkables-modal .ufr-lang-linkables-area .ufr-shout-spinner {
  margin: 0 auto;
  position: relative;
  top: 55px;
}
.ufr-linkables-modal .ufr-modal-infotext {
  font-size: 12px;
  color: #777;
  top: 10px;
  margin: 7px 10px 0;
}
.ufr-linkable-items-modal .ufr-control-bar-search {
  width: 60%;
  display: inline-block;
  position: relative;
  left: -10px;
}
.ufr-linkable-items-modal #searchable-select-locale-ufr-input-container {
  width: 40%;
  display: inline-block;
}
.ufr-linkable-items-modal #searchable-select-locale-ufr-input-container .react-input-description {
  display: none;
}
