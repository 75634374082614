.ufr-media-upload-external-url {
  position: relative;

  button {
    border-radius: 3rem;
  }

  .ufr-external-url-body {
    margin: 0 20px;
  }

  .ufr-external-url-title {
    font-size: 2rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: #364a87;
    margin-block-start: 1.5em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .ufr-external-url-description {
    font-size: 1em;
    line-height: 1.5rem;
    margin: 2rem 0;
  }

  .ufr-external-url-input-outer-container {
    padding: 0.5rem;
    width: 600px;
    max-width: 80%;

    .ufr-svg-icon {
      stroke: currentColor;
      fill: currentColor;
    }

    .ufr-svg-icon.has-error {
      stroke: #d6401c;
      fill: #d6401c;
    }

    .ufr-svg-icon.invisible {
      visibility: hidden;
    }

    .ufr-input-fieldset-container {
      max-width: 100% !important;

      .ufr-input-fieldset {
        padding-top: 2px;
        display: flex;
        width: 100%;
        align-items: center;
        border-radius: 0.5rem;
        border: 1px #c9dae4 solid;
        background-color: #f8fbfd;
        padding: 0.5rem 1rem;

        .ufr-input-legend {
          width: auto;
          border: 0;
          margin: 0;
          padding: 2px;
          font-family: 'IBM Plex Sans', sans-serif;
          font-size: 11px;
          font-weight: 500;
          line-height: 0.75rem;
          color: #364a87;
        }

        .ufr-input-container {
          margin: 0.5rem 0;
          display: flex;
          width: 100%;
          align-items: center;

          .ufr-input-element {
            outline: none;
            flex-grow: 1;
            border-style: none;
            background-color: transparent;
            padding: 0 0.5rem;
            text-align: left;
            font-family: sans-serif;
            font-size: 2rem;
            font-weight: 400;
            line-height: 1rem;
            color: #030d36;
            border-style: none;
            font-size: 1.5rem;
            line-height: 1.5rem;
          }
        }
      }

      .ufr-input-fieldset.has-error {
        border-color: #d6401c;

        .ufr-input-legend.has-error {
          color: #d6401c;
        }
      }
    }

    .ufr-input-error-text {
      color: #d6401c;
      margin: 10px 0;
    }
  }

  .ufr-external-url-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    background-color: #f9f9f9;
    text-align: right;
  }
}
