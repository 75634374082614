@import '../../shared/styles/variables';

.ufr-form-section-header {
  min-height: 32px;
  margin: 24px 0 8px 0;

  div {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 4px;
    color: @fontColorDark;
    font-family: @roboto;
  }
}
