@import '../styles/variables';
@import '../styles/mixins';

.transfer-list {
  display: flex;
  gap: 34px;
  font-family: @roboto;
  font-size: 13px;

  .tl-box-col {
    display: flex;
    flex-direction: column;

    .tl-box-text {
      gap: 6px;
      margin-bottom: 12px;

      .tl-box-header {
        font-size: 16px;
        font-weight: bold;
        color: @fontColorDark;
      }

      .tl-box-description {
        font-size: 14px;
        color: @fontColorDark;
      }
    }

    .tl-box {
      display: flex;
      flex-direction: column;
      height: 385px;
      width: 367px;
      border: solid 1px @mediumGreyBorder;
      background-color: white;

      .tl-control-bar-box {
        padding: 15px 14px 0px 14px;

        .tl-control-bar {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          .tl-search-box {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            height: 41px;
            border: solid 2px @lightGreyBorder;
            border-radius: 4px;
            padding: 0px 22px;
            gap: 12px;

            .tl-search-icon {
              font-size: 16px;
              color: black;
            }

            .tl-search-input {
              all: unset;
              flex-grow: 1;
              color: @fontColorDark;
            }
          }

          .tl-button {
            all: unset;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 86px;
            height: 32px;
            border: solid 1px @mediumGreyBorder;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
              background-color: hsl(0, 0%, 95%);
            }

            &:disabled {
              background-color: hsl(0, 0%, 90%);
            }
          }
        }
      }

      .tl-row-box {
        margin: 9px 0px;
        overflow-y: auto;
        flex-grow: 1;
        scrollbar-width: thin;
        scrollbar-color: #bababa transparent;

        &::-webkit-scrollbar {
          background-color: transparent;
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: #bababa;
          border: solid 2px white;
        }

        .tl-no-data {
          display: flex;
          align-items: center;
          justify-content: center;
          color: @fontColorMedium;
          height: 100%;
        }

        .tl-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 42px;
          padding: 0 18px 0 14px;
          cursor: pointer;

          &:hover {
            background-color: rgba(216, 216, 216, 0.57);

            .tl-icon {
              visibility: visible;
            }
          }

          .tl-text {
            color: @fontColorDark;
          }

          .tl-icon {
            color: @uberPink;
            visibility: hidden;
          }
        }
      }
    }
  }
}
