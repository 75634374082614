//topnav vars
@import './variables.less';

.topnav-dropdown {
  position: relative;
  display: inline-block;
  color: inherit;

  &:hover .topnav-item-button:not(.topnav-item-button-active) {
    background: @hoverBackground;
    color: white;
  }

  .topnav-link-active .topnav-item-button-active,
  &:active .topnav-link .topnav-item-button {
    background: @activeBackground;
    color: white;
  }

  .topnav-dropdown-content {
    display: none;
    position: absolute;
    min-width: 100%;
    max-width: 300px;
    background: white;
    color: #333;
    border-color: #cdcdcd;
    border-width: 0 1px 1px;
    border-style: solid;
    padding: 0;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.topnav-dropdown-right {
    float: right;
    min-width: 147px;

    .topnav-dropdown-content {
      right: 0;
    }

    > .topnav-link {
      width: 100%;
    }
  }

  &:hover .topnav-dropdown-content {
    display: block;
  }

  li {
    margin-bottom: 0;
  }

  .ufr-nav-item-disabled {
    pointer-events: none;
  }
}
