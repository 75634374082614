@import "../../shared/styles/variables";

input[type=text].ufr-custom-labels-input {
  width: 348px;
  max-width: 100%;
  font-family: @roboto;
  font-size: 13px;
  font-weight: 400;
  color: #333;
  padding: 6px 12px;

  &[disabled] {
    background: #eee;

    &:hover {
      cursor: not-allowed;
    }
  }
}
