.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-checkbox-input-container.ufr-checkbox-input-container.ufr-checkbox-input-container#sfi-uf-activity-ufr-input-container {
  padding-top: 0;
}
.ufr-checkbox-input-container .ufr-checkbox-wrapper {
  align-items: center;
  display: flex;
  margin: 10px 0 5px;
}
.ufr-checkbox-input-container .ufr-input-wrapper {
  position: static;
}
.ufr-checkbox-input-container.ufr-checkbox-input-container.ufr-checkbox-input-container label.react-input-title {
  margin-bottom: 0;
  margin-left: 14px;
}
.ufr-checkbox-input-container .ufr-checkbox-inner {
  display: inline-block;
}
.ufr-checkbox-input-container .ufr-checkbox {
  margin: 5px 0 0 0;
  padding: 0;
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  position: relative;
}
.ufr-checkbox-input-container .ufr-checkbox:checked {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.ufr-checkbox-input-container .ufr-checkbox:checked:after {
  font-family: "Glyphicons Regular", sans-serif;
  content: "\E207";
  font-size: 9px;
  position: absolute;
  top: -1px;
  left: 1px;
  color: white;
}
.ufr-checkbox-input-container .ufr-checkbox.ufr-color-picker-input--disabled {
  opacity: 0.6;
}
.ufr-checkbox-input-container .ufr-checkbox:focus {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.ufr-checkbox-input-container--modal label.react-input-title {
  font-size: 13px;
}
.ufr-checkbox-input-container--modal .ufr-checkbox {
  top: 1px;
}
