.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.success-cta-accordion-content {
  margin-top: 20px;
}
.success-cta-accordion-content .ufr-input-container {
  padding: 0 0 0 0 !important;
}
.success-cta-accordion-content .ufr-input-container .react-input-title {
  font-size: 13px;
  font-weight: 500;
}
.success-cta-accordion-content .ufr-input-container .ufr-input-wrapper textarea {
  margin-top: 5px;
}
.success-cta-accordion-content .ufr-input-container .react-input-description {
  margin-bottom: 0px;
}
.success-cta-accordion-content .ufr-input-container .ufr-textarea-inner .ufr-input-component {
  width: 100%;
}
.success-cta-accordion-content .success-cta-success-message .ufr-textarea-inner .ufr-character-count {
  min-width: 20px;
  right: 2px;
  top: 40px;
  background-color: white;
  color: #b7b7b7;
}
.success-cta-accordion-content .success-cta-checkbox {
  width: 94%;
  margin-left: 10px;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content {
  border-left: solid 2px #eee;
  margin: 8px 0px 0px 5px;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content #link-destination-link-to-content-ufr-input-container {
  z-index: 1;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input .react-input-title {
  margin-bottom: 4px;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input .link-destination-text-note {
  display: flex;
  margin-left: 5px;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input .link-destination-text-note .glyphicon-info-sign {
  color: #a4a4a4;
  top: 12px;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input,
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-button-label-text-area {
  margin-left: 10px;
  position: relative;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input .find-item-link,
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-button-label-text-area .find-item-link {
  position: absolute;
  z-index: 100;
  padding-bottom: 2px;
  font-size: 13px;
  color: #ce0058;
  margin-left: 212px;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input .find-item-link:hover,
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-button-label-text-area .find-item-link:hover {
  cursor: pointer;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input .link-destination-note,
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-button-label-text-area .link-destination-note {
  width: 85%;
  font-size: 13px;
  color: #999;
  margin: 10px 0px 10px 7px;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input .ufr-input-container,
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-button-label-text-area .ufr-input-container {
  margin-bottom: 5px;
}
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-destination-text-input .ufr-input-container .ufr-input-component,
.success-cta-accordion-content .success-cta-checkbox .link-to-content-checked-content .link-button-label-text-area .ufr-input-container .ufr-input-component {
  width: 100%;
}
.success-cta-accordion-content .success-cta-checkbox .ufr-textarea-inner .ufr-character-count {
  min-width: 20px;
  right: 2px;
  top: 21px;
  background-color: white;
  color: #b7b7b7;
}
.success-cta-accordion-content .success-cta-checkbox .never-hide-cta {
  display: inline-flex;
}
.success-cta-accordion-content .success-cta-checkbox .never-hide-cta .ufr-input-container.ufr-checkbox-input-container {
  width: 100%;
}
.success-cta-accordion-content .success-cta-checkbox .never-hide-cta .glyphicon {
  padding-left: 3px;
  top: 15px;
  font-size: 11px;
}
.success-cta-accordion-content .success-cta-checkbox .never-hide-cta .glyphicon:hover {
  cursor: pointer;
}
