@import "../../../shared/styles/base";
@import "../../../shared/styles/mixins";

#ufr-privacy-group-functionality-data-table {
  .rt-table {
    overflow: unset;

    .rt-tbody {
      overflow: unset;
    }
  }

  .rt-tr {
    align-items: inherit;
  }

  .rt-tr-group {
    margin-top: 15px;

    .ufr-service {
      display: flex;
      align-items: center;

      .ufr-service-icon {
        margin-right: 20px;
      }

      .ufr-service-name {
        font-weight: 700;
      }
    }

    .ufr-dt-privacy-groups-functionality-listing-service-name-cell {
      padding: 8px;
    }

    .ufr-dt-privacy-groups-functionality-listing-privacy-group-select-cell {
      padding-top: 0;

      .ufr-privacy-group-selector {
        width: 450px;
      }
    }
  }
}

