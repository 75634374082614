@import '../shared/styles/variables';
@import '../shared/styles/base.less';

* {
  box-sizing: border-box;
  margin: 0;
}

.responsive {
  height: 100%;
  width: 100%;
}

.carousel-container {
  margin: 10px 0 10px 0;
  position: relative;
  overflow: hidden;
}

.carousel-list {
  height: 100%;
  padding: 0;
}

.carousel-list-item {
  position: absolute;
  transition: all 0.3s;
}

.carousel-list-item.override-animation {
  transition: none !important;
}

.carousel-slider-container {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
}

.inactive-item {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.clickable-area {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 9999;
}
