@import url('../../../../../../html/src/css/static/google-fonts/roboto.css');
@import url('../../../../../../html/src/css/static/google-fonts/ibm-plex-sans.css');
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
html body {
  min-width: 700px;
}
html body.layout-login {
  min-width: 100%;
}
html body input {
  font-size: 12.5px;
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-accordion-title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .react-input-description,
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .react-input-title {
  display: none;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .ufr-input-container {
  padding: 0;
  display: inline;
  width: 90%;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .ufr-input-container #opt-in-url {
  margin-left: 10px;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .ufr-input-container .ufr-checkbox-wrapper,
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .ufr-input-container .ufr-input-inner,
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .ufr-input-container .ufr-input-wrapper {
  display: inline;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .ufr-opt-in-fields {
  display: flex;
  align-items: center;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-opt-in .ufr-opt-in-fields .ufr-checkbox-input-container {
  width: auto;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-progressive-profiling .ufr-input-container {
  padding: 0;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-progressive-profiling .ufr-input-container .react-input-description {
  display: none;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-progressive-profiling .ufr-input-container .react-input-title {
  color: #cecece;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-form-field-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin: 20px 0 5px 0;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-form-field-header a.manage-form-fields {
  color: #ce0058;
  line-height: 16px;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-form-field-header a.manage-form-fields:hover,
#ufr-form-field-accordion .ufr-form-field-body .ufr-form-field-header a.manage-form-fields:active,
#ufr-form-field-accordion .ufr-form-field-body .ufr-form-field-header a.manage-form-fields:focus {
  outline: 0;
  text-decoration: none;
  color: #9c0042;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-form-field-header p {
  margin: auto 0 auto 0;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-form-field-fields {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-right: 2%;
}
#ufr-form-field-accordion .ufr-form-field-body .ufr-field-title {
  font-size: 13px;
  font-weight: 500;
  color: #555;
}
