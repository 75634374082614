@import '../../shared/styles/variables';

#place-cta-modal {
  .ufr-modal-body {
    max-height: 65vh;

    .glyphicons:before {
      color: #999;
    }
  }

  .search-input {
    float: left;

    .glyphicons-search {
      position: relative;
      left: 29px;
      top: 38px;
      display: block;
      z-index: 1000;
      color: #999;
      width: 30px;
    }
  }

  .cta {
    height: 293px;
    width: 225px;

    .hidden-cta-fields {
      top: 100%;
    }

    > a {
      width: 65%;
    }
  }

  .no-ctas-found {
    margin: 150px;
    text-align: center;
    font-weight: bold;
  }

  .carousel-container {
    margin: 100px auto 0;
  }

  .selected-cta-info {
    width: 225px;
    margin: 10px auto 0;

    .name {
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      margin: 15px 0 5px 0;
    }

    .modified {
      color: @fontColorDark;
      margin-bottom: 5px;
    }

    .id {
      color: @fontColorDark;
      margin-bottom: 0;
    }
  }

  #searchable-select-place-cta-types-ufr-input-container {
    width: 180px;
    margin: 0;
    padding: 0;
    float: left;

    label {
      font-size: 12px;
    }

    #searchable-select-place-cta-types-input-description {
      display: none;
    }

    .ufr-dropdown-selected-item {
      font-size: 14px;
    }

    .ufr-dropdown-button {
      height: 33px;

      .glyphicons {
        margin-top: 3px;
      }
    }

    .ufr-select-dropdown {
      width: 182px;
    }

    .ufr-select-dropdown-item-container {
      width: 180px;

      .ufr-select-dropdown-item {
        font-size: 14px;
      }
    }
  }

  #place-cta-search-ufr-input-container {
    width: 350px;
    margin: 11px 0 0 20px;
    padding: 0;
    float: left;

    #place-cta-search-input-description {
      display: none;
    }

    #place-cta-search {
      width: 100%;
      font-size: 14px;
      padding-left: 30px;
    }
  }
}
