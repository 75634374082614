@import '../../shared/styles/variables';
@import '../../shared/styles/mixins';

@colorPickerHeight: 32px;

.ufr-color-picker .ufr-color-picker-input {
  width: 94px;
  height: @colorPickerHeight;
  border-radius: 2px;
  display: block;
  align-items: unset;
  border: none;
  padding: 0;
  margin: 0;

  &:focus {
    .focus-outline;
  }

  .ufr-color-picker-preview {
    display: inline-block;
    width: 69px;
    height: @colorPickerHeight;
    border: solid 1px #ccc;
    border-radius: 2px 0 0 2px;
    background-color: #fff;
    padding: 8px;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  .ufr-color-picker-dropdown-btn {
    display: inline-block;
    width: 26px;
    height: @colorPickerHeight;
    border: solid 1px #ccc;
    border-radius: 0 2px 2px 0;
    background-color: @lightGrey;
    margin-left: -1px;
    vertical-align: top;

    .dropdown-icon {
      position: relative;
      font-size: 8px;
      top: 12px;
    }
  }
}

.ufr-color-picker-popover {
  position: absolute;
  z-index: 2;
  padding-top: 2px;
}

.ufr-color-picker-overlay {
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ufr-color-picker-input.ufr-color-picker-input--disabled {
  .ufr-color-picker-preview {
    background: #eee;
    color: #777;
  }

  * {
    pointer-events: none;
  }

  &:hover {
    cursor: not-allowed;
  }
}

.ufr-color-picker-cta {
  display: flex;

  .ufr-color-picker-label-cta {
    flex-grow: 1;
    margin: 3px 38px 4px 0;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #555;
  }

  .ufr-color-picker-hex-cta {
    width: 80px;
    height: 32px;
    border-radius: 3px;
    border: solid 1px #979797;
    text-transform: uppercase;
  }

  .ufr-color-picker-input-cta {
    width: 32px;
    height: 32px;
    border: none;
    padding: 0px;
    border-radius: 3px;
    margin-left: 7px;

    &:focus {
      .focus-outline;
    }

    .ufr-color-picker-preview-cta {
      width: 32px;
      height: 32px;
      border: solid 1px #979797;
      border-radius: 3px;
      background-color: #fff;

      > div {
        width: 100%;
        height: 100%;
      }
    }
  }

  .ufr-color-picker-popover-cta {
    position: absolute;
    z-index: 2;
    padding-top: 22px;
    padding-left: 135px;
  }
}
