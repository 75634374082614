#topnav {
  position: relative;
  width: 100%;
  height: 40px;
  background: #ce0058;
  color: white;
}
#topnav.topnav-whitelabelled {
  background: #f7f7f7;
  color: #333;
}
