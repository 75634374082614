@import "../../../shared/styles/base";
@import "../../../shared/styles/mixins";

#ufr-streams-data-table,
#ufr-archived-streams-data-table {
  .ufr-dt-streams-listing-stream-name-cell,
  .ufr-dt-streams-listing-stream-owner-cell {
    display: flex;
    align-items: center;
  }

  .ufr-dt-archived-streams-listing-buttons-cell {
    justify-content: flex-start;

    .ufr-btn-icon {
      opacity: 1;
    }
  }

  .ufr-stream-internal-name a {
    text-decoration: none;
    font-weight: bold;
    color: inherit;
  }

  .ufr-stream-internal-name,
  .ufr-stream-owner {
    padding-left: 8px;
  }

  .ufr-datatable-thumbnail {
    display: block;
  }

  .ufr-datatable-thumbnail-hidden {
    background-color: rgba(51, 51, 51, 0.5);
    position: relative;

    > .ufr-datatable-thumbnail {
      opacity: 0.5;
    }

    &:before {
      content: 'Hidden';
      background: @white;
      padding: 0 3px;
      position: absolute;
      right: 0;
      z-index: 1;
      font-size: 10px;
      font-weight: 700;
      color: @gray;
      line-height: 13px;
      margin: -1px;
    }
  }

  .ufr-stream-internal-name,
  .ufr-stream-title {
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    word-wrap: break-word;
  }

  .ufr-modal-header-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  #ufr-stream-dependencies-error {
    font-family: @roboto;

    .ufr-stream-dependency {
      margin-bottom: 10px;

      ul li {
        list-style: disc;
        margin-left: 24px;
      }
    }
  }

  .rt-noData {
    #ufr-no-data-create-stream-btn {
      margin-top: 22px;
    }
  }
}

.ufr-about-hub-modal .ufr-modal-body {
  overflow: hidden;
}

#ufr-internal-stream-name-tooltip,
#ufr-stream-title-tooltip {
  max-width: 100%;
  word-break: break-all;
}
