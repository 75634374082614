
@import '../../shared/styles/variables.less';
@import '../../shared/styles/mixins.less';

@import '../../shared/button/button.less';

.cta-placement-font-styling {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555;
}

#edit-cta-placement-tab {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 32px 9px 0 9px;

  .cta-placement-tab-title {
    margin: 0 0 39px 0;
    font-size: 16px;
    .cta-placement-font-styling
  }

  .cta-placement-tab-container {
    min-height: 0;
    overflow-y: auto;
    margin-bottom: 39px;
  }

  .ufr-shout-spinner {
    height: 30px;
    width: 30px;
    margin: auto;
  }

  .cta-placement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    & > * {
      flex: 1;
    }

    &:last-child {
      margin-bottom: 39px;
    }

    .cta-placement-title {
      display: flex;
      flex-direction: row;

      .sprite-container {
        overflow: hidden;
        padding-top: 2px;
        margin-right: 6px;
      }

      .cta-placement-title-name:extend(.cta-placement-font-styling) {
        font-size: 13px;
      }

      .cta-placement-title-type:extend(.cta-placement-font-styling) {
        font-size: 11px;
        font-weight: normal;
      }
    }

    .cta-placement-remove-container {
      display: flex;

      .cta-placement-remove {
        display: flex;
        flex: 0 1 auto;
        align-items: center;
        color: @uberPink;
        outline: none;

        &:hover {
          cursor: pointer;
          color: @uberPinkDark;
        }

        > .glyphicons {
          margin-right: 2px;
        }
      }
    }
  }

  .add-new-placement-btn a {
    .ufr-btn;
    .ufr-btn-primary;
  }
}
