@import '../../shared/styles/mixins';

.ufr-radio-button-input {
  .ufr-radio-button {
    margin-bottom: 10px;
    overflow: visible;

    input {
      margin: 0 10px 0 0;
      overflow: visible;

      &:focus {
        outline: initial;
        outline-offset: initial;
      }
    }

    label {
      font-weight: 400;
      margin-bottom: 0;
      font-size: 1em;
      cursor: pointer;
    }
  }
}
