.uf-app-icon {
  text-align: center;
  line-height: 1;
  display: inline-block;
  overflow: hidden;
}
.uf-app-icon img {
  display: block;
  height: 100%;
}
